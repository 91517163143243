import React, { useState, useEffect } from 'react';
import {
  Drawer,
  Typography,
  Box,
  Button,
  Table,
  TableHead,
  TableContainer,
  TableBody,
  TableCell,
  TableRow,
  CircularProgress,
  Checkbox,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TextField,
  FormControl,
} from '@material-ui/core';
import LibraryAddCheckIcon from '@material-ui/icons/LibraryAddCheck';
import PropTypes, { element } from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import MaterialTable, { MTableToolbar } from 'material-table';
import WaveLines from './WaveLines';
import materialTableLocaleES from '../../../components/MaterialTableLocaleES';

// Components
import { getWaveInventory, registerWave, verifyStockAgain, verifyPassword, removePiecesFromWave } from '../../../api/WaveServices/WavesApi';
import SnackBarMessage from '../../../components/SnackBarMessage';
import { getPermissions } from '../../../api/AuthApi';
import {useSnackbar} from "notistack";

const drawerWidth = 1200;
const useStyles = makeStyles(theme => ({
  container: {
    width: drawerWidth,
  },
  containerPaper: {
    width: drawerWidth,
    padding: 20,
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  formControlTableToolBar: {
    margin: theme.spacing(0),
    marginLeft: theme.spacing(50),
    marginTop: -theme.spacing(7),
    marginRight: theme.spacing(2),
    minWidth: 220,
    maxWidth: 10,
  },
  smallColor: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  buttonValidate: {
    left: '40%',
  },
  messagePwd: {
    textAlign: 'center',
  },
}));

const initialState = {
  orderGroupId: 0,
  waveData: [],
  updated_at: '',
  total_ask: 0,
  total_stock: 0,
  total_diff: 0,
  percentage_available: 0,
  statusWave: 0,
};

export default function WaveInventory({
  formOpen,
  toggleForm,
  refreshTable,
  dataObject,
  waveRef,
  pickingEnabled,
  waveId,
}) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [openLinesDrawer, setOpenLinesDrawer] = useState(false);
  const [dataLinesDrawer, setDataLinesDrawer] = useState(0);
  const [form, setForm] = useState(initialState);
  const [closeClick, setCloseClick] = useState('');
  const tableRef = React.createRef();
  const [selectedSkus, setSelectedSkus] = useState([]);
  const [selectedButton, setSelectedButton] = useState(true);
  const [password, setPassword] = useState([]);
  const [checkedPassword, setCheckedPassword] = useState(true);
  const [messagePassword, setMessagePassword] = useState('');
  const [openPasswordDialog, setOpenPasswordDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [userId, setUserId] = useState([]);

  const messageError = (message, variant) => {
    enqueueSnackbar(message, {
        variant,
      });
  };
  const messageSuccess = (message, variant) => {
      enqueueSnackbar(message, {
          variant,
      });
  };

  useEffect(() => {
    getPermissions().then(response => {
        setUserId(response[0].pivot.model_id);
      }).catch(e => {
          console.log(e);
      })
  }, []);

  const clearData = () => {
    setForm(initialState);    
    setCloseClick('');
    setIsLoading(true);
  };

  const toggleLinesDrawer = () => {
    setDataLinesDrawer(0);
    setOpenLinesDrawer(!setOpenLinesDrawer);
  };

  const downloadCsv = (data, fileName) => {
    const finalFileName = fileName.endsWith(".csv") ? fileName : `${fileName}.csv`;
    const a = document.createElement("a");
    a.href = URL.createObjectURL(new Blob([data], { type: "text/csv" }));
    a.setAttribute("download", finalFileName);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const initData = () => {
    setIsLoading(true);
    getWaveInventory(dataObject)
      .then(response => {
        setForm({
          waveData: response.lines,
          updated_at: response.updated_at,
          initial_stock: response.initial_stock,
          total_ask: response.total_ask,
          total_stock: response.total_stock,
          total_prepacks: response.total_prepacks,
          total_diff: response.total_diff,
          percentage_initial: response.percentage_initial,
          percentage_available: response.percentage_available,
          devolution: response.devolution,
          statusWave: response.statusWave
        });
        setSelectedSkus([]);
        setSelectedButton(true);
        console.log(selectedSkus);
        setIsLoading(false);
      })
      .catch(error => {
        console.log(error);
        setIsLoading(false);
      });
    setCloseClick(false);
  };

  const handleRequestPicking = () => {
    setIsLoading(true);
    registerWave(dataObject)
      .then(response => {
        setIsLoading(false);
        toggleForm();
      })
      .catch(error => {
        console.log(error);
        setIsLoading(false);
      });

    setCloseClick(false);
  };

  const handleVerifyStock = () => {
    setIsLoading(true);
    verifyStockAgain(dataObject)
      .then(response => {
        setIsLoading(false);
        toggleForm();
      })
      .catch(error => {
        console.log(error);
        setIsLoading(false);
      });

    setCloseClick(false);
  };

  const handleSubmitSkus = () => {    
    setOpenPasswordDialog(true);
  }

  const handleChangePassword = (event) => {
    setPassword(event.target.value);
  };

  const handlePasswordDialog = () => {
    setOpenPasswordDialog(false);
    setMessagePassword('');
    setCheckedPassword(true);
  };

  const checkPassword = () => {
    verifyPassword(userId, password)
      .then(response => {
          setCheckedPassword(response.status);
          setMessagePassword(response.message);
          setLoading(false);
      })
      .catch(() => {
        console.log('errors');
        setLoading(false);
      });
  };

  const handleSubmit = () => {
    setLoading(true);
    setMessagePassword('');
    removePiecesFromWave(waveRef, selectedSkus)
      .then(response => {
        if (response.status == 200) {
          setLoading(false);
          messageSuccess(response.message, 'success');
          setOpenPasswordDialog(false);
          setCheckedPassword(true);
          setSelectedButton(true);
          toggleForm();
        }
      })
      .catch(() => {
        messageError('Error al procesar la solicitud', 'error');
        console.log('errors');
        setLoading(false);
        setOpenPasswordDialog(false);
        setCheckedPassword(true);
        setSelectedButton(true);
        toggleForm();
      });
  };

  return (
    <Box>      
      <WaveLines
        formOpen={openLinesDrawer}
        toggleForm={toggleLinesDrawer}
        dataObject={dataLinesDrawer}
        waveRef={waveRef}
        waveId={waveId}
      />
      <Drawer
        anchor="right"
        open={formOpen}
        onClose={toggleForm}
        className={classes.container}
        classes={{ paper: classes.containerPaper }}
      >        
        <Box display="flex" flexDirection="row">
          <Button
            variant="contained"
            color="primary"
            style={{ marginRight: 50, borderRadius: 20, color: 'white' }}
            size="small"
            disabled={!pickingEnabled || isLoading || form.statusWave == 5}
            onClick={handleRequestPicking}
          >
            <LibraryAddCheckIcon />
            Solicitar picking en SAALMA
            {isLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
          </Button>
          <Typography>{`Fecha de la información: ${form.updated_at}`}</Typography>
          <Button
            variant="contained"
            color="secondary"
            style={{ marginLeft: 50, borderRadius: 20, color: 'white' }}
            size="small"
            disabled={!pickingEnabled || isLoading || form.devolution || form.statusWave == 5}
            onClick={handleVerifyStock}
          >
            <LibraryAddCheckIcon />
            Nuevo Check de Inventario
            {isLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
          </Button>
        </Box>
        <Box>
          <TableContainer>
            <Table aria-label="Resumen">
              <TableHead>
                <TableRow>
                  <TableCell align="right">Total P. solicitadas</TableCell>
                  <TableCell align="right">Total P. en stock (inicial)</TableCell>
                  <TableCell align="right">Total P. en stock</TableCell>
                  <TableCell align="right">Total prepacks</TableCell>
                  <TableCell align="right">Total diferencia</TableCell>
                  <TableCell align="right">Porcentaje Disponible (inicial)</TableCell>
                  <TableCell align="right">Porcentaje Disponible</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell align="right">{form.total_ask}</TableCell>
                  <TableCell align="right">{form.initial_stock}</TableCell>
                  <TableCell align="right">{form.total_stock}</TableCell>
                  <TableCell align="right">{form.total_prepacks}</TableCell>
                  <TableCell align="right">{form.total_diff}</TableCell>
                  <TableCell
                    align="right"
                    style={{ color: form.percentage_initial > 85 ? 'green' : 'red' }}
                  >
                    {`${form.percentage_initial}%`}
                  </TableCell>
                  <TableCell
                    align="right"
                    style={{ color: form.percentage_available > 85 ? 'green' : 'red' }}
                  >
                    {`${form.percentage_available}%`}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        <MaterialTable
          tableRef={tableRef}
          columns={[
            { 
              title: 'Sku',
              field: 'sku',
              render: rowData => (
                <Button
                  key={rowData.sku}
                  href="#text-buttons"
                  color="primary"
                  onClick={(event, rowDataBtn) => {
                    setDataLinesDrawer(rowData.variation_id);
                    setOpenLinesDrawer(!openLinesDrawer);
                  }}
                >
                  {`${rowData.sku}`}
                </Button>
              )
            },
            { title: 'Estilo', field: 'style.style', type: 'numeric' },
            { title: 'Número de Tiendas', field: 'stores', type: 'numeric' },
            { title: 'PPK', field: 'ppk', type: 'numeric' },
            { title: 'Piezas solicitadas', field: 'ask_pieces', type: 'numeric' },
            { title: 'Piezas en stock', field: 'in_stock', type: 'numeric' },
            { title: 'Prepacks', field: 'prepacks', type: 'numeric' },
            {
              title: 'Diferencia',
              field: 'difference',
              defaultSort: 'desc',
              type: 'numeric',
              render: rowData => {
                let color = 'green';
                let sign = '+';
                const dif = Math.abs(rowData.difference);
                if (rowData.difference === 0) {
                  sign = '';
                }
                if (rowData.difference > 0) {
                  color = 'red';
                  sign = '-';
                }
                return <Typography style={{ color }}>{`${sign}${dif}`}</Typography>;
              },
            },
          ]}
          title={`Ola ${waveRef}: Distribución general`}
          isLoading={isLoading}
          localization={materialTableLocaleES}         
          data={form.waveData}
          components={{
            Toolbar: props => (
              <div>
                <MTableToolbar {...props} />
                <FormControl className={classes.formControlTableToolBar}>
                 <Button
                    variant="contained"
                    color="primary"
                    style={{marginTop: 10, borderRadius: 20, color: 'white' }}
                    size="small"
                    disabled={selectedButton}
                    onClick={handleSubmitSkus}
                  >
                    Remover SKU(s) de la ola
                    {isLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
                  </Button>
                </FormControl>
              </div>
            )
          }}
          options={{
            selection: true,
            showSelectAllCheckbox: false,
            selectionProps: rowData => ({
              disabled: (form.statusWave == 5 && rowData.pieces_in_carton == 0) ? false:true,
              color: 'primary'
            }),
            pageSize: 10,
            search: true,
            padding: 'dense',
            actionsColumnIndex: -1,
            pageSizeOptions: [5, 10, 20, 50, 100],
            exportButton: true,
            exportCsv: (columns, rowData) => {
              let data = [];
              rowData.forEach( function(value, key, array) {
                  let sign = '+';
                  const dif = Math.abs(value.difference);
                  if (value.difference === 0) {
                      sign = '';
                  }
                  if (value.difference > 0) {
                      sign = '-';
                  }
                  data[key] = [
                      value.sku,
                      value.style.style,
                      value.stores,
                      value.ppk,
                      value.ask_pieces,
                      value.in_stock,
                      value.prepacks,
                      sign + dif,
                  ]
              });
              const headerRow = columns.map(col => {
                if (typeof col.title === 'object') {
                  return col.title.props.text;
                }
                return col.title;
              });
                const { exportDelimiter } = tableRef.current.props.options;
                const delimiter = exportDelimiter ? exportDelimiter : ",";
                const csvContent = [headerRow, ...data].map(e => e.join(delimiter)).join("\n");
                const csvFileName = tableRef.current.props.title;
                downloadCsv(csvContent, csvFileName);
              }
          }}
          onSelectionChange={(rowData) => {
            let checked = [];
            for (var i = 0; i < rowData.length; i++) {
              let checkbox = rowData[i];
              if (checkbox.sku != null) checked.push(checkbox.sku);
            }
            if(checked.length == 0) {
              setSelectedButton(true)
            }else {
              setSelectedButton(false);
            }
            setSelectedSkus(checked);
          }}
        />

        <Dialog
            disableBackdropClick
            disableEscapeKeyDown
            maxWidth="xs"
            onEntering={undefined}
            aria-labelledby="confirmation-dialog-title"
            open={openPasswordDialog}
          >
            <DialogTitle id="confirmation-dialog-title">{`Remover piezas de la Ola - ${waveRef}`}</DialogTitle>
            <DialogContent dividers>
              <DialogContentText id="alert-dialog-description">
                Para continuar con el proceso, ingrese su contraseña.
              </DialogContentText>
              <TextField
                id="input-password"
                name="input-password"
                fullWidth
                type="password"
                onChange={handleChangePassword}
                ></TextField>
              <Button className={classes.buttonValidate} color="primary" onClick={checkPassword}>Validar</Button>
              <Typography className={classes.messagePwd} style={checkedPassword == false ? { color: 'green'} : {color: 'red'} } variant="caption" display="block" gutterBottom>
                { messagePassword }
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button autoFocus onClick={handlePasswordDialog} color="primary">
                Cerrar
              </Button>
              <Button onClick={handleSubmit} color="primary" disabled={checkedPassword || loading}>
                Enviar
                {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
              </Button>
            </DialogActions>
          </Dialog>

        <Box component="form" className={classes.form}>
          <Box />
          <Box className={classes.bottomSpace}>
            {formOpen && closeClick === '' && initData()}
            {!formOpen && closeClick !== '' && clearData()}
          </Box>
        </Box>
      </Drawer>

      <SnackBarMessage
        open={form.policyAccessError}
        message={form.roleAccessMessage}
        onClose={() => setForm({ ...form, policyAccessError: false })}
        variant="warning"
      />
    </Box>
  );
}

WaveInventory.defaultProps = {
  refreshTable: null,
  dataObject: 0,
  waveRef: '',
  waveId: 0,
};

WaveInventory.propTypes = {
  refreshTable: PropTypes.func,
  formOpen: PropTypes.bool.isRequired,
  toggleForm: PropTypes.func.isRequired,
  dataObject: PropTypes.number,
  waveId: PropTypes.number,
  waveRef: PropTypes.string,
  pickingEnabled: PropTypes.bool.isRequired,
};
