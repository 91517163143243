import { apiClientBearerToken } from './../ApiClientConfig';
import Logger from '../../Logger';

const Log = new Logger('src/api/UsersApi.js');

const STORE_PATH = '/stores';

export const getAllStores = (
    size?: Number = null,
    page?: Number = null,
    search?: String = null,
    order?: String = null,
    sort?:String = null,
): Promise<Array<Object>> => {
    return apiClientBearerToken()
        .get(`${STORE_PATH}/all`, {
            params: {
                per_page:size,
                page,
                search: search?.trim(),
                order,
                sort
            },
        })
        .then(response => {
            return Promise.resolve(response.data);
        })
        .catch(error => {
            Log.error(
                getAllStores.name,
                error.message,
                error.response ? error.response : error,
                !(error.response && error.response.status === 404),
            );
            return Promise.reject();
        });
};

export const deleteStore = (id: Number): Promise<Object> => {
    return apiClientBearerToken()
        .get(`${STORE_PATH}/delete/${id}`, {
        })
        .then(() => {
            return Promise.resolve();
        })
        .catch(error => {
            Log.error(deleteStore.name, error.message, error.response ? error.response : error);
            return Promise.reject();
        });
};


export const createStore = (
    name: String,
    number: Number,
    ranking: Number,
    route_id: Number,
    pbl_ranking: Number,
    sorter_ranking: Number,
): Promise<Object> => {
    return apiClientBearerToken()
        .post(`${STORE_PATH}/create`, {
            name,
            number,
            ranking,
            route_id,
            pbl_ranking,
            sorter_ranking
        })
        .then(response => {
            return Promise.resolve(response.data);
        })
        .catch(error => {
            if (error.response) {
                return Promise.reject();
            }
            Log.error(createStore.name, error.message, error.response ? error.response : error);
            return Promise.reject();
        });
};

export const getStoreById = (id: Number): Promise<Object> => {
    return apiClientBearerToken()
        .get(`${STORE_PATH}/${id}`)
        .then(response => {
            return Promise.resolve(response.data);
        })
        .catch(error => {
            Log.error(getStoreById.name, error.message, error.response ? error.response : error);
            return Promise.reject();
        });
};


export const updateStoreById = (
    id: Number,
    name: String,
    number: Number,
    ranking: Number,
    route_id: Number,
    pbl_ranking: Number,
    sorter_ranking: Number,
    stores_id_ranking: Array,
    stores_id_sorter: Array,

): Promise<Object> => {
    return apiClientBearerToken()
        .post(`${STORE_PATH}/update`, {
            id,
            name,
            number,
            ranking,
            route_id,
            pbl_ranking,
            sorter_ranking,
            stores_id_ranking,
            stores_id_sorter
        })
        .then(response => {
            return Promise.resolve(response.data);
        })
        .catch(error => {
            return Promise.reject();
        });
};