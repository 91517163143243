import React, { useState, useRef, useEffect } from 'react';

import { Box, Tabs, Tab, AppBar, FormControl, TextField, Checkbox, Button } from '@material-ui/core';
import { Visibility as ViewIcon } from '@material-ui/icons';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';

import { makeStyles } from '@material-ui/core/styles';

// Components
import MaterialTable, { MTableToolbar } from 'material-table';
import Moment from 'moment';
import PageHeader from '../../../components/PageHeader';
import WithSnackBarMessages from '../../../components/WithSnackBarMessages';


// Security
// import Permissions from '../../../AppsPermissions/ShopsAdministratorPermissions';
// import { hasAuthority } from '../../AccessValidators';

// Styles
import { AppStyles } from '../../../AppStyles';
import materialTableLocaleES from '../../../components/MaterialTableLocaleES';

// Routes
import { APP_ROUTES, WAVES_ROUTES } from '../../../Routes';
import {useSnackbar} from "notistack";
// Data Apis
import { getCartons, getCartonsCsv, getZplCarton, getDetailCaronById, getCartonLog, resendCartons } from '../../../api/CartonService/CartonApi';

// Routes
import Dialog from "@material-ui/core/Dialog/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import DialogActions from "@material-ui/core/DialogActions/DialogActions";
import {getDepartmentWave} from "../../../api/WaveServices/WavesApi";


const breadcrumbsData = [
  { id: 1, label: 'Listado', base: APP_ROUTES.WAVES },
  {
    id: 2,
    label: 'Cartones',
    base: APP_ROUTES.WAVES + WAVES_ROUTES.CARTON,
  },
];
const useStyles = makeStyles(theme => ({
  actionDescriptionEdit: {
    '& .MuiInput-root': {
      fontSize: 'small',
    },
  },
  formControlTableToolBar: {
    margin: theme.spacing(1),
    marginTop: -theme.spacing(7),
    marginRight: theme.spacing(2),
    minWidth: 160,
    maxWidth: 10,
  },
  formControl2TableToolBar: {
    margin: theme.spacing(1),
    marginTop: -theme.spacing(7),
    marginRight: theme.spacing(2),
    minWidth: 110,
    maxWidth: 10,
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

function CartonPage() {
  const classes = useStyles();
  const appStyles = AppStyles();
  const tableRef = useRef();
  const tableRef2 = useRef();
  const tableRefDialog = useRef();
  const [areaValue, setAreaValue] = useState(0);
  const area = useState(0);
  const [sorter, setSorter] = useState(0);
  const [openDialogLog, setOpenDialogLog] = React.useState(false);
  const [value, setValue] = useState(0);
  const [fullWidth, setFullWidth] = React.useState(true);


    const [selectedDateInit, setSelectedDateInit] = React.useState(
    Moment(new Date()).format('YYYY-MM-DD'),
  );
  const [selectedDateEnd, setSelectedDateEnd] = React.useState(
    Moment(new Date()).format('YYYY-MM-DD'),
  );
  const [selectedStyle, setSelectedStyle] = React.useState();
 
  const [selectedSku, setSelectedSku] = React.useState();
  const [selectedWave, setSelectedWave] = React.useState();
  const { enqueueSnackbar } = useSnackbar();
  const messageSuccess = (message, variant) => {
      enqueueSnackbar(message, {
          variant,
      });
    };
  const messageError = (message, variant) => {
      enqueueSnackbar(message, {
          variant,
      });
  };

  const downloadCsv = (data, fileName) => {
    const finalFileName = fileName.endsWith(".csv") ? fileName : `${fileName}.csv`;
    const a = document.createElement("a");
    a.href = URL.createObjectURL(new Blob([data], { type: "text/csv" }));
    a.setAttribute("download", finalFileName);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const handleChangeTabTwo = (event, newValue) => {
    setAreaValue(newValue);
    tableRef.current.onQueryChange();
  };

  const handleChangeDateInit = event => {
    if (tableRef.current) {
      tableRef.current.state.query.page = 0;
      tableRef.current.state.query.area = area + 1;
      tableRef.current.state.query.dateInit = event.target.value;
      tableRef.current.state.query.dateEnd = selectedDateEnd;
      tableRef.current.state.query.style = null;
      tableRef.current.state.query.sku = null;
      tableRef.current.state.query.wave = null;
      setSelectedDateInit(event.target.value);
      setSelectedSku("");
      setSelectedStyle("");
      setSelectedWave("");
      tableRef.current.onQueryChange();
    }
  };

  const handleChangeDateEnd = event => {
    if (tableRef.current) {
      tableRef.current.state.query.page = 0;
      tableRef.current.state.query.area = area + 1;
      tableRef.current.state.query.dateEnd = event.target.value;
      tableRef.current.state.query.dateInit = selectedDateInit;
      tableRef.current.state.query.style = null;
      tableRef.current.state.query.sku = null;
      tableRef.current.state.query.wave = null;
      setSelectedDateEnd(event.target.value);
      setSelectedStyle("");
      setSelectedSku("");
      setSelectedWave("");
      tableRef.current.onQueryChange();
    }
  };

  const handleChangeStyle = event => {
    if (tableRef.current) {
      tableRef.current.state.query.page = 0;
      tableRef.current.state.query.area = area + 1;
      tableRef.current.state.query.dateInit = null;
      tableRef.current.state.query.dateEnd = null;
      tableRef.current.state.query.style = event.target.value;
      tableRef.current.state.query.sku = null;
      tableRef.current.state.query.wave = null;
      setSelectedStyle(event.target.value);
      setSelectedSku("");
      setSelectedWave("");
      tableRef.current.onQueryChange();
    }
  };

  const handleChangeSku = event => {
    if (tableRef.current) {
      tableRef.current.state.query.page = 0;
      tableRef.current.state.query.area = area + 1;
      tableRef.current.state.query.dateInit = null;
      tableRef.current.state.query.dateEnd = null;
      tableRef.current.state.query.style = null;
      tableRef.current.state.query.sku = event.target.value;
      tableRef.current.state.query.wave = null;
      setSelectedSku(event.target.value);
      setSelectedStyle("");
      setSelectedWave("");
      tableRef.current.onQueryChange();
    } 
  };

  const handleChangeWave = event => {
    if (tableRef.current) {
      tableRef.current.state.query.page = 0;
      tableRef.current.state.query.area = area + 1;
      tableRef.current.state.query.dateInit = null;
      tableRef.current.state.query.dateEnd = null;
      tableRef.current.state.query.style = null;
      tableRef.current.state.query.sku = null;
      tableRef.current.state.query.wave = event.target.value;
      setSelectedWave(event.target.value);
      setSelectedStyle("");
      setSelectedSku("");
      tableRef.current.onQueryChange();
    } 
  };
  const InputComponentInit = ({ defaultValue, inputRef, ...props }: any) => {
    const classes = useStyles();
    const handleChange = event => {
      setSelectedDateInit(event.target.value);
      if (props.onChange) {
        props.onChange(event);
      }
    };
    return (
      <div className={classes.root}>
        <input
          className={classes.input}
          ref={inputRef}
          {...props}
          onChange={handleChange}
          value={selectedDateInit}
        />
      </div>
    );
  };

  const InputComponentEnd = ({ defaultValue, inputRef, ...props }: any) => {
    const classes = useStyles();
    const handleChange = event => {
      setSelectedDateEnd(event.target.value);
      if (props.onChange) {
        props.onChange(event);
      }
    };
    return (
      <div className={classes.root}>
        <input
          className={classes.input}
          ref={inputRef}
          {...props}
          onChange={handleChange}
          value={selectedDateEnd}
        />
      </div>
    );
  };
  function a11yProps(index: any) {
    return {
      id: `scrollable-auto-tab-${index}`,
      'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
  }

  const getZpl = oldData =>
    new Promise((resolve, reject) => {
      setTimeout(() => {
        getZplCarton(oldData.carton_id ?? oldData.id)
          .then(response => {
            window.open(response.url, '_blank');
          })
          .catch(() => reject());
        resolve();
      }, 1000);
    });

  useEffect(() => {
    // hasAuthority(Permissions.GetAllRoutes, setCanGetAllRoutes);
    // hasAuthority(Permissions.CreateRoute, setCanCreateRoute);
    // hasAuthority(Permissions.UpdateRouteByID, setCanUpdateRoute);
    // hasAuthority(Permissions.DeleteRouteByID, setCanDeleteRoute);
    // hasAuthority(Permissions.AddShopToRouteByID, setCanAddShopToRoute);
    // hasAuthority(Permissions.RemoveShopFromRouteByID, setRemoveShopFromRoute);
  }, []);

  const parseQueryCartons = (query, area) => {
    if (sorter !== area) {
      query.page = 0;
      setSorter(area);
      return allCartons(query, area);
    }
    return allCartons(query, area);
  };

  const allCartonsCsv = (columns, query, area) =>
    getCartonsCsv(
      area + 1,      
      query.search,
      selectedDateInit,
      selectedDateEnd,
      selectedStyle,
      selectedSku,
      selectedWave,
    )
      .then(response => {
        const data = [];
        response.forEach(function(value, key, array) {
            data[key] = [
                value.created_at,
                value.barcode,
                value.wave_id,
                value.transferNum,
                value.total_pieces,
                value.store                
            ];
        });
        const headerRow = columns.map(col => {
          if (typeof col.title === 'object') {
            return col.title.props.text;
          }
          return col.title;
        });
        const { exportDelimiter } = tableRef.current.props.options;
        const delimiter = exportDelimiter ? exportDelimiter : ",";
        const csvContent = [headerRow, ...data].map(e => e.join(delimiter)).join("\n");
        const csvFileName = tableRef.current.props.title;
        downloadCsv(csvContent, csvFileName);        
      })
      .catch(error => {
        return Promise.reject(error);
      });

  const allCartons = (query, area) =>
    getCartons(
      area + 1,
      query.pageSize,
      query.page + 1,
      query.search,
      selectedDateInit,
      selectedDateEnd,
      selectedStyle,
      selectedSku,
      selectedWave,
    )
      .then(response => ({
        ...query,
        data: response.data,
        page: response.current_page - 1,
        totalCount: response.total,
      }))
      .catch(error => {
        return Promise.reject(error);
      });

  const resendManualCartons = (query, barcode) =>
    resendCartons(
      barcode
    )
        .then(response => {
            if (response.exito == true){
                return {
                    render: messageSuccess(response.mensaje, 'success')
                };
            }else{
                return {
                    render: messageError(response.mensaje, 'error')
                };
            }
        })
      .catch(error => {
        return Promise.reject(error);

      });

  const getDetailCarton = (query, idCarton) =>
    getDetailCaronById(idCarton)
      .then(response => {
        return {
          ...query,
          page: 0,
          pageSize: 15,
          totalCount: 0,
          data: response,
        };
      })
      .catch(() => ({
        ...query,
        page: 0,
        pageSize: 15,
        totalCount: 0,
        data: [],
      }));

    const handleCloseDialogLog = (accept) => {
        if (accept === true) {
            setOpenDialogLog(false);

        } else {
            setOpenDialogLog(false);
        }

    };

  const renderDetailCarton = rowData => {
    return (
      <MaterialTable
        nameEntity="Cartones"
        columns={[
          {
            title: 'SKU',
            field: 'sku',
            type: 'string',
          },
          {
            title: 'Estilo',
            field: 'style',
            type: 'string',
          },
          {
            title: 'Division',
            field: 'division',
            type: 'string',
          },
          {
            title: 'Categoría',
            field: 'category',
            type: 'string',
          },
          {
            title: 'Clasificación',
            field: 'classification',
            type: 'string',
          },
          {
            title: 'Piezas',
            field: 'pieces',
            type: 'string',
          },
        ]}
        data={query => getDetailCarton(query, rowData.carton_id ?? rowData.id)}
        tableRef={tableRef2}
        options={{
          search: false,
          showTitle: false,          
          toolbar: false,
          paging: false,
          header: true,
          padding: 'dense',
          rowStyle: {
            backgroundColor: '#EEE',
          },
        }}
      />
    );
  };

  const handleClickOpenDialog = () => {
    setOpenDialogLog(true);
  };


    const getLogCarton = (query, value) =>
        getCartonLog(value)
            .then(response => {
                return {
                    ...query,
                    page: 0,
                    pageSize: 15,
                    totalCount: 0,
                    data: response,
                };
            })
            .catch(() => ({
                ...query,
                page: 0,
                pageSize: 15,
                totalCount: 0,
                data: [],
            }));



    return (
    <Box id="app-routes-page" component="main" className={appStyles.pageRoot}>
      <PageHeader title="Cartones" canExecuteButton breadcrumbsData={breadcrumbsData} />

      <AppBar
        position="static"
        color="default"
        style={{ alignItems: 'center', backgroundColor: 'white' }}
      >
        <Tabs
          value={areaValue}
          onChange={handleChangeTabTwo}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="Elija el area"
        >
          <Tab label="SORTER 1" {...a11yProps(1)} />
          <Tab label="PBL" {...a11yProps(2)} />
          <Tab label="SORTER 3" {...a11yProps(3)} />
          <Tab label="TODOS" {...a11yProps(4)} />
          <Tab label="TRF FALTANTES" {...a11yProps(5)} />

        </Tabs>
      </AppBar>
      <MaterialTable
        columns={[
          { title: 'Fecha', field: 'created_at', type: 'string' },
          { title: 'Box Id', field: 'barcode', type: 'string' },
          {
            title: 'OLA',
            field: 'waveNumber',
            type: 'string',
            render: rowData => {
              return `${rowData.waveNumber}`;
            },
          },
          { title: 'Transfer', field: 'transferNum', type: 'string' },
          { title: 'Piezas', field: 'total_pieces', type: 'string' },
          { title: 'Destino', field: 'store', type: 'string' },
        ]}
        title="Cartones"
        localization={materialTableLocaleES}
        tableRef={tableRef}
        data={query => parseQueryCartons(query, areaValue)}
        detailPanel={rowData => renderDetailCarton(rowData)}
        options={{
          pageSize: 10,
          actionsColumnIndex: -1,
          paging: true,
          toolbar: true,
          showTitle: false,
          padding: 'dense',
          pageSizeOptions: [5, 10, 20, 50, 100],
          exportButton: true,
          exportCsv: (columns) => {
            allCartonsCsv(columns, tableRef.current.state.query, areaValue);
          },
        }}
        actions={[
          {
            icon: () => <AutorenewIcon color="primary" className="icon-small" />,
            tooltip: 'Reenviar Cartones Manualmente',
            onClick: (event, rowData) => {
              if(rowData.transferStatus == 0 || rowData.transferStatus == null){
                resendManualCartons(event, rowData.barcode);
              }
            },    
            },
          {
            icon: () => <ViewIcon color="primary" className="icon-small" />,
            tooltip: 'Ver Etiqueta',
            onClick: (event, rowData) => {
              getZpl(rowData);
            },
          },
          {
              icon: () => <AssignmentTurnedInIcon color="primary" className="icon-small" />,
              tooltip: 'Ver Registro',
              onClick: (event, rowData) => {
                 setValue(rowData.barcode);
                 console.log(rowData.barcode);
                  handleClickOpenDialog();
              },
          },
        ]}
        components={{
          Toolbar: props => (
            <div>
              <MTableToolbar {...props} />
              <FormControl className={classes.formControlTableToolBar}>
                <TextField
                  fullWidth
                  id="date-Init"
                  label="Fecha Inicio"
                  type="date"
                  onChange={handleChangeDateInit}
                  InputProps={{
                    inputComponent: InputComponentInit,
                  }}
                  defaultValue={selectedDateInit}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </FormControl>
              <FormControl className={classes.formControlTableToolBar}>
                <TextField
                  fullWidth
                  id="date-End"
                  label="Fecha Fin"
                  type="date"
                  onChange={handleChangeDateEnd}
                  InputProps={{
                    inputComponent: InputComponentEnd,
                  }}
                  defaultValue="2017-05-24"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </FormControl>
              <FormControl className={classes.formControl2TableToolBar}>
                <TextField
                  fullWidth
                  id="style"
                  label="Estilo"
                  defaultValue={selectedStyle}
                  onBlurCapture={handleChangeStyle}
                />
              </FormControl>
              <FormControl className={classes.formControl2TableToolBar}>
                <TextField
                  fullWidth
                  id="sku"
                  label="Sku"
                  defaultValue={selectedSku}
                  onBlurCapture={handleChangeSku}
                />
              </FormControl>
              <FormControl className={classes.formControl2TableToolBar}>
                <TextField
                  fullWidth
                  id="wave"
                  label="Ola"
                  defaultValue={selectedWave}
                  onBlurCapture={handleChangeWave}
                />
              </FormControl>
            </div>
          ),
        }}
      />
      <Dialog
        open={openDialogLog}
        onClose={handleCloseDialogLog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="sm"
        fullWidth={fullWidth}
      >
        <DialogTitle id="alert-dialog-title">{"REGISTRO DE IMPRESIÓN"}</DialogTitle>
        <DialogContent>
            <MaterialTable
                nameEntity="Logs"
                columns={[
                    {
                        title: 'Fecha de impresión',
                        field: 'created_at',
                        type: 'string',
                    },
                    {
                        title: 'BOX ID',
                        field: 'barcode',
                        type: 'string',
                    },
                ]}
                data={query => getLogCarton(query, value)}
                tableRef={tableRefDialog}
                options={{
                    search: false,
                    showTitle: false,
                    toolbar: false,
                    paging: false,
                    header: true,
                    padding: 'dense',
                }}
            />
        </DialogContent>
        <DialogActions>
            <Button onClick={() => {handleCloseDialogLog(true)}} color="primary" autoFocus>
                Aceptar
            </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default WithSnackBarMessages(CartonPage);
