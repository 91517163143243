import React, { useState, useEffect } from 'react';
import {
  Drawer,
  ListItem,
  List,
  ListItemIcon,
  ListItemText,
  Divider,
  Fab,
  Box,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory, useRouteMatch } from 'react-router-dom';
import {
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
  Build as BuildIcon,
  AllInbox as AllInboxIcon,
  Ballot as BallotIcon,
  Layers as LayersIcon,
  LocationSearching as LocationSearchingIcon,
  ViewAgenda as CartonIcon,
  ViewWeek as PalletIcon,
} from '@material-ui/icons';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import clsx from 'clsx';

// Routes
import { WAVES_ROUTES } from '../../../Routes';

// Security
// import Permissions from '../../../AppsPermissions/WavesAdministratorPermissions';
// import { hasAuthority } from '../../AccessValidators';

const drawerWidth = 180;
const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: theme.palette.primary.contrastText,
    left: 'auto',
  },
  nestedItemList: {
    paddingLeft: theme.spacing(4),
  },
  content: {
    flexGrow: 1,
    paddingTop: theme.spacing(4),
    transition: theme.transitions.create('margin'),
    marginLeft: -drawerWidth,
    width: theme.spacing(1),
  },
  contentShift: {
    transition: theme.transitions.create('margin'),
    marginLeft: 0,
    width: theme.spacing(1),
  },
  fabOpen: {
    transition: theme.transitions.create(['left', 'background-color', 'color']),
    position: 'fixed',
    height: 36,
    width: 36,
    left: 58 + drawerWidth,
    zIndex: 1201,
    '&:hover': {
      color: 'white',
      backgroundColor: theme.palette.primary.main,
    },
  },
  fabClose: {
    transition: theme.transitions.create(['left', 'background-color', 'color']),
    position: 'fixed',
    height: 36,
    width: 36,
    left: 58,
    zIndex: 1201,
    '&:hover': {
      color: 'white',
      backgroundColor: theme.palette.primary.main,
    },
  },
}));

export default function WavesDrawer() {
  const classes = useStyles();
  const { push } = useHistory();
  const { url } = useRouteMatch();
  const [open, setOpen] = useState(true);

  useEffect(() => {
    // hasAuthority(Permissions.GetAllShops, setCanShowStores);
    // hasAuthority(Permissions.GetAllPortages, setCanShowPortages);
  }, []);

  const handleDrawerClose = () => {
    setOpen(!open);
  };

  return (
    <Box className={classes.root}>
      <Drawer
        id="app-waves-router-drawer"
        className={classes.drawer}
        variant="persistent"
        open={open}
        onClose={handleDrawerClose}
        classes={{
          paper: classes.drawerPaper,
        }}
        anchor="left"
      >
        <List disablePadding>
          <ListItem button onClick={() => push(`${url}`)}>
            <ListItemIcon>
              <AllInboxIcon color="primary" />
            </ListItemIcon>
            <ListItemText primary="Olas" />
          </ListItem>
          <ListItem button onClick={() => push(`${url}${WAVES_ROUTES.WAVES}`)}>
            <ListItemIcon>
              <BuildIcon color="primary" />
            </ListItemIcon>
            <ListItemText primary="Constructor" />
          </ListItem>
          <ListItem button onClick={() => push(`${url}${WAVES_ROUTES.SUPPLYORDERS}`)}>
            <ListItemIcon>
              <BallotIcon color="primary" />
            </ListItemIcon>
            <ListItemText primary="Ordenes de Surtido" />
          </ListItem>
          <ListItem button onClick={() => push(`${url}${WAVES_ROUTES.PICKING}`)}>
            <ListItemIcon>
              <LayersIcon color="primary" />
            </ListItemIcon>
            <ListItemText primary="Ordenes de Picking" />
          </ListItem>
          <ListItem button onClick={() => push(`${url}${WAVES_ROUTES.CARTON}`)}>
              <ListItemIcon>
                  <CartonIcon color="primary" />
              </ListItemIcon>
              <ListItemText primary="Cartones" />
          </ListItem>
          <ListItem button onClick={() => push(`${url}${WAVES_ROUTES.ZONES}`)}>
            <ListItemIcon>
              <LocationSearchingIcon color="primary" />
            </ListItemIcon>
            <ListItemText primary="Zonas" />
          </ListItem>
          {/*<ListItem button onClick={() => push(`${url}${WAVES_ROUTES.BUFFER}`)}>
              <ListItemIcon>
                  <PalletIcon color="primary" />
              </ListItemIcon>
              <ListItemText primary="Buffers" />
          </ListItem>
          <ListItem button onClick={() => push(`${url}${WAVES_ROUTES.STAGING}`)}>
              <ListItemIcon>
                  <HourglassEmptyIcon color="primary" />
              </ListItemIcon>
              <ListItemText primary="Staging" />
          </ListItem>*/}
        </List>
      </Drawer>
      <Box
        component="main"
        className={clsx(classes.content, {
          [classes.contentShift]: open,
        })}
      >
        <Box>
          <Divider orientation="vertical" variant="inset" absolute light />
          <Fab
            onClick={handleDrawerClose}
            className={clsx(classes.fabOpen, {
              [classes.fabClose]: !open,
            })}
            size="small"
          >
            {open ? <ChevronLeftIcon fontSize="small" /> : <ChevronRightIcon fontSize="small" />}
          </Fab>
        </Box>
      </Box>
    </Box>
  );
}
