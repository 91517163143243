import React, { useState, useRef } from 'react';
import { Drawer, Typography, Box, CircularProgress, IconButton } from '@material-ui/core';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Close as CloseIcon } from '@material-ui/icons';

// Data Api
import Grid from '@material-ui/core/Grid';
import MaterialTable from 'material-table';
import { getPalletById, getPalletsByZone } from '../../../api/WaveServices/WavesApi';

// Components

import SnackBarMessage from '../../../components/SnackBarMessage';
import materialTableLocaleES from '../../../components/MaterialTableLocaleES';

const drawerWidth = '75%';
const useStyles = makeStyles(theme => ({
  container: {
    width: drawerWidth,
  },
  containerPaper: {
    width: drawerWidth,
    padding: 20,
  },
  header: {
    display: 'flex',
    alignItems: 'center',
  },
  selectZoneType: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  selectFormTextField: {
    justifyContent: 'space-between',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  spaceOption: { padding: theme.spacing(1) },
  headerIcon: {
    fontSize: 35,
    marginRight: theme.spacing(1),
    alignSelf: 'baseline',
  },
  headerCloseButton: {
    padding: theme.spacing(1),
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    justifyContent: 'space-between',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  selectForm: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  selectStyle: {
    minWidth: '100%',
    maxWidth: '100%',
  },
  selectShops: {
    minWidth: 355,
    maxWidth: 355,
  },

  selectAddButton: {
    padding: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  bottomSpace: {
    paddingTop: theme.spacing(2),
  },
  actionDescriptionEdit: {
    '& .MuiInput-root': {
      fontSize: 'small',
    },
  },
}));

const initialState = {
  orderGroupId: 0,
  skuInput: '',
  styleInput: '',
  skuDetail: [],
  provider: 0,
};

export default function BufferForm({ formOpen, toggleForm, refreshTable, dataObject }) {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [form, setForm] = useState(initialState);
  const [closeClick, setCloseClick] = useState('');
  const tableRef = useRef();
  const tableRef2 = useRef();

  const clearData = () => {
    setForm(initialState);
    setCloseClick('');
    setIsLoading(false);
  };

  const renderDetailCarton = rowData => {
    return (
      <MaterialTable
        columns={[
          { title: 'Sku', field: 'sku', type: 'string' },
          { title: 'Folio movimiento', field: 'folio_mov', type: 'string' },
          { title: 'Cantidad', field: 'cantidad', type: 'string' },
          { title: 'Cajas', field: 'cajas', type: 'string' },
          { title: 'Departamento', field: 'department.name', type: 'string' },
          { title: 'Familia', field: 'jdaName', type: 'string' },
        ]}
        data={query => getPalletContent(query, rowData.id)}
        tableRef={tableRef2}
        options={{
          search: false,
          showTitle: false,
          toolbar: false,
          paging: false,
          header: true,
          padding: 'dense',
          rowStyle: {
            backgroundColor: '#EEE',
          },
        }}
      />
    );
  };

  const getPalletContent = (query, dataObject) =>
    getPalletById(dataObject, query.page + 1, query.pageSize)
      .then(response => ({
        ...query,
        data: response.data,
        pageSize: 15,
        page: response.current_page - 1,
        totalCount: response.total,
      }))
      .catch(() => {
        return Promise.resolve({
          ...query,
          page: 0,
          pageSize: 40,
          totalCount: 0,
          data: [],
        });
      });

  const getPalletsZone = (query, dataObject) =>
    getPalletsByZone(dataObject, query.page + 1, query.pageSize)
      .then(response => ({
        ...query,
        data: response.data[0].pallets,
        pageSize: 15,
        page: response.current_page - 1,
        totalCount: response.total,
      }))
      .catch(() => {
        return Promise.resolve({
          ...query,
          page: 0,
          pageSize: 40,
          totalCount: 0,
          data: [],
        });
      });

  const initData = () => {
    setCloseClick(false);
  };

  return (
    <Box>
      <Drawer
        anchor="right"
        open={formOpen}
        onClose={toggleForm}
        className={classes.container}
        classes={{ paper: classes.containerPaper }}
      >
        <Box className={classes.header}>
          <Box flexGrow={1}>
            <Typography variant="h5" color="primary">
              Detalle de Tarimas
            </Typography>
          </Box>
          <IconButton
            color="secondary"
            className={classes.headerCloseButton}
            onClick={() => {
              toggleForm();
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <div className={classes.root} style={{ marginTop: '30px' }}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <MaterialTable
                columns={[
                  { title: 'Fecha de Movimiento', field: 'fecha_mov', type: 'string' },
                  { title: 'LPN', field: 'lpn_transportador', type: 'string' },
                  { title: 'Almacen destino', field: 'almacen_dest', type: 'string' },
                  { title: 'Ubicación actual', field: 'zone.code', type: 'string' },
                ]}
                localization={materialTableLocaleES}
                tableRef={tableRef}
                data={query => getPalletsZone(query, dataObject)}
                detailPanel={rowData => renderDetailCarton(rowData)}
                options={{
                  search: false,
                  pageSize: 15,
                  actionsColumnIndex: -1,
                  paging: true,
                  toolbar: false,
                  showTitle: false,
                  padding: 'dense',
                  pageSizeOptions: [5, 10, 20, 50, 100],
                }}
              />
            </Grid>
          </Grid>
        </div>
        <Box component="form" className={classes.form}>
          <Box />
          <Box className={classes.bottomSpace}>
            {isLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
            {formOpen && closeClick === '' && initData()}
            {!formOpen && closeClick !== '' && clearData()}
          </Box>
        </Box>
      </Drawer>

      <SnackBarMessage
        open={form.policyAccessError}
        message={form.roleAccessMessage}
        onClose={() => setForm({ ...form, policyAccessError: false })}
        variant="warning"
      />
    </Box>
  );
}

BufferForm.defaultProps = {
  refreshTable: null,
  dataObject: 0,
};

BufferForm.propTypes = {
  refreshTable: PropTypes.func,
  formOpen: PropTypes.bool.isRequired,
  toggleForm: PropTypes.func.isRequired,
  dataObject: PropTypes.number,
};
