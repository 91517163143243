import React, { useState, useRef } from 'react';

// Components
import {
    Drawer,
    Typography,
    Box,
    IconButton,
} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import MaterialTable from 'material-table';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
    Close as CloseIcon,
} from '@material-ui/icons';
import LayersIcon from '@material-ui/icons/Layers';
import materialTableLocaleES from "../../../components/MaterialTableLocaleES";

// Data Api
import {getPalletsByZone, getOnlyPalletsByZone, getPalletById} from "../../../api/WaveServices/WavesApi";


const drawerWidth = "75%";
const useStyles = makeStyles(theme => ({
    container: {
        width: drawerWidth,
    },
    containerPaper: {
        width: drawerWidth,
        padding: 20,
    },
    header: {
        display: 'flex',
        alignItems: 'center',
    },
    headerIcon: {
        fontSize: 35,
        marginRight: theme.spacing(1),
    },
    headerCloseButton: {
        padding: theme.spacing(1),
    },
}));


export default function StagingForm({
      formOpen,
      toggleForm,
      refreshTable,
      dataObject,
      dataWave,
  }) {

    const classes = useStyles();
    const [closeClick, setCloseClick] = useState('');
    const tableRef = useRef();
    const tableRef2 = useRef();

    const close = () => {
        toggleForm();
    };

    const clearData = () => {
        setCloseClick('');
    };

    const getPalletsZone = (query, zone, wave) =>
     getOnlyPalletsByZone(zone, wave, query.page + 1, query.pageSize )
            .then(response => ({
                ...query,
                data: response.data,
                page: response.current_page - 1,
                totalCount: response.total,
            }))
            .catch(() => ({
                ...query,
                page: 0,
                pageSize: 15,
                totalCount: 0,
                data: [],
            }));

    const rederDetailPallet = rowData => {
        return (
            <MaterialTable
                nameEntity="Pallets"
                columns={[
                    {
                        title: 'Cajas',
                        field: 'cajas',
                        type: 'string',
                    },
                    {
                        title: 'Cantidad',
                        field: 'cantidad',
                        type: 'string',
                    },
                    {
                        title: 'Departamento',
                        field: 'department.name',
                        type: 'string',
                    },
                    {
                        title: 'SKU',
                        field: 'sku',
                        type: 'string',
                    },
                ]}
                data={query => getDetailPallet(query, rowData.pallet_id)}
                tableRef={tableRef2}
                options={{
                    search: false,
                    showTitle: false,
                    toolbar: false,
                    paging: false,
                    header: true,
                    padding: 'dense',
                    rowStyle: {
                        backgroundColor: '#EEE',
                    },
                }}
            />
        );
    };

    const getDetailPallet = (query, idCarton) =>
        getPalletById(idCarton, query.page +1)
            .then(response => {
                return {
                    ...query,
                    page: 0,
                    pageSize: 15,
                    totalCount: 0,
                    data: response.data,
                };
            })
            .catch(() => ({
                ...query,
                page: 0,
                pageSize: 15,
                totalCount: 0,
                data: [],
            }));

    return (
        <Box>
            <Drawer
                anchor="right"
                open={formOpen}
                onClose={toggleForm}
                className={classes.container}
                classes={{ paper: classes.containerPaper }}
            >
                <Box className={classes.header}>
                    <LayersIcon color="primary" className={classes.headerIcon} />
                    <Box flexGrow={4}>
                        <Typography variant="h5" color="primary">
                            DETALLE
                        </Typography>
                    </Box>
                    <IconButton
                        color="secondary"
                        className={classes.headerCloseButton}
                        onClick={() => {
                            close();
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                    {formOpen && closeClick === ''}
                    {!formOpen && closeClick !== '' && clearData()}
                </Box>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography variant="h5" color="primary">
                            Pallets
                        </Typography>
                        <MaterialTable
                            columns={[
                                { title: 'Ola', field: 'wave_id', type: 'string' },                                
                                { 
                                    title: 'LPN',
                                    field: 'lpn_transportador',
                                    type: 'string',
                                    render: rowData => (rowData.lpn_transportador.slice(0, -1)) 
                                },
                                { title: 'Destino', field: 'almacen_dest', type: 'string' },
                                { title: 'Ubico', field: 'assignated_by', type: 'string' },
                            ]}
                            title=" "
                            localization={materialTableLocaleES}
                            tableRef={tableRef}
                            data={query => getPalletsZone(query, dataObject, dataWave)}
                            options={{
                                pageSize: 10,
                                search: false,
                                paging: true,
                                toolbar: false,
                                padding: 'dense',
                            }}
                            detailPanel={rowData => rederDetailPallet(rowData)}
                        />
                    </Grid>
                </Grid>
            </Drawer>
        </Box>
    );
}

StagingForm.defaultProps = {
    refreshTable: null,
    dataObject: 0,
};

StagingForm.propTypes = {
    refreshTable: PropTypes.func,
    formOpen: PropTypes.bool.isRequired,
    toggleForm: PropTypes.func.isRequired,
    dataObject: PropTypes.number,
};

