import React, { Component, useState, useEffect } from 'react';
import { Box, LinearProgress } from '@material-ui/core';

// Components
import AppDrawer from './AppDrawer';

// Api
import { checkPermission } from '../api/AuthApi';

export function WithPublicLayout(WrappedComponent: Component, appDrawer: Boolean = true) {
  return function Layout(props) {
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
      const {
        history: { goBack },
      } = props;

      setIsLoading(false);
      checkPermission(props.match.path)
        .then(access => !access && goBack())
        .catch(() => goBack());      
    }, []);

    return isLoading ? (
      <Box id="app-layout-page">
        <LinearProgress color="primary" />
      </Box>
    ) : (
      <Box id="app-layout-page" style={{ display: 'flex', flexGrow: 1 }}>
        {appDrawer && <AppDrawer />}
        <WrappedComponent {...props} />
      </Box>
    );
  };
}

export const withAuthority = (
  WrappedComponent: Component,
  appDrawer: Boolean = true,
  authority: String,
) => {
  return class WithAuthority extends Component {
    constructor(props) {
      super(props);
      this.state = {
        isLoading: true,
      };
    }

    async componentDidMount() {
      const {
        history: { goBack },
      } = this.props;

      // await validateAuthority(authority)
      //   .then(access => !access && goBack())
      //   .catch(() => goBack());      
      this.setState({ isLoading: false });
    }

    render() {
      const { isLoading } = this.state;

      return (
        <Box id="app-layout-page" style={{ display: 'flex', flexGrow: 1 }}>
          {appDrawer && <AppDrawer />}
          {isLoading ? (
            <Box id="app-loading-page" component="main" style={{ flexGrow: 1 }}>
              <LinearProgress color="primary" />
            </Box>
          ) : (
            <WrappedComponent {...this.props} />
          )}
        </Box>
      );
    }
  };
};
