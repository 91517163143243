import React, { useState } from 'react';
import {Drawer, Typography, Box, Avatar, IconButton} from '@material-ui/core';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import MaterialTable from 'material-table';
import materialTableLocaleES from '../../../components/MaterialTableLocaleES';

// Components
import { getWaveData } from '../../../api/WaveServices/WavesApi';
import { updateOrderByID } from '../../../api/SupplyOrderService/SupplyOrderApi';
import SnackBarMessage from '../../../components/SnackBarMessage';
import CardMembershipIcon from "@material-ui/core/SvgIcon/SvgIcon";
import {Close as CloseIcon, Edit as EditIcon} from "@material-ui/icons";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import {useSnackbar} from "notistack";


const drawerWidth = 1050;
const useStyles = makeStyles(theme => ({
    container: {
        width: drawerWidth,
    },
    containerPaper: {
        width: drawerWidth,
        padding: 20,
    },
    header: {
        display: 'flex',
        alignItems: 'center',
    },
    headerIcon: {
        fontSize: 35,
        marginRight: theme.spacing(1),
    },
    headerCloseButton: {
        padding: theme.spacing(1),
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        justifyContent: 'space-between',
    },
    buttonProgress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    selectForm: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },
    selectStyle: {
        minWidth: 195,
        maxWidth: 195,
    },
    pickerStyle: {
        minWidth: 225,
        maxWidth: 225,
    },
    selectAddButton: {
        padding: theme.spacing(1),
        marginTop: theme.spacing(1),
    },
    addRolesPolices: {
        margin: theme.spacing(4),
    },
    card: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    cardContent: {
        padding: theme.spacing(2),
    },
    formCardInput: {
        display: 'flex',
        flexGrow: 1,
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    bottomSpace: {
        paddingTop: theme.spacing(2),
    },
    smallColor: {
        width: theme.spacing(3),
        height: theme.spacing(3),
    },
}));

const initialState = {
  orderGroupId: 0,
  waveData: [],
  stores: 0,
  maxSlots: 0,
  slots: 0,
  waveStatus:0
};

export default function WaveDetail({ formOpen, toggleForm, refreshTable, dataObject, waveRef }) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(true);
  const [form, setForm] = useState(initialState);
  const [closeClick, setCloseClick] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const [openDialogTwo, setOpenDialogTwo] = useState(false);
  const [textDialog, setTextDialog] = useState('');
  const [idOrder, setIdOrder] = useState(0);
  const [newSlots, setNewSlots] = useState(0);
  const [wave, setWave] = useState(0);


  const messageError = (message, variant) => {
    enqueueSnackbar(message, {
      variant,
    });
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleCloseDialogTwo = () => {
    setOpenDialogTwo(false);
  };

  const handleCloseDialogAccept = () => {
      updateOrderByID(idOrder, newSlots, wave)
          .then(() => {
              clearData();
              messageError('Salidas actualizadas', 'success');
          })
          .catch(error => {
              if (error !== undefined) {
                  if (error.servicesNameErrorMessage) {
                      messageError(error.servicesNameErrorMessage, 'error');
                  }
              } else {
                  messageError('Servicio no disponible.', 'warning');
              }
          });
      setOpenDialogTwo(false);
  };

  const clearData = () => {
    setForm(initialState);
    setCloseClick('');
    setIsLoading(true);
  };

  const initData = () => {
    setIsLoading(true);
    getWaveData(dataObject)
      .then(response => {
        setIsLoading(false);
        console.log(response);
        setWave(response.data.wave);
        setForm({
            waveData: response.data.routes,
            stores: response.data.stores,
            maxSlots: response.data.maxslots,
            slots: response.data.slots,
            waveStatus: response.data.wavestatus,
        });
      })
      .catch(error => {
        setIsLoading(false);
        console.log(error);
      });
    setCloseClick(false);
  };

  const renderOrdersDetail = rowData => {
    const columns = [
        { title: 'Orden', field: 'id', editable: 'never' },
        { title: 'No. de tienda', field: 'store', editable: 'never' },
        { title: 'Tienda', field: 'storeDescription', editable: 'never' },
        { title: 'Ruta', field: 'route', editable: 'never' },
        { title: 'Piezas', field: 'sumpzas', editable: 'never' },
        { title: 'Prepacks', field: 'sumppk', editable: 'never' },
        { title: 'Salidas', field: 'slots', type: 'numeric',
          render: rowData => rowData.sumppk === 0 ? 0 : rowData.slots ,
        },
    ];
    return (
        <MaterialTable
            columns={columns}
            data={rowData.orders}
            title={`Distribución de tiendas ${rowData.description}`}
            options={{
                search: false,
                paging: false,
                padding: 'dense',
                toolbar: true,
                rowStyle: {
                    backgroundColor: '#EEE',
                },
            }}
            localization={materialTableLocaleES}
            editable={{
                isEditable: rowData => {return ( (form.waveStatus === 2 && rowData.sumppk > 0) || (form.waveStatus === 3 ||rowData.sumppk > 0) );},
                onRowUpdate: (newData, oldData) =>
                    new Promise((resolve, reject) => {
                        setTimeout(() => {
                            let update = updateOrder(oldData, newData);
                            if (update === true) {
                                updateOrderByID(newData.id, newData.slots, wave)
                                    .then(() => {
                                        clearData();
                                        resolve();
                                        messageError('Salidas actualizadas', 'success');
                                    })
                                    .catch(error => {
                                        if (error !== undefined) {
                                            if (error.servicesNameErrorMessage) {
                                                messageError(error.servicesNameErrorMessage, 'error');
                                            }
                                        } else {
                                            messageError('Servicio no disponible.', 'warning');
                                        }
                                        reject();

                                    })
                            } else {
                                reject();
                            }
                        }, 1000)
                    }),
            }}
        />
    );
  };

  const updateOrder = (oldData, newData) => {
      let oldSlots = parseInt(oldData.slots);
      let slots = (form.slots - oldSlots) + newData.slots;
      setIdOrder(newData.id);
      setNewSlots(newData.slots);
      if (slots > form.maxSlots) {
          setTextDialog('El número de salidas permitidas es: '+ form.maxSlots +' y usted quiere ocupar: '+ slots +' para esta ola. Verifique de nuevo');
          setOpenDialog(true);
          return false;
      }
      if (newData.slots <= 0) {
        setTextDialog('Asignando 0 salidas a esta tienda NO SE SURTIRA EN LA OLA; ¿Esta de acuerdo?');
        setOpenDialogTwo(true);
        return false;
      }
      return true;
  };

  return (
    <Box>
      <Drawer
        anchor="right"
        open={formOpen}
        onClose={toggleForm}
        className={classes.container}
        classes={{ paper: classes.containerPaper }}
      >

        <Box className={classes.header}>
            <CardMembershipIcon color="primary" className={classes.headerIcon} />
            <Box flexGrow={1}>
                <Typography variant="h5" color="primary">
                    {`${waveRef} Distribución por ruta:`}
                </Typography>
            </Box>
            <Box flexGrow={1} className={classes.headerCloseButton}>
                <Typography variant="h5" color="primary">
                    {`Total de tiendas: ${form.stores} `}
                </Typography>
            </Box>
            <IconButton
                color="secondary"
                className={classes.headerCloseButton}
                onClick={() => {
                    toggleForm();
                }}
            >
                <CloseIcon />
            </IconButton>
        </Box>
        <MaterialTable
          columns={[
            { title: 'No. de Ruta', field: 'id' },
            { title: 'Nombre de Ruta', field: 'description' },
            {
              title: 'Piezas',
              field: 'pieces',
              type: 'numeric',
              render: rowData => {
                return rowData.pieces.toLocaleString();
              },
            },
            {
              title: 'Color',
              field: 'color',
              render: rowData => {
                return (
                  <Avatar
                    variant="square"
                    style={{ backgroundColor: rowData.color }}
                    className={classes.smallColor}
                  >
                    {' '}
                  </Avatar>
                );
              },
            },
            { title: 'No. de tiendas', field: 'stores' },
          ]}
          isLoading={isLoading}
          localization={materialTableLocaleES}
          data={form.waveData}
          options={{
            search: false,
            paging: false,
            toolbar: false,
            padding: 'dense',
          }}
          detailPanel={rowData => renderOrdersDetail(rowData)}
        />

        <Box component="form" className={classes.form}>
          <Box />
          <Box className={classes.bottomSpace}>
            {formOpen && closeClick === '' && initData()}
            {!formOpen && closeClick !== '' && clearData()}
          </Box>
        </Box>
      </Drawer>

      <SnackBarMessage
        open={form.policyAccessError}
        message={form.roleAccessMessage}
        onClose={() => setForm({ ...form, policyAccessError: false })}
        variant="warning"
      />

      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Alerta"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
                { textDialog }
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialog}  color="primary" autoFocus>
                Aceptar
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
            open={openDialogTwo}
            onClose={handleCloseDialogTwo}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{"Alerta"}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    { textDialog }
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCloseDialogTwo} color="primary">
                    Cancelar
                </Button>
                <Button onClick={handleCloseDialogAccept}  color="primary" autoFocus>
                    Aceptar
                </Button>
            </DialogActions>
        </Dialog>
    </Box>

  );
}

WaveDetail.defaultProps = {
  refreshTable: null,
  dataObject: 0,
  waveRef: '',
};

WaveDetail.propTypes = {
  refreshTable: PropTypes.func,
  formOpen: PropTypes.bool.isRequired,
  toggleForm: PropTypes.func.isRequired,
  dataObject: PropTypes.number,
  waveRef: PropTypes.string,
};
