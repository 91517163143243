import React, { useState, useEffect } from 'react';
import {
  Drawer,
  ListItem,
  List,
  ListItemIcon,
  ListItemText,
  Divider,
  Fab,
  Box,
} from '@material-ui/core';
import {
  ImportExport as PriorityIcon,
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
} from '@material-ui/icons';
import StorefrontIcon from '@material-ui/icons/Storefront';
import ListAltIcon from '@material-ui/icons/ListAlt';
import SubwayIcon from '@material-ui/icons/Subway';
import AssessmentIcon from '@material-ui/icons/Assessment';
import PostAddIcon from '@material-ui/icons/PostAdd';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';

import { makeStyles } from '@material-ui/core/styles';
import { useHistory, useRouteMatch } from 'react-router-dom';

import clsx from 'clsx';

// Routes
import { PRIORITY_ROUTES } from '../../../Routes';
// Security
// import Permissions from '../../../AppsPermissions/WavesAdministratorPermissions';
// import { hasAuthority } from '../../AccessValidators';
import { getPermissions } from '../../../api/AuthApi';

const drawerWidth = 220;
const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: theme.palette.primary.contrastText,
    left: 'auto',
  },
  nestedItemList: {
    paddingLeft: theme.spacing(4),
  },
  content: {
    flexGrow: 1,
    paddingTop: theme.spacing(4),
    transition: theme.transitions.create('margin'),
    marginLeft: -drawerWidth,
    width: theme.spacing(1),
  },
  contentShift: {
    transition: theme.transitions.create('margin'),
    marginLeft: 0,
    width: theme.spacing(1),
  },
  fabOpen: {
    transition: theme.transitions.create(['left', 'background-color', 'color']),
    position: 'fixed',
    height: 36,
    width: 36,
    left: 58 + drawerWidth,
    zIndex: 1201,
    '&:hover': {
      color: 'white',
      backgroundColor: theme.palette.primary.main,
    },
  },
  fabClose: {
    transition: theme.transitions.create(['left', 'background-color', 'color']),
    position: 'fixed',
    height: 36,
    width: 36,
    left: 58,
    zIndex: 1201,
    '&:hover': {
      color: 'white',
      backgroundColor: theme.palette.primary.main,
    },
  },
}));

export default function WavesDrawer() {
  const classes = useStyles();
  const { push } = useHistory();
  const { url } = useRouteMatch();
  const [open, setOpen] = useState(true);
  const [access, setAccess] = useState(false);

  useEffect(() => {        
      getPermissions().then(response => {
          let check = response.filter(obj => obj.name === 'Full Access');
          if (check.length === 0) {              
            setAccess(true);
          } else {
            setAccess(false);
          }
        }).catch(e => {
            console.log(e);
        })
  }, []);

  const handleDrawerClose = () => {
    setOpen(!open);
  };
  return (
    <Box className={classes.root}>
      <Drawer
        id="app-waves-router-drawer"
        className={classes.drawer}
        variant="persistent"
        open={open}
        onClose={handleDrawerClose}
        classes={{
          paper: classes.drawerPaper,
        }}
        anchor="left"
      >
        <List disablePadding>
          <ListItem button onClick={() => push(`${url}${PRIORITY_ROUTES.PRIORITY}`)}>
            <ListItemIcon>
              <PriorityIcon color="primary" />
            </ListItemIcon>
            <ListItemText primary="Prioridades" />
          </ListItem>
          <ListItem button onClick={() => push(`${url}${PRIORITY_ROUTES.PRODUCT_FAMILIES}`)}>
            <ListItemIcon>
              <ListAltIcon color="primary" />
            </ListItemIcon>
            <ListItemText primary="Familia productos" />
          </ListItem>
          <ListItem button onClick={() => push(`${url}${PRIORITY_ROUTES.STORES}`)}>
            <ListItemIcon>
              <StorefrontIcon color="primary" />
            </ListItemIcon>
            <ListItemText primary="Tiendas" />
          </ListItem>
          <ListItem button onClick={() => push(`${url}${PRIORITY_ROUTES.ROUTES}`)}>
              <ListItemIcon>
                  <SubwayIcon color="primary" />
              </ListItemIcon>
              <ListItemText primary="Rutas" />
          </ListItem>
          <ListItem button onClick={() => push(`${url}${PRIORITY_ROUTES.REPORT_SUPPLY}`)}>
              <ListItemIcon>
                  <AssessmentIcon color="primary" />
              </ListItemIcon>
              <ListItemText primary="Orden de surtido" />
          </ListItem>
          <ListItem button disabled={access} onClick={() => push(`${url}${PRIORITY_ROUTES.STYLES}`)}>
              <ListItemIcon>
                  <PostAddIcon color="primary" />
              </ListItemIcon>
              <ListItemText primary="Alta de Estilos" />
          </ListItem>
          <ListItem button disabled={access} onClick={() => push(`${url}${PRIORITY_ROUTES.USER_REPORTS_ADMIN}`)}>
              <ListItemIcon>
                  <SupervisedUserCircleIcon color="primary" />
              </ListItemIcon>
              <ListItemText primary="Suscripción a reportes" />
          </ListItem>
        </List>
      </Drawer>
      <Box
        component="main"
        className={clsx(classes.content, {
          [classes.contentShift]: open,
        })}
      >
        <Box>
          <Divider orientation="vertical" variant="inset" absolute light />
          <Fab
            onClick={handleDrawerClose}
            className={clsx(classes.fabOpen, {
              [classes.fabClose]: !open,
            })}
            size="small"
          >
            {open ? <ChevronLeftIcon fontSize="small" /> : <ChevronRightIcon fontSize="small" />}
          </Fab>
        </Box>
      </Box>
    </Box>
  );
}
