import React, {useRef, useState, useEffect} from 'react';

//Routes
import {APP_ROUTES, REPORT_ROUTES} from "../../../Routes";

// Styles
import { AppStyles } from '../../../AppStyles';
import { makeStyles } from '@material-ui/core/styles';

// Components
import {Box, FormControl, InputLabel, MenuItem, Select, Typography} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import PageHeader from "../../../components/PageHeader";
import Card from "../../../components/DashboardWaceComponents/Card/Card.js";
import CardBody from "../../../components/DashboardWaceComponents/Card/CardBody.js";
import CardHeader from "../../../components/DashboardWaceComponents/Card/CardHeader.js";
import CardIcon from "../../../components/DashboardWaceComponents/Card/CardIcon.js";
import CardFooter from "../../../components/DashboardWaceComponents/Card/CardFooter.js";
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';
import MaterialTable from "material-table";
import materialTableLocaleES from "../../../components/MaterialTableLocaleES";
import Button from '@material-ui/core/Button';
import Fade from '@material-ui/core/Fade';
import CircularProgress from '@material-ui/core/CircularProgress';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import * as XLSX from "xlsx";
import Tabs from "@material-ui/core/Tabs/Tabs";
import Tab from "@material-ui/core/Tab/Tab";
import AppBar from "@material-ui/core/AppBar/AppBar";

// API
import { getGlobalReport, getWavesReportFilter, getWavesPlanned, validatePpk } from '../../../api/ReportService/ReportApi';
import { checkSkuInventory } from '../../../api/WaveServices/WavesApi';
const breadcrumbsData = [
    {
        id: 1,
        label: 'Reportes',
        base: APP_ROUTES.REPORTS,
    },
    { id: 1, label: 'Olas', base: REPORT_ROUTES.DASHBOARD_WAVES },
];

const useStyles = makeStyles(theme => ({
    actionDescriptionEdit: {
        '& .MuiInput-root': {
            fontSize: 'small',
        },
    },
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(3),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    typography: {
        textAlign: 'center',
    },
    typographyColor: {
        color: "green",
    },
    cardCategory: {
        color: "black",
        fontSize: "14px",
        marginTop: "0",
    },
    cardCategoryWhite: {
        color: "white",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
    },
    cardTitle: {
        color: "gray",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "gray",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    cardTitleWhite: {
        color: "white",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "gray",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    cardFooter : {
        with: "100%",
    },
    cardStyle: {
        height: '50px',
    },
    filters: {
        '& > *': {
            margin: theme.spacing(1),
            width: '23%',
        },
    },
    dividerFullWidth: {
        margin: `5px 0 0 ${theme.spacing(2)}px`,
    },
    placeholder: {
        align:'center',
        height: 40,
    },
    input: {
        display: 'none',
    },
}));

const initialState = {
    initDay: '',
    endDay: '',
    sorter: '',
    wave: 0,
};

const initialStatePicking = {
    horas_lab_pick: 12,
    pickers: 18,
    box_picker_hr: 80
};

const initialStateSorter = {
    horas_lab_sort: 10,
    inductores: 12,
    prepacks_min: 16,
};

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && <Box p={3}>{children}</Box>}
        </Typography>
    );
}

function a11yProps(index: any) {
    return {
        id: `scrollable-auto-tab-${index}`,
        'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
}

export default function WavesReport() {
    const classes = useStyles();
    const appStyles = AppStyles();
    const tableRef = useRef();
    const [selectedDateInit, setSelectedDateInit] = React.useState();
    const [selectedDateEnd, setSelectedDateEnd] = React.useState();
    const [globalWaves, setGlobalWaves] = useState(0);
    const [globalCartons, setGlobalCartons] = useState(0);
    const [globalPallets, setGlobalPallets] = useState(0);
    const [globalPieces, setGlobalPieces] = useState(0);
    const [form, setForm] = useState(initialState);
    const [formPincking, setFormPicking] = useState(initialStatePicking);
    const [formSorter, setFormSorter] = useState(initialStateSorter);
    const [dataWave, setDataWave] = useState([]);
    const [departmentWave, setDepartmentWave] = useState([]);
    const [value, setValue] = React.useState(0);
    const [loading, setLoading] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [openSorter, setOpenSorter] = React.useState(false);
    const [openNullData, setOpenNullData] = React.useState(false);
    const [fileInput, setFileInput]= useState('');
    const [fileInputSku, setFileInputSku]= useState('');
    const [data, setData] = useState([]);
    const [dataSku, setDataSku] = useState([]);
    const [showTable, setShowTable]= useState(true);
    
    useEffect(() => {
        initData();
        getGlobalReport().then(response => {
            setGlobalWaves(response[0].total_waves);
            setGlobalCartons(response[0].total_cartons);
            setGlobalPallets(response[0].total_pallets);
            setGlobalPieces(response[0].total_pieces);
        }).catch(e => {
            console.log(e);
        });
    }, []);

    const handleChangeTab = (event, newValue) => {
        setValue(newValue);
    };

    const handleChange = event => {
        setForm({
            ...form,
            [event.target.name]: event.target.value,
        });
        event.preventDefault();
    };

    const handleChangePicking = event => {
        setFormPicking({
            ...formPincking,
            [event.target.name]: event.target.value,
        });
        event.preventDefault();
    };

    const handleChangeSorter = event => {
        setFormSorter({
            ...formSorter,
            [event.target.name]: event.target.value,
        });
        event.preventDefault();
    };

    const getWaves = () => {
        setLoading(prevLoading => !prevLoading);
        getWavesReportFilter(form.initDay, form.endDay, form.sorter, form.wave)
            .then(response => {
                setDataWave(response.scorecard[0]);
                setDepartmentWave(response.deptos);
                setLoading(prevLoading => !prevLoading);
            })
            .catch(e => {
                setLoading(prevLoading => !prevLoading);
                setForm(initialState);
                setOpenNullData(true);
                console.log(e);
            });
    };

    const getReportWavePlanned = () => {
        setLoading(prevLoading => !prevLoading);
        getWavesPlanned(form.initDay, form.endDay)
            .then(response => {
                const a = document.createElement("a");
                a.href = URL.createObjectURL(new Blob([response], { type: "text/xlsx" }));
                a.setAttribute("download", "reporte_"+form.initDay+".xlsx");
                document.body.appendChild(a);
                a.click();
                setLoading(prevLoading => !prevLoading);
            })
            .catch(e => {
                setLoading(prevLoading => !prevLoading);
                setForm(initialState);
                setOpenNullData(true);
                console.log(e);
            });
    };

    const InputComponentInit = ({ defaultValue, inputRef, ...props }: any) => {
        const classes = useStyles();

        const handleChange = event => {
            setForm({
                ...form,
                [event.target.name]: event.target.value,
            });
            setSelectedDateInit(event.target.value);
            if (props.onChange) {
                props.onChange(event);
            }
        };

        return (
            <div className={classes.root}>
                <input
                    name='initDay'
                    className={classes.input}
                    ref={inputRef}
                    {...props}
                    onChange={handleChange}
                    value={form.initDay}
                />
            </div>
        );
    };

    const InputComponentEnd = ({ defaultValue, inputRef, ...props }: any) => {
        const classes = useStyles();

        const handleChange = event => {
            setForm({
                ...form,
                [event.target.name]: event.target.value,
            });
            setSelectedDateEnd(event.target.value);
            if (props.onChange) {
                props.onChange(event);
            }
        };

        return (
            <div className={classes.root}>
                <input
                    name='endDay'
                    className={classes.input}
                    ref={inputRef}
                    {...props}
                    onChange={handleChange}
                    value={form.endDay}
                />
            </div>
        );
    };

    const initData = () => {

    };

    const handleClickOpenSorter = () => {
        setOpenSorter(true);
    };

    const handleCloseSorter = () => {
        setOpenSorter(false);
    };

    const handleUpdateSorter = () => {
        setOpenSorter(false);
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleUpdatePicking = () => {
        setOpen(false);
    };

    const handleCloseNullData = () => {

        setOpenNullData(false);
    };

    const calculateDayPicking = (boxPicking) => {
        let box_picked_day_person = formPincking.box_picker_hr * formPincking.horas_lab_pick;
        let box_picked_day_total = box_picked_day_person * formPincking.pickers;
        let percent = (boxPicking / box_picked_day_total) * 100;

        return parseFloat(percent).toFixed(2);
    };

    const calculateWeekPicking = (boxPicking) => {
        let box_picked_day_person = formPincking.box_picker_hr * formPincking.horas_lab_pick;
        let box_picked_day_total = (box_picked_day_person * formPincking.pickers) * 7;
        let percent = (boxPicking / box_picked_day_total) * 100;

        return parseFloat(percent).toFixed(2);
    };

    const calculateDaySorter = (prepaks) => {
        let prepakc_hora = formSorter.prepacks_min * 60;
        let prepaks_day_person = prepakc_hora * formSorter.horas_lab_sort;
        let prepaks_totales = formSorter.inductores * prepaks_day_person;
        let percent = (prepaks / prepaks_totales) * 100;

        return parseFloat(percent).toFixed(2);
    };

    const calculateWeekSorter = (prepaks) => {
        let prepakc_hora = formSorter.prepacks_min * 60;
        let prepaks_week_person = (prepakc_hora * formSorter.horas_lab_sort);
        let prepaks_totales = (formSorter.inductores * prepaks_week_person) * 7;
        let percent = (prepaks / prepaks_totales) * 100;

        return parseFloat(percent).toFixed(2);
    };

    const handleSubmitFile = (event) => {
        event.preventDefault();
        setFileInput(event.target.files[0].name);
    };

    const handleSubmitFileSku = (event) => {
        event.preventDefault();
        setFileInputSku(event.target.files[0].name);
    };

    const readExcel = (file) => {
        const promise = new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsArrayBuffer(file);
            fileReader.onload = (e) => {
                const bufferArray = e.target.result;
                const wb = XLSX.read(bufferArray, { type: "buffer" });
                const wsname = wb.SheetNames[0];
                const ws = wb.Sheets[wsname];
                const data = XLSX.utils.sheet_to_json(ws);
                resolve(data);
            };

            fileReader.onerror = (error) => {
                reject(error);
            };
        });
        promise.then((d) => {
            setLoading(prevLoading => !prevLoading);
            validatePpk(d)
                .then(response => {
                    setData(response);
                    return response;
                })
                .then(response => {
                    setLoading(prevLoading => !prevLoading);
                    setShowTable(false);
                })
                .catch(e => {
                    console.log(e);
                });

        });
    };

    const readExcelSku = (file) => {
        const promise = new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsArrayBuffer(file);
            fileReader.onload = (e) => {
                const bufferArray = e.target.result;
                const wb = XLSX.read(bufferArray, { type: "buffer" });
                const wsname = wb.SheetNames[0];
                const ws = wb.Sheets[wsname];
                const data = XLSX.utils.sheet_to_json(ws);
                resolve(data);
            };

            fileReader.onerror = (error) => {
                reject(error);
            };
        });
        promise.then((d) => {
            setLoading(prevLoading => !prevLoading);
            checkSkuInventory(d)
                .then(response => {
                    console.log(response);
                    setDataSku(response);
                    return response;
                })
                .then(response => {
                    setLoading(prevLoading => !prevLoading);
                    setShowTable(false);
                })
                .catch(e => {
                    console.log(e);
                });

        });
    };

    const downloadCsv = (data, fileName) => {
        const finalFileName = fileName.endsWith(".csv") ? fileName : `${fileName}.csv`;
        const a = document.createElement("a");
        a.href = URL.createObjectURL(new Blob([data], { type: "text/csv" }));
        a.setAttribute("download", finalFileName);
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    };

    return (
        <Box id="app-home-waves-page" component="main" className={appStyles.pageRoot}>
            <AppBar
                position="static"
                color="default"
                style={{ alignItems: 'center', backgroundColor: 'white' }}
            >
                <Tabs
                    value={value}
                    onChange={handleChangeTab}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="scrollable"
                    scrollButtons="auto"
                    aria-label="scrollable auto tabs example"
                >
                    <Tab label="Reporte General" {...a11yProps(0)} />
                    <Tab label="Validacion PPK" {...a11yProps(1)} />
                    <Tab label="Inventario SKU's" {...a11yProps(2)} />
                </Tabs>
            </AppBar>
            <TabPanel value={value} index={0}>

                <PageHeader
                    title="Reporte de olas"
                    breadcrumbsData={breadcrumbsData}
                />
                <div style={{
                    zIndex:100,
                    position:'absolute',
                    marginLeft:'50%',
                    marginTop:'20%',
                }}>
                    <Fade
                        in={loading}
                        style={{
                            transitionDelay: loading ? '800ms' : '0ms',
                            textAlign:'center'
                        }}
                        disableShrink
                    >
                        <CircularProgress size={150} />
                    </Fade>
                </div>
                <Grid container spacing={1} style={{marginTop:'20px'}}>
                    <Grid item xs={3} >
                        <Card className={classes.root} style={{height: '91%'}}>
                            <CardHeader color="success" stats icon>
                                <CardIcon color="success">
                                    <h2>GLOBAL</h2>
                                </CardIcon>
                            </CardHeader>
                            <CardBody style={{
                                height: 200,
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center"}}>
                                <Typography variant="h2" component="h3" align='center' color="primary">
                                    { globalWaves }
                                </Typography>
                                <Typography variant="h4" component="h3" align='center' color="primary">
                                    Total de olas
                                </Typography>
                                <Divider variant="middle" />
                                <br/>
                                <Typography variant="h5" component="h2" align='center'>
                                    { globalCartons }
                                </Typography>
                                <Typography variant="h5" component="h2" align='center'>
                                    Total de cajas
                                </Typography>
                                <Divider variant="middle" />
                                <br/>

                                <Typography variant="h5" component="h2" align='center'>
                                    { globalPieces }
                                </Typography>
                                <Typography variant="h5" component="h2" align='center'>
                                    Total de piezas
                                </Typography>
                                <Divider variant="middle" />
                                <br/>

                                <Typography variant="h5" component="h2" align='center'>
                                    { globalPallets }
                                </Typography>
                                <Typography variant="h5" component="h2" align='center'>
                                    Total de bines
                                </Typography>
                            </CardBody>
                        </Card>
                    </Grid>
                    <Grid item xs={9}>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <Card>
                                    <CardHeader color="success" stats icon>
                                    </CardHeader>
                                    <CardBody>
                                        <form className={classes.filters} noValidate autoComplete="off" style={{margin:'-10px' , textAlign:'center'}} >
                                            <FormControl>
                                                <TextField
                                                    fullWidth
                                                    id="date-Init"
                                                    label="Fecha Inicio"
                                                    type="date"
                                                    name="initDay"
                                                    InputProps={{
                                                        inputComponent: InputComponentInit,
                                                    }}
                                                    onChange={handleChange}
                                                    defaultValue={selectedDateInit}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            </FormControl>

                                            <FormControl>
                                                <TextField
                                                    fullWidth
                                                    id="date-End"
                                                    label="Fecha Fin"
                                                    type="date"
                                                    name="endDay"
                                                    InputProps={{
                                                        inputComponent: InputComponentEnd
                                                    }}
                                                    onChange={handleChange}
                                                    defaultValue="2017-05-24"
                                                    InputLabelProps={{
                                                        shrink: true
                                                    }}
                                                />
                                            </FormControl>

                                            <FormControl>
                                                <InputLabel id="statusLabel">Filtrar por sorter</InputLabel>
                                                <Select
                                                    labelId="statusLabel"
                                                    id="demo-simple-select-required"
                                                    name="sorter"
                                                    onChange={handleChange}
                                                >
                                                    <MenuItem value={''}>Todos</MenuItem>
                                                    <MenuItem value={'SORTER1'}>Sorter 1</MenuItem>
                                                    <MenuItem value={'SORTER3'}>Sorter 3</MenuItem>
                                                    <MenuItem value={'PTL'}>PTL</MenuItem>
                                                </Select>
                                            </FormControl>

                                            <TextField
                                                name="wave"
                                                label="Ola"
                                                fullWidth
                                                onChange={handleChange}
                                            />
                                            <Button variant="contained" color="primary" onClick={getWaves}>
                                                Filtrar
                                            </Button>
                                            <Button variant="contained" color="primary" onClick={getReportWavePlanned}>
                                                Reporte Olas Planeadas
                                            </Button>
                                        </form>
                                    </CardBody>
                                    <CardFooter>
                                    </CardFooter>
                                </Card>
                            </Grid>

                            <Grid item xs={3}>
                                <Card className={classes.root} >
                                    <CardHeader color="success" stats icon>
                                        <CardIcon color="success">
                                            <h3>OLAS</h3>
                                        </CardIcon>
                                    </CardHeader>
                                    <CardBody style={{
                                        height: 576,
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center"}}>
                                        <Typography variant="h3" component="h3" align='center' color="primary">
                                            {dataWave.olas_planeadas}
                                        </Typography>
                                        <Typography variant="h5" component="h3" align='center' color="primary">
                                            Olas
                                        </Typography>
                                        <Divider variant="middle" />
                                        <br/>
                                        <Typography variant="h6" component="h2" align='center'>
                                            {dataWave.piezas_planeadas}
                                        </Typography>
                                        <Typography variant="h6" component="h2" align='center'>
                                            Piezas planeadas
                                        </Typography>
                                        <Divider variant="middle" />
                                        <br/>
                                        <Typography variant="h6" component="h2" align='center'>
                                            {dataWave.skus}
                                        </Typography>
                                        <Typography variant="h6" component="h2" align='center'>
                                            SKUs
                                        </Typography>
                                        <Divider variant="middle" />
                                    </CardBody>
                                    <CardFooter>
                                    </CardFooter>
                                </Card>
                            </Grid>

                            <Grid item xs={3}>
                                <Card className={classes.root}>
                                    <CardHeader color="success" stats icon>
                                        <CardIcon color="success">
                                            <h3>PICKING</h3>
                                        </CardIcon>
                                    </CardHeader>
                                    <CardBody style={{ height: 550 }}>
                                        <Typography variant="body1" component="h2" align='center'>
                                            {dataWave.cajas_pickeadas  ?? "No hay datos"}
                                        </Typography>
                                        <Typography variant="body1" component="h2" align='center'>
                                            Cajas pickeadas
                                        </Typography>
                                        <Divider variant="middle" />
                                        <br/>

                                        <Typography variant="body1" component="h2" align='center'>
                                            {dataWave.piezas_pickeadas  ?? "No hay datos"}
                                        </Typography>
                                        <Typography variant="body1" component="h2" align='center'>
                                            Piezas pickeadas
                                        </Typography>
                                        <Divider variant="middle" />
                                        <br/>

                                        <Typography variant="body1" component="h2" align='center'>
                                            {dataWave.horas_picking ?? "No hay datos"}
                                        </Typography>
                                        <Typography variant="body1" component="h2" align='center'>
                                            Horas de picking
                                        </Typography>
                                        <Divider variant="middle" />
                                        <br/>
                                        <Typography variant="body1" component="h2" align='center'>
                                            Piezas planeadas vs pickeadas
                                        </Typography>
                                        <Typography variant="body1" component="h2" align='center'>
                                            {(dataWave.piezas_planeadas_vs_piezas_pickeadas) ? dataWave.piezas_planeadas_vs_piezas_pickeadas+'%' : "No hay datos"}
                                        </Typography>
                                        <Divider variant="middle" />
                                        <br/>

                                        <Grid container spacing={1}>
                                            <Grid item xs={6} >
                                                <Typography variant="h6" component="h2" align='center' color="primary">
                                                    Dia
                                                </Typography>
                                                <Divider variant="middle" />
                                                <br/>
                                                <Typography variant="body1" component="h2" align='center'>
                                                    Cap. Cajas
                                                </Typography>
                                                <Typography variant="body1" component="h2" align='center'>
                                                    {(dataWave.total_cajas_picking) ? calculateDayPicking(dataWave.total_cajas_picking)+"%" : "No hay datos"}
                                                </Typography>
                                                <Divider variant="middle" />
                                            </Grid>
                                            <Grid item xs={6} >
                                                <Typography variant="h6" component="h2" align='center' color="primary">
                                                    Semana
                                                </Typography>
                                                <Divider variant="middle" />
                                                <br/>
                                                <Typography variant="body1" component="h2" align='center'>
                                                    Cap. Cajas
                                                </Typography>
                                                <Typography variant="body1" component="h2" align='center'>
                                                    {(dataWave.total_cajas_picking) ? calculateWeekPicking(dataWave.total_cajas_picking)+"%" : "No hay datos"}
                                                </Typography>
                                                <Divider variant="middle" />
                                                <br/>
                                            </Grid>
                                            <Grid item xs={12} style={{alignContent:'center'}}>
                                            </Grid>
                                        </Grid>
                                    </CardBody>
                                    <CardFooter>
                                        <Button style={{width: '100%'}} size="small" variant="contained" color="primary" onClick={handleClickOpen}>
                                            Recalcular
                                        </Button>
                                    </CardFooter>
                                </Card>
                            </Grid>
                            <Grid item xs={3}>
                                <Card className={classes.root}>
                                    <CardHeader color="success" stats icon>
                                        <CardIcon color="success">
                                            <h3>STAGING</h3>
                                        </CardIcon>
                                    </CardHeader>
                                    <CardBody style={{ height: 576 }}>
                                        <Typography variant="body1" component="h2" align='center'>
                                            {dataWave.cajas_ubicadas ?? "No hay datos"}
                                        </Typography>
                                        <Typography variant="body1" component="h2" align='center'>
                                            Cajas ubicadas
                                        </Typography>
                                        <Divider variant="middle" />
                                        <br/>

                                        <Typography variant="body1" component="h2" align='center'>
                                            {dataWave.piezas_ubicadas ?? "No hay datos"}
                                        </Typography>
                                        <Typography variant="body1" component="h2" align='center'>
                                            Piezas ubicadas
                                        </Typography>
                                        <Divider variant="middle" />
                                        <br/>

                                        <Typography variant="body1" component="h2" align='center'>
                                            {dataWave.horas_staging ?? "No hay datos"}
                                        </Typography>
                                        <Typography variant="body1" component="h2" align='center'>
                                            Horas de ubicacion
                                        </Typography>
                                        <Divider variant="middle" />
                                        <br/>
                                        <Typography variant="body1" component="h2" align='center'>
                                            Piezas pickeadas vs ubicadas
                                        </Typography>
                                        <Typography variant="body1" component="h2" align='center'>
                                            {(dataWave.piezas_pickeadas_vs_piezas_ubicadas) ? dataWave.piezas_pickeadas_vs_piezas_ubicadas+'%' : "No hay datos"}
                                        </Typography>
                                        <Divider variant="middle" />
                                        <br/>

                                        <Grid container spacing={1}>
                                            <Grid item xs={12} >
                                                <Typography variant="body1" component="h2" align='center'>
                                                    Cap. Almacenaje
                                                </Typography>
                                                <Typography variant="body1" component="h2" align='center'>
                                                    {(dataWave.capacidad_almacenaje) ? dataWave.capacidad_almacenaje+'%' : "No hay datos"}
                                                </Typography>
                                                <Divider variant="middle" />
                                                <br/>
                                            </Grid>
                                        </Grid>

                                    </CardBody>
                                    <CardFooter>
                                    </CardFooter>
                                </Card>
                            </Grid>
                            <Grid item xs={3}>
                                <Card className={classes.root}>
                                    <CardHeader color="success" stats icon>
                                        <CardIcon color="success">
                                            <h3>SORTER</h3>
                                        </CardIcon>
                                    </CardHeader>
                                    <CardBody style={{ height: 550 }}>
                                        <Typography variant="body1" component="h2" align='center'>
                                            {dataWave.cajas_surtidas ?? "No hay datos"}
                                        </Typography>
                                        <Typography variant="body1" component="h2" align='center'>
                                            Cajas surtidas
                                        </Typography>
                                        <Divider variant="middle" />
                                        <br/>

                                        <Typography variant="body1" component="h2" align='center'>
                                            {dataWave.piezas_surtidas ?? "No hay datos"}
                                        </Typography>
                                        <Typography variant="body1" component="h2" align='center'>
                                            Piezas surtidas
                                        </Typography>
                                        <Divider variant="middle" />
                                        <br/>

                                        <Typography variant="body1" component="h2" align='center'>
                                            {dataWave.prepacks_procesados ?? "No hay datos"}
                                        </Typography>
                                        <Typography variant="body1" component="h2" align='center'>
                                            Prepacks surtidos
                                        </Typography>
                                        <Divider variant="middle" />
                                        <br/>

                                        <Typography variant="body1" component="h2" align='center'>
                                            {dataWave.horas_surtido ?? "No hay datos"}
                                        </Typography>
                                        <Typography variant="body1" component="h2" align='center'>
                                            Horas de surtido
                                        </Typography>
                                        <Divider variant="middle" />
                                        <br/>
                                        <Typography variant="body1" component="h2" align='center'>
                                            Piezas pickeadas vs surtidas
                                        </Typography>
                                        <Typography variant="body1" component="h2" align='center'>
                                            {(dataWave.piezas_surtidas_vs_piezas_pickeadas) ? dataWave.piezas_surtidas_vs_piezas_pickeadas+'%' : "No hay datos"}
                                        </Typography>
                                        <Divider variant="middle" />
                                        <br/>

                                        <Grid container spacing={1}>
                                            <Grid item xs={6} >
                                                <Typography variant="h6" component="h2" align='center' color="primary">
                                                    Dia
                                                </Typography>
                                                <Divider variant="middle" />
                                                <br/>
                                                <Typography variant="body1" component="h2" align='center'>
                                                    Cap. Prepacks
                                                </Typography>
                                                <Typography variant="body1" component="h2" align='center'>
                                                    { (dataWave.prepacks_procesados) ? calculateDaySorter(dataWave.prepacks_procesados)+"%" : "No hay datos" }
                                                </Typography>
                                                <Divider variant="middle" />
                                                <br/>
                                            </Grid>
                                            <Grid item xs={6} >
                                                <Typography variant="h6" component="h2" align='center' color="primary">
                                                    Semana
                                                </Typography>
                                                <Divider variant="middle" />
                                                <br/>
                                                <Typography variant="body1" component="h2" align='center'>
                                                    Cap. Prepacks
                                                </Typography>
                                                <Typography variant="body1" component="h2" align='center'>
                                                    { (dataWave.prepacks_procesados) ? calculateWeekSorter(dataWave.prepacks_procesados)+"%" : "No hay datos" }
                                                </Typography>
                                                <Divider variant="middle" />

                                            </Grid>
                                        </Grid>

                                    </CardBody>
                                    <CardFooter>
                                        <Button style={{width: '100%'}} size="small" variant="contained" color="primary" onClick={handleClickOpenSorter}>
                                            Recalcular
                                        </Button>
                                    </CardFooter>
                                </Card>
                            </Grid>
                        </Grid>
                    </Grid>


                    <Grid item xs={12}>
                        <Card>
                            <CardHeader color="success" stats icon>
                                <CardIcon color="success">
                                    <h1>DEPARTAMENTOS: {dataWave.deptos_procesados ?? 'N/A'}</h1>
                                </CardIcon>
                            </CardHeader>
                            <CardBody>
                                <MaterialTable
                                    columns={[
                                        { title: 'Division',  field: 'division' },
                                        { title: 'Departamento', field: 'departamento' },
                                        { title: 'Clasificación', field: 'clasificacion' },
                                        { title: 'Categoría', field: 'categoria' },
                                    ]}
                                    localization={materialTableLocaleES}
                                    tableRef={tableRef}
                                    data={departmentWave}
                                    options={{
                                        pageSize: 10,
                                        showTitle: false,
                                        search: true,
                                        paging: true,
                                        toolbar: true,
                                        padding: 'dense',
                                    }}
                                />
                            </CardBody>
                            <CardFooter>
                            </CardFooter>
                        </Card>
                    </Grid>
                </Grid>
            </TabPanel>
            <TabPanel index={1} value={value}>
                <PageHeader
                    title="Validacion Prepacks"
                    breadcrumbsData={breadcrumbsData}
                />
                <div style={{
                    zIndex:100,
                    position:'absolute',
                    marginLeft:'40%',
                    marginTop:'20%',
                }}>
                    <Fade
                        in={loading}
                        style={{
                            transitionDelay: loading ? '800ms' : '0ms',
                            textAlign:'center'
                        }}
                        disableShrink
                    >
                        <CircularProgress size={150} />
                    </Fade>
                </div>
                <Grid container spacing={1} style={{marginTop:'20px'}}>
                    <Grid item xs={12} >
                        <Card className={classes.root} style={{height: '91%'}}>
                            <CardHeader color="success" stats icon>
                                <CardIcon color="success">
                                    <h2>Validacion de PPK</h2>
                                </CardIcon>
                            </CardHeader>
                            <CardBody style={{
                                height: 100,
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center"}}>
                                <div className={classes.root}>
                                    <form className={classes.filters} noValidate autoComplete="off" style={{margin:'-10px' , textAlign:'center'}}>
                                        <input
                                            className={classes.input}
                                            id="contained-button-file"
                                            multiple
                                            type="file"
                                            onChange={ (event) => {
                                                handleSubmitFile(event);
                                                const file = event.target.files[0];
                                                readExcel(file);
                                            } }
                                        />
                                        <label htmlFor="contained-button-file">
                                            <Button variant="contained" color="primary" component="span">
                                                Seleccionar Archivo
                                            </Button>
                                            <Typography variant="h5" component="h5">
                                                { fileInput }
                                            </Typography>
                                        </label>
                                    </form>
                                </div>

                            </CardBody>
                        </Card>
                    </Grid>
                    <Grid item xs={12} style={{marginTop:'20px'}}>
                        <div style={{display: showTable ? 'none' : 'block' }}>
                            <MaterialTable
                                tableRef={tableRef}
                                columns={[
                                    { title: 'SKU', field: 'sku' },
                                    { title: 'PPK', field: 'ppk' },
                                    {
                                        title: 'PPK OMS',
                                        field: 'ppkOMS',
                                        type: 'string',
                                        render: rowData =>
                                            rowData.ppkOMS ? rowData.ppkOMS : 'NO HAY DATO',
                                    },
                                    {
                                        title: 'Coincidencia',
                                        field: 'match',
                                        defaultSort: 'asc',
                                        type: 'string',
                                        render: rowData =>
                                            rowData.match ? <Typography className={classes.typographyColor} variant="button" >SI</Typography> :
                                                <Typography variant="button" color="error">NO</Typography>,
                                    },
                                ]}
                                title={`Validacion de PPK`}
                                localization={materialTableLocaleES}
                                data={data}
                                options={{
                                    pageSize: 25,
                                    padding: 'dense',
                                    search: false,
                                    pageSizeOptions: [5, 10, 25, 50, 100],
                                    exportButton: true,
                                    exportCsv: (columns, rowData) => {
                                        let data = [];
                                        rowData.forEach( function(value, key, array) {
                                            let sign = 'NO';
                                            if (value.match === true) {
                                                sign = 'SI';
                                            }

                                            data[key] = [
                                                value.sku,
                                                value.ppk,
                                                value.ppkOMS,
                                                sign
                                            ]
                                        });
                                        const headerRow = columns.map(col => {
                                            if (typeof col.title === 'object') {
                                                return col.title.props.text;
                                            }
                                            return col.title;
                                        });
                                        const { exportDelimiter } = tableRef.current.props.options;
                                        const delimiter = exportDelimiter ? exportDelimiter : ",";
                                        const csvContent = [headerRow, ...data].map(e => e.join(delimiter)).join("\n");
                                        const csvFileName = tableRef.current.props.title;
                                        downloadCsv(csvContent, csvFileName);
                                    }
                                }}
                            />
                        </div>
                    </Grid>
                </Grid>
            </TabPanel>

            <TabPanel index={2} value={value}>
                <PageHeader
                    title="Inventario SKU's"
                    breadcrumbsData={breadcrumbsData}
                />
                <div style={{
                    zIndex:100,
                    position:'absolute',
                    marginLeft:'40%',
                    marginTop:'20%',
                }}>
                    <Fade
                        in={loading}
                        style={{
                            transitionDelay: loading ? '800ms' : '0ms',
                            textAlign:'center'
                        }}
                        disableShrink
                    >
                        <CircularProgress size={150} />
                    </Fade>
                </div>
                <Grid container spacing={1} style={{marginTop:'20px'}}>
                    <Grid item xs={12} >
                        <Card className={classes.root} style={{height: '91%'}}>
                            <CardHeader color="success" stats icon>
                                <CardIcon color="success">
                                    <h2>Consultar inventario SKUs</h2>
                                </CardIcon>
                            </CardHeader>
                            <CardBody style={{
                                height: 100,
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center"}}>
                                <div className={classes.root}>
                                    <form className={classes.filters} noValidate autoComplete="off" style={{margin:'-10px' , textAlign:'center'}}>
                                        <input
                                            className={classes.input}
                                            id="contained-button-file"
                                            multiple
                                            type="file"
                                            onChange={ (event) => {
                                                handleSubmitFileSku(event);
                                                const file = event.target.files[0];
                                                readExcelSku(file);
                                            } }
                                        />
                                        <label htmlFor="contained-button-file">
                                            <Button variant="contained" color="primary" component="span">
                                                Seleccionar Archivo
                                            </Button>
                                            <Typography variant="h5" component="h5">
                                                { fileInputSku }
                                            </Typography>
                                        </label>
                                    </form>
                                </div>

                            </CardBody>
                        </Card>
                    </Grid>
                    <Grid item xs={12} style={{marginTop:'20px'}}>
                        <div style={{display: showTable ? 'none' : 'block' }}>
                            <MaterialTable
                                tableRef={tableRef}
                                columns={[
                                    { title: 'SKU', field: 'sku' },
                                    { title: 'Almacen', field: 'almacenId' },
                                    { title: 'Cantidad', field: 'catidadPzas' },
                                    { title: 'PPK', field: 'prepack' },
                                ]}
                                title={`Reporte de inventario SKU`}
                                localization={materialTableLocaleES}
                                data={dataSku}
                                options={{
                                    pageSize: 25,
                                    padding: 'dense',
                                    search: false,
                                    pageSizeOptions: [5, 10, 25, 50, 100],
                                    exportButton: true,
                                    exportCsv: (columns, rowData) => {
                                        let data = [];
                                        rowData.forEach( function(value, key, array) {
                        
                                            data[key] = [
                                                value.sku,
                                                value.almacenId,
                                                value.catidadPzas,
                                                value.prepack
                                            ]
                                        });
                                        const headerRow = columns.map(col => {
                                            if (typeof col.title === 'object') {
                                                return col.title.props.text;
                                            }
                                            return col.title;
                                        });
                                        const { exportDelimiter } = tableRef.current.props.options;
                                        const delimiter = exportDelimiter ? exportDelimiter : ",";
                                        const csvContent = [headerRow, ...data].map(e => e.join(delimiter)).join("\n");
                                        const csvFileName = tableRef.current.props.title;
                                        downloadCsv(csvContent, csvFileName);
                                    }
                                }}
                            />
                        </div>
                    </Grid>
                </Grid>
            </TabPanel>

            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Nuevo calculo de capacidades en PICKING</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Ingresa los datos a continuación para generar de nuevo los calculos:
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        name="horas_lab_pick"
                        id="horas_lab_pick"
                        label="Horas laborables"
                        type="email"
                        fullWidth
                        onChange={handleChangePicking}
                    />
                    <TextField
                        margin="dense"
                        id="pickers"
                        name="pickers"
                        label="Pickers por día"
                        type="email"
                        fullWidth
                        onChange={handleChangePicking}
                    />
                    <TextField
                        margin="dense"
                        id="box_picker_hr"
                        name="box_picker_hr"
                        label="Cajas que se pueden pickear (persona x hora)"
                        type="email"
                        fullWidth
                        onChange={handleChangePicking}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancelar
                    </Button>
                    <Button onClick={handleUpdatePicking} color="primary">
                        Calcular
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={openSorter} onClose={handleCloseSorter} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Nuevo calculo de capacidades en SORTER</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Ingresa los datos a continuación para generar de nuevo los calculos:
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        name="horas_lab_sort"
                        id="horas_lab_sort"
                        label="Horas laborables"
                        type="email"
                        fullWidth
                        onChange={handleChangeSorter}
                    />
                    <TextField
                        margin="dense"
                        id="inductores"
                        name="inductores"
                        label="Surtidores por día"
                        type="email"
                        fullWidth
                        onChange={handleChangeSorter}
                    />
                    <TextField
                        margin="dense"
                        id="prepacks_min"
                        name="prepacks_min"
                        label="Prepacks que se pueden inducir (persona x min)"
                        type="email"
                        fullWidth
                        onChange={handleChangeSorter}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseSorter} color="primary">
                        Cancelar
                    </Button>
                    <Button onClick={handleUpdateSorter} color="primary">
                        Calcular
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={openNullData}
                onClose={handleCloseNullData}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"La busqueda no arrojo resultados"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Realice una nueva busqueda
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseNullData} color="primary" autoFocus>
                        Aceptar
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
}