import React, { useState } from 'react';
import {
  Drawer,
  Typography,
  Box,
  Button,
  Table,
  TableHead,
  TableContainer,
  TableBody,
  TableCell,
  TableRow,
  CircularProgress,
} from '@material-ui/core';
import LibraryAddCheckIcon from '@material-ui/icons/LibraryAddCheck';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import MaterialTable from 'material-table';
import WaveLines from './WaveLines';
import materialTableLocaleES from '../../../components/MaterialTableLocaleES';

// Components
import { getDevolutionTransfers, makeDevolution } from '../../../api/WaveServices/WavesApi';
import SnackBarMessage from '../../../components/SnackBarMessage';

const drawerWidth = 1050;
const useStyles = makeStyles(theme => ({
  container: {
    width: drawerWidth,
  },
  containerPaper: {
    width: drawerWidth,
    padding: 20,
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  smallColor: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
}));

const initialState = {
  orderGroupId: 0,
  transferData: [],
  updated_at: '',
  total_pieces: 0,
  total_sku: 0,
  total_boxes: 0,
  total_transfers: 0,
  percentage_available: 0,
};

export default function Devolutions({
  formOpen,
  toggleForm,
  refreshTable,
  dataObject,  
}) {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [openLinesDrawer, setOpenLinesDrawer] = useState(false);
  const [dataLinesDrawer, setDataLinesDrawer] = useState(0);
  const [form, setForm] = useState(initialState);
  const [closeClick, setCloseClick] = useState('');

  const clearData = () => {
    setForm(initialState);
    setCloseClick('');
    setIsLoading(true);
  };

  const toggleLinesDrawer = () => {
    setDataLinesDrawer(0);
    setOpenLinesDrawer(!setOpenLinesDrawer);
  };

  const initData = () => {
    setIsLoading(true);
    getDevolutionTransfers()
      .then(response => {        
        setForm({
          transferData: response.data,
          updated_at: response.updated_at,
          total_sku: response.total_sku,
          total_pieces: response.total_pieces,
          total_transfers: response.total_transfers,
        });
        setIsLoading(false);
      })
      .catch(error => {
        console.log(error);
        setIsLoading(false);
      });
    setCloseClick(false);
  };

  const handleMakeDevolution = () => {
    setIsLoading(true);
    makeDevolution(form.transferData)
      .then(response => {
        setIsLoading(false);
        toggleForm();
      })
      .catch(error => {
        console.log(error);
        setIsLoading(false);
      });

    setCloseClick(false);
  };

  return (
    <Box>      
      <Drawer
        anchor="right"
        open={formOpen}
        onClose={toggleForm}
        className={classes.container}
        classes={{ paper: classes.containerPaper }}
      >
        <Box display="flex" flexDirection="row">
          <Button
            variant="contained"
            color="primary"
            style={{ marginRight: 50, borderRadius: 20, color: 'white' }}
            size="small"
            disabled={isLoading}
            onClick={handleMakeDevolution}
          >
            <LibraryAddCheckIcon />
            Crear Ola de devolución
            {isLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
          </Button>          
        </Box>
        <Box>
          <TableContainer>
            <Table aria-label="Resumen">
              <TableHead>
                <TableRow>
                  <TableCell align="right">Total Sku's</TableCell>                  
                  <TableCell align="right">Total Piezas</TableCell>
                  <TableCell align="right">Total Transferencias</TableCell>                  
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell align="right">{form.total_sku}</TableCell>                  
                  <TableCell align="right">{form.total_pieces}</TableCell>
                  <TableCell align="right">{form.total_transfers}</TableCell>                  
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        <MaterialTable
          columns={[            
            { title: 'SKU', field: 'sku', type: 'numeric' },
            { title: 'Piezas', field: 'totalPiezas', type: 'numeric' },
            { title: 'Transferencia', field: 'transferencia', type: 'numeric' },
          ]}
          title={`Transferencias de devolución`}
          isLoading={isLoading}
          localization={materialTableLocaleES}
          data={form.transferData}
          options={{
            pageSize: 10,
            padding: 'dense',
            pageSizeOptions: [5, 10, 20, 50, 100],
          }}          
        />

        <Box component="form" className={classes.form}>
          <Box />
          <Box className={classes.bottomSpace}>
            {formOpen && closeClick === '' && initData()}
            {!formOpen && closeClick !== '' && clearData()}
          </Box>
        </Box>
      </Drawer>

      <SnackBarMessage
        open={form.policyAccessError}
        message={form.roleAccessMessage}
        onClose={() => setForm({ ...form, policyAccessError: false })}
        variant="warning"
      />
    </Box>
  );
}

Devolutions.defaultProps = {
  refreshTable: null,
  dataObject: 0,  
};

Devolutions.propTypes = {
  refreshTable: PropTypes.func,
  formOpen: PropTypes.bool.isRequired,
  toggleForm: PropTypes.func.isRequired,
  dataObject: PropTypes.number,
};
