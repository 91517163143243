import React from 'react';
import { Box } from '@material-ui/core';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

// Routes
import { SQS_ROUTES } from '../../Routes';

// Styles
import { AppStyles } from '../../AppStyles';

// Components
import { WithPublicLayout } from '../../components/AppLayout';
import SQSPage from './pages/SqsPage';
import { NotFoundPage } from '../NotFoundPage';

function UsersRouter() {
    const appStyles = AppStyles();
    const { path } = useRouteMatch();

    return (
        <Box id="app-users-router" component="main" className={appStyles.pageRouter}>
            <Switch>
                <Route exact path={`${path}${SQS_ROUTES.DASHBOARD_SQS}`} component={SQSPage} />
                <Route component={NotFoundPage} />
            </Switch>
        </Box>
    );
}

export default WithPublicLayout(UsersRouter);
