import React from 'react';
import { Box } from '@material-ui/core';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

// Routes
import { PRIORITY_ROUTES } from '../../Routes';

// Styles
import { AppStyles } from '../../AppStyles';

// Components
import { WithPublicLayout } from '../../components/AppLayout';
import PriorityPage from './pages/PriorityPage';
import StoresPage from '../Store/pages/StoresPage';
import ProductFamilyPage from './pages/ProductFamilyPage';
import CatalogDashboardPage from './pages/HomePriorities';
import RoutesPage from '../Route/pages/RoutesPage';
import ReportSupplyOrderPage from '../ReportSupplyOrder/pages/ReportSupplyOrderPage';
import StylesPage from '../Style/pages/StylesPage';
import UserReportAdminPage from '../UserReportAdmin/UserReportAdminPage';

import { NotFoundPage } from '../NotFoundPage';
import PriorityDrawer from './components/PriorityDrawer';

function PriorityRouter() {
  const appStyles = AppStyles();
  const { path } = useRouteMatch();

  return (
    <Box id="app-users-router" component="main" className={appStyles.pageRouter}>
      <PriorityDrawer />
      <Switch>
        <Route exact path={path} component={CatalogDashboardPage} />
        <Route
          exact
          path={`${path}${PRIORITY_ROUTES.PRODUCT_FAMILIES}`}
          component={ProductFamilyPage}
        />
        <Route exact path={`${path}${PRIORITY_ROUTES.PRIORITY}`} component={PriorityPage} />
        <Route exact path={`${path}${PRIORITY_ROUTES.STORES}`} component={StoresPage} />
        <Route exact path={`${path}${PRIORITY_ROUTES.ROUTES}`} component={RoutesPage} />
        <Route exact path={`${path}${PRIORITY_ROUTES.REPORT_SUPPLY}`} component={ReportSupplyOrderPage} />
        <Route exact path={`${path}${PRIORITY_ROUTES.STYLES}`} component={StylesPage} />
        <Route exact path={`${path}${PRIORITY_ROUTES.USER_REPORTS_ADMIN}`} component={UserReportAdminPage} />
        <Route component={NotFoundPage} />
      </Switch>
    </Box>
  );
}

export default WithPublicLayout(PriorityRouter);
