import { apiClientBearerToken } from '../ApiClientConfig';
import { getCookie } from '../../AppCookies';
import Logger from '../../Logger';

const Log = new Logger('src/api/ShopServices/PortagesServicesApi.js');

const PORTAGES_PATH = '/shops/portages';

export const getAllPortages = (
  paginated: Boolean,
  size?: Number = null,
  page?: Number = null,
  search?: String = null,
  resourceView?: String = 'not_removed',
): Promise<Array<Object>> => {
  return apiClientBearerToken()
    .get(PORTAGES_PATH, {
      params: {
        paginated,
        size,
        page,
        search: search?.trim(),
        resourceView,
      },
    })
    .then(response => {
      return Promise.resolve(response.data);
    })
    .catch(error => {
      Log.error(
        getAllPortages.name,
        error.message,
        error.response ? error.response : error,
        !(error.response && error.response.status === 404),
      );
      return Promise.reject();
    });
};

export const getPortagesById = (id: Number): Promise<Object> => {
  return apiClientBearerToken()
    .get(`${PORTAGES_PATH}/${id}`)
    .then(response => {
      return Promise.resolve(response.data);
    })
    .catch(error => {
      Log.error(getPortagesById.name, error.message, error.response ? error.response : error);
      const errors = {
        errorCode: error.response.request.response.code,
        errorMessage: error.response.request.response.message,
      };
      return Promise.reject(errors);
    });
};

export const createPortage = ({
  tdaJda,
  name,
  email,
  telephone,
  telephoneExtension,
  surface,
  status,
  comingSoon,
  managerName,
  branchOffice,
  district,
}): Promise<Object> => {
  return apiClientBearerToken()
    .post(PORTAGES_PATH, {
      tdaJda,
      name,
      email,
      telephone: telephone === '' ? null : telephone,
      telephoneExtension: telephoneExtension === '' ? null : telephoneExtension,
      surface: surface === '' ? null : surface,
      status,
      comingSoon,
      managerName: managerName === '' ? null : managerName,
      branchOffice: branchOffice === '' ? null : branchOffice,
      district,
    })
    .then(response => {
      return Promise.resolve(response.data);
    })
    .catch(error => {
      if (error.response) {
        if (!error.response.data.errors) {
          const errorDuplicate = {
            errorTdaMessage: 'Código de JDA duplicado.',
          };
          return Promise.reject(errorDuplicate);
        }
        const errors = {
          errorSurfaceMessage: null,
          errorManagerNameMessage: null,
          errorBranchOfficeMessage: null,
        };
        error.response.data.errors.forEach(e => {
          if (e.field === 'portage.surface') {
            errors.errorSurfaceMessage = e.message;
          }
          if (e.field === 'portage.managerName') {
            errors.errorManagerNameMessage = e.message;
          }
          if (e.field === 'portage.branchOffice') {
            errors.errorBranchOfficeMessage = e.message;
          }
        });

        return Promise.reject(errors);
      }

      Log.error(createPortage.name, error.message, error.response ? error.response : error);
      return Promise.reject();
    });
};

export const updatePortageById = (
  id: Number,
  tdaJda: String,
  name: String,
  email: String,
  branchOffice: String,
  telephone: String,
  telephoneExtension: String,
  surface: Number,
  status: Boolean,
  comingSoon: Boolean,
  managerName: String,
  idDistrict: Number,
): Promise<Object> => {
  const userToken = getCookie('user_token');

  return apiClientBearerToken(userToken.access_token)
    .patch(`${PORTAGES_PATH}/${id}`, {
      tdaJda,
      name,
      email,
      branchOffice: branchOffice === '' ? null : branchOffice,
      telephone: telephone === '' ? null : telephone,
      telephoneExtension: telephoneExtension === '' ? null : telephoneExtension,
      surface: surface === '' ? null : surface,
      status,
      comingSoon,
      managerName: managerName === '' ? null : managerName,
      district: idDistrict > 0 ? { id: idDistrict } : null,
    })
    .then(response => {
      return Promise.resolve(response.data);
    })
    .catch(error => {
      if (error.response) {
        if (!error.response.data.errors) {
          const errorDuplicate = {
            shopTdaJdaMessageError: 'Código de JDA duplicado.',
          };
          if (
            error.response.data.message === "Duplicate entry '123' for key 'PORTAGE_TDA_JDA_INDEX'"
          )
            errorDuplicate.shopTdaJdaMessageError = 'Código de JDA duplicado.';
          else errorDuplicate.shopTdaJdaMessageError = error.response.data.message;
          return Promise.reject(errorDuplicate);
        }
        const errors = {
          shopTdaJdaMessageError: null,
          shopNameErrorMessage: null,
          shopEmailMessageError: null,
          shopBranchOfficeMessageError: null,
          shopTelephoneMessageError: null,
          shopTelephoneExtensionMessageError: null,
          shopManagerNameMessageError: null,
          shopSurfaceMessageError: null,
          shopDistrictIdMessageError: null,
        };
        error.response.data.errors.forEach(e => {
          if (e.field === 'portage.tdaJda') {
            errors.shopTdaJdaMessageError = e.message;
          }
          if (e.field === 'portage.name') {
            errors.shopNameErrorMessage = e.message;
          }
          if (e.field === 'portage.email') {
            errors.shopEmailMessageError = e.message;
          }
          if (e.field === 'portage.branchOffice') {
            errors.shopBranchOfficeMessageError = e.message;
          }
          if (e.field === 'portage.telephone') {
            errors.shopTelephoneMessageError = e.message;
          }
          if (e.field === 'portage.telephoneExtension') {
            errors.shopTelephoneExtensionMessageError = e.message;
          }
          if (e.field === 'portage.managerName') {
            errors.shopManagerNameMessageError = e.message;
          }
          if (e.field === 'portage.surface') {
            errors.shopSurfaceMessageError = e.message;
          }
          if (e.field === 'portage.district.id') {
            errors.shopDistrictIdMessageError = e.message;
          }
        });

        return Promise.reject(errors);
      }

      Log.error(updatePortageById.name, error.message, error.response ? error.response : error);
      return Promise.reject();
    });
};

export const deletePortage = (id: Number, restore?: Boolean = false): Promise<Object> => {
  return apiClientBearerToken()
    .delete(`${PORTAGES_PATH}/${id}`, {
      params: {
        restore,
      },
    })
    .then(() => {
      return Promise.resolve();
    })
    .catch(error => {
      Log.error(deletePortage.name, error.message, error.response ? error.response : error);
      return Promise.reject();
    });
};

// Portage

export const getSchedulePortageByID = (id: Number): Promise<Object> => {
  return apiClientBearerToken()
    .get(`${PORTAGES_PATH}/${id}/schedule`)
    .then(response => {
      return Promise.resolve(response.data);
    })
    .catch(error => {
      Log.error(
        getSchedulePortageByID.name,
        error.message,
        error.response ? error.response : error,
        !(error.response && error.response.status === 404),
      );
      return Promise.reject();
    });
};

export const updateSchedulePortageByID = (
  id: Number,
  mondayOpen: String,
  mondayClose: String,
  mondayAvailable: Boolean,
  tuesdayOpen: String,
  tuesdayClose: String,
  tuesdayAvailable: Boolean,
  wednesdayOpen: String,
  wednesdayClose: String,
  wednesdayAvailable: Boolean,
  thursdayOpen: String,
  thursdayClose: String,
  thursdayAvailable: Boolean,
  fridayOpen: String,
  fridayClose: String,
  fridayAvailable: Boolean,
  saturdayOpen: String,
  saturdayClose: String,
  saturdayAvailable: Boolean,
  sundayOpen: String,
  sundayClose: String,
  sundayAvailable: Boolean,
): Promise<Object> => {
  const userToken = getCookie('user_token');

  return apiClientBearerToken(userToken.access_token)
    .patch(`${PORTAGES_PATH}/${id}/schedule`, {
      mondayOpen,
      mondayClose,
      mondayAvailable,
      tuesdayOpen,
      tuesdayClose,
      tuesdayAvailable,
      wednesdayOpen,
      wednesdayClose,
      wednesdayAvailable,
      thursdayOpen,
      thursdayClose,
      thursdayAvailable,
      fridayOpen,
      fridayClose,
      fridayAvailable,
      saturdayOpen,
      saturdayClose,
      saturdayAvailable,
      sundayOpen,
      sundayClose,
      sundayAvailable,
    })
    .then(response => {
      return Promise.resolve(response.data);
    })
    .catch(error => {
      if (error.response) {
        const errors = {
          mondayMessageError: null,
          tuesdayMessageError: null,
          wednesdayMessageError: null,
          thursdayMessageError: null,
          fridayMessageError: null,
          saturdayMessageError: null,
          sundayMessageError: null,
        };
        error.response.data.errors.forEach(e => {
          if (e.message.includes('Lunes') || e.message.includes('Monday')) {
            errors.mondayMessageError = e.message;
          }
          if (e.message.includes('Martes') || e.message.includes('Tuesday')) {
            errors.tuesdayMessageError = e.message;
          }
          if (e.message.includes('Miércoles') || e.message.includes('Wednesday')) {
            errors.wednesdayMessageError = e.message;
          }
          if (e.message.includes('Jueves') || e.message.includes('Thursday')) {
            errors.thursdayMessageError = e.message;
          }
          if (e.message.includes('Viernes') || e.message.includes('Friday')) {
            errors.fridayMessageError = e.message;
          }
          if (e.message.includes('Sábado') || e.message.includes('Saturday')) {
            errors.saturdayMessageError = e.message;
          }
          if (e.message.includes('Domingo') || e.message.includes('Sunday')) {
            errors.sundayMessageError = e.message;
          }
        });

        return Promise.reject(errors);
      }

      Log.error(
        updateSchedulePortageByID.name,
        error.message,
        error.response ? error.response : error,
      );
      return Promise.reject();
    });
};

// Address

export const getPortageAddressByID = (id: Number): Promise<Object> => {
  return apiClientBearerToken()
    .get(`${PORTAGES_PATH}/${id}/address`)
    .then(response => {
      return Promise.resolve(response.data);
    })
    .catch(error => {
      Log.error(
        getPortageAddressByID.name,
        error.message,
        error.response ? error.response : error,
        !(error.response && error.response.status === 404),
      );
      return Promise.reject();
    });
};

export const updatePortageAddressByID = (
  id: Number,
  street: String,
  latitude: Number,
  longitude: Number,
  state: String,
  city: String,
  zip: String,
  extNumber: String,
  intNumber: String,
  neighborhood: String,
  town: String,
  countryId: Number,
): Promise<Object> => {
  const userToken = getCookie('user_token');

  return apiClientBearerToken(userToken.access_token)
    .patch(`${PORTAGES_PATH}/${id}/address`, {
      street,
      latitude,
      longitude,
      state,
      city,
      zip,
      extNumber: extNumber === '' ? null : extNumber,
      intNumber: intNumber === '' ? null : intNumber,
      neighborhood: neighborhood === '' ? null : neighborhood,
      town: town === '' ? null : town,
      country: countryId > 0 ? { id: countryId } : null,
    })
    .then(response => {
      return Promise.resolve(response.data);
    })
    .catch(error => {
      if (error.response) {
        if (!error.response.data.errors) {
          const errorDuplicate = {
            errorMessage: 'Código de JDA duplicado.',
          };
          return Promise.reject(errorDuplicate);
        }
        const errors = {
          addressStreetErrorMessage: null,
          addressLatitudeMessageError: null,
          addressLongitudeMessageError: null,
          addressStateMessageError: null,
          addressCityMessageError: null,
          addressZipMessageError: null,
          addressExtNumberMessageError: null,
          addressIntNumberMessageError: null,
          addressNeighborhoodMessageError: null,
          addressTownMessageError: null,
          addressCountryMessageError: null,
        };
        error.response.data.errors.forEach(e => {
          if (e.field === 'address.street') {
            errors.addressStreetErrorMessage = e.message;
          }
          if (e.field === 'address.latitude') {
            errors.addressLatitudeMessageError = e.message;
          }
          if (e.field === 'address.longitude') {
            errors.addressLongitudeMessageError = e.message;
          }
          if (e.field === 'address.state') {
            errors.addressStateMessageError = e.message;
          }
          if (e.field === 'address.city') {
            errors.addressCityMessageError = e.message;
          }
          if (e.field === 'address.zip') {
            errors.addressZipMessageError = e.message;
          }
          if (e.field === 'address.extNumber') {
            errors.addressExtNumberMessageError = e.message;
          }
          if (e.field === 'address.intNumber') {
            errors.addressIntNumberMessageError = e.message;
          }
          if (e.field === 'address.neighborhood') {
            errors.addressNeighborhoodMessageError = e.message;
          }
          if (e.field === 'address.town') {
            errors.addressTownMessageError = e.message;
          }
          if (e.field === 'address.country') {
            errors.addressCountryMessageError = e.message;
          }
        });

        return Promise.reject(errors);
      }

      Log.error(
        updatePortageAddressByID.name,
        error.message,
        error.response ? error.response : error,
      );
      return Promise.reject();
    });
};

// shop
export const getAllPortageShopsByID = (
  id: Number,
  paginated: Boolean,
  size: Number,
  page: Number,
): Promise<Array<Object>> => {
  return apiClientBearerToken()
    .get(`${PORTAGES_PATH}/${id}/shops`, {
      params: {
        paginated,
        size,
        page,
      },
    })
    .then(response => {
      return Promise.resolve(response.data);
    })
    .catch(error => {
      Log.error(
        getAllPortageShopsByID.name,
        error.message,
        error.response ? error.response : error,
        !(error.response && error.response.status === 404),
      );
      return Promise.reject();
    });
};
