import React, { useState, useRef, useEffect } from 'react';
import { Box } from '@material-ui/core';

// Components
import { Visibility as ViewIcon } from '@material-ui/icons';
import AdminForm from '../components/PriorityForm';
import PageHeader from '../../../components/PageHeader';
import WithSnackBarMessages from '../../../components/WithSnackBarMessages';
import DataTable from '../../../components/DataTable';

// Security
// import Permissions from '../../../AppsPermissions/UsersAdministratorPermissions';
// import { hasAuthority } from '../../AccessValidators';

// Styles
import { AppStyles } from '../../../AppStyles';

// Data Apis
import { getAllPriorities, deletePriority } from '../../../api/PriorityServices/PriorityApi';

// Routes
import {APP_ROUTES, PRIORITY_ROUTES} from '../../../Routes';
import {useSnackbar} from "notistack";
import { getPermissions } from '../../../api/AuthApi';

const breadcrumbsData = [
    {
        id: 1,
        label: 'Catalogos',
        base: APP_ROUTES.PRIORITY,
    },
    {
        id: 2,
        label: 'Prioridades',
        base: PRIORITY_ROUTES.PRIORITY,
    }
];

function PriorityPage() {
    const appStyles = AppStyles();
    const tableRef = useRef();
    const { enqueueSnackbar } = useSnackbar();
    const [openForm, setOpenForm] = useState(false);
    const [dataObject, setDataObject] = useState(0);    
    const [access, setAccess] = useState(false);

    const toggleForm = () => {
        if (tableRef.current) {
            tableRef.current.state.query.page = 0;
            if (openForm) {
                tableRef.current.onQueryChange();
            }
        }
        setOpenForm(!openForm);
        setDataObject(0);
    };
    const messageError = (message, variant) => {
        enqueueSnackbar(message, {
            variant,
        });
    };

    useEffect(() => {        
        getPermissions().then(response => {
            let check = response.filter(obj => obj.name === 'Full Access');
            if (check.length === 0) {              
              setAccess(false);
            } else {              
              setAccess(true);
            }
          }).catch(e => {
              console.log(e);
          })
    }, []);

    const getPrioritiesData = query =>
        //canGetAllUsers ?

        getAllPriorities(query.pageSize, query.page, query.search)
            .then(response => {                
                return {
                    ...query,
                    data: response.priorities.data,
                    page: response.priorities.current_page - 1,
                    totalCount: response.priorities.total,
                }})
            .catch((error) => {                
                return {
                    ...query,
                    page: 0,
                    pageSize: 15,
                    totalCount: 0,
                    data: [],
                }
            });    

    const deletePriorityData = oldData =>
        new Promise((resolve, reject) => {
            setTimeout(() => {
                deletePriority(oldData.id)
                    .then(() => {
                        if (tableRef.current) {
                            tableRef.current.state.query.page = 0;
                            tableRef.current.onQueryChange();
                        }
                        messageError('Prioridad eliminada', 'warning');
                    })
                    .catch(() => reject());
                resolve();
            }, 1000);
        });

    return (
        <Box id="app-users-admins-page" component="main" className={appStyles.pageRoot} style={{ backgroundColor: 'white', height: '100vh' }}>
            <PageHeader
                title="Prioridades"
                titleButton="Nueva Prioridad"
                displayButton                
                canExecuteButton={access}
                action={toggleForm}
                breadcrumbsData={breadcrumbsData}
            />

            <AdminForm
                formOpen={openForm}
                toggleForm={toggleForm}
                refreshTable={() => tableRef.current && tableRef.current.onQueryChange()}
                dataObject={dataObject}
                canUpdateUser={access}
                canGetUserData={access}
                canUpdateUserData={access}
            />
            <DataTable
                nameEntity="Prioridad"
                columns={[
                    {
                        title: 'Nombre',
                        field: 'label',
                        type: 'string',
                    },
                    {
                        title: 'Orden',
                        field: 'order',
                        type: 'string',
                    },


                ]}
                tableActions={[
                    {
                        disabled: !access,
                        icon: () => (
                            <ViewIcon color={access ? 'primary' : 'disabled'} className="icon-small" />
                        ),
                        tooltip: true ? 'Ver Prioridad' : undefined,
                        onClick: (event, rowData) => {
                            setDataObject(rowData.id);
                            setOpenForm(!openForm);
                        },
                    },
                ]}
                tableRef={tableRef}
                getDataFunction={getPrioritiesData}
                deleteDataFunction={deletePriorityData}
                canGetAll={true}
                canDelete={access}

            />
        </Box>
    );
}

export default WithSnackBarMessages(PriorityPage);
