import { apiClientBearerToken } from '../ApiClientConfig';
import Logger from '../../Logger';

const Log = new Logger('src/api/WaveServices/WavesApi.js');

const WAVES_PATH = '/waves';
const ORDER_GROUPS_PATH = '/ordergroups';
const DIVISIONS_PATH = '/divisions';
const CLASSIFICATIONS_PATH = '/classifications';
const FAMILIES_PATH = '/productfamily';
const ROUTES_PATH = '/routes';
const ZONES_PATH = '/zones';
const CLASSES_PATH = '/productclasses';
const TYPES_PATH = '/producttypes';
const PRODUCT_PROVIDERS = '/productprovider';
const STORES_PATH = '/stores';
const PALLET_PATH = 'pallets';
const REPORT_PATH = '/report';
const STYLES_PATH = '/styles';
const LINES_PATH = '/lines';

export const getWaveByArea = (
    area: Number,
    wave_id : Number
    ) : Promise<Array<Object>> => {
    return apiClientBearerToken()
        .get(`${WAVES_PATH}/getinsorter`,{
            params : {
                area,
                wave_id
            }
        })
        .then(response => {
            return Promise.resolve(response);
        })
        .catch(error => {
            Log.error(
                getWaveByArea.name,
                error.message,
                error.response ? error.response : error,
                !(error.response && error.response.status === 404),
            );
            return Promise.reject();
        });
};

export const getDataDashboard = () : Promise<Array<Object>> => {
 return apiClientBearerToken()
  .get(`${WAVES_PATH}/getdata`)
  .then(response => {
   return Promise.resolve(response);
  })
  .catch(error => {
   Log.error(
    getDataDashboard.name,
    error.message,
    error.response ? error.response : error,
    !(error.response && error.response.status === 404),
   );
   return Promise.reject();
  });
};

export const getWaveById = (
    id: Number,
): Promise<Array<Object>> => {
    return apiClientBearerToken()
        .get(`${WAVES_PATH}/get`, {
            params: {
               id,
            },
        })
        .then(response => {
            return Promise.resolve(response.data);
        })
        .catch(error => {
            Log.error(
                getWaveById.name,
                error.message,
                error.response ? error.response : error,
                !(error.response && error.response.status === 404),
            );
            return Promise.reject();
        });
};

export const getAllWaves = (
  paginated: Boolean,
  size?: Number,
  page?: Number,
  search?: String,
  status?: Number,
  dateInit?: String,
  dateEnd?: String,
): Promise<Array<Object>> => {
  return apiClientBearerToken()
    .get(`${WAVES_PATH}/getall`, {
      params: {
        status,
        dateInit,
        dateEnd,
        paginated,
        size,
        page,
        search: search?.trim(),
      },
    })
    .then(response => {
      return Promise.resolve(response.data);
    })
    .catch(error => {
      Log.error(
        getAllWaves.name,
        error.message,
        error.response ? error.response : error,
        !(error.response && error.response.status === 404),
      );
      return Promise.reject();
    });
};

export const getAllProviders = (order_group?: Number): Promise<Array<Object>> => {
  return apiClientBearerToken()
    .get(`${PRODUCT_PROVIDERS}/all`, {
      params: {order_group}
    })
    .then(response => {
      return Promise.resolve(response.data);
    })
    .catch(error => {
      Log.error(
        getAllProviders.name,
        error.message,
        error.response ? error.response : error,
        !(error.response && error.response.status === 404),
      );
      return Promise.reject();
    });
};

export const getAllStores = (): Promise<Array<Object>> => {
  return apiClientBearerToken()
    .get(`${STORES_PATH}/list`)
    .then(response => {
      return Promise.resolve(response.data);
    })
    .catch(error => {
      Log.error(
        getAllStores.name,
        error.message,
        error.response ? error.response : error,
        !(error.response && error.response.status === 404),
      );
      return Promise.reject();
    });
};

export const getAllOrderGroups = (
  paginated: Boolean,
  size?: String,
  page?: Number,
  dateInit?: String,
  dateEnd?: String,
  style?: String,
  orderBy?: String,
  orderDirection?: String,
): Promise<Array<Object>> => {
  return apiClientBearerToken()
    .get(`${ORDER_GROUPS_PATH}/getcurrentweek`, {
      params: { paginated, size, page ,dateInit, dateEnd, style, orderBy, orderDirection },
    })
    .then(response => {      
      return Promise.resolve(response.data);
    })
    .catch(error => {
      Log.error(
        getAllOrderGroups.name,
        error.message,
        error.response ? error.response : error,
        !(error.response && error.response.status === 404),
      );
      return Promise.reject();
    });
};

export const getAllWavesForPicking = (
  paginated: Boolean,
  size?: Number,
  page?: Number,
  search?: String,
): Promise<Array<Object>> => {
  return apiClientBearerToken()
    .get(`${WAVES_PATH}/getforpicking`, {
      params: { paginated },
    })
    .then(response => {
      return Promise.resolve(response.data);
    })
    .catch(error => {
      Log.error(
        getAllWavesForPicking.name,
        error.message,
        error.response ? error.response : error,
        !(error.response && error.response.status === 404),
      );
      return Promise.reject();
    });
};

export const getAllWavesForFinished = (
    dateInit: String,
    dateEnd: String,
    wave:Number,
    size?: Number,
    page?: Number,
    search?: String,
): Promise<Array<Object>> => {
    return apiClientBearerToken()
        .get(`${WAVES_PATH}/getforfinished`, {
            params: {
                dateInit,
                dateEnd,
                wave,
                size,
                page,
                search: search?.trim(),
            }
        })
        .then(response => {
            return Promise.resolve(response.data);
        })
        .catch(error => {
            Log.error(
                getAllWavesForFinished.name,
                error.message,
                error.response ? error.response : error,
                !(error.response && error.response.status === 404),
            );
            return Promise.reject();
        });
};

export const getDetailsForWaveRef = (
  wave_id: String,
  department_id: String,
  page: Number,
  size: Number,
): Promise<Array<Object>> => {
  return apiClientBearerToken()
    .get(`${WAVES_PATH}/getdetailswaveref`, {
      params: { wave_id, department_id, page, size },
    })
    .then(response => {
      return Promise.resolve(response.data);
    })
    .catch(error => {
      Log.error(
        getDetailsForWaveRef.name,
        error.message,
        error.response ? error.response : error,
        !(error.response && error.response.status === 404),
      );
      return Promise.reject();
    });
};

export const getPalletById = (
  palletId: Number,
  page?: Number,
  per_page?: Number,
): Promise<Array<Object>> => {
  return apiClientBearerToken()
    .get(`${PALLET_PATH}/${palletId}`, {
      params: {
        page,
        per_page,
      },
    })
    .then(response => {
      return Promise.resolve(response.data);
    })
    .catch(error => {
      Log.error(
        getPalletById.name,
        error.message,
        error.response ? error.response : error,
        !(error.response && error.response.status === 404),
      );
      return Promise.reject();
    });
};

export const getOnlyPalletsByZone = (
 zoneId: Number,
 wave: Number,
 page?: Number,
 per_page?: Number,
): Promise<Array<Object>> => {
 return apiClientBearerToken()
  .get(`${PALLET_PATH}/getbyzone/${zoneId}`, {
   params: {
    wave,
    page,
    per_page,
   },
  })
  .then(response => {
   return Promise.resolve(response.data);
  })
  .catch(error => {
   Log.error(
    getOnlyPalletsByZone.name,
    error.message,
    error.response ? error.response : error,
    !(error.response && error.response.status === 404),
   );
   return Promise.reject();
  });
};

export const getPalletsByZone = (
    zoneId: Number,
    page?: Number,
    per_page?: Number,
): Promise<Array<Object>> => {
    return apiClientBearerToken()
        .get(`${PALLET_PATH}/zone/${zoneId}`, {
            params: {
                page,
                per_page,
            },
        })
        .then(response => {
            return Promise.resolve(response.data);
        })
        .catch(error => {
            Log.error(
                getPalletsByZone.name,
                error.message,
                error.response ? error.response : error,
                !(error.response && error.response.status === 404),
            );
            return Promise.reject();
        });
};

export const getDepartmentsOrderById = (
  idOrder: Number,
  idDivision: Number,
): Promise<Array<Object>> => {
  return apiClientBearerToken()
    .get(`${ORDER_GROUPS_PATH}/getdepartmentsorder/${idOrder}`, {
      params: {
        division: idDivision,
      },
    })
    .then(response => {
      return Promise.resolve(response.data);
    })
    .catch(error => {
      Log.error(
        getDepartmentsOrderById.name,
        error.message,
        error.response ? error.response : error,
        !(error.response && error.response.status === 404),
      );
      return Promise.reject();
    });
};

export const getAllDepartments = (divisions: String): Promise<Array<Object>> => {
  return apiClientBearerToken()
    .get(`${DIVISIONS_PATH}/getdepartments?divisions=${divisions}`, {
      params: {},
    })
    .then(response => {
      return Promise.resolve(response.data);
    })
    .catch(error => {
      Log.error(
        getAllOrderGroups.name,
        error.message,
        error.response ? error.response : error,
        !(error.response && error.response.status === 404),
      );
      return Promise.reject();
    });
};

export const getAllClassifications = (divisions?: String): Promise<Array<Object>> => {
  return apiClientBearerToken()
    .get(`${CLASSIFICATIONS_PATH}/all`, {
      params: { divisions },
    })
    .then(response => {
      return Promise.resolve(response.data);
    })
    .catch(error => {
      Log.error(
        getAllClassifications.name,
        error.message,
        error.response ? error.response : error,
        !(error.response && error.response.status === 404),
      );
      return Promise.reject();
    });
};

export const getAllFamilies = (divisions?: String): Promise<Array<Object>> => {
  return apiClientBearerToken()
    .get(`${FAMILIES_PATH}/getall`, {
      params: { divisions },
    })
    .then(response => {
      return Promise.resolve(response.data);
    })
    .catch(error => {
      Log.error(
        getAllFamilies.name,
        error.message,
        error.response ? error.response : error,
        !(error.response && error.response.status === 404),
      );
      return Promise.reject();
    });
};

export const getAllRoutes = (
  paginated: Boolean,
  size?: Number,
  page?: Number,
  search?: String,
): Promise<Array<Object>> => {
  return apiClientBearerToken()
    .get(`${ROUTES_PATH}/getall`, {
      params: {},
    })
    .then(response => {
      return Promise.resolve(response.data);
    })
    .catch(error => {
      Log.error(
        getAllRoutes.name,
        error.message,
        error.response ? error.response : error,
        !(error.response && error.response.status === 404),
      );
      return Promise.reject();
    });
};

export const getWaveData = (wave_id: Number): Promise<Array<Object>> => {
  return apiClientBearerToken()
    .get(`${ORDER_GROUPS_PATH}/getroutes`, {
      params: {
        wave_id,
      },
    })
    .then(response => {
      return Promise.resolve(response);
    })
    .catch(error => {
      Log.error(
        getWaveData.name,
        error.message,
        error.response ? error.response : error,
        !(error.response && error.response.status === 404),
      );
      return Promise.reject();
    });
};

export const getOrderGroupSkuDetail = (
  order_group: Number,
  sku: Number,
  style: Number,
  provider: Number,
): Promise<Array<Object>> => {
  return apiClientBearerToken()
    .get(`${ORDER_GROUPS_PATH}/getskudetail`, {
      params: {
        order_group,
        sku,
        style,
        provider,
      },
    })
    .then(response => {
      return Promise.resolve(response);
    })
    .catch(error => {
      Log.error(
        getOrderGroupSkuDetail.name,
        error.message,
        error.response ? error.response : error,
        !(error.response && error.response.status === 404),
      );
      return Promise.reject();
    });
};

export const getOrderGroupExcel = (order_group: Number): Promise<Array<Object>> => {
  return apiClientBearerToken()
    .get(`${ORDER_GROUPS_PATH}/getexcel`, {
      params: {
        order_group,
      },      
      responseType: 'blob',
    })
    .then(response => {
      return Promise.resolve(response);
    })
    .catch(error => {
      Log.error(
        getOrderGroupExcel.name,
        error.message,
        error.response ? error.response : error,
        !(error.response && error.response.status === 404),
      );
      return Promise.reject();
    });
};

export const getAllClasses = (departments: String): Promise<Array<Object>> => {
  return apiClientBearerToken()
    .get(`${CLASSES_PATH}/all`, {
      params: {
        departments,
      },
    })
    .then(response => {
      return Promise.resolve(response.data);
    })
    .catch(error => {
      Log.error(
        getAllClasses.name,
        error.message,
        error.response ? error.response : error,
        !(error.response && error.response.status === 404),
      );
      return Promise.reject();
    });
};

export const getAllTypes = (classes: String): Promise<Array<Object>> => {
  return apiClientBearerToken()
    .get(`${TYPES_PATH}/all`, {
      params: {
        classes,
      },
    })
    .then(response => {
      return Promise.resolve(response.data);
    })
    .catch(error => {
      Log.error(
        getAllClasses.name,
        error.message,
        error.response ? error.response : error,
        !(error.response && error.response.status === 404),
      );
      return Promise.reject();
    });
};

export const getAllZones = (
  zoneType: Number,
  staging: Number,
  paginated: false,
  page?: Number,
  search?: String,
): Promise<Array<Object>> => {
  return apiClientBearerToken()
    .get(`${ZONES_PATH}/getzones`, {
      params: {
        zoneType,
        staging,
        paginated,
        page,
        search: search?.trim(),
      },
    })
    .then(response => {
      return Promise.resolve(response.data);
    })
    .catch(error => {
      Log.error(
        getAllZones.name,
        error.message,
        error.response ? error.response : error,
        !(error.response && error.response.status === 404),
      );
      return Promise.reject();
    });
};

export const getAllZoneTypes = (): Promise<Array<Object>> => {
  return apiClientBearerToken()
    .get(`${ZONES_PATH}/getallzonetypes`, {
      params: {},
    })
    .then(response => {
      return Promise.resolve(response);
    })
    .catch(error => {
      Log.error(
        getAllZoneTypes.name,
        error.message,
        error.response ? error.response : error,
        !(error.response && error.response.status === 404),
      );
      return Promise.reject();
    });
};

export const getZoneNameWave = (zoneType: Number): Promise<Array<Object>> => {
  return apiClientBearerToken()
    .get(`${ZONES_PATH}/getnamewavezone`, {
      params: {
        zoneType,
      },
    })
    .then(response => {
      return Promise.resolve(response.data);
    })
    .catch(error => {
      Log.error(
        getAllZones.name,
        error.message,
        error.response ? error.response : error,
        !(error.response && error.response.status === 404),
      );
      return Promise.reject();
    });
};

export const getStylesBuffer = (
  style: Number,
  page?: Number,
  per_page?: Number,
): Promise<Array<Object>> => {
  return apiClientBearerToken()
    .get(`${ZONES_PATH}/getstylesbuffer`, {
      params: {
        style,
        page,
        per_page,
      },
    })
    .then(response => {
      return Promise.resolve(response.data);
    })
    .catch(error => {
      Log.error(
        getAllZones.name,
        error.message,
        error.response ? error.response : error,
        !(error.response && error.response.status === 404),
      );
      return Promise.reject();
    });
};

export const preCalculateWave = (
    orderGroupId: Number,
    divisions: Array,
    divisionsNames: Array,
    description: String,
    excludedDepartments: Array,
    excludedRoutes: Array,
    excludedClassifications: Array,
    excludedFamilies: Array,
    excludedStores: Array,
    excludedProviders: Array,
    excludedStyles: Array
): Promise<Object> => {
    return apiClientBearerToken()
        .post(`${WAVES_PATH}/precalculate`, {
            order_group_id: orderGroupId,
            description: description,
            business_rules: {
                divisions,
                divisionsNames,
                excludedDepartments,
                excludedRoutes,
                excludedClassifications,
                excludedFamilies,
                excludedStores,
                excludedProviders,
                excludedStyles
            },
        })
        .then(response => {
            return Promise.resolve(response.data);
        })
        .catch(error => {
            if (error.response) {
                return Promise.reject(error.response.data.errors);
            }
            Log.error(createWave.name, error.message, error.response ? error.response : error);
            return Promise.reject();
        });
};

export const createWave = (
    orderGroupId: Number,
    divisions: Array,
    description: String,
    divisionsNames: Array,
    excludedDepartments: Array,
    excludedRoutes: Array,
    excludedClassifications: Array,
    excludedFamilies: Array,
    excludedStores: Array,
    excludedProviders: Array,
    excludedStyles: Array,
): Promise<Object> => {
    return apiClientBearerToken()
        .post(`${WAVES_PATH}/create`, {
            order_group_id: orderGroupId,
            description: description,
            business_rules: {
                divisions,
                divisionsNames,
                excludedDepartments,
                excludedRoutes,
                excludedClassifications,
                excludedFamilies,
                excludedStores,
                excludedProviders,
                excludedStyles,
            },
        })
        .then(response => {
            return Promise.resolve(response.data);
        })
        .catch(error => {
            if (error.response) {
                return Promise.reject(error.response.data.errors);
            }
            Log.error(createWave.name, error.message, error.response ? error.response : error);
            return Promise.reject();
        });
};

export const updateWave = (waveId: Number, status: Number): Promise<Object> => {
  return apiClientBearerToken()
    .post(`${WAVES_PATH}/update/${waveId}`, {
      status,
    })
    .then(response => {
      return Promise.resolve(response.data);
    })
    .catch(error => {
      if (error.response) {
        return Promise.reject(error.response.data.errors);
      }
      Log.error(createWave.name, error.message, error.response ? error.response : error);
      return Promise.reject();
    });
};

export const createZone = (
  zoneTypeId: Number,
  description: String,
  code: String,
  location: String,
): Promise<Object> => {
  return apiClientBearerToken()
    .post(`${ZONES_PATH}/createzone`, {
      zone_type_id: zoneTypeId,
      description,
      code,
      location,
    })
    .then(response => {
      return Promise.resolve(response.data);
    })
    .catch(error => {
      if (error.response) {
        return Promise.reject(error.response.data.errors);
      }
      Log.error(createZone.name, error.message, error.response ? error.response : error);
      return Promise.reject();
    });
};

export const getZoneSticker = (zone_id: Number): Promise<Array<Object>> => {
  return apiClientBearerToken()
    .get(`${ZONES_PATH}/${zone_id}/printsticker`, {
      responseType: 'blob',
    })
    .then(response => {
      return Promise.resolve(response);
    })
    .catch(error => {
      Log.error(
        getZoneSticker.name,
        error.message,
        error.response ? error.response : error,
        !(error.response && error.response.status === 404),
      );
      return Promise.reject();
    });
};

export const getZoneById = (id: Number): Promise<Array<Object>> => {
  return apiClientBearerToken()
    .get(`${ZONES_PATH}/${id}`)
    .then(response => {
      return Promise.resolve(response.data);
    })
    .catch(error => {
      Log.error(
        getZoneById.name,
        error.message,
        error.response ? error.response : error,
        !(error.response && error.response.status === 404),
      );
      return Promise.reject();
    });
};

export const updateZoneById = (
  id: Number,
  zoneTypeId: Number,
  description: String,
  code: String,
  location: String,
): Promise<Object> => {
  return apiClientBearerToken()
    .post(`${ZONES_PATH}/${id}/update`, {
      zone_type_id: zoneTypeId,
      description,
      code,
      location,
    })
    .then(response => {
      return Promise.resolve(response.data);
    })
    .catch(error => {
      if (error.response) {
        return Promise.reject(error);
      }
      return Promise.reject();
    });
};

export const deleteZone = (id: Number, restore?: Boolean): Promise<Object> => {
  return apiClientBearerToken()
    .delete(`${ZONES_PATH}/${id}`, {
      params: {
        restore,
      },
    })
    .then(() => {
      return Promise.resolve();
    })
    .catch(error => {
      Log.error(deleteZone.name, error.message, error.response ? error.response : error);
      return Promise.reject();
    });
};

export const deleteWave = (id: Number, restore?: Boolean): Promise<Object> => {
  return apiClientBearerToken()
    .delete(`${WAVES_PATH}/${id}`, {
      params: {
        restore,
      },
    })
    .then(() => {
      return Promise.resolve();
    })
    .catch(error => {
      Log.error(deleteWave.name, error.message, error.response ? error.response : error);
      return Promise.reject();
    });
};

export const cancelWave = (userId: Number, waveId: Number, reason: String, restore?: Boolean): Promise<Object> => {
  return apiClientBearerToken()
    .post(`${WAVES_PATH}/cancel/${waveId}`, {
        restore, reason, userId
    })
    .then(() => {
      return Promise.resolve();
    })
    .catch(error => {
      Log.error(cancelWave.name, error.message, error.response ? error.response : error);
      return Promise.reject();
    });
};

export const getWaveInventory = (id: Number): Promise<Object> => {
  return apiClientBearerToken()
    .get(`${WAVES_PATH}/verifystock/${id}`)
    .then(response => {
      return Promise.resolve(response.data);
    })
    .catch(error => {
      Log.error(getWaveInventory.name, error.message, error.response ? error.response : error);
      return Promise.reject();
    });
};

export const verifyStockAgain = (id: Number): Promise<Object> => {
  return apiClientBearerToken()
    .get(`${WAVES_PATH}/verifystockagain/${id}`)
    .then(response => {
      return Promise.resolve(response.data);
    })
    .catch(error => {
      Log.error(verifyStockAgain.name, error.message, error.response ? error.response : error);
      return Promise.reject();
    });
};

export const registerWave = (id: Number): Promise<Object> => {
  return apiClientBearerToken()
    .get(`${WAVES_PATH}/register/${id}`)
    .then(response => {
      return Promise.resolve(response.data);
    })
    .catch(error => {
      Log.error(registerWave.name, error.message, error.response ? error.response : error);
      return Promise.reject();
    });
};

export const WaveSkuDetail = (waveId: Number, variation: Number): Promise<Object> => {
  return apiClientBearerToken()
    .get(`${WAVES_PATH}/getskulines?waveId=${waveId}&variation=${variation}`)
    .then(response => {
      return Promise.resolve(response.data);
    })
    .catch(error => {
      Log.error(getWaveInventory.name, error.message, error.response ? error.response : error);
      return Promise.reject();
    });
};

export const makeDevolution = (transferList?: Array): Promise<Object> => {
  return apiClientBearerToken()
    .post(`${WAVES_PATH}/makedevolution`, {      
        transferList,
    })
    .then(() => {
      return Promise.resolve();
    })
    .catch(error => {
      Log.error(makeDevolution.name, error.message, error.response ? error.response : error);
      return Promise.reject();
    });
};

export const updateWaveLinesPPK = (skuArray?: Array, removeSkuArray?: Array, waveId: Number): Promise<Object> => {
  return apiClientBearerToken()
    .post(`${WAVES_PATH}/updatewavelinesppk/${waveId}`, {
        skuArray,
        removeSkuArray,
    })
    .then(() => {
      return Promise.resolve();
    })
    .catch(error => {
      Log.error(updateWaveLinesPPK.name, error.message, error.response ? error.response : error);
      return Promise.reject();
    });
};

export const getDevolutionTransfers = (
    area: Number,
    wave_id : Number
    ) : Promise<Array<Object>> => {
    return apiClientBearerToken()
        .get(`${WAVES_PATH}/querydevolution`,{
            params : {
              page: 0,
              pageSize: 200,
              almacen: '34',
            }
        })
        .then(response => {
            return Promise.resolve(response.data);
        })
        .catch(error => {
            Log.error(
                getDevolutionTransfers.name,
                error.message,
                error.response ? error.response : error,
                !(error.response && error.response.status === 404),
            );
            return Promise.reject();
        });
};

export const getWavesToday = () : Promise<Array<Object>> => {
    return apiClientBearerToken()
        .get(`${REPORT_PATH}/wave/today`,{
            headers: {
                "Access-Control-Allow-Origin": "*",
            },
            responseType: 'blob',
        })
        .then(response => {
            return Promise.resolve(response);
        })
        .catch( error => {
            Log.error(
                getWavesToday.name,
                error.message,
                error.response ? error.response : error,
                !(error.response && error.response.status === 404),
            );
            return Promise.reject();
        })
};

export const getWavesWeek = () : Promise<Array<Object>> => {
    return apiClientBearerToken()
        .get(`${REPORT_PATH}/wave/week`,{
            headers: {
                "Access-Control-Allow-Origin": "*",
            },
            responseType: 'blob',
        })
        .then(response => {
            return Promise.resolve(response);
        })
        .catch( error => {
            Log.error(
                getWavesWeek.name,
                error.message,
                error.response ? error.response : error,
                !(error.response && error.response.status === 404),
            );
            return Promise.reject();
        })
};

export const getCartonsToday = () : Promise<Array<Object>> => {
    return apiClientBearerToken()
        .get(`${REPORT_PATH}/carton/today`,{
            headers: {
                "Access-Control-Allow-Origin": "*",
            },
            responseType: 'blob',
        })
        .then(response => {
            return Promise.resolve(response);
        })
        .catch( error => {
            Log.error(
                getCartonsToday.name,
                error.message,
                error.response ? error.response : error,
                !(error.response && error.response.status === 404),
            );
            return Promise.reject();
        })
};

export const getCartonsWeek = () : Promise<Array<Object>> => {
    return apiClientBearerToken()
        .get(`${REPORT_PATH}/carton/week`,{
            headers: {
                "Access-Control-Allow-Origin": "*",
            },
            responseType: 'blob',
        })
        .then(response => {
            return Promise.resolve(response);
        })
        .catch( error => {
            Log.error(
                getCartonsWeek.name,
                error.message,
                error.response ? error.response : error,
                !(error.response && error.response.status === 404),
            );
            return Promise.reject();
        })
};

export const getDetailOrder = (
    ordergroup: Number
): Promise<Array<Object>> => {
    return apiClientBearerToken()
        .get(`${ORDER_GROUPS_PATH}/getdetailorder/${ordergroup}`)
        .then(response => {
            return Promise.resolve(response);
        })
        .catch(error => {
            Log.error(
                getDevolutionTransfers.name,
                error.message,
                error.response ? error.response : error,
                !(error.response && error.response.status === 404),
            );
            return Promise.reject();
        });
};

export const getDepartmentWave = (wave_id: Number): Promise<Array<Object>> => {
    return apiClientBearerToken()
        .get(`${WAVES_PATH}/getdepartment/${wave_id}`)
        .then(response => {
            return Promise.resolve(response);
        })
        .catch(error => {
            Log.error(
                getDepartmentWave.name,
                error.message,
                error.response ? error.response : error,
                !(error.response && error.response.status === 404),
            );
            return Promise.reject();
        });
};

export const recalculateOrderGroup =  (og_id: Number) : Promise<Array<Object>> => {
    return apiClientBearerToken()
        .get(`${ORDER_GROUPS_PATH}/recalculate/${og_id}`)
        .then(response => {
            return Promise.resolve(response);
        })
        .catch(error => {
            Log.error(
                recalculateOrderGroup.name,
                error.message,
                error.response ? error.response : error,
                !(error.response && error.response.status === 404),
            );
            return Promise.reject();
        });
};

export const waveSupply = (codigoOla: Number, completa: Boolean): Promise<Object> => {
    return apiClientBearerToken()
        .post(`${WAVES_PATH}/completewave/`, {
            codigoOla,
            completa
        })
        .then(response => {
            return Promise.resolve(response.data);
        })
        .catch(error => {
            if (error.response) {
                return Promise.reject(error.response.data.errors);
            }
            Log.error(waveSupply.name, error.message, error.response ? error.response : error);
            return Promise.reject();
        });
};

export const checkSkuInventory = (data: Array) : Promise<Array<Object>> => {
  return apiClientBearerToken()
      .post(`${WAVES_PATH}/checkinventory`,{
        data
      })
      .then(response => {
          return Promise.resolve(response.data);
      })
      .catch( error => {
          Log.error(
              error.message,
              error.response ? error.response : error,
              !(error.response && error.response.status === 404),
          );
          return Promise.reject();
      })
};

export const verifyPassword = (id: Number, password: String): Promise<Object> => {
  return apiClientBearerToken()
    .post(`${WAVES_PATH}/verifypassword`, {
      id, password,
    })
    .then(response => {
      return Promise.resolve(response.data);
    })
    .catch(error => {
      if (error.response) {
        return Promise.reject(error.response.data.errors);
      }
      Log.error(createWave.name, error.message, error.response ? error.response : error);
      return Promise.reject();
    });
};

export const cancelWaveInPicking = (userId: Number, waveId: Number, reason?: String): Promise<Object> => {
  return apiClientBearerToken()
    .post(`${WAVES_PATH}/cancelwave`, {
      userId, waveId, reason
    })
    .then(response => {
      return Promise.resolve(response.data);
    })
    .catch(error => {
      Log.error(cancelWaveInPicking.name, error.message, error.response ? error.response : error);
      return Promise.reject();
    });
};

export const getStyles = (orderGroupId?: Number, divisionId: Number): Promise<Array<Object>> => {
  return apiClientBearerToken()
    .get(`${STYLES_PATH}/getstyles`, {
      params: {
        orderGroupId,
        divisionId
      }
    })
    .then(response => {
      return Promise.resolve(response.data);
    })
    .catch(error => {
      Log.error(
        getStyles.name,
        error.message,
        error.response ? error.response : error,
        !(error.response && error.response.status === 404),
      );
      return Promise.reject();
    });
};

export const removePiecesFromWave = (waveId: Number,skus: Array): Promise<Object> => {
  return apiClientBearerToken()
    .post(`${LINES_PATH}/removepieces`, {
      skus,
      waveId
    })
    .then(response => {
      return Promise.resolve(response.data);
    })
    .catch(error => {
      Log.error(removePiecesFromWave.name, error.message, error.response ? error.response : error);
      return Promise.reject();
    });
};

export const forceFinishWave = (wave: Number): Promise<Object> => {
    return apiClientBearerToken()
        .post(`${WAVES_PATH}/forcefinishwave`, {
            wave,
        })
        .then(response => {
            return Promise.resolve(response.data);
        })
        .catch(error => {
            Log.error(forceFinishWave.name, error.message, error.response ? error.response : error);
            return Promise.reject();
        });
};