import React, { useState } from 'react';
import {
  Drawer,
  Typography,
  Box,
  CircularProgress,
  TextField,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  Search as SearchIcon,
} from '@material-ui/icons';

// Components
import MaterialTable from 'material-table';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import SnackBarMessage from '../../../components/SnackBarMessage';
import { getOrderGroupSkuDetail, getAllProviders } from '../../../api/WaveServices/WavesApi';
import materialTableLocaleES from '../../../components/MaterialTableLocaleES';

const drawerWidth = 1000;
const useStyles = makeStyles(theme => ({
  container: {
    width: drawerWidth,
  },
  containerPaper: {
    width: drawerWidth,
    padding: 20,
  },
  smallColor: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  root: {
    marginTop: '15px',
    marginBottom: '15px',
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

const initialState = {
  orderGroupId: 0,
  skuInput: '',
  styleInput: '',
  skuDetail: [],
  provider: 0,
};

export default function OrderGroupDetail({ formOpen, toggleForm, refreshTable, dataObject }) {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [form, setForm] = useState(initialState);
  const [closeClick, setCloseClick] = useState('');
  const [ods, setOds] = useState(false);
  const [providers, setProviders] = useState([]);

  const clearData = () => {
    setForm(initialState);
    setCloseClick('');
    setIsLoading(false);
  };

  const initData = () => {
    setCloseClick(false);
    setOds(dataObject);

    if (providers.length === 0) {
      getAllProviders()
        .then(response => {
          setProviders(response);
        })
        .catch(() => {
          setProviders([]);
        });
    }
  };

  const handleChange = event => {
    setForm({
      ...form,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = event => {
    event.preventDefault();
    getSkuDetail(form.skuInput, form.styleInput, form.provider);
  };

  const getSkuDetail = (sku, style, provider) => {
    getOrderGroupSkuDetail(dataObject.order_group_id, sku, style, provider)
      .then(response => {
        setForm({
          ...form,
          skuDetail: response.data,
        });
      })
      .catch(error => console.log(error));
  };

  const getProviderName = providerId => {
    const buscar = providers.filter(function(obj) {
      return obj.id === providerId;
    });
    return buscar[0].jdaName;
  };

  return (
    <Box>
      <Drawer
        anchor="right"
        open={formOpen}
        onClose={toggleForm}
        className={classes.container}
        classes={{ paper: classes.containerPaper }}
      >
        <Box className={classes.header}>
          <Box flexGrow={1}>
            <Typography variant="h5" color="primary">
              {`Detalle de orden de surtido ${ods.order_group}`}
            </Typography>
          </Box>
        </Box>
        <div className={classes.root}>
          <Grid container spacing={3}>
            <Grid item xs={4}>
              <TextField
                label="Sku"
                fullWidth
                name="skuInput"
                size="small"
                id="outlined-size-small"
                value={form.skuInput || ''}
                variant="outlined"
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                label="Estilo"
                fullWidth
                size="small"
                name="styleInput"
                id="outlined-size-small1"
                value={form.styleInput || ''}
                variant="outlined"
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={4}>
              <Autocomplete
                id="combo-box-zonetypes"
                size="small"
                options={providers}
                renderOption={option => (
                  <div className={classes.selectZoneType}>
                    <Box className={classes.spaceOption}>
                      <option value={option.id} name={option.id} key={option.id}>
                        {`${option.jdaName}`}
                      </option>
                    </Box>
                  </div>
                )}
                noOptionsText="No hay proveedores"
                getOptionLabel={option => `${option.jdaName}`}
                autoComplete
                onChange={(event, value) => {
                  setForm({ ...form, provider: value?.id || 0 });
                }}
                renderInput={params => (
                  <TextField
                    {...params}
                    label="Proveedor"                    
                    variant="outlined"
                    placeholder="Seleccione un proveedor"
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item xs={4}>
              <Box component="form" onSubmit={handleSubmit}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  endIcon={<SearchIcon>Filtrar</SearchIcon>}
                >
                  Filtrar
                </Button>
              </Box>
            </Grid>
          </Grid>
        </div>
        <MaterialTable
          title="Detalle de orden de surtido"
          columns={[
            { title: 'Tienda #', field: 'order.storeNumber' },
            { title: 'Ranking tda', field: 'order.storePriority' },
            { title: 'Piezas', field: 'pieces' },
            { title: 'Piezas reales', field: 'expected_pieces' },
            { title: 'Sku', field: 'sku' },
            { title: 'Estilo', field: 'style' },
            {
              title: 'Proveedor',
              field: 'provider_id',
              render: rowData => {
                return getProviderName(rowData.provider_id);
              },
            },
            { title: 'PPK', field: 'ppk' },
          ]}
          localization={materialTableLocaleES}
          data={form.skuDetail}
          options={{
            pageSize: 10,
            search: false,
            toolbar: true,
            showTitle: false,
            padding: 'dense',
            exportButton: true,
            pageSizeOptions: [5, 10, 20, 50, 100],
          }}
        />

        <Box component="form" className={classes.form}>
          <Box />
          <Box className={classes.bottomSpace}>
            {isLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
            {formOpen && closeClick === '' && initData()}
            {!formOpen && closeClick !== '' && clearData()}
          </Box>
        </Box>
      </Drawer>

      <SnackBarMessage
        open={form.policyAccessError}
        message={form.roleAccessMessage}
        onClose={() => setForm({ ...form, policyAccessError: false })}
        variant="warning"
      />
    </Box>
  );
}

OrderGroupDetail.defaultProps = {
  refreshTable: null,
  dataObject: 0,
};

OrderGroupDetail.propTypes = {
  refreshTable: PropTypes.func,
  formOpen: PropTypes.bool.isRequired,
  toggleForm: PropTypes.func.isRequired,
  dataObject: PropTypes.object,
};
