import React, { useState, useRef } from 'react';

// Components
import { Drawer, Typography, Box, Card, CardContent, IconButton } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import MaterialTable from 'material-table';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Close as CloseIcon } from '@material-ui/icons';
import LayersIcon from '@material-ui/icons/Layers';
import LinearProgress from '@material-ui/core/LinearProgress/LinearProgress';

import materialTableLocaleES from '../../../components/MaterialTableLocaleES';
import { getWaveByArea } from '../../../api/WaveServices/WavesApi';
import { getCartonsByWave, getDetailCaronById } from '../../../api/CartonService/CartonApi';

// Data Api
const drawerWidth = '70%';
const useStyles = makeStyles(theme => ({
  container: {
    width: drawerWidth,
  },
  containerPaper: {
    width: drawerWidth,
    padding: 20,
  },
  header: {
    display: 'flex',
    alignItems: 'center',
  },
  headerIcon: {
    fontSize: 35,
    marginRight: theme.spacing(1),
  },
  headerCloseButton: {
    padding: theme.spacing(1),
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    justifyContent: 'space-between',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  selectForm: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  selectStyle: {
    minWidth: 195,
    maxWidth: 195,
  },
  pickerStyle: {
    minWidth: 225,
    maxWidth: 225,
  },
  selectAddButton: {
    padding: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  addRolesPolices: {
    margin: theme.spacing(4),
  },
  card: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  cardContent: {
    padding: theme.spacing(2),
  },
  formCardInput: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  bottomSpace: {
    paddingTop: theme.spacing(2),
  },
  progressWave: {
    marginTop: theme.spacing(1),
    padding: 20,
    alignItems: 'rigth',
  },
  typography: {
    textAlign: 'center',
  },
  inputWave: {
    marginRight: '10px',
  },
  footerBox: {
    marginRight: '10px',
    marginTop: '80%',
  },
}));

const initialState = {
  wave_id: 0,
  pieces: 0,
  pzas_restates: 0,
  percent_complete: 0,
  sorted_pieces: 0,
  complete: 0,
  wave_ref: '',
};

export default function WaveFormDashboard({ formOpen, toggleForm, refreshTable, dataObject }) {
  const classes = useStyles();
  const [form, setForm] = useState(initialState);
  const [closeClick, setCloseClick] = useState('');
  const tableRef = useRef();
  const tableRef2 = useRef();

  const close = () => {
    toggleForm();
  };

  const clearData = () => {
    setCloseClick('');
    setForm(initialState);
  };

  const allCartonsWave = (query, wave) =>
    getCartonsByWave(wave, query.pageSize, query.page + 1)
      .then(response => ({
        ...query,
        data: response.data,
        page: response.current_page - 1,
        totalCount: response.total,
      }))
      .catch(() => ({
        ...query,
        page: 0,
        pageSize: 15,
        totalCount: 0,
        data: [],
      }));

  const initData = () => {
    if (dataObject === 0) {
      setForm({
        ...form,
        pieces: 0,
      });
    } else {
      getWaveByArea(0, dataObject)
        .then(response => {
          const complete = (response.data[0].sorted_pieces / response.data[0].pieces) * 100;
          const round_complete = complete.toFixed(2);
          setForm({
            wave_id: response.data[0].id,
            pieces: response.data[0].pieces,
            pzas_restates: response.data[0].pieces - response.data[0].sorted_pieces,
            percent_complete: round_complete,
            wave_ref: response.data[0].wave_ref,
            sorted_pieces: response.data[0].sorted_pieces,
          });
          return response;
        })
        .catch(() => {
          return Promise.resolve(setForm({}));
        });
      setCloseClick(false);
    }
  };

  const renderDetailCarton = rowData => {
    return (
      <MaterialTable
        nameEntity="Cartones"
        columns={[
          {
            title: 'SKU',
            field: 'sku',
            type: 'string',
          },
          {
            title: 'Division',
            field: 'division',
            type: 'string',
          },
          {
            title: 'Categoría',
            field: 'category',
            type: 'string',
          },
          {
            title: 'Clasificación',
            field: 'classification',
            type: 'string',
          },
          {
            title: 'Piezas',
            field: 'pieces',
            type: 'string',
          },
        ]}
        data={query => getDetailCarton(query, rowData.id)}
        tableRef={tableRef2}
        options={{
          search: false,
          showTitle: false,
          toolbar: false,
          paging: false,
          header: true,
          padding: 'dense',
          rowStyle: {
            backgroundColor: '#EEE',
          },
        }}
      />
    );
  };

  const getDetailCarton = (query, idCarton) =>
    getDetailCaronById(idCarton)
      .then(response => {
        return {
          ...query,
          page: 0,
          pageSize: 15,
          totalCount: 0,
          data: response,
        };
      })
      .catch(() => ({
        ...query,
        page: 0,
        pageSize: 15,
        totalCount: 0,
        data: [],
      }));

  return (
    <Box>
      <Drawer
        anchor="right"
        open={formOpen}
        onClose={toggleForm}
        className={classes.container}
        classes={{ paper: classes.containerPaper }}
      >
        <Box className={classes.header}>
          <LayersIcon color="primary" className={classes.headerIcon} />
          <Box flexGrow={4}>
            <Typography variant="h5" color="primary">
              OLA: {form.wave_ref}
            </Typography>
          </Box>
          <Typography>Progreso:</Typography>
          <Grid item xs={6} className={classes.progressWave} style={{ textAlign: 'rigth' }}>
            <LinearProgress variant="determinate" value={parseFloat(form.percent_complete)} />
            <Typography className={classes.typography}>{`${form.percent_complete}%`}</Typography>
          </Grid>
          <IconButton
            color="secondary"
            className={classes.headerCloseButton}
            onClick={() => {
              close();
            }}
          >
            <CloseIcon />
          </IconButton>
          {formOpen && closeClick === '' && initData()}
          {!formOpen && closeClick !== '' && clearData()}
        </Box>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Box component="form" className={classes.form}>
              <Box className={classes.inputWave} style={{ marginBottom: '10px' }}>
                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <Card className={classes.root}>
                      <CardContent>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>
                          Piezas
                        </Typography>
                        <Typography variant="h5" component="h2">
                          {form.pieces}
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={6}>
                    <Card className={classes.root}>
                      <CardContent>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>
                          Piezas Restantes
                        </Typography>
                        <Typography variant="h5" component="h2">
                          {form.pzas_restates}
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box component="form" className={classes.form}>
              <Box className={classes.inputWave} style={{ marginBottom: '10px' }}>
                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <Card className={classes.root}>
                      <CardContent>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>
                          Completadas
                        </Typography>
                        <Typography variant="h5" component="h2">
                          {form.sorted_pieces}
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h5" color="primary">
              Cartones
            </Typography>
            <MaterialTable
              columns={[
                { title: 'Fecha', field: 'created_at', type: 'string' },
                { title: 'Box Id', field: 'barcode', type: 'string' },
                {
                  title: 'OLA',
                  field: 'waveNumber',
                  type: 'string',
                  render: rowData => {
                    return `${rowData.waveNumber}`;
                  },
                },
                { title: 'Transfer', field: 'transferNum', type: 'string' },
                { title: 'Piezas', field: 'total_pieces', type: 'string' },
                { title: 'Destino', field: 'store', type: 'string' },
              ]}
              title=" "
              localization={materialTableLocaleES}
              tableRef={tableRef}
              data={query => allCartonsWave(query, dataObject)}
              options={{
                pageSize: 10,
                search: false,
                paging: true,
                toolbar: false,
                padding: 'dense',
              }}
              detailPanel={rowData => renderDetailCarton(rowData)}
            />
          </Grid>
        </Grid>
      </Drawer>
    </Box>
  );
}

WaveFormDashboard.defaultProps = {
  refreshTable: null,
  dataObject: 0,
};

WaveFormDashboard.propTypes = {
  refreshTable: PropTypes.func,
  formOpen: PropTypes.bool.isRequired,
  toggleForm: PropTypes.func.isRequired,
  dataObject: PropTypes.number,
};
