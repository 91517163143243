import React from 'react';
import { Box, Typography, CardMedia } from '@material-ui/core';

// App Layout
import { WithPublicLayout } from '../components/AppLayout';

// Styles
import LoginStyles from './Login/styles/LoginStyles';
import { AppStyles } from '../AppStyles';

function HomePage() {
  const appStyles = AppStyles();
  const loginStyles = LoginStyles();

  return (
    <Box id="app-home-page" component="main" className={appStyles.pageRoot}>
        <Box className={loginStyles.divImage}>
            <CardMedia className={loginStyles.imageCard} image="/logo512.png" title="CCP Logo" />
        </Box>
        <Typography align="center" variant="h2">
            CCP Order Manager System
        </Typography>
        <Typography align="center" variant="h5">
            Administrador CCP OMS
        </Typography>
    </Box>
  );
}

export default WithPublicLayout(HomePage);
