import React, { useState, useRef, useEffect } from 'react';
import {Avatar, Box, makeStyles} from '@material-ui/core';

// Components
import {Delete, Visibility as ShowIcon, Visibility as ViewIcon} from '@material-ui/icons';
import RouteForm from '../components/RouteForm';
import PageHeader from '../../../components/PageHeader';
import WithSnackBarMessages from '../../../components/WithSnackBarMessages';

// Styles
import { AppStyles } from '../../../AppStyles';

// Data Apis
import { getAllRoutes, deleteRoute } from '../../../api/RouteServices/RouteCatalogApi';


// Routes
import {APP_ROUTES, PRIORITY_ROUTES} from '../../../Routes';
import {useSnackbar} from "notistack";
import materialTableLocaleES from "../../../components/MaterialTableLocaleES";
import MaterialTable, { MTableEditRow, MTableEditField, MTableActions } from "material-table";
import { getPermissions } from '../../../api/AuthApi';

const breadcrumbsData = [
    {
        id: 1,
        label: 'Catalogos',
        base: APP_ROUTES.PRIORITY,
    },
    {
        id: 2,
        label: 'Rutas',
        base: PRIORITY_ROUTES.ROUTES,
    }
];

const useStyles = makeStyles(theme => ({
    smallColor: {
        width: theme.spacing(3),
        height: theme.spacing(3),
    },
}));

function RoutesPage() {
    const classes = useStyles();
    const appStyles = AppStyles();
    const tableRef = useRef();
    const { enqueueSnackbar } = useSnackbar();
    const [openForm, setOpenForm] = useState(false);
    const [dataObject, setDataObject] = useState(0);
    const [access, setAccess] = useState(false);

    const toggleForm = () => {
        if (tableRef.current) {
            tableRef.current.state.query.page = 0;
            if (openForm) {
                tableRef.current.onQueryChange();
            }
        }
        setOpenForm(!openForm);
        setDataObject(0);
    };
    const messageError = (message, variant) => {
        enqueueSnackbar(message, {
            variant,
        });
    };

    useEffect(() => {        
        getPermissions().then(response => {
            let check = response.filter(obj => obj.name === 'Full Access');
            if (check.length === 0) {              
              setAccess(false);
            } else {              
              setAccess(true);
            }
          }).catch(e => {
              console.log(e);
          })
    }, []);

    const getRoutesData = query =>
        getAllRoutes(query.pageSize, query.page + 1 , query.search, null,'asc')
            .then(response => {
                return {
                    ...query,
                    data: response.data,
                    page: response.current_page -1 ,
                    totalCount: response.total,
                }})
            .catch((error) => {
                console.log(error);
                return {
                    ...query,
                    page: 0,
                    pageSize: 15,
                    totalCount: 0,
                    data: [],
                }
            });

    return (
        <Box id="app-users-admins-page" component="main" className={appStyles.pageRoot} style={{ backgroundColor: 'white', height: '100vh' }}>
            <PageHeader
                title="Rutas"
                titleButton="Nueva Ruta"
                displayButton
                canExecuteButton={access}
                action={toggleForm}
                breadcrumbsData={breadcrumbsData}
            />

            <RouteForm
                formOpen={openForm}
                toggleForm={toggleForm}
                refreshTable={() => tableRef.current && tableRef.current.onQueryChange()}
                dataObject={dataObject}
                canUpdateUser={access}
                canGetUserData={true}
                canUpdateUserData={access}
            />

            <MaterialTable
                columns={[
                    { title: 'Nombre', field: 'name', type: 'string',},
                    { title: 'Descripcion', field: 'description', type: 'string',},
                    { title: 'Color', field: 'color', type: 'string',
                        render: rowData => {
                            return (
                                <Avatar
                                    variant="square"
                                    style={{ backgroundColor: rowData.color }}
                                    className={classes.smallColor}
                                >
                                    {' '}
                                </Avatar>
                            );
                        },
                    }
                ]}
                title="Rutas"
                localization={materialTableLocaleES}
                tableRef={tableRef}
                data={query => getRoutesData(query)}
                actions={[
                    rowData => ({
                        disabled: !access,
                        icon: () => (
                            <ShowIcon color={access ? 'primary' : 'disabled'} className="icon-small" />
                        ),
                        tooltip: access ? 'Ver Tienda' : undefined,
                        onClick: (event, rowData) => {
                            if (rowData.id) {
                                setDataObject(rowData.id);
                                setOpenForm(!openForm);
                            }
                        },
                    })
                ]}                
                options={{
                    search: true,
                    pageSize: 20,
                    actionsColumnIndex: -1,
                    paging: true,
                    toolbar: true,
                    showTitle: false,
                    padding: 'dense',
                    pageSizeOptions: [5, 10, 20, 50, 100],
                }}
                components={{
                    Actions: props => (
                        <div style={{ color: '#FF0000' }}>
                            <MTableActions {...props} />
                        </div>
                    )
                }}
            />
        </Box>
    );
}

export default WithSnackBarMessages(RoutesPage);
