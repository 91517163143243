import React, { useState } from 'react';
import {
  Drawer,
  TextField,
  Typography,
  Box,
  Button,
  CircularProgress,
  IconButton,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import { Edit as EditIcon, Close as CloseIcon } from '@material-ui/icons';
import { useSnackbar } from 'notistack';
// Components
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import SnackBarMessage from '../../../components/SnackBarMessage';

// Data Api
import {
  getOperatorById,
  getAllTeams,
  updateOperatorTeam,
} from '../../../api/TeamServices/TeamsApi';

const drawerWidth = 500;
const useStyles = makeStyles(theme => ({
  container: {
    width: drawerWidth,
  },
  containerPaper: {
    width: drawerWidth,
    padding: 20,
  },
  header: {
    display: 'flex',
    alignItems: 'center',
  },
  selectZoneType: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  selectFormTextField: {
    justifyContent: 'space-between',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  spaceOption: { padding: theme.spacing(1) },
  headerIcon: {
    fontSize: 35,
    marginRight: theme.spacing(1),
    alignSelf: 'baseline',
  },
  headerCloseButton: {
    padding: theme.spacing(1),
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    justifyContent: 'space-between',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  selectForm: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  selectStyle: {
    minWidth: '100%',
    maxWidth: '100%',
  },
  selectShops: {
    minWidth: 355,
    maxWidth: 355,
  },

  selectAddButton: {
    padding: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  bottomSpace: {
    paddingTop: theme.spacing(2),
  },
  actionDescriptionEdit: {
    '& .MuiInput-root': {
      fontSize: 'small',
    },
  },
}));

const initialState = {
  operatorID: 0,
  teamID: 0,
  nameOperator: ' ',
  rol: ' ',
};

export default function OperatorTeamForm({
  formOpen,
  toggleForm,
  refreshTable,
  dataObject,
  canUpdateZone,
  match,
}) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [form, setForm] = useState(initialState);
  const [closeClick, setCloseClick] = useState('');
  const [editForm, setEditForm] = useState(false);

  const [teams, setTeams] = useState([]);

  const messageError = (message, variant) => {
    enqueueSnackbar(message, {
      variant,
    });
  };

  const handleChange = event => {
    setForm({
      ...form,
      [event.target.name]: event.target.value,
    });

    setCloseClick(true);
  };

  const handleSubmit = event => {
    console.log(form.teamID);
    event.preventDefault();
    setIsLoading(true);
    updateOperatorTeam(form.idUserTeam, form.teamID, form.operatorID)
      .then(() => {
        toggleForm();
        setForm(initialState);
        setIsLoading(false);
        messageError('Operador asignado', 'success');
        refreshTable();
      })
      .catch(error => {
        if (error !== undefined) {
          setForm({
            ...form,
          });
        } else {
          refreshTable();
          messageError('Servicio no disponible.', 'warning');
        }
        setIsLoading(false);
      });
    setForm(initialState);
  };

  const clearData = () => {
    setForm(initialState);
    setCloseClick('');
    setEditForm(false);
    setIsLoading(false);
  };

  const initData = () => {
    if (teams.length === 0) {
      getAllTeams()
        .then(response => {
          setTeams(response);
        })
        .catch(() => {
          setTeams([]);
        });
    }
    if (dataObject === 0) {
      setForm(initialState);
    } else if (dataObject !== form.operatorID || form.operatorID <= 0) {
      getOperatorById(dataObject)
        .then(response => {
          console.log(response.id);
          setForm({
            idUserTeam: response[0].idUserTeam,
            operatorID: response[0].id,
            teamID: response[0].idTeam,
            nameOperator: response[0].nameUser,
            rol: response[0].nameRol,
          });
        })
        .catch(() => {
          refreshTable(form.operatorID - 1);
          toggleForm();
          messageError('Sucedio algun error.', 'error');
        });
    }
  };

  const close = () => {
    setForm(initialState);
    toggleForm();
  };

  const disableFormControl = () => {
    if (isLoading) {
      return true;
    }
    if (dataObject === 0) {
      return false;
    }
    if (dataObject !== 0 && editForm) {
      return false;
    }
    return true;
  };

  const optionStartTeam = () => {
    let selectedTeam = null;
    if (teams.length > 0)
      teams.forEach(tm => {
        if (tm.id === form.teamID) {
          selectedTeam = tm;
        }
      });
    return selectedTeam;
  };

  return (
    <Box>
      <Drawer
        anchor="right"
        open={formOpen}
        onClose={toggleForm}
        className={classes.container}
        classes={{ paper: classes.containerPaper }}
      >
        <Box className={classes.header}>
          <GroupAddIcon color="primary" className={classes.headerIcon} />
          <Box flexGrow={1}>
            <Typography variant="h5" color="primary">
              {form.nameOperator}
            </Typography>
          </Box>
          {dataObject !== 0 && (
            <IconButton
              color="primary"
              className={classes.headerCloseButton}
              onClick={() => {
                setEditForm(!editForm);
              }}
            >
              <EditIcon />
            </IconButton>
          )}
          <IconButton
            color="secondary"
            className={classes.headerCloseButton}
            onClick={() => {
              close();
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <Box className={classes.selectFormTextField}>
          <TextField
            required
            disabled
            label="Rol de operador"
            id="description-textfield"
            value={form.rol}
            name="rol"
            fullWidth
            variant="outlined"
            onChange={handleChange}
          />
        </Box>
        <Box className={classes.selectFormTextField}>
          <Autocomplete
            id="combo-box-zonetypes"
            options={teams}
            renderOption={option => (
              <div className={classes.selectZoneType}>
                <Box className={classes.spaceOption}>
                  <option value={option.name} name={option.id} key={option.id}>
                    {`${option.name}`}
                  </option>
                </Box>
              </div>
            )}
            noOptionsText="No hay administradores disponibles"
            disabled={disableFormControl()}
            getOptionLabel={option => `${option.name}`}
            value={optionStartTeam()}
            autoComplete
            onChange={(event, value) => {
              setForm({ ...form, teamID: value?.id || 0 });
            }}
            renderInput={params => (
              // eslint-disable-next-line react/jsx-props-no-spreading
              <TextField
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...params}
                label="Equipo"
                variant="outlined"
                placeholder="Seleccione un equipo"
                fullWidth
              />
            )}
          />
        </Box>
        <Box component="form" className={classes.form} onSubmit={handleSubmit}>
          <Box />
          <Box className={classes.bottomSpace}>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              type="submit"
              disabled={disableFormControl()}
              style={{ borderRadius: 30 }}
              size="small"
            >
              Asignar Operador
            </Button>

            {isLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
            {formOpen && closeClick === '' && initData()}
            {!formOpen && closeClick !== '' && clearData()}
          </Box>
        </Box>
      </Drawer>

      <SnackBarMessage
        open={form.policyAccessError}
        message={form.roleAccessMessage}
        onClose={() => setForm({ ...form, policyAccessError: false })}
        variant="warning"
      />
    </Box>
  );
}

OperatorTeamForm.defaultProps = {
  refreshTable: null,
  dataObject: 0,
  canUpdateZone: false,
};

OperatorTeamForm.propTypes = {
  refreshTable: PropTypes.func,
  formOpen: PropTypes.bool.isRequired,
  toggleForm: PropTypes.func.isRequired,
  dataObject: PropTypes.number,
  canUpdateZone: PropTypes.bool,
};
