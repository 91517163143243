import {apiClientBasicAuth,  apiClientBearerToken } from '../ApiClientConfig';
import Logger from '../../Logger';
import {removeCookie, setCookie} from "../../AppCookies";
const Log = new Logger('src/api/ReportService/ReportApi.js');

const REPORT_PATH = '/report';
const USER_PATH_API = '/api/v1';

export const getGlobalReport = (): Promise<Array<Object>> => {
    return apiClientBearerToken()
        .get(`${REPORT_PATH}/waves/`)
        .then(response => {
            return Promise.resolve(response.data);
        })
        .catch(error => {
            Log.error(
                getGlobalReport.name,
                error.message,
                error.response ? error.response : error,
                !(error.response && error.response.status === 404),
            );
            return Promise.reject();
        });
};

export const getWavesReportFilter = (
    init: String,
    end: String,
    area: String,
    wave : Number
): Promise<Array<Object>> => {
    return apiClientBearerToken()
        .get(`${REPORT_PATH}/waves/sort`,{
            params : {
                init,
                end,
                area,
                wave
            }
        })
        .then(response => {
            return Promise.resolve(response.data);
        })
        .catch(error => {
            Log.error(
                getWavesReportFilter.name,
                error.message,
                error.response ? error.response : error,
                !(error.response && error.response.status === 404),
            );
            return Promise.reject();
        });
};

export const getWavesPlanned = (
    initDate: String,
    endDate: String
): Promise<Array<Object>> => {
    return apiClientBearerToken()
        .get(`${REPORT_PATH}/wave/planned`,{
            params : {
                initDate,
                endDate
            },
            responseType: 'blob'
        })
        .then(response => {
            return Promise.resolve(response.data);
        })
        .catch(error => {
            Log.error(
                getWavesPlanned.name,
                error.message,
                error.response ? error.response : error,
                !(error.response && error.response.status === 404),
            );
            return Promise.reject();
        });
};

export const subscriteReport = (
    name: String,
    email: String,
    subscriteTo: Array,
): Promise<Array<Object>> => {
    return apiClientBasicAuth
        .post(`${USER_PATH_API}/subscritereport`, {
            email: email,
            name: name,
            subscrited_to: subscriteTo
        })
        .then(response => {
            return Promise.resolve(response.data);
        })
        .catch(error => {
            return Promise.reject(new Error('Servicio no disponible.'));
        });
};


export const validatePpk = (
    data: Array,
): Promise<Array<Object>> => {
    return apiClientBearerToken()
        .post(`${REPORT_PATH}/ppk`,{
            data,
        })
        .then(response => {
            return Promise.resolve(response.data);
        })
        .catch(error => {
            Log.error(
                validatePpk.name,
                error.message,
                error.response ? error.response : error,
                !(error.response && error.response.status === 404),
            );
            return Promise.reject();
        });
};

export const getSupplyOrderFinishedReports = (wave: Number): Promise<Array<Object>> => {
    return apiClientBearerToken()
        .get(`${REPORT_PATH}/waves/orderreport`, {
            params: {
                wave: wave,
            },
            responseType: 'blob',
        })
        .then(response => {
            return Promise.resolve(response);
        })
        .catch(error => {
            Log.error(
                getSupplyOrderFinishedReports.name,
                error.message,
                error.response ? error.response : error,
                !(error.response && error.response.status === 404),
            );
            return Promise.reject();
        });
};