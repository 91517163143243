import 'date-fns';
import React, { useState, useRef, useEffect } from 'react';
import { Box, TextField, FormControl, Typography, CircularProgress } from '@material-ui/core';
import {
    Visibility as ShowIcon,
    AccountTree as TreeIcon,
    Search as SearchIcon, Visibility as ViewIcon,
} from '@material-ui/icons';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import BarChartIcon from '@material-ui/icons/BarChart';
import AllInboxIcon from '@material-ui/icons/AllInbox';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';

// Components
import MaterialTable, { MTableToolbar } from 'material-table';

import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import ReportSupplyOrderForm from '../components/ReportSupplyOrderForm';
import WithSnackBarMessages from '../../../components/WithSnackBarMessages';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Divider from '@material-ui/core/Divider';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';


// Security

// Styles
import { AppStyles } from '../../../AppStyles';
import materialTableLocaleES from '../../../components/MaterialTableLocaleES';

// Data Apis
import {
    getAllOrderGroups,
    getOrderGroupExcel,
    getDepartmentsOrderById,
} from '../../../api/WaveServices/WavesApi';
import {
    getStoresBySupplyOrder,
    updateOrder,
    getOrderGroupReports,
} from '../../../api/SupplyOrderService/SupplyOrderApi';


const useStyles = makeStyles(theme => ({
    actionDescriptionEdit: {
        '& .MuiInput-root': {
            fontSize: 'small',
        },
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    display: {
        display: 'none',
    },
    formControlTableToolBar: {
        margin: theme.spacing(3),
        marginTop: -theme.spacing(7),
        minWidth: 170,
    },
    formClearData: {
        marginTop: '10px',
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const renderOrderGroupsDetail = rowData => {
    const columns = [
        { title: 'Division', field: 'division' },
        {
            title: 'Piezas',
            field: 'pieces',
            type: 'numeric',
            render: rowData => {
                return `${rowData.pieces.toLocaleString()}`;
            },
        },
        {
            title: 'En Ola',
            field: 'in_wave',
            type: 'numeric',
            render: rowData => {
                return `${rowData.in_wave.toLocaleString()}`;
            },
        },
        {
            title: 'Pendientes',
            field: 'pending',
            type: 'numeric',
            render: rowData => {
                return `${rowData.pending.toLocaleString()}`;
            },
        },
        {
            title: 'En surtido',
            field: 'pending',
            type: 'numeric',
            render: rowData => {
                return `${rowData.in_wave.toLocaleString()}`;
            },
        },
    ];
    return (
        <MaterialTable
            columns={columns}
            data={rowData.divisions}
            detailPanel={rowData => renderOrderDepartmentsDetail(rowData)}
            title="Distribución de divisiones"
            options={{
                search: false,
                paging: false,
                toolbar: false,
                rowStyle: {
                    backgroundColor: '#EEE',
                },
            }}
            localization={materialTableLocaleES}
        />
    );
};

const getDepartmentOrder = (query, idOrder, idDivision) =>
    getDepartmentsOrderById(idOrder, idDivision)
        .then(response => {
            return {
                ...query,
                page: 0,
                pageSize: 15,
                totalCount: 0,
                data: response,
            };
        })
        .catch(() => ({
            ...query,
            page: 0,
            pageSize: 15,
            totalCount: 0,
            data: [],
        }));

const renderOrderDepartmentsDetail = rowData => {
    return (
        <MaterialTable
            nameEntity="Departamentos"
            columns={[
                {
                    title: 'Departamento',
                    field: 'department_name',
                    type: 'string',
                },
                {
                    title: 'Piezas',
                    field: 'total_pieces',
                    type: 'numeric',
                },
                {
                    title: 'En Ola',
                    field: 'total_in_wave',
                    type: 'numeric',
                },
                {
                    title: 'Pendiente',
                    field: 'total_pending',
                    type: 'numeric',
                },
            ]}
            data={query => getDepartmentOrder(query, rowData.order_group, rowData.id)}
            options={{
                search: false,
                showTitle: false,
                toolbar: false,
                paging: false,
                header: true,
                padding: 'dense',
                rowStyle: {
                    backgroundColor: 'white',
                },
            }}
        />
    );
};
interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}


function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && <Box p={3}>{children}</Box>}
        </Typography>
    );
}

function a11yProps(index: any) {
    return {
        id: `scrollable-auto-tab-${index}`,
        'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
}

function ReportSupplyOrdersPage() {
    const classes = useStyles();
    const appStyles = AppStyles();
    const [openDetail, setOpenDetail] = useState(false);
    const tableRef = useRef();
    const tableRef1 = useRef();
    const tableRef2 = useRef();
    const [openForm, setOpenForm] = useState(false);
    const [dataObject, setDataObject] = useState(0);
    const [nameObject, setNameObject] = useState('');
    const [selectedDateInit, setSelectedDateInit] = React.useState();
    const [selectedDateEnd, setSelectedDateEnd] = React.useState();
    const [open, setOpen] = React.useState(false);
    const [style, setStyle] = useState('');
    const [value, setValue] = React.useState(0);
    const [reference, setreference] = useState('');
    const [order, setOrder] = useState(0);
    const [storesActives, setStoresActives] = useState([]);
    const [storesDesactives, setStoresDesactives] = useState([]);
    const [loading, setLoading] = useState(false);

    const [openDialog, setOpenDialog] = useState(false);

    const handleClickOpenDialog = (newOrder) => {
        if (order !== newOrder) {
            getStoresBySupplyOrder(null, newOrder)
                .then(response => {
                    setStoresDesactives(response);
                })
                .catch(() => ({

                }));

            getStoresBySupplyOrder(true, newOrder)
                .then(response => {
                    setStoresActives(response);
                })
                .catch(() => ({

                }));
        }
        setOpenDialog(true);

    };

    const updateStores = (order) => {
        getStoresBySupplyOrder(null, order)
            .then(response => {
                    setStoresDesactives(response);
                })
        .catch(() => ({

        }));

        getStoresBySupplyOrder(true, order)
            .then(response => {
                setStoresActives(response);
            })
            .catch(() => ({

            }));
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const handleChangeTab = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
    };

    const handleChangeStyle = event => {
        if (tableRef.current) {
            tableRef.current.state.query.page = 0;
            setStyle(event.target.value);
            tableRef.current.state.query.dateInit = selectedDateInit;
            tableRef.current.state.query.dateEnd = selectedDateEnd;
            tableRef.current.onQueryChange();
        }
    };

    const handleChangeDateInit = event => {
        if (tableRef.current) {
            tableRef.current.state.query.page = 0;
            tableRef.current.state.query.style = style;
            tableRef.current.state.query.dateInit = event.target.value;
            tableRef.current.state.query.dateEnd = selectedDateEnd;
            setSelectedDateInit(event.target.value);
            tableRef.current.onQueryChange();
        }
    };
    const handleChangeDateEnd = event => {
        if (tableRef.current) {
            tableRef.current.state.query.page = 0;
            tableRef.current.state.query.area = style;
            tableRef.current.state.query.dateEnd = event.target.value;
            tableRef.current.state.query.dateInit = selectedDateInit;
            setSelectedDateEnd(event.target.value);
            tableRef.current.onQueryChange();
        }
    };

    const handleClose = () => {
        setOpen(false);
    };

    const toggleForm = () => {
        if (tableRef.current) {
            tableRef.current.state.query.page = 0;
            if (openForm) {
                tableRef.current.onQueryChange();
            }
        }
        setOpenForm(!openForm);
        setDataObject(0);
        setNameObject('');
    };

    useEffect(() => {
        // hasAuthority(Permissions.GetAllRoutes, setCanGetAllRoutes);
        // hasAuthority(Permissions.CreateRoute, setCanCreateRoute);
        // hasAuthority(Permissions.UpdateRouteByID, setCanUpdateRoute);
        // hasAuthority(Permissions.DeleteRouteByID, setCanDeleteRoute);
        // hasAuthority(Permissions.AddShopToRouteByID, setCanAddShopToRoute);
        // hasAuthority(Permissions.RemoveShopFromRouteByID, setRemoveShopFromRoute);
    }, []);

    const getWavesData  = query =>
        getAllOrderGroups(true, query.pageSize, query.page + 1, selectedDateInit, selectedDateEnd, style)
            .then(response => ({
                ...query,
                data: response.data,
                page: response.current_page -1 ,
                totalCount: response.total,
            }))
            .catch(() => ({
                ...query,
                page: 0,
                pageSize: 15,
                totalCount: 0,
                data: [],
            }));

    const handleSubmitClear = event => {
        event.preventDefault();
        setStyle('');
        setSelectedDateInit();
        setSelectedDateEnd();
        if (tableRef.current) {
            tableRef.current.state.query.page = 0;
            tableRef.current.state.query.style = style;
            tableRef.current.state.query.dateInit = selectedDateInit;
            tableRef.current.state.query.dateEnd = selectedDateEnd;
            setSelectedDateInit(event.target.value);
            tableRef.current.onQueryChange();
        }
    };

    const InputComponentInit = ({ defaultValue, inputRef, ...props }: any) => {
        const handleChange = event => {
            setSelectedDateInit(event.target.value);
            if (props.onChange) {
                props.onChange(event);
            }
        };

        return (
            <div className={classes.root}>
                <div className={classes.display}>{selectedDateInit}</div>
                <input
                    className={classes.input}
                    ref={inputRef}
                    {...props}
                    onChange={handleChange}
                    value={selectedDateInit}
                />
            </div>
        );
    };
    const InputComponentEnd = ({ defaultValue, inputRef, ...props }: any) => {
        const handleChange = event => {
            setSelectedDateEnd(event.target.value);
            if (props.onChange) {
                props.onChange(event);
            }
        };

        return (
            <div className={classes.root}>
                <div className={classes.display}>{selectedDateEnd}</div>
                <input
                    className={classes.input}
                    ref={inputRef}
                    {...props}
                    onChange={handleChange}
                    value={selectedDateEnd}
                />
            </div>
        );
    };

    const downloadXlsx = (orderGroupId, reference, route) => {
        setLoading(true);
        getOrderGroupReports(orderGroupId, route)
            .then(response => {
                const contentDisposition = response.headers['content-disposition'];
                const contentType = response.headers['content-type'];
                const filename = reference+'.xlsx';
                const file = new Blob([response.data], { type: contentType });
                // For Internet Explorer and Edge
                if ('msSaveOrOpenBlob' in window.navigator) {
                    window.navigator.msSaveOrOpenBlob(file, filename);
                }
                // For Firefox and Chrome
                else {
                    // Bind blob on disk to ObjectURL
                    const data = URL.createObjectURL(file);
                    const a = document.createElement('a');
                    a.style = 'display: none';
                    a.href = data;
                    a.download = filename;
                    document.body.appendChild(a);
                    a.click();
                    // For Firefox
                    setTimeout(function() {
                        document.body.removeChild(a);
                        // Release resource on disk after triggering the download
                        window.URL.revokeObjectURL(data);
                    }, 100);
                }
                setLoading(false);
            })
            .catch(e => {
                setLoading(false);
                console.log(e);
            });
    };

    return (
        <Box
            id="app-routes-page"
            component="main"
            className={appStyles.pageRoot}
            style={{ backgroundColor: 'white', height: '100vh' }}
        >
            <Box className={classes.header} style={{ marginBottom: '30px' }}>
                <Box flexGrow={1}>
                    <Typography variant="h4" color="primary">
                        Ordenes de Surtido
                    </Typography>
                </Box>
            </Box>
            <div style={{
                zIndex:100,
                position:'absolute',
                marginLeft:'40%',
                marginRight:'50%',
                marginTop:'30%',
            }}>
                {loading && <CircularProgress className={classes.loadingProgress} />}
            </div>
            <ReportSupplyOrderForm
                formOpen={openForm}
                toggleForm={toggleForm}
                refreshTable={() => tableRef.current && tableRef.current.onQueryChange()}
                dataObject={dataObject}
                nameObject={nameObject}
                canUpdateUser={true}
                canGetUserData={true}
                canUpdateUserData={true}
            />
            <MaterialTable
                columns={[
                    { title: 'Fecha de creación', field: 'created_at', type: 'datetime' },
                    { title: 'Local', field: 'local' },
                    { title: 'Referencia', field: 'reference' },
                    { title: 'Grupo', field: 'order_group_id' },
                    {
                        title: 'Piezas totales',
                        field: 'total_pieces',
                        type: 'numeric',
                        render: rowData => {
                            return `${rowData.total_pieces.toLocaleString()}`;
                        },
                    },
                    {
                        title: 'Piezas surtidas',
                        field: 'sorted_pieces',
                        type: 'numeric',
                        render: rowData => {
                            return `${rowData.sorted_pieces.toLocaleString()}`;
                        },
                    }                    
                ]}
                title="Reporte Ordenes de Surtido"
                localization={materialTableLocaleES}
                tableRef={tableRef}
                data={query => getWavesData(query)}
                options={{
                    search: false,
                    pageSize: 20,
                    actionsColumnIndex: -1,
                    paging: true,
                    toolbar: true,
                    showTitle: false,
                    padding: 'dense',
                    pageSizeOptions: [5, 10, 20, 50, 100],
                }}
                actions={[
                    rowData => ({
                        icon: () => (
                            <ShowIcon color={rowData.order_group_id ? 'primary' : 'disabled'} className="icon-small" />
                        ),
                        tooltip: rowData.order_group_id ? 'Ver Detalle' : undefined,
                        onClick: (event, rowData) => {
                            if (rowData.order_group_id) {
                                setDataObject(rowData.order_group_id);
                                setNameObject(rowData.reference);
                                setOpenForm(!openForm);
                            }
                        },
                    }),
                    {
                        icon: () =>  <CheckBoxIcon color="primary" className="icon-small" />,
                        tooltip: 'Ver Tiendas',
                        onClick: (event, rowData) => {
                           setOrder(rowData.order_group_id);
                           setreference(rowData.reference);
                           handleClickOpenDialog(rowData.order_group_id);
                        },
                    },
                    {
                        icon: () =>  <BarChartIcon color="primary" className="icon-small" />,
                        tooltip: 'Exportar Resumen',
                        onClick: (event, rowData) => {
                           downloadXlsx(rowData.order_group_id, rowData.reference, '/getordergroupsummary');
                        },
                    },
                    {
                        icon: () =>  <AllInboxIcon color="primary" className="icon-small" />,
                        tooltip: 'Exportar Reporte de contenidos',
                        onClick: (event, rowData) => {
                           downloadXlsx(rowData.order_group_id, rowData.reference, '/getcartonsreport');
                        },
                    },
                ]}
                components={{
                    Toolbar: props => (
                        <div>
                            <MTableToolbar {...props} />
                            <FormControl className={classes.formControlTableToolBar}>
                                <TextField
                                    className={classes.formClearData}
                                    label="Estilo"
                                    fullWidth
                                    size="small"
                                    name="styleInput"
                                    id="outlined-size-small1"
                                    variant="outlined"
                                    value={style || ''}
                                    onChange={handleChangeStyle}
                                />
                            </FormControl>
                            <FormControl className={classes.formControlTableToolBar}>
                                <TextField
                                    fullWidth
                                    id="date-Init"
                                    label="Fecha Inicio"
                                    type="date"
                                    onChange={handleChangeDateInit}
                                    InputProps={{
                                        inputComponent: InputComponentInit,
                                    }}
                                    defaultValue={selectedDateInit}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </FormControl>
                            <FormControl className={classes.formControlTableToolBar}>
                                <TextField
                                    fullWidth
                                    id="date-End"
                                    label="Fecha Fin"
                                    type="date"
                                    onChange={handleChangeDateEnd}
                                    InputProps={{
                                        inputComponent: InputComponentEnd,
                                    }}
                                    defaultValue="2017-05-24"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </FormControl>
                            <FormControl className={classes.formControlTableToolBar}>
                                <Box
                                    component="form"
                                    onSubmit={handleSubmitClear}
                                    className={classes.formClearData}
                                >
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        className={classes.button}
                                        endIcon={<SearchIcon>Filtrar</SearchIcon>}
                                    >
                                        Limpiar filtro
                                    </Button>
                                </Box>
                            </FormControl>
                        </div>
                    ),
                }}
            />

            <Dialog fullScreen open={openDialog} onClose={handleCloseDialog} TransitionComponent={Transition}>
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={handleCloseDialog} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" className={classes.title}>
                            Tiendas en orden de surtido: {reference}
                        </Typography>
                    </Toolbar>
                    <AppBar position="static" color="default">
                        <Tabs
                            value={value}
                            onChange={handleChangeTab}
                            indicatorColor="primary"
                            textColor="primary"
                            centered
                            scrollButtons="auto"
                            aria-label="scrollable auto tabs example"

                        >
                            <Tab label="Tiendas desactivadas" {...a11yProps(0)} />
                            <Tab label="Tiendas activas" {...a11yProps(1)} />
                        </Tabs>
                    </AppBar>
                </AppBar>

                <TabPanel value={value} index={0} style={{marginTop:"100px"}}>

                    <MaterialTable
                        columns={[
                            { title: '#', field: 'number', editable: 'never' },
                            { title: 'Tienda', field: 'name', editable: 'never' },
                            {
                                title: 'Activa',
                                field: 'store_id',
                                type: 'boolean',
                                render: rowData =>
                                    rowData.store_id === 0 ? 'No' : 'Si'
                            },
                        ]}
                        title="TIENDAS DESACTIVADAS"
                        localization={materialTableLocaleES}
                        tableRef={tableRef1}
                        data={storesDesactives}
                        options={{
                            search: true,
                            pageSize: 20,
                            actionsColumnIndex: -1,
                            paging: true,
                            toolbar: true,
                            showTitle: true,
                            padding: 'dense',
                            pageSizeOptions: [5, 10, 20, 50, 100],
                        }}
                        editable={{
                            onRowUpdate: (newData, oldData) =>
                                new Promise((resolve, reject) => {
                                    console.log(newData);
                                    let orderId = newData.id;
                                    let storeNumber = newData.number;

                                    if (newData.store_id  === true) {
                                        updateOrder(orderId, storeNumber)
                                            .then(response => {
                                                updateStores(order);
                                                console.log(response);
                                                tableRef.current.state.query.page = 0;
                                                tableRef.current.onQueryChange();
                                                resolve();

                                            }).catch(response => {
                                            console.log(response);
                                            reject();
                                        });
                                    }
                                    resolve();
                                }),
                        }}
                    />
                </TabPanel>
                <TabPanel value={value} index={1} style={{marginTop:"100px"}}>
                    <MaterialTable
                        columns={[
                            { title: '#', field: 'number', editable: 'never' },
                            { title: 'Tienda', field: 'name', editable: 'never' },
                            {
                                title: 'Activa',
                                field: 'store_id',
                                type: 'boolean',
                                render: rowData =>
                                    rowData.store_id === 0 ? 'No' : 'Si'
                            },
                        ]}
                        title="TIENDAS ACTIVAS"
                        localization={materialTableLocaleES}
                        tableRef={tableRef2}
                        data={storesActives}
                        options={{
                            search: true,
                            pageSize: 20,
                            actionsColumnIndex: -1,
                            paging: true,
                            toolbar: true,
                            showTitle: true,
                            padding: 'dense',
                            pageSizeOptions: [5, 10, 20, 50, 100],
                        }}
                        editable={{
                            onRowUpdate: (newData, oldData) =>
                                new Promise((resolve, reject) => {
                                    let orderId = newData.id;
                                    let storeNumber = newData.number;

                                    if (newData.store_id  === false) {
                                        updateOrder(orderId, storeNumber, true)
                                            .then(response => {
                                                updateStores(order);
                                                tableRef.current.state.query.page = 0;
                                                tableRef.current.onQueryChange();
                                                resolve();
                                            }).catch(response => {
                                            reject();
                                        });
                                    }
                                    resolve();
                                }),
                        }}
                    />
                </TabPanel>
            </Dialog>

            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <div className={classes.paper}>
                        <h2 id="transition-modal-title">No hay datos</h2>
                        <p id="transition-modal-description">No hay datos con los filtros seleccionados</p>
                    </div>
                </Fade>
            </Modal>
        </Box>
    );
}

export default WithSnackBarMessages(ReportSupplyOrdersPage);
