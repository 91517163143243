import React, { useState, useRef, useEffect } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import LinearProgress from '@material-ui/core/LinearProgress';
import Button from '@material-ui/core/Button';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import MaterialTable, {MTableToolbar} from 'material-table';
import OrderPickingDetail from './OrderPickingDetail';
import PageHeader from '../../../components/PageHeader';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import BarChartIcon from '@material-ui/icons/BarChart';

// Styles
import materialTableLocaleES from '../../../components/MaterialTableLocaleES';

// Routes
import { APP_ROUTES } from '../../../Routes';
import {
    getAllWavesForPicking,
    getAllWavesForFinished,
    waveSupply,
    size,
    page
} from '../../../api/WaveServices/WavesApi';
import { getPermissions } from '../../../api/AuthApi';
import { getSupplyOrderFinishedReports } from '../../../api/ReportService/ReportApi'
import {CircularProgress, FormControl, InputLabel, MenuItem, Select, TextField} from "@material-ui/core";

const breadcrumbsData = [
  {
    id: 1,
    label: 'Ordenes de Picking',
    base: APP_ROUTES.WAVES,
  },
];

const useStyles = makeStyles(theme => ({
  actionDescriptionEdit: {
    '& .MuiInput-root': {
      fontSize: 'small',
    },
  },
  root: {
    marginTop: '15px',
    marginLeft: '19px',
    marginRight: '19px',
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(3),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  typography: {
    textAlign: 'center',
  },
  formControlTableToolBar: {
    margin: theme.spacing(1),
    marginTop: -theme.spacing(7),
    marginRight: theme.spacing(2),
    minWidth: 160,
    maxWidth: 10,
  },

  formControl2TableToolBar: {
    margin: theme.spacing(1),
    marginTop: theme.spacing(-5),
    marginRight: theme.spacing(2),
    minWidth: 110,
    maxWidth: 10,
  },
}));

const LinearProgressPicking = withStyles({
  colorPrimary: {
    backgroundColor: '#81c784',
  },
  barColorPrimary: {
    backgroundColor: '#519657',
  },
})(LinearProgress);

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

function a11yProps(index: any) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

function a11yPropsTwo(index: any) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

function PickingPage() {
  const classes = useStyles();
  const [openForm, setOpenForm] = useState(false);
  const tableRef = useRef();
  const tableRefAllWaves = useRef();
  const [dataObject, setDataObject] = useState({});
  const [value, setValue] = React.useState(0);
  const [valueA, setValueA] = React.useState(0);
  const [waveComplete, setWaveComplete] = useState(0);
  const [open, setOpen] = React.useState(false);
  const [permissions, setPermissions] = useState([]);
  const [selectedDateInit, setSelectedDateInit] = React.useState();
  const [selectedDateEnd, setSelectedDateEnd] = React.useState();
  const [waveNumber, setWaveNumber] = useState();
  const [loading, setLoading] = useState(false);

  const handleClickOpen = () => {
      setOpen(true);
  };

  const handleChangeWave = event => {
    if (tableRefAllWaves.current) {
        tableRefAllWaves.current.state.query.page = 0;
        tableRefAllWaves.current.state.query.wave = event.target.value;
        tableRefAllWaves.current.state.query.dateInit = null;
        tableRefAllWaves.current.state.query.dateEnd = null;
        setWaveNumber(event.target.value);
        setSelectedDateInit(null);
        setSelectedDateEnd(null);
        tableRefAllWaves.current.onQueryChange();
    }
  };

  const handleChangeDateInit = event => {
    if (tableRefAllWaves.current) {
        tableRefAllWaves.current.state.query.page = 0;
        tableRefAllWaves.current.state.query.wave = null;
        tableRefAllWaves.current.state.query.dateInit = event.target.value;
        tableRefAllWaves.current.state.query.dateEnd = selectedDateEnd;
        setSelectedDateInit(event.target.value);
        setWaveNumber('');
        tableRefAllWaves.current.onQueryChange();
    }
  };

  const handleChangeDateEnd = event => {
    if (tableRefAllWaves.current) {
        tableRefAllWaves.current.state.query.page = 0;
        tableRefAllWaves.current.state.query.wave = null;
        tableRefAllWaves.current.state.query.dateEnd = event.target.value;
        tableRefAllWaves.current.state.query.dateInit = selectedDateInit;
        setSelectedDateEnd(event.target.value);
        setWaveNumber('');
        tableRefAllWaves.current.onQueryChange();
    }
  };

  const InputComponentInit = ({ defaultValue, inputRef, ...props }: any) => {
    const classes = useStyles();
    const handleChange = event => {
      setSelectedDateInit(event.target.value);
          if (props.onChange) {
            props.onChange(event);
          }
      };
      return (
        <div className={classes.root}>
            <input
                className={classes.input}
                ref={inputRef}
                {...props}
                onChange={handleChange}
                value={selectedDateInit}
            />
        </div>
      );
  };

  const InputComponentEnd = ({ defaultValue, inputRef, ...props }: any) => {
    const classes = useStyles();
    const handleChange = event => {
      setSelectedDateEnd(event.target.value);
        if (props.onChange) {
            props.onChange(event);
        }
      };
    return (
      <div className={classes.root}>
        <input
            className={classes.input}
            ref={inputRef}
            {...props}
            onChange={handleChange}
            value={selectedDateEnd}
        />
      </div>
    );
    };

  const handleClose = () => {
      setOpen(false);
  };

  const toggleForm = () => {
    if (tableRef.current) {
      tableRef.current.state.query.page = 0;
      if (openForm) {
        tableRef.current.onQueryChange();
      }
    }
    setOpenForm(!openForm);
    setDataObject(dataObject);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeTabTwo = (event, newValue) => {
    setValueA(newValue);
  };

  const calculatePercent = (linesDetail, pieces) => {
      let expected = 0;
      let avg;
      for (const f in linesDetail) {
          expected += linesDetail[f].pzas;
      }
      avg = Math.round((expected / pieces) * 10000) / 100;
      avg = avg > 100 ? 100 : avg;
      return avg;

  };

  const openDialogSuccess  = (wave) => {
    setOpen(true);
    setWaveComplete(wave);
  };

  const completeWave = () =>
      waveSupply(waveComplete, true)
          .then(response => {
              console.log("se completo");
              setWaveComplete(0);
              handleClose();
              window.location.reload();
          })
          .catch(e => {
              console.log("no se completo");
              console.log(e);
          });

  const checkFullAccess = () => {
    let check = permissions.filter(obj => obj.name === 'Full Access');
      if (check.length === 0) {
          return true
      } else {
          return false
      }
  };

  useEffect(() => {
      getPermissions().then(response => {
        setPermissions(response);
      }).catch(e => {
          console.log(e);
      })
    /*
      hasAuthority(Permissions.GetAllUsers, setCanGetAllUsers);
      hasAuthority(Permissions.GetUserById, setCanGetUser);
      hasAuthority(Permissions.CreateUser, setCanCreateUser);
      hasAuthority(Permissions.UpdateUser, setCanUpdateUser);
      hasAuthority(Permissions.GetUserDataById, setCanGetUserData);
      hasAuthority(Permissions.UpdateUserDataById, setCanUpdateUserData);
      hasAuthority(Permissions.DeleteUser, setCanDeleteUser);
      */
  }, []);
  const [rows, setRows] = useState([{}]);
  const [header, setHeader] = useState([{}]);

  const renderOrderGroupsDetail = rowData => {
    let rows = rowData.lines_detail;
    return (
      <TableContainer>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center">No. Departamento</TableCell>
              <TableCell align="left">Departamento</TableCell>
              <TableCell align="center">Piezas</TableCell>
              <TableCell align="center">A Completar</TableCell>
              <TableCell align="center">Cajas</TableCell>
              <TableCell align="center">Avance</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map(row => {
              let expected = 1;
              let percent = 0;
              for (const x in rowData.lines_progress) {
                if (parseInt(rowData.lines_progress[x].department_id) === row.id)
                  expected = rowData.lines_progress[x].expected_pieces;
              }
              percent = Math.round((row.pzas / expected) * 10000) / 100;
              percent = percent > 100 ? 100 : percent;
              return (
                <TableRow key={Math.random() * 100}>
                  <TableCell align="center" component="th" scope="row">
                    {row.name.substring(0, 3)}
                  </TableCell>
                  <TableCell align="left">{row.name.substring(4, row.name.length)}</TableCell>
                  <TableCell align="center">{row.pzas}</TableCell>
                  <TableCell align="center">{expected}</TableCell>
                  <TableCell align="center">{row.cajas}</TableCell>
                  <TableCell align="center">
                    {rowData.status === 3 ? (
                      <LinearProgressPicking variant="determinate" value={percent} />
                    ) : (
                      <LinearProgress variant="determinate" value={percent} />
                    )}
                    <Typography className={classes.typography}>{`${percent}%`}</Typography>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  const getSupplyOrders = query =>
    getAllWavesForPicking(true)
      .then(response => ({
        ...query,
        data: response.data,
        page: response.current_page - 1,
        totalCount: response.total,
      }))
      .catch(() => ({
        ...query,
        page: 0,
        pageSize: 15,
        totalCount: 0,
      }));

  const getSupplyOrdersFinished = query =>
      getAllWavesForFinished(
          selectedDateInit,
          selectedDateEnd,
          waveNumber,
          query.size,
          query.page +1,
          query.search
      )
        .then(response => ({
            ...query,
            data: response.data,
            page: response.current_page - 1,
            totalCount: response.total,
        }))
        .catch(() => ({
            ...query,
            page: 0,
            pageSize: 15,
            totalCount: 0,
        }));

  useEffect(() => {
    getAllWavesForPicking(false)
      .then(response => {
        const header = [];
        const row = [];
        for (const x in response) {
          if (!header.includes(response[x].dept)) {
            header.push(response[x].dept);
            row[response[x].dept] = [response[x]];
          } else {
            row[response[x].dept].push(response[x]);
          }
        }
        setHeader(header);
        setRows(row);
      })
      .catch(e => {
        console.log(e);
      });
  }, []);

  const downloadXlsx = (wave) => {
    setLoading(true);
      getSupplyOrderFinishedReports(wave)
        .then(response => {
            const contentDisposition = response.headers['content-disposition'];
            const contentType = response.headers['content-type'];
            const filename = wave+'-reporte.xlsx';
            const file = new Blob([response.data], { type: contentType });
            // For Internet Explorer and Edge
            if ('msSaveOrOpenBlob' in window.navigator) {
                window.navigator.msSaveOrOpenBlob(file, filename);
            }
            // For Firefox and Chrome
            else {
                // Bind blob on disk to ObjectURL
                const data = URL.createObjectURL(file);
                const a = document.createElement('a');
                a.style = 'display: none';
                a.href = data;
                a.download = filename;
                document.body.appendChild(a);
                a.click();
                // For Firefox
                setTimeout(function() {
                    document.body.removeChild(a);
                    // Release resource on disk after triggering the download
                    window.URL.revokeObjectURL(data);
                }, 100);
            }
            setLoading(false);
        })
        .catch(e => {
            setLoading(false);
            console.log(e);
        });
    };

  return (
    <div className={classes.root}>
      <div style={{
        zIndex:100,
        position:'absolute',
        marginLeft:'40%',
        marginRight:'50%',
        marginTop:'30%',
      }}>
        {loading && <CircularProgress className={classes.loadingProgress} />}
      </div>
      <AppBar
        position="static"
        color="default"
        style={{ alignItems: 'center', backgroundColor: 'white' }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
        >
          <Tab label="Ordenes Por Olas" {...a11yProps(0)} />
          <Tab label="Ordenes Finalizadas" {...a11yProps(1)} />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <PageHeader title="Ordenes de Picking" breadcrumbsData={breadcrumbsData} />
        <OrderPickingDetail
          formOpen={openForm}
          toggleForm={toggleForm}
          refreshTable={() => tableRef.current && tableRef.current.onQueryChange()}
          dataObject={dataObject}
        />
        <MaterialTable
          columns={[
            {
              title: 'ID OLA',
              field: 'wave_ref',
              render: rowData => {
                let qtyPz = 0;
                let qtyBox = 0;
                let skus = 0;
                for (const j in rowData.lines_detail) {
                  qtyPz += rowData.lines_detail[j].pzas;
                  qtyBox += rowData.lines_detail[j].cajas;
                  skus += rowData.lines_detail[j].skus;
                }
                const obj = {
                  skus,
                  pzas: qtyPz,
                  cajas: qtyBox,
                };
                return [
                  <Button
                    key={`button${rowData.id}`}
                    href="#text-buttons"
                    color="primary"
                    onClick={(event, rowDataBtn) => {
                      setDataObject(Object.assign(rowData, obj));
                      setOpenForm(!openForm);
                    }}
                  >
                    {`${rowData.id}`}
                  </Button>,
                ];
              },
            },
            { title: 'Pzs. totales', field: 'planned_pieces' },
            { title: 'Pzs. a pickear', field: 'pieces' },
            {
              title: 'Pzs. pickeadas',
              field: 'cajas',
              render: rowData => {
                const details = rowData.lines_detail;
                let pzas = 0;
                for (const f in details) {
                  pzas += details[f].pzas;
                }
                return pzas;
              }
            },
            { title: 'SKUs con stock', field: 'available_skus' },
            {
              title: 'SKUs pickeados',
              field: 'cajas',
              render: rowData => {
                  const details = rowData.lines_detail;
                  let skus = 0;
                  for (const f in details) {
                      skus += details[f].skus;
                  }
                  return skus;
              }
            },
            {
              title: 'Cajas pickeadas',
              field: 'cajas',
              render: rowData => {
                const details = rowData.lines_detail;
                let boxes = 0;
                for (const f in details) {
                  boxes += details[f].cajas;
                }
                return boxes;
              }
            },
            {
              title: 'Surtido',
              field: 'complete',
              render: rowData => {
                const details = rowData.lines_detail;
                let skus = 0;
                let avg;
                for (const f in details) {
                  skus += details[f].skus;
                }
                avg = Math.round((skus / rowData.available_skus) * 10000) / 100;
                avg = avg > 100 ? 100 : avg;
                return [
                  rowData.status === 3 ? (
                    <LinearProgressPicking key={`bar${rowData.id}`} variant="determinate" value={avg} />
                  ) : (
                    <LinearProgress key={`bar${rowData.id}`} variant="determinate" value={avg} />
                  ),
                  <Typography key={`text${rowData.id}`} className={classes.typography}>
                    {`${avg}%`}
                  </Typography>,
                ];
              },
            }
          ]}
          title=" "
          localization={ {
              body: {
                  emptyDataSourceMessage: 'No se encontraron registros',
              },
              header: {
                  actions: ' ',
              },
          } }
          tableRef={tableRef}
          data={getSupplyOrders}
          options={{
            search: false,
            paging: false,
            toolbar: true,
            padding: 'dense',
            actionsColumnIndex: -1,
          }}
          actions={[
              rowData => ({
                  icon: () => <PlayCircleOutlineIcon color={ (calculatePercent(rowData.lines_detail, rowData.pieces) < 98) ? 'disabled' : 'primary'} className="icon-small" />,
                  tooltip: 'Lanzar ola',
                  onClick: (event, rowData) => {
                    openDialogSuccess(rowData.wave_ref);
                  },
                  disabled : calculatePercent(rowData.lines_detail, rowData.pieces) < 98,
                  hidden: checkFullAccess()
              })
          ]}
          detailPanel={rowData => renderOrderGroupsDetail(rowData)}
        />
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{"¿Desea completar el surtido de la ola?"}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Confirme que desea completar el surtido de la ola {waveComplete}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="secondary">
                    Cancelar
                </Button>
                <Button onClick={completeWave} color="primary">
                    Aceptar
                </Button>
            </DialogActions>
        </Dialog>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <PageHeader title="Ordenes Finalizadas" breadcrumbsData={breadcrumbsData} />
        <OrderPickingDetail
          formOpen={openForm}
          toggleForm={toggleForm}
          refreshTable={() => tableRef.current && tableRef.current.onQueryChange()}
          dataObject={dataObject}
        />
        <AppBar
          position="static"
          color="default"
          style={{ alignItems: 'center', backgroundColor: 'white' }}
        >
        </AppBar>
        <MaterialTable
          columns={[
              {
                  title: 'ID OLA',
                  field: 'wave_ref',
                  render: rowData => {
                      let qtyPz = 0;
                      let qtyBox = 0;
                      let skus = 0;
                      for (const j in rowData.lines_detail) {
                          qtyPz += rowData.lines_detail[j].pzas;
                          qtyBox += rowData.lines_detail[j].cajas;
                          skus += rowData.lines_detail[j].skus;
                      }
                      const obj = {
                          skus,
                          pzas: qtyPz,
                          cajas: qtyBox,
                      };
                      return [
                          <Button
                              key={`button${rowData.id}`}
                              href="#text-buttons"
                              color="primary"
                              onClick={(event, rowDataBtn) => {
                                  setDataObject(Object.assign(rowData, obj));
                                  setOpenForm(!openForm);
                              }}
                          >
                              {`${rowData.id}`}
                          </Button>,
                      ];
                  },
              },
              { title: 'Pzs. totales', field: 'planned_pieces' },
              { title: 'Pzs. a pickear', field: 'pieces' },
              {
                  title: 'Pzs. pickeadas',
                  field: 'cajas',
                  render: rowData => {
                      const details = rowData.lines_detail;
                      let pzas = 0;
                      for (const f in details) {
                          pzas += details[f].pzas;
                      }
                      return pzas;
                  }
              },
              { title: 'SKUs con stock', field: 'available_skus' },
              {
                  title: 'SKUs pickeados',
                  field: 'cajas',
                  render: rowData => {
                      const details = rowData.lines_detail;
                      let skus = 0;
                      for (const f in details) {
                          skus += details[f].skus;
                      }
                      return skus;
                  }
              },
              {
                  title: 'Cajas pickeadas',
                  field: 'cajas',
                  render: rowData => {
                      const details = rowData.lines_detail;
                      let boxes = 0;
                      for (const f in details) {
                          boxes += details[f].cajas;
                      }
                      return boxes;
                  }
              },
              {
                  title: 'Surtido',
                  field: 'complete',
                  render: rowData => {
                      const details = rowData.lines_detail;
                      let skus = 0;
                      let avg;
                      for (const f in details) {
                          skus += details[f].skus;
                      }
                      avg = Math.round((skus / rowData.available_skus) * 10000) / 100;
                      avg = avg > 100 ? 100 : avg;
                      return [
                          rowData.status === 3 ? (
                              <LinearProgressPicking key={`bar${rowData.id}`} variant="determinate" value={avg} />
                          ) : (
                              <LinearProgress key={`bar${rowData.id}`} variant="determinate" value={avg} />
                          ),
                          <Typography key={`text${rowData.id}`} className={classes.typography}>
                              {`${avg}%`}
                          </Typography>,
                      ];
                  },
              }
          ]}
          title=" "
          localization={ {
              body: {
                  emptyDataSourceMessage: 'No se encontraron registros',
              },
              header: {
                  actions: 'Acciones',
              },
          } }
          tableRef={tableRefAllWaves}
          data={query => getSupplyOrdersFinished(query)}
          options={{
              search: false,
              paging: true,
              toolbar: true,
              padding: 'dense',
              toolbarButtonAlignment:"left",
              pageSize : 20,
          }}
          detailPanel={rowData => renderOrderGroupsDetail(rowData)}
          actions={[
              {
                  icon: () =>  <BarChartIcon color="primary" className="icon-small" />,
                  tooltip: 'Exportar Reporte',
                  onClick: (event, rowData) => {
                      downloadXlsx(rowData.wave_ref);
                  },
              },
          ]}
          components={{
              Toolbar: props => (
                  <div>
                      <MTableToolbar {...props} />
                      <FormControl className={classes.formControl2TableToolBar}>
                          <TextField
                              fullWidth
                              id="wave"
                              label="Ola"
                              defaultValue={waveNumber}
                              onBlurCapture={handleChangeWave}
                          />
                      </FormControl>
                      <FormControl className={classes.formControlTableToolBar}>
                          <TextField
                              fullWidth
                              id="date-Init"
                              label="Fecha Inicio"
                              type="date"
                              onChange={handleChangeDateInit}
                              InputProps={{
                                  inputComponent: InputComponentInit,
                              }}
                              defaultValue={selectedDateInit}
                              InputLabelProps={{
                                  shrink: true,
                              }}
                          />
                      </FormControl>
                      <FormControl className={classes.formControlTableToolBar}>
                          <TextField
                              fullWidth
                              id="date-End"
                              label="Fecha Fin"
                              type="date"
                              onChange={handleChangeDateEnd}
                              InputProps={{
                                  inputComponent: InputComponentEnd
                              }}
                              defaultValue="2017-05-24"
                              InputLabelProps={{
                                  shrink: true
                              }}
                          />
                      </FormControl>
                  </div>
              ),
          }}
        />
      </TabPanel>
    </div>


  );
}

export default PickingPage;
