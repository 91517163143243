import React, { useState } from 'react';
import {
  Drawer,
  TextField,
  Typography,
  Box,
  Button,
  CircularProgress,
  IconButton,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import {
  Edit as EditIcon,
  Close as CloseIcon,
  LocationSearching as LocationSearchingIcon,
} from '@material-ui/icons';
import { useSnackbar } from 'notistack';
// Components
import SnackBarMessage from '../../../components/SnackBarMessage';

// Data Api
import {
  createZone,
  updateZoneById,
  getZoneById,
} from '../../../api/WaveServices/WavesApi';

const drawerWidth = 450;
const useStyles = makeStyles(theme => ({
  container: {
    width: drawerWidth,
  },
  containerPaper: {
    width: drawerWidth,
    padding: 20,
  },
  header: {
    display: 'flex',
    alignItems: 'center',
  },
  selectZoneType: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  selectFormTextField: {
    justifyContent: 'space-between',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  spaceOption: { padding: theme.spacing(1) },
  headerIcon: {
    fontSize: 35,
    marginRight: theme.spacing(1),
    alignSelf: 'baseline',
  },
  headerCloseButton: {
    padding: theme.spacing(1),
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    justifyContent: 'space-between',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  selectForm: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  selectStyle: {
    minWidth: '100%',
    maxWidth: '100%',
  },
  selectShops: {
    minWidth: 355,
    maxWidth: 355,
  },

  selectAddButton: {
    padding: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  bottomSpace: {
    paddingTop: theme.spacing(2),
  },
  actionDescriptionEdit: {
    '& .MuiInput-root': {
      fontSize: 'small',
    },
  },
}));

const initialState = {
  zoneTypeId: '',
  zoneId: '',
  zoneTypeError: false,
  zoneTypeErrorMessage: '',
  description: '',
  code: '',
  location: '',
  policyAccessError: false,
  roleAccessMessage: '',
};

export default function ZoneForm({
  formOpen,
  toggleForm,
  refreshTable,
  dataObject,
  canUpdateZone,
  match,
}) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [form, setForm] = useState(initialState);
  const [closeClick, setCloseClick] = useState('');
  const [editForm, setEditForm] = useState(false);

  const messageError = (message, variant) => {
    enqueueSnackbar(message, {
      variant,
    });
  };

  const zoneTypes = [
    { id: 1, name: 'Rack' },
    { id: 2, name: 'Pasillo' },
    { id: 3, name: 'Staging' },
    { id: 4, name: 'Buffer' },
    { id: 5, name: 'Inducción' },
  ];

  const handleChange = event => {
    setForm({
      ...form,
      [event.target.name]: event.target.value,
    });

    setCloseClick(true);
  };

  const handleSubmit = event => {
    event.preventDefault();
    setIsLoading(true);

    if (dataObject === 0) {      
      createZone(form.zoneTypeId, form.description, form.code, form.location)
        .then(() => {
          toggleForm();
          setForm(initialState);
          setIsLoading(false);
          refreshTable(form.zoneTypeId-1);
        })
        .catch(error => {
          if (error !== undefined) {
            console.log(error);
          } else {
            refreshTable(form.zoneTypeId-1);
            messageError('Servicio no disponible.', 'warning');
          }
          setIsLoading(false);
        });
    } else {
      updateZoneById(form.zoneId, form.zoneTypeId, form.description, form.code, form.location)
        .then(() => {
          toggleForm();
          setForm(initialState);
          setIsLoading(false);
          refreshTable(form.zoneTypeId-1);
        })
        .catch(error => {
          if (error !== undefined) {
            setForm({
              ...form,
              zoneTypeError: !!error.routeTypeErrorMessage,
              zoneTypeErrorMessage: error.routeNameErrorMessage,
            });
          } else {
            refreshTable(form.zoneTypeId-1);
            messageError('Servicio no disponible.', 'warning');
          }
          setIsLoading(false);
        });
    }
  };

  const clearData = () => {
    setForm(initialState);
    setCloseClick('');
    setEditForm(false);
    setIsLoading(false);
  };

  const optionStartZoneType = () => {
    let selectedZoneType = null;
    if (zoneTypes.length > 0)
      zoneTypes.forEach(zoneType => {
        if (zoneType.id === form.zoneTypeId) {
          selectedZoneType = zoneType;
        }
      });
    return selectedZoneType;
  };

  const initData = () => {
    if (dataObject === 0) {
      setForm(initialState);
    } else {
      getZoneById(dataObject)
        .then(response => {
          setForm({
            zoneId: response.id,
            zoneTypeId: response.zone_type_id,
            description: response.description,
            code: response.code,
            location: response.location
          });
        })
        .catch(() => {
          refreshTable(form.zoneTypeId-1);
          toggleForm();
          messageError('Servicio no disponible.', 'warning');
        });
    }

    setCloseClick(false);

    dataObject !== 0 ? setEditForm(false) : setEditForm(true);
  };

  const disableFormControl = () => {
    if (isLoading) {
      return true;
    }
    if (dataObject === 0) {
      return false;
    }
    if (dataObject !== 0 && editForm) {
      return false;
    }
    return true;
  };

  return (
    <Box>
      <Drawer
        anchor="right"
        open={formOpen}
        onClose={toggleForm}
        className={classes.container}
        classes={{ paper: classes.containerPaper }}
      >
        <Box className={classes.header}>
          <LocationSearchingIcon color="primary" className={classes.headerIcon} />
          <Box flexGrow={1}>
            <Typography variant="h5" color="primary">
              {dataObject === 0 ? ' Nueva Zona' : 'Actualizar Zona'}
            </Typography>
          </Box>
          {dataObject !== 0 && (
            <IconButton
              disabled={!canUpdateZone}
              color="primary"
              className={classes.headerCloseButton}
              onClick={() => {
                setEditForm(!editForm);
              }}
            >
              <EditIcon />
            </IconButton>
          )}
          <IconButton
            color="secondary"
            className={classes.headerCloseButton}
            onClick={() => {
              toggleForm();
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <Box className={classes.selectFormTextField}>
          <Autocomplete
            id="combo-box-zonetypes"
            options={zoneTypes}
            renderOption={option => (
              <div className={classes.selectZoneType}>
                <Box className={classes.spaceOption}>
                  <option value={option.name} name={option.id} key={option.id}>
                    {`${option.name}`}
                  </option>
                </Box>
              </div>
            )}
            noOptionsText="No hay tipos de zona disponibles"
            disabled={disableFormControl()}
            getOptionLabel={option => `${option.name}`}
            value={optionStartZoneType()}
            autoComplete
            onChange={(event, value) => {
              setForm({ ...form, zoneTypeId: value?.id || 0 });
            }}
            renderInput={params => (
              // eslint-disable-next-line react/jsx-props-no-spreading
              <TextField
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...params}
                label="Tipo de Zona"
                variant="outlined"
                placeholder="Seleccione un tipo de zona"
                fullWidth
                error={form.zoneTypeError}
                helperText={form.zoneTypeErrorMessage}
              />
            )}
          />
        </Box>
        <Box className={classes.selectFormTextField}>
          <TextField
            label="Descripción"
            id="description-textfield"
            value={form.description}
            name="description"
            fullWidth
            variant="outlined"
            onChange={handleChange}
          />
        </Box>

        <Box className={classes.selectFormTextField}>
          <TextField
            label="Ubicación"
            id="location-textfield"
            value={form.location}
            name="location"
            fullWidth
            variant="outlined"
            onChange={handleChange}
          />
        </Box>

        <Box className={classes.selectFormTextField}>
          <TextField
            label="Código"
            id="code-textfield"
            value={form.code}
            name="code"
            fullWidth
            variant="outlined"
            onChange={handleChange}
          />
        </Box>
        <Box component="form" className={classes.form} onSubmit={handleSubmit}>
          <Box />
          <Box className={classes.bottomSpace}>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              type="submit"
              disabled={disableFormControl()}
              style={{ borderRadius: 30 }}
              size="small"
            >
              {dataObject === 0 ? 'Guardar Zona' : 'Actualizar Zona'}
            </Button>

            {isLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
            {formOpen && closeClick === '' && initData()}
            {!formOpen && closeClick !== '' && clearData()}
          </Box>
        </Box>
      </Drawer>

      <SnackBarMessage
        open={form.policyAccessError}
        message={form.roleAccessMessage}
        onClose={() => setForm({ ...form, policyAccessError: false })}
        variant="warning"
      />
    </Box>
  );
}

ZoneForm.defaultProps = {
  refreshTable: null,
  dataObject: 0,
  canUpdateZone: false,
};

ZoneForm.propTypes = {
  refreshTable: PropTypes.func,
  formOpen: PropTypes.bool.isRequired,
  toggleForm: PropTypes.func.isRequired,
  dataObject: PropTypes.number,
  canUpdateZone: PropTypes.bool,
};
