 import React, { useState, useRef } from 'react';
import WithSnackBarMessages from '../../../components/WithSnackBarMessages';

import StylesForm from "../components/StylesForm";

function StylesPage() {
  const tableRef = useRef();
  const [openForm, setOpenForm] = useState(false);
  const [dataObject, setDataObject] = useState(0);

  const toggleForm = () => {
    if (tableRef.current) {
      tableRef.current.state.query.page = 0;
      if (openForm) {
        tableRef.current.onQueryChange();
      }
    }
    setOpenForm(!openForm);
    setDataObject(0);
  };

  return (
   <StylesForm
        formOpen={openForm}
        toggleForm={toggleForm}
        refreshTable={() => tableRef.current && tableRef.current.onQueryChange()}
        dataObject={dataObject}
   />
  );
}

export default WithSnackBarMessages(StylesPage);
