import 'date-fns';
import React, { useState, useRef, useEffect , useLayoutEffect} from 'react';
import {Box, TextField, FormControl, Typography, CircularProgress} from '@material-ui/core';
import {
    Visibility as ViewIcon,
    AccountTree as TreeIcon,
    Search as SearchIcon,
} from '@material-ui/icons';
import RefreshIcon from '@material-ui/icons/Refresh';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';

// Components
import MaterialTable, { MTableToolbar } from 'material-table';

import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import OrderGroupDetail from '../components/OrderGroupDetail';
import WithSnackBarMessages from '../../../components/WithSnackBarMessages';
import { Checkbox } from '@material-ui/core';

// Security
// import Permissions from '../../../AppsPermissions/ShopsAdministratorPermissions';
// import { hasAuthority } from '../../AccessValidators';

// Styles
import { AppStyles } from '../../../AppStyles';
import materialTableLocaleES from '../../../components/MaterialTableLocaleES';

// Data Apis
import {
    getAllOrderGroups,
    getOrderGroupExcel,
    getDepartmentsOrderById,
    recalculateOrderGroup,
} from '../../../api/WaveServices/WavesApi';
import {mergeSupplyOrders} from "../../../api/SupplyOrderService/SupplyOrderApi";
import {useSnackbar} from "notistack";
import { getPermissions } from '../../../api/AuthApi';

// Routes

const useStyles = makeStyles(theme => ({
    actionDescriptionEdit: {
        '& .MuiInput-root': {
            fontSize: 'small',
        },
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    display: {
        display: 'none',
    },
    formControlTableToolBar: {
        margin: theme.spacing(3),
        marginTop: -theme.spacing(7),
        minWidth: 170,
    },
    formClearData: {
        marginTop: '10px',
    },
    loadingProgress: {
        zIndex:100,
        position:'absolute',
        marginLeft:'50%',
        top: '25%',
        left: '55%',
    },
}));

const renderOrderGroupsDetail = rowData => {
    const columns = [
        { title: 'Division', field: 'division' },
        {
            title: 'Piezas',
            field: 'pieces',
            type: 'numeric',
            render: rowData => {
                return `${rowData.pieces.toLocaleString()}`;
            },
        },
        {
            title: 'En Ola',
            field: 'in_wave',
            type: 'numeric',
            render: rowData => {
                return `${rowData.in_wave.toLocaleString()}`;
            },
        },
        {
            title: 'Pendientes',
            field: 'pending',
            type: 'numeric',
            render: rowData => {
                return `${rowData.pending.toLocaleString()}`;
            },
        },
        {
            title: 'En surtido',
            field: 'pending',
            type: 'numeric',
            render: rowData => {
                return `${rowData.in_wave.toLocaleString()}`;
            },
        },
    ];
    return (
        <MaterialTable
            columns={columns}
            data={rowData.divisions}
            detailPanel={rowData => renderOrderDepartmentsDetail(rowData)}
            title="Distribución de divisiones"
            options={{
                search: false,
                paging: false,
                toolbar: false,
                rowStyle: {
                    backgroundColor: '#EEE',
                },
            }}
            localization={materialTableLocaleES}
        />
    );
};

const getDepartmentOrder = (query, idOrder, idDivision) =>
    getDepartmentsOrderById(idOrder, idDivision)
        .then(response => {
            return {
                ...query,
                page: 0,
                pageSize: 15,
                totalCount: 0,
                data: response,
            };
        })
        .catch(() => ({
            ...query,
            page: 0,
            pageSize: 15,
            totalCount: 0,
            data: [],
        }));

const renderOrderDepartmentsDetail = rowData => {
    return (
        <MaterialTable
            nameEntity="Departamentos"
            columns={[
                {
                    title: 'Departamento',
                    field: 'department_name',
                    type: 'string',
                },
                {
                    title: 'Piezas',
                    field: 'total_pieces',
                    type: 'numeric',
                },
                {
                    title: 'P Bajas',
                    field: 'total_lower',
                    type: 'numeric',
                },
                {
                    title: 'Resto',
                    field: 'total_lower',
                    type: 'numeric',
                    render: rowData => {
                        return `${rowData.total_pieces - rowData.total_lower}`;
                    },
                },
                {
                    title: 'En Ola',
                    field: 'total_in_wave',
                    type: 'numeric',
                },
                {
                    title: 'Pendiente',
                    field: 'total_pending',
                    type: 'numeric',
                },
            ]}
            data={query => getDepartmentOrder(query, rowData.order_group, rowData.id)}
            options={{
                search: false,
                showTitle: false,
                toolbar: false,
                paging: false,
                header: true,
                padding: 'dense',
                rowStyle: {
                    backgroundColor: 'white',
                },
            }}
        />
    );
};

function SupplyOrdersPage() {
    const classes = useStyles();
    const appStyles = AppStyles();
    const [openDetail, setOpenDetail] = useState(false);
    const tableRef = useRef();
    const [dataDetail, setDataDetail] = useState({});
    // const [canGetAllSupplyOrders, setCanGetAllSupplyOrders] = useState(true);
    const [selectedDateInit, setSelectedDateInit] = React.useState();
    const [selectedDateEnd, setSelectedDateEnd] = React.useState();
    const [open, setOpen] = React.useState(false);
    const [style, setStyle] = useState('');
    const [loading, setLoading] = useState(false);
    const [selectedSupplyOrderOne, setSelectedSupplyOrderOne] = useState([]);
    const [selectedSupplyOrderTwo, setSelectedSupplyOrderTwo] = useState([]);
    const [contador, setContador] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [permissions, setPermissions] = useState([]);
    const { enqueueSnackbar } = useSnackbar();
    const messageSuccess = (message, variant) => {
        enqueueSnackbar(message, {
            variant,
        });
    };
    const messageError = (message, variant) => {
        enqueueSnackbar(message, {
            variant,
        });
    };

    const handleChangeStyle = event => {
        if (tableRef.current) {
            tableRef.current.state.query.page = 0;
            setStyle(event.target.value);
            tableRef.current.state.query.dateInit = selectedDateInit;
            tableRef.current.state.query.dateEnd = selectedDateEnd;
            tableRef.current.onQueryChange();
        }
    };

    const handleChangeDateInit = event => {
        if (tableRef.current) {
            tableRef.current.state.query.page = 0;
            tableRef.current.state.query.style = style;
            tableRef.current.state.query.dateInit = event.target.value;
            tableRef.current.state.query.dateEnd = selectedDateEnd;
            setSelectedDateInit(event.target.value);
            tableRef.current.onQueryChange();
        }
    };
    const handleChangeDateEnd = event => {
        if (tableRef.current) {
            tableRef.current.state.query.page = 0;
            tableRef.current.state.query.area = style;
            tableRef.current.state.query.dateEnd = event.target.value;
            tableRef.current.state.query.dateInit = selectedDateInit;
            setSelectedDateEnd(event.target.value);
            tableRef.current.onQueryChange();
        }
    };

    const handleClose = () => {
        setOpen(false);
    };

    const toggleDetailDrawer = () => {
        setDataDetail(0);
        setOpenDetail(!openDetail);
    };

    useEffect(() => {
        setLoading(false);
        getPermissions().then(response => {
            setPermissions(response);
          }).catch(e => {
              console.log(e);
          })
    }, []);

    const checkFullAccess = () => {
        let check = permissions.filter(obj => obj.name === 'Full Access');
          if (check.length === 0) {
            return true
          } else {
            return false
          }
      };

    const getWavesData = (query) => {
        console.log(query);
        return getAllOrderGroups(
            true,
            query.pageSize,
            query.page + 1,
            selectedDateInit,
            selectedDateEnd,
            style,
            query.orderBy?.field ?? null,
            query.orderDirection
        )
            .then(response => ({
                ...query,
                data: response.data,
                page: response.current_page -1 ,
                totalCount: response.total,
            }))
            .catch(() => ({
                ...query,
                page: 0,
                pageSize: 15,
                totalCount: 0,
                data: [],
            }));
    } 

    const handleSubmitClear = event => {
        event.preventDefault();
        setStyle('');
        setSelectedDateInit();
        setSelectedDateEnd();
        if (tableRef.current) {
            tableRef.current.state.query.page = 0;
            tableRef.current.state.query.style = style;
            tableRef.current.state.query.dateInit = selectedDateInit;
            tableRef.current.state.query.dateEnd = selectedDateEnd;
            setSelectedDateInit(event.target.value);
            tableRef.current.onQueryChange();
        }
    };

    const downloadXlsx = (orderGroupId, reference) => {
        getOrderGroupExcel(orderGroupId)
            .then(response => {
                const contentDisposition = response.headers['content-disposition'];
                const contentType = response.headers['content-type'];
                const filename = reference+'.xlsx';
                const file = new Blob([response.data], { type: contentType });
                // For Internet Explorer and Edge
                if ('msSaveOrOpenBlob' in window.navigator) {
                    window.navigator.msSaveOrOpenBlob(file, filename);
                }
                // For Firefox and Chrome
                else {
                    // Bind blob on disk to ObjectURL
                    const data = URL.createObjectURL(file);
                    const a = document.createElement('a');
                    a.style = 'display: none';
                    a.href = data;
                    a.download = filename;
                    document.body.appendChild(a);
                    a.click();
                    // For Firefox
                    setTimeout(function() {
                        document.body.removeChild(a);
                        // Release resource on disk after triggering the download
                        window.URL.revokeObjectURL(data);
                    }, 100);
                }
            })
            .catch(e => {
                console.log(e);
            });
    };
    const InputComponentInit = ({ defaultValue, inputRef, ...props }: any) => {
        const handleChange = event => {
            setSelectedDateInit(event.target.value);
            if (props.onChange) {
                props.onChange(event);
            }
        };

        return (
            <div className={classes.root}>
                <div className={classes.display}>{selectedDateInit}</div>
                <input
                    className={classes.input}
                    ref={inputRef}
                    {...props}
                    onChange={handleChange}
                    value={selectedDateInit}
                />
            </div>
        );
    };
    const InputComponentEnd = ({ defaultValue, inputRef, ...props }: any) => {
        const handleChange = event => {
            setSelectedDateEnd(event.target.value);
            if (props.onChange) {
                props.onChange(event);
            }
        };

        return (
            <div className={classes.root}>
                <div className={classes.display}>{selectedDateEnd}</div>
                <input
                    className={classes.input}
                    ref={inputRef}
                    {...props}
                    onChange={handleChange}
                    value={selectedDateEnd}
                />
            </div>
        );
    };

    const recalculateOrderById = (rowData) => {
        recalculateOrderGroup(rowData.order_group_id)
            .then(response => {
                tableRef.current.onQueryChange();
                setLoading(false);

            })
            .catch(e => {
                console.log(e);
            });
    };

    const selectedValue = (event) => {
        let suma = contador+1;
        if(event.target.checked == true){
            if(suma == 1) {
                setSelectedSupplyOrderOne(event.target.value)
                setContador(suma);
            }
            if (suma == 2) {
                setSelectedSupplyOrderTwo(event.target.value)
                setContador(suma);
            }
        }else {
            setContador(0);
        } 
    }

    const submitMergeSupplyOrders = () => {
        setIsLoading(true);
        if (contador == 2){
            const confirm = window.confirm("Estas seguro(a)?");
            if(confirm == true){
                mergeSupplyOrders(
                    selectedSupplyOrderOne,
                    selectedSupplyOrderTwo
                )
                    .then(response => {
                        if (response.status == 200) {
                            messageSuccess(response.message, 'success');
                            setSelectedSupplyOrderOne(0);
                            setSelectedSupplyOrderTwo(0);
                            setIsLoading(false);
                            setTimeout(function() {
                                window.location.reload();
                            }, 3500);
                            
                        }else {
                            messageError(response.message, 'warning');
                            setIsLoading(false);
                            setTimeout(function() {
                                window.location.reload();
                            }, 3000);
                        }
                    })
                    .catch(error => {
                        if (error !== undefined) {
                            console.log(error);
                        } else {
                            messageError('Error al procesar la solicitud', 'error');
                        }
                        setIsLoading(false);
                    });
            }else{
                setIsLoading(false);
            }
        }
    }
    
    return (
        <Box
            id="app-routes-page"
            component="main"
            className={appStyles.pageRoot}
            style={{ backgroundColor: 'white', height: '100vh' }}
        >
            <Box className={classes.header} style={{ marginBottom: '30px' }}>
                <Box flexGrow={1}>
                    <Typography variant="h4" color="primary">
                        Ordenes de Surtido
                    </Typography>
                </Box>
            </Box>
            <OrderGroupDetail
                formOpen={openDetail}
                toggleForm={toggleDetailDrawer}
                dataObject={dataDetail}
            />
            <div style={{
                zIndex:100,
                position:'absolute',
                marginLeft:'40%',
                marginRight:'50%',
                marginTop:'30%',
            }}>
                {loading && <CircularProgress className={classes.loadingProgress} />}
            </div>
            <MaterialTable
                columns={[
                    { title: 'Fecha de creación', field: 'created_at', type: 'datetime' },
                    { title: 'Local', field: 'local' },
                    { title: 'Referencia', 
                      field: 'reference',
                      cellStyle: (e, rowData) => {
                        if (rowData.statusMerged == 0) {
                          return { color: "#62B961" };
                        }else {
                          return { color: "#F34B4B" };
                        }
                      }
                    },
                    { title: 'Grupo', field: 'order_group_id' },
                    {
                        title: 'Piezas totales',
                        field: 'total_pieces',
                        type: 'numeric',
                        render: rowData => {
                            return `${rowData.total_pieces.toLocaleString()}`;
                        },
                    },
                    {
                        title: 'Piezas en Ola',
                        field: 'total_in_wave',
                        type: 'numeric',
                        render: rowData => {
                            return `${rowData.total_in_wave.toLocaleString()}`;
                        },
                    },
                    {
                        title: 'Piezas pendientes',
                        field: 'total_pending',
                        type: 'numeric',
                        render: rowData => {
                            return `${rowData.total_pending.toLocaleString()}`;
                        },
                    },
                    {
                        title: 'En surtido',
                        field: 'total_in_wave',
                        type: 'numeric',
                        render: rowData => {
                            return `${rowData.total_in_wave.toLocaleString()}`;
                        },
                    },
                    {
                        title: 'Pzas Surtidas',
                        field: 'sorted_pieces',
                        type: 'numeric',
                        render: rowData => {
                            return `${rowData.sorted_pieces.toLocaleString()}`;
                        },
                    },
                ]}
                title="Ordenes de Surtido"
                localization={materialTableLocaleES}
                tableRef={tableRef}
                data={query => getWavesData(query)}
                options={{
                    pageSize: 20,
                    showTitle: false,
                    search: false,
                    toolbar: true,
                    exportButton: true,
                    padding: 'dense',
                    pageSizeOptions: [5, 10, 20, 50, 100],
                }}
                detailPanel={rowData => renderOrderGroupsDetail(rowData)}
                actions={[
                    {
                        icon: () => <ViewIcon color="primary" className="icon-small" />,
                        tooltip: 'Buscar detalles por SKU',
                        onClick: (event, rowData) => {
                            setDataDetail(rowData);
                            setOpenDetail(!openDetail);
                        },
                    },
                    {
                        icon: () => <TreeIcon color="primary" className="icon-small" />,
                        tooltip: 'Exportar detalles a excel',
                        onClick: (event, rowData) => {
                            downloadXlsx(rowData.order_group_id, rowData.reference);
                        },
                    },
                    {
                        icon: () => <RefreshIcon color="primary" className="icon-small" />,
                        tooltip: 'Actualizar conteo',
                        onClick: (event, rowData) => {
                            setTimeout(function() {
                                setLoading(true);
                            }, 10);
                            recalculateOrderById(rowData);
                        },
                    },
                    rowData => ({
                        icon: () => <Checkbox id="button-merge-orders"
                                              color="primary"
                                              value={rowData.order_group_id}
                                              onChange={selectedValue}
                                              defaultChecked={(rowData.statusMerged == 1) ? true:false}
                                              disabled={(contador>= 2) ? true:false  || (rowData.statusMerged == 1) ? true:false || (rowData.total_in_wave > 0) ? true:false}
                                              />,
                        tooltip: 'Fusionar OS',
                        hidden:checkFullAccess(),
                    })
                ]}
                components={{
                    Toolbar: props => (
                        <div>
                            <MTableToolbar {...props} />
                            <FormControl className={classes.formControlTableToolBar}>
                                <TextField
                                    className={classes.formClearData}
                                    label="Estilo"
                                    fullWidth
                                    size="small"
                                    name="styleInput"
                                    id="outlined-size-small1"
                                    variant="outlined"
                                    value={style || ''}
                                    onChange={handleChangeStyle}
                                />
                            </FormControl>
                            <FormControl className={classes.formControlTableToolBar}>
                                <TextField
                                    fullWidth
                                    id="date-Init"
                                    label="Fecha Inicio"
                                    type="date"
                                    onChange={handleChangeDateInit}
                                    InputProps={{
                                        inputComponent: InputComponentInit,
                                    }}
                                    defaultValue={selectedDateInit}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </FormControl>
                            <FormControl className={classes.formControlTableToolBar}>
                                <TextField
                                    fullWidth
                                    id="date-End"
                                    label="Fecha Fin"
                                    type="date"
                                    onChange={handleChangeDateEnd}
                                    InputProps={{
                                        inputComponent: InputComponentEnd,
                                    }}
                                    defaultValue="2017-05-24"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </FormControl>
                            <FormControl className={classes.formControlTableToolBar}>
                                <Box
                                    component="form"
                                    onSubmit={handleSubmitClear}
                                    className={classes.formClearData}
                                >
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        className={classes.button}
                                        endIcon={<SearchIcon>Filtrar</SearchIcon>}
                                    >
                                        Limpiar filtro
                                    </Button>
                                </Box>
                            </FormControl>
                            <FormControl className={classes.formControlTableToolBar}>
                                <Box
                                    className={classes.formClearData}
                                    hidden={checkFullAccess()}
                                >
                                    <Button
                                        id="button-merge-orders"
                                        color="primary"
                                        type="submit"
                                        variant="contained"
                                        disabled={(contador>= 2) ? false:true}
                                        onClick={submitMergeSupplyOrders}
                                        className={classes.button}
                                        hidden={checkFullAccess()}
                                    >
                                        Unir Ordenes
                                    </Button>
                                    {isLoading && <CircularProgress size={30} className={classes.loadingProgress} />}
                                </Box>
                            </FormControl>                            
                        </div>
                    ),
                }}
            />

            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <div className={classes.paper}>
                        <h2 id="transition-modal-title">No hay datos</h2>
                        <p id="transition-modal-description">No hay datos con los filtros seleccionados</p>
                    </div>
                </Fade>
            </Modal>
        </Box>
    );
}

export default WithSnackBarMessages(SupplyOrdersPage);
