import React, { useState, useRef, useEffect } from 'react';
import { Box } from '@material-ui/core';
import { Visibility as ShowIcon } from '@material-ui/icons';

// Components
import MaterialTable from 'material-table';
import ProductsFamilyForm from '../components/ProductFamilyFrom';
import PageHeader from '../../../components/PageHeader';
import WithSnackBarMessages from '../../../components/WithSnackBarMessages';

// Security
// import Permissions from '../../../AppsPermissions/UsersAdministratorPermissions';
// import { hasAuthority } from '../../AccessValidators';

// Styles
import { AppStyles } from '../../../AppStyles';

// Data Apis
import { getAllProductFamilies } from '../../../api/ProductFamilyServices/ProductFamilyApi';

// Routes
import { APP_ROUTES, PRIORITY_ROUTES } from '../../../Routes';
import materialTableLocaleES from '../../../components/MaterialTableLocaleES';

const breadcrumbsData = [
  {
    id: 1,
    label: 'Catalogos',
    base: APP_ROUTES.PRIORITY,
  },
  {
    id: 2,
    label: 'Familia productos',
    base: PRIORITY_ROUTES.PRODUCT_FAMILIES,
  },
];

function ProductFamilyPage() {
  const appStyles = AppStyles();
  const tableRef = useRef();
  const [openForm, setOpenForm] = useState(false);
  const [dataObject, setDataObject] = useState(0);

  const toggleForm = () => {
    if (tableRef.current) {
      tableRef.current.state.query.page = 0;
      if (openForm) {
        tableRef.current.onQueryChange();
      }
    }
    setOpenForm(!openForm);
    setDataObject(0);
  };

  useEffect(() => {
    
  }, []);

  const getproductFamiliesData = query =>
    // canGetAllUsers ?

    getAllProductFamilies(query.pageSize, query.page + 1, query.search)
      .then(response => {
        return {
          ...query,
          data: response.data,
          pageSize: 40,
          page: response.current_page - 1,
          totalCount: response.total,
        };
      })
      .catch(error => {
        console.log(error);
        return {
          ...query,
          page: 0,
          pageSize: 15,
          totalCount: 0,
          data: [],
        };
      });

  return (
    <Box
      id="app-users-admins-page"
      component="main"
      className={appStyles.pageRoot}
      style={{ backgroundColor: 'white', height: '100vh' }}
    >
      <PageHeader
        title="Familias de Productos"
        canExecuteButton={true}
        action={toggleForm}
        breadcrumbsData={breadcrumbsData}
      />

      <ProductsFamilyForm
        formOpen={openForm}
        toggleForm={toggleForm}
        refreshTable={() => tableRef.current && tableRef.current.onQueryChange()}
        dataObject={dataObject}
        canUpdateUser={true}
        canGetUserData={true}
        canUpdateUserData={true}
      />
      <MaterialTable
        columns={[
          { title: 'Nombre', field: 'jdaName', type: 'string' },
          { title: 'Ranking', field: 'ranking', type: 'string' },
        ]}
        localization={materialTableLocaleES}
        tableRef={tableRef}
        data={query => getproductFamiliesData(query)}
        actions={[
          rowData => ({
            icon: () => <ShowIcon color="primary" className="icon-small" />,
            disabled: true,
            tooltip: 'Ver Familia',
            onClick: (event, rowData) => {
              setDataObject(rowData.id);
              setOpenForm(!openForm);
            },
          }),
        ]}
        options={{
          search: false,
          pageSize: 40,
          actionsColumnIndex: -1,
          paging: true,
          toolbar: false,
          showTitle: false,
          padding: 'dense',
          pageSizeOptions: [5, 10, 20, 50, 100],
        }}
      />
    </Box>
  );
}

export default WithSnackBarMessages(ProductFamilyPage);
