import React, { useState, useRef, useEffect } from 'react';
import {
  Box,
  Chip,
  Select,
  MenuItem,
  FormControl,
  FormControlLabel,
  InputLabel,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Dialog,
  RadioGroup,
  Radio,
  Button,
  CircularProgress,
  TextField,
  Typography,
  Tooltip,
} from '@material-ui/core';
import {
  Visibility as ViewIcon,
  Block as BlockIcon,
  PlaylistAddCheck as PlayListAddCheckIcon,
  Warning as WarningIcon,
} from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';

// Components
import MaterialTable, { MTableToolbar } from 'material-table';
import WaveForm from '../components/WaveForm';
import WaveDetail from '../components/WaveDetail';
import WaveInventory from '../components/WaveInventory';
import PageHeader from '../../../components/PageHeader';
import WithSnackBarMessages from '../../../components/WithSnackBarMessages';
import { getPermissions } from '../../../api/AuthApi';


// Security
// import Permissions from '../../../AppsPermissions/ShopsAdministratorPermissions';
// import { hasAuthority } from '../../AccessValidators';

// Styles
import { AppStyles } from '../../../AppStyles';

// Data Apis
import { getAllWaves, cancelWave, getWaveInventory, updateWaveLinesPPK, verifyPassword, cancelWaveInPicking } from '../../../api/WaveServices/WavesApi';

// Routes
import { APP_ROUTES, WAVES_ROUTES } from '../../../Routes';
import materialTableLocaleES from '../../../components/MaterialTableLocaleES';
import Moment from "moment";
import * as XLSX from "xlsx";
import {useSnackbar} from "notistack";


const breadcrumbsData = [
  { id: 1, label: 'Listado', base: APP_ROUTES.WAVES },
  {
    id: 2,
    label: 'Olas',
    base: APP_ROUTES.WAVES + WAVES_ROUTES.WAVES,
  },
];

const useStyles = makeStyles(theme => ({
  formControlTableToolBar: {
    margin: theme.spacing(3),
    marginTop: -theme.spacing(7),
    minWidth: 170,
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  buttonValidate: {
    left: '40%',
  },
  messagePwd: {
    textAlign: 'center',
  }
}));

function WavePage() {
  const classes = useStyles();
  const appStyles = AppStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [openForm, setOpenForm] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [openConfirmUpdateDialog, setOpenConfirmUpdateDialog] = useState(false);
  const [modifications, setModifications] = useState([]);
  const [openDetail, setOpenDetail] = useState(false);
  const [openInventory, setOpenInventory] = useState(false);
  const [selectedWave, setSelectedWave] = useState(null);
  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  const [openInventoryCheckDialog, setOpenInventoryCheckDialog] = useState(false);
  const tableRef = useRef();
  const radioGroupRef = useRef();
  const [selectedDateInit, setSelectedDateInit] = React.useState(Moment(new Date()).format(('YYYY-MM-DD')));
  const [selectedDateEnd, setSelectedDateEnd] = React.useState();
  const [radioCancelValue, setRadioCancelValue] = useState([]);
  const [dataObject, setDataObject] = useState(0);
  const [waveStatus, setWaveStatus] = useState(-1);
  const [dataDetail, setDataDetail] = useState(0);
  const [dataInventory, setDataInventory] = useState(0);
  const [waveRef, setWaveRef] = useState('');
  const [pickingEnabled, setPickingEnabled] = useState(false);
  const [fileInput, setFileInput] = useState("");
  // const [canGetAllWaves, setCanGetAllWaves] = useState(true);
  // const [canCancelWave, setCanCancelWave] = useState(true);
  // const [canVerifyStock, setCanVerifyStock] = useState(true);
  // const [canUpdateWave, setCanUpdateWave] = useState(true);
  // const [canDeleteWave, setCanDeleteWave] = useState(true);

  const [data, setData] = useState([]);
  
  const cancelOptions = [
    'Equivocación',
    'Insuficiente personal',
    'Operativamente inviable',
    'Otro',
  ];

  const [password, setPassword] = useState([]);
  const [checkedPassword, setCheckedPassword] = useState(true);
  const [messagePassword, setMessagePassword] = useState('')
  const [openPasswordDialog, setOpenPasswordDialog] = useState(false);
  const [userId, setUserId] = useState([]);
  const [pageTable, setPageTable] = useState();

  const messageError = (message, variant) => {
    enqueueSnackbar(message, {
        variant,
      });
  };
  const messageSuccess = (message, variant) => {
      enqueueSnackbar(message, {
          variant,
      });
  };


  useEffect(() => {
    getPermissions().then(response => {
        setUserId(response[0].pivot.model_id);
      }).catch(e => {
          console.log(e);
      })
}, []);

  const InputComponentInit = ({ defaultValue, inputRef, ...props }: any) => {
    const classes = useStyles();

    const handleChange = event => {
      setSelectedDateInit(event.target.value);
      if (props.onChange) {
        props.onChange(event);
      }
    };

    return (
      <div className={classes.root}>        
        <input
          className={classes.input}
          ref={inputRef}
          {...props}
          onChange={handleChange}
          value={selectedDateInit}
        />
      </div>
    );
  };
  const InputComponentEnd = ({ defaultValue, inputRef, ...props }: any) => {
    const classes = useStyles();

    const handleChange = event => {
      setSelectedDateEnd(event.target.value);
      if (props.onChange) {
        props.onChange(event);
      }
    };

    return (
      <div className={classes.root}>        
        <input
          className={classes.input}
          ref={inputRef}
          {...props}
          onChange={handleChange}
          value={selectedDateEnd}
        />
      </div>
    );
  };

  const toggleForm = () => {
    if (tableRef.current) {
      tableRef.current.state.query.page = 0;
      if (openForm) {
        tableRef.current.onQueryChange();
      }
    }
    setDataObject(0);
    setOpenForm(!openForm);
  };

  const toggleDetailDrawer = () => {
    setDataDetail(0);
    setOpenDetail(!openDetail);
  };

  const toggleInventoryDrawer = () => {
    if (tableRef.current) {
      tableRef.current.state.query.page = pageTable;
      if (openInventory) {
        tableRef.current.onQueryChange();
      }
    }
    setDataInventory(0);
    setOpenInventory(!openInventory);
  };

  const handleCloseDialog = () => {
    setOpenCancelDialog(false);
  };

  const handleCloseConfirmDialog = () => {
    setOpenConfirmUpdateDialog(false);
  };

  const handleCloseInventoryCheckDialog = () => {
    setOpenInventoryCheckDialog(false);
  };

  const handlePasswordDialog = () => {
    setOpenPasswordDialog(false);
    setMessagePassword('');
    setCheckedPassword(true);
    setRadioCancelValue('');
  };

  const handleChangePassword = (event) => {
    setPassword(event.target.value);
  };
  
  const handleInventoryCheckConfirm = () => {
    setIsLoading(true);
    getWaveInventory(selectedWave)
      .then(response => {
        setIsLoading(false);
        handleCloseInventoryCheckDialog();
        tableRef.current.onQueryChange();
      })
      .catch(error => {
        console.log(error);
        setIsLoading(false);
      });
  };

  const handleBulkUpdate = (changes) => {
    changes = Object.keys(changes).map(function(k) { return changes[k] });    
    setModifications(changes);
    setOpenConfirmUpdateDialog(true);
  }

  const handleConfirmUpdate = () => {
    setIsLoading(true);
    let skuArray = [];
    let removeSkuArray = [];
    modifications.forEach(v => {
      if (v.newData.ppkreal > 0) {
        skuArray.push({ppkreal: v.newData.ppkreal, sku: v.newData.sku});  
      } else {
        removeSkuArray.push(v.newData.sku);
      }
    });
    updateWaveLinesPPK(skuArray, removeSkuArray, selectedWave)
      .then(response => {        
        tableRef.current.onQueryChange();
        setOpenDialog(false);
        setOpenConfirmUpdateDialog(false);
        setIsLoading(false);
      })
      .catch(error => {
        console.log(error);
        setIsLoading(false);
      });    
  };

  const handleCancelConfirm = () => {
    setIsLoading(true);
    cancelWave(userId, selectedWave, radioCancelValue)
      .then(response => {
        tableRef.current.onQueryChange();
        setIsLoading(false);
        setOpenCancelDialog(false);
        setRadioCancelValue('');
      })
      .catch(() => {
        console.log('errors');
        setIsLoading(false);
        setOpenCancelDialog(false);
      });
  };

  const handleCancelConfirmWave = () => {
    setIsLoading(true);
    setMessagePassword('');
    cancelWaveInPicking(userId, selectedWave, radioCancelValue)
      .then(response => {
        if (response.status == 200) {
          tableRef.current.onQueryChange();
          setIsLoading(false);
          messageSuccess(response.message, 'success');
          setOpenPasswordDialog(false);
          setRadioCancelValue('');
          setCheckedPassword(true);
        }
      })
      .catch(() => {
        messageError('Error al procesar la solicitud', 'error');
        console.log('errors');
        setIsLoading(false);
        setOpenCancelDialog(false);
      });
  };

  const handleRadioChange = event => {
    setRadioCancelValue(event.target.value);
  };

  const handleChangeStatus = event => {
    if (tableRef.current) {
      tableRef.current.state.query.page = 0;
      tableRef.current.state.query.status = event.target.value;
      tableRef.current.state.query.dateInit = selectedDateInit;
      tableRef.current.state.query.dateEnd = selectedDateEnd;
      setWaveStatus(event.target.value);
      tableRef.current.onQueryChange();
    }
  };

  const handleChangeDateInit = event => {
    if (tableRef.current) {
        tableRef.current.state.query.page = 0;
        tableRef.current.state.query.status = waveStatus;
        tableRef.current.state.query.dateInit = event.target.value;
        tableRef.current.state.query.dateEnd = selectedDateEnd;
        setSelectedDateInit(event.target.value);
        tableRef.current.onQueryChange();
    }
  };

  const handleChangeDateEnd = event => {
      if (tableRef.current) {
          tableRef.current.state.query.page = 0;
          tableRef.current.state.query.status = waveStatus;
          tableRef.current.state.query.dateEnd = event.target.value;
          tableRef.current.state.query.dateInit = selectedDateInit;
          setSelectedDateEnd(event.target.value);
          tableRef.current.onQueryChange();
      }
  };

  const getWavesData = query =>
    getAllWaves(true, query.pageSize, query.page + 1, query.search, query.status, selectedDateInit, selectedDateEnd)
      .then(response => {
        setPageTable(query.page);
        return response;
      })
      .then(response => ({
        ...query,
        data: response.data,
        page: response.current_page - 1,
        totalCount: response.total,
      }))
      .catch(() => ({
        ...query,
        page: 0,
        pageSize: 15,
        totalCount: 0,
        data: [],
      }));

const checkPassword = () => {
    verifyPassword(userId, password)
      .then(response => {
          setCheckedPassword(response.status);
          setMessagePassword(response.message);
          setIsLoading(false);
      })
      .catch(() => {
        console.log('errors');
        setIsLoading(false);
      });
}

  return (
    <Box id="app-routes-page" component="main" className={appStyles.pageRoot}>
      <PageHeader
        title="Olas"
        titleButton="Nueva Ola"
        displayButton
        canExecuteButton
        action={toggleForm}
        breadcrumbsData={breadcrumbsData}
      />

      <WaveDetail
        formOpen={openDetail}
        toggleForm={toggleDetailDrawer}
        dataObject={dataDetail}
        waveRef={waveRef}
      />

      <WaveInventory
        formOpen={openInventory}
        toggleForm={toggleInventoryDrawer}
        dataObject={dataInventory}
        waveRef={waveRef}
        pickingEnabled={pickingEnabled}
        waveId={selectedWave}
      />

      <WaveForm
        formOpen={openForm}
        toggleForm={toggleForm}
        refreshTable={() => tableRef.current && tableRef.current.onQueryChange()}
        dataObject={dataObject}
      />

      <Dialog        
        maxWidth="xs"
        onEntering={undefined}
        aria-labelledby="confirmation-dialog-title3"
        open={openConfirmUpdateDialog}
      >
        <DialogTitle id="confirmation-dialog-title3">Alerta de confirmación</DialogTitle>
        <DialogContent dividers>
          <DialogContentText id="alert-dialog-description3">
            ¿Está seguro que ésta información es correcta? Aquí la puedes volver a revisar...
          </DialogContentText>
          <table style={{border: '1px solid black'}}>
            <thead>
              <tr>
                <th>Sku</th>                
                <th>PPK OS</th>
                <th>PPK SAALMA</th>
                <th>PPK Real</th>
              </tr>
            </thead>
            <tbody>
            {modifications.map(option => (
              <tr key={`tr${option.newData.sku}`}>
                <th>{option.newData.sku}</th>                
                <th>{option.newData.ppk}</th>
                <th>{option.newData.ppksaalma}</th>
                <th>{option.newData.ppkreal}</th>
              </tr>
            ))}
            </tbody>
          </table>          
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="secondary"
            style={{ marginRight: 100, borderRadius: 20, color: 'white' }}
            size="small"
            disabled={isLoading}
            onClick={handleConfirmUpdate}
          >
            <PlayListAddCheckIcon />
            Confirmar y actualizar
          </Button>
          <Button autoFocus onClick={handleCloseConfirmDialog} color="primary">
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        fullWidth={true}
        maxWidth="md"
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">Múltiplos no coiciden...</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Estos SKU tienen un múltiplo de prepack (PPK) en la órden de surtido que no coincide 
            con el múltiplo declarado en SAALMA. Por favor indique el múltiplo correcto 
            cuidadosamente para recalcular el surtido y continuar con la ola.
          </DialogContentText>
          <MaterialTable
            columns={[
              { title: 'Sku', field: 'sku', type: 'numeric', editable: 'never' },
              { title: 'Estilo', field: 'style.style', type: 'numeric', editable: 'never' },
              { title: 'PPK OS', field: 'ppk', type: 'numeric', editable: 'never' },
              { title: 'PPK SAALMA', field: 'ppksaalma', type: 'numeric', editable: 'never' },
              { title: 'PPK Real', field: 'ppkreal', type: 'numeric' },
            ]}
            title=""
            editable={{
              onBulkUpdate: changes =>
                new Promise((resolve, reject) => {
                  setTimeout(() => {
                    handleBulkUpdate(changes);
                    resolve();
                  }, 1000);
                }),
            }}
            isLoading={isLoading}
            localization={materialTableLocaleES}
            data={data}
            actions={[
              {
                icon: () => <WarningIcon className="icon-small" />,
                tooltip: 'Atención',
                onClick: () => {
                },
              }
            ]}
            options={{
              pageSize: 10,
              paging: false,
              padding: 'dense',
              pageSizeOptions: [5, 10, 20, 50, 100],
            }}
          />
        </DialogContent>
      </Dialog>

      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        maxWidth="xs"
        onEntering={undefined}
        aria-labelledby="confirmation-dialog-title"
        open={openCancelDialog}
      >
        <DialogTitle id="confirmation-dialog-title">{`Cancelar ${waveRef}`}</DialogTitle>
        <DialogContent dividers>
          <DialogContentText id="alert-dialog-description">
            Si está seguro de cancelar ésta ola, por favor especifique el motivo de la cancelación.
          </DialogContentText>
          <RadioGroup
            ref={radioGroupRef}
            aria-label="ringtone"
            name="ringtone"
            value={radioCancelValue}
            onChange={handleRadioChange}
          >
            {cancelOptions.map(option => (
              <FormControlLabel value={option} key={option} control={<Radio />} label={option} />
            ))}
          </RadioGroup>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleCloseDialog} color="primary">
            Cerrar
          </Button>
          <Button onClick={handleCancelConfirm} color="primary" disabled={isLoading}>
            Enviar
            {isLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        maxWidth="xs"
        onEntering={undefined}
        aria-labelledby="confirmation-dialog-title"
        open={openPasswordDialog}
      >
        <DialogTitle id="confirmation-dialog-title">{`Cancelar Ola - ${waveRef}`}</DialogTitle>
        <DialogContent dividers>
          <DialogContentText id="alert-dialog-description">
            Para continuar con el proceso, ingrese su contraseña y por favor especifique el motivo de la cancelación.
          </DialogContentText>
          <TextField
            id="input-password"
            name="input-password"
            fullWidth
            type="password"
            onChange={handleChangePassword}
            ></TextField>
          <Button className={classes.buttonValidate} color="primary" onClick={checkPassword}>Validar</Button>
          <Typography className={classes.messagePwd} style={checkedPassword == false ? { color: 'green'} : {color: 'red'} } variant="caption" display="block" gutterBottom>
            { messagePassword }
          </Typography>
          <RadioGroup
            ref={radioGroupRef}
            aria-label="ringtone"
            name="ringtone"
            value={radioCancelValue}
            onChange={handleRadioChange}
          >
            {cancelOptions.map(option => (
              <FormControlLabel disabled={checkedPassword} value={option} key={option} control={<Radio />} label={option} />
            ))}
          </RadioGroup>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handlePasswordDialog} color="primary">
            Cerrar
          </Button>
          <Button onClick={handleCancelConfirmWave} color="primary" disabled={checkedPassword || isLoading}>
            Enviar
            {isLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        maxWidth="xs"
        onEntering={undefined}
        aria-labelledby="confirmation-dialog-title2"
        open={openInventoryCheckDialog}
      >
        <DialogTitle id="confirmation-dialog-title2">{waveRef}</DialogTitle>
        <DialogContent dividers>
          <DialogContentText id="alert-dialog-description2">
            Ésta operación puede tomar algunos minutos en completarse
          </DialogContentText>
          <Button
            variant="contained"
            color="primary"
            style={{ marginRight: 100, borderRadius: 20, color: 'white' }}
            size="small"
            disabled={isLoading}
            onClick={handleInventoryCheckConfirm}
          >
            <PlayListAddCheckIcon />
            Check de inventario en SAALMA
          </Button>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleCloseInventoryCheckDialog} color="primary">
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
      
      <MaterialTable
        columns={[
          { title: 'Fecha', field: 'created_at', type: 'datetime' },
          { title: 'Grupo', field: 'ordergroup.reference' },
          { title: 'Ola', field: 'id' },
          {
            title: 'Estado',
            field: 'status',
            render: rowData => {
              const statusChips = [
                <Chip label="Cancelada" variant="outlined" color="secondary" size="small" />,
                <Chip label="Creada" variant="outlined" color="primary" size="small" />,
                <Chip
                  label="Picking"
                  variant="outlined"
                  style={{ color: 'purple', borderColor: 'purple' }}
                  size="small"
                />,
                <Chip
                  label="Surtida"
                  variant="outlined"
                  style={{ color: 'brown', borderColor: 'brown' }}
                  size="small"
                />,
                <Chip
                  label="Sorter"
                  variant="outlined"
                  style={{ color: 'orange', borderColor: 'orange' }}
                  size="small"
                />,
                <Chip
                  label="Completada"
                  variant="outlined"
                  style={{ color: 'green', borderColor: 'green' }}
                  size="small"
                />,
              ];
              return <Box>{statusChips[rowData.status]}</Box>;
            },
          },
          {
            title: 'Division(es)',
            field: 'business_rules',
            render: rowData => {
              const parsed = JSON.parse(rowData.business_rules);
              return (
                <Box>
                  {parsed.divisionsNames.map(name => {
                    return (
                      <Box key={`r${rowData.id}-c1-${name}`}>
                        <Chip label={name} variant="outlined" color="primary" size="small" />
                      </Box>
                    );
                  })}
                </Box>
              );
            },
          },
          { title: 'Descripcion', field: 'description' },
          { title: 'SKUS', field: 'total_sku' },          
          {
            title: 'Pz sol.',
            field: 'planned_pieces',
            type: 'numeric',
            render: rowData => {
                return rowData.planned_pieces.toLocaleString();
            },
          },
          {
            title: 'Pz Disp.',
            field: 'pieces',
            type: 'numeric',
            render: rowData => {
              return rowData.pieces.toLocaleString();
            },
          },
          {
            title: '% Disp.',
            field: 'planned_pieces',
            type: 'numeric',
            render: rowData => {
                let percent = '';
                let color = '';
                if (rowData.verified_stock === 0) {
                  percent = '?';
                  color = 'blue'; 
                } else {
                  percent = ((rowData.pieces/rowData.planned_pieces) * 100).toFixed(0);  
                  color = (percent < 85) ? 'red' : 'green';
                }                
                return <Typography style={{ color }}>{`${percent}%`}</Typography>;
            }
          },

        ]}
        title="Olas"
        localization={materialTableLocaleES}
        tableRef={tableRef}
        data={query => getWavesData(query)}
        options={{
          pageSize: 10,
          actionsColumnIndex: -1,
          paging: true,
          toolbar: true,
          showTitle: false,
          pageSizeOptions: [5, 10, 20, 50, 100],
        }}
        actions={[
          waveStatus !== 0
            ? {
                icon: () => <ViewIcon color="primary" className="icon-small" />,
                tooltip: 'Ver Ola',
                onClick: (event, rowData) => {
                  setDataDetail(rowData.id);
                  setWaveRef(rowData.wave_ref);
                  setOpenDetail(!openDetail);
                },
              }
            : undefined,
          rowData =>
            true && rowData.status !== 0
              ? rowData.verified_stock === 1
                ? {
                    icon: () => (
                      <PlayListAddCheckIcon style={{ color: 'green' }} className="icon-small" />
                    ),
                    tooltip: 'Check de inventario',
                    onClick: (event, rowData) => {
                      setDataInventory(rowData.id);
                      setPickingEnabled(rowData.status !== 2);
                      setWaveRef(rowData.wave_ref);
                      setSelectedWave(rowData.id);
                      setOpenInventory(!openInventory);
                    },
                  }
                : rowData.verified_stock === 0
                ? {
                    icon: () => (
                      <PlayListAddCheckIcon style={{ color: 'blue' }} className="icon-small" />
                    ),
                    tooltip: 'Check de inventario',
                    onClick: (event, rowData) => {
                      setSelectedWave(rowData.id);
                      setWaveRef(rowData.wave_ref);
                      setOpenInventoryCheckDialog(true);
                    },
                  }
                : rowData.verified_stock === 2 
                  ? {
                      icon: () => (
                        <PlayListAddCheckIcon style={{ color: 'grey' }} className="icon-small" />
                      ),
                      tooltip: 'Verificando inventario...',
                    }
                  : {
                      icon: () => (
                        <PlayListAddCheckIcon style={{ color: 'orange' }} className="icon-small" />
                      ),
                      tooltip: 'Check de inventario',
                      onClick: (event, rowData) => {
                        setSelectedWave(rowData.id);
                        getWaveInventory(rowData.id)
                          .then(response => {
                            setData(response); 
                            setOpenDialog(true);                           
                          })
                          .catch(error => {
                            console.log(error);                            
                          });                        
                      },
                    }
              : undefined,
          rowData =>
            true && rowData.status === 1
              ? {
                  icon: () => <BlockIcon color="secondary" className="icon-small" />,
                  tooltip: 'Cancelar Ola',
                  onClick: (event, rowData) => {
                    setSelectedWave(rowData.id);
                    setWaveRef(rowData.wave_ref);
                    setOpenCancelDialog(!openCancelDialog);
                  },
                }
              : undefined,
              rowData =>
              true && rowData.status === 2
                ? {
                    icon: () => <BlockIcon color="secondary" className="icon-small" />,
                    tooltip: 'Cancelar Ola Pickeada',
                    onClick: (event, rowData) => {
                      setSelectedWave(rowData.id);
                      setWaveRef(rowData.wave_ref);
                      setOpenPasswordDialog(!openPasswordDialog);
                    },
                  }
                : undefined,
        ]}
        components={{
          Toolbar: props => (
            <div>
              <MTableToolbar {...props} />
              <FormControl className={classes.formControlTableToolBar}>
                <InputLabel id="statusLabel">Filtrar por status</InputLabel>
                <Select
                  labelId="statusLabel"
                  id="demo-simple-select-required"
                  value={waveStatus}
                  onChange={handleChangeStatus}
                >
                  <MenuItem value={-1}>Todas</MenuItem>
                  <MenuItem value={1}>Creadas</MenuItem>
                  <MenuItem value={2}>En Picking</MenuItem>
                  <MenuItem value={3}>En Sorter</MenuItem>
                  <MenuItem value={5}>Completadas</MenuItem>
                  <MenuItem value={0}>Canceladas</MenuItem>
                </Select>
              </FormControl>
              <FormControl className={classes.formControlTableToolBar}>
                <TextField
                  fullWidth
                  id="date-Init"
                  label="Fecha Inicio"
                  type="date"
                  onChange={handleChangeDateInit}
                  InputProps={{
                    inputComponent: InputComponentInit,
                  }}
                  defaultValue={selectedDateInit}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </FormControl>
              <FormControl className={classes.formControlTableToolBar}>
                <TextField
                  fullWidth
                  id="date-End"
                  label="Fecha Fin"
                  type="date"
                  onChange={handleChangeDateEnd}
                  InputProps={{
                    inputComponent: InputComponentEnd
                  }}
                  defaultValue="2017-05-24"
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              </FormControl>
            </div>
          ),
        }}
      />
    </Box>
  );
}

export default WithSnackBarMessages(WavePage);
