import React, { useState, useRef, useEffect } from 'react';
import { Box, Chip, Tabs, Tab, AppBar } from '@material-ui/core';
import { Visibility as ShowIcon } from '@material-ui/icons';

// Components
import MaterialTable from 'material-table';
import PageHeader from '../../../components/PageHeader';
import WithSnackBarMessages from '../../../components/WithSnackBarMessages';
import BufferForm from '../components/BufferForm';

// Styles
import { AppStyles } from '../../../AppStyles';
import materialTableLocaleES from '../../../components/MaterialTableLocaleES';

// Data Apis
import { getAllZones, getZoneNameWave } from '../../../api/WaveServices/WavesApi';

// Routes
import { APP_ROUTES, WAVES_ROUTES } from '../../../Routes';

const breadcrumbsData = [
  { id: 1, label: 'Olas', base: APP_ROUTES.WAVES },
  {
    id: 2,
    label: 'Buffers',
    base: APP_ROUTES.WAVES + WAVES_ROUTES.BUFFER,
  },
];

function BufferPage() {
  const appStyles = AppStyles();
  const [openForm, setOpenForm] = useState(false);
  const tableRefZone = useRef();

  const tableRef = useRef();
  const [dataObject, setDataObject] = useState(0);
  const [areaValue, setAreaValue] = useState(0);
  const [zone, setZone] = useState(0);

  const toggleForm = () => {
    if (tableRef.current) {
      tableRef.current.state.query.page = 0;
      if (openForm) {
        tableRef.current.onQueryChange();
      }
    }
    setOpenForm(!openForm);
    setDataObject(0);
  };

  const handleChangeTabTwo = (event, newValue) => {
    setAreaValue(newValue);
    tableRef.current.onQueryChange();
  };

  function a11yProps(index: any) {
    return {
      id: `scrollable-auto-tab-${index}`,
      'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
  }

  useEffect(() => {
    // hasAuthority(Permissions.GetAllRoutes, setCanGetAllRoutes);
    // hasAuthority(Permissions.CreateRoute, setCanCreateRoute);
    // hasAuthority(Permissions.UpdateRouteByID, setCanUpdateRoute);
    // hasAuthority(Permissions.DeleteRouteByID, setCanDeleteRoute);
    // hasAuthority(Permissions.AddShopToRouteByID, setCanAddShopToRoute);
    // hasAuthority(Permissions.RemoveShopFromRouteByID, setRemoveShopFromRoute);
  }, []);

  const parseQueryZones = (query, zoneType) => {
    console.log(query);
    if (zone !== zoneType) {
      query.page = 0;
      setZone(zoneType);
      return getZoneByZoneType(query, zoneType);
    }
    return getZoneByZoneType(query, zoneType);
  };

  const getZoneByZoneType = (query, zoneType) =>
    getAllZones(zoneType + 1, 0, null, query.page + 1)
      .then(response => ({
        ...query,
        data: response.data,
        pageSize: 40,
        page: response.current_page - 1,
        totalCount: response.total,
      }))
      .catch(error => {
        return Promise.reject(error);
      });

  return (
    <Box
      id="app-routes-page"
      component="main"
      className={appStyles.pageRoot}
      style={{ backgroundColor: 'white', height: '100vh' }}
    >
      <PageHeader title="Buffer" canExecuteButton breadcrumbsData={breadcrumbsData} />
      <BufferForm
        formOpen={openForm}
        toggleForm={toggleForm}
        refreshTable={() => {          
          tableRefZone.current.onQueryChange();
        }}
        dataObject={dataObject}
      />
      <AppBar
        position="static"
        color="default"
        style={{ alignItems: 'center', backgroundColor: 'white' }}
      >
        <Tabs
          value={areaValue}
          onChange={handleChangeTabTwo}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="Elija el area"
        >
          <Tab label="BUFFER S1" {...a11yProps(1)} />
          <Tab label="BUFFER S3" {...a11yProps(2)} />
          <Tab label="BUFFER PBL" {...a11yProps(3)} />
        </Tabs>
      </AppBar>
      <MaterialTable
        columns={[
          { title: 'Descripción', field: 'description', type: 'string' },
          { title: 'Código', field: 'code', type: 'string' },
          {
            title: '# Tarimas',
            field: 'pallets_count',
            type: 'string',
            render: rowData => rowData.pallets_count ?? '--------------------',
          },
          {
            title: 'Ola',
            field: 'pallets.wave_id',
            type: 'string',
            render: rowData => {
              const waveList = [];
              rowData.pallets.forEach(option => {
                waveList.push(option.wave_id);
              });
              return [...new Set(waveList)].join(',');
            },
          },
        ]}
        title="Ubicaciones"
        localization={materialTableLocaleES}
        tableRef={tableRef}
        data={query => parseQueryZones(query, areaValue)}
        actions={[
          rowData => ({
            icon: () => (
              <ShowIcon
                color={rowData.pallets_count > 0 ? 'primary' : 'disabled'}
                className="icon-small"
              />
            ),            
            tooltip: rowData.pallets_count > 0 ? 'Ver Tarima' : undefined,
            onClick: (event, rowData) => {
              if (rowData.id) {
                if (rowData.pallets_count > 0) {
                  setDataObject(rowData.id);
                  setOpenForm(!openForm);  
                }                
              }
            },
          }),
        ]}
        options={{
          search: false,
          pageSize: 20,
          actionsColumnIndex: -1,
          paging: true,
          toolbar: true,
          showTitle: true,
          padding: 'dense',
          pageSizeOptions: [5, 10, 20, 50, 100],
        }}
      />
    </Box>
  );
}

export default WithSnackBarMessages(BufferPage);
