import React, {useEffect, useRef, useState} from "react";
import PropTypes from 'prop-types';
import {
    getListStaging,
    saveOrderStaging,
    getPickingWaves,
    getStagingAllList,
    verifyZoneEmpty,
    sendToInduction,
    getNextPallets,
    getNextDetailPallet,
    changeStatusBin
} from "../../../api/PickingApi";
import {
    getAllZones,
    getDepartmentWave
} from "../../../api/WaveServices/WavesApi";

import {
    Tabs,
    AppBar,
    Box,
    Typography,
    Tab,
    List,
    ListItem,
    ListItemText,
    Divider,
    Modal,
    Fade,
    Backdrop,
    TextField,
    Grid,
    Button,
    ButtonGroup,
    IconButton,
    InputLabel,
    Select,
    MenuItem,
    FormControl,
    FormHelperText,
    Slide,
    Toolbar
} from "@material-ui/core";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';
import makeStyles from "@material-ui/core/styles/makeStyles";
import useTheme from "@material-ui/core/styles/useTheme";
import {AppStyles} from "../../../AppStyles";
import PageHeader from "../../../components/PageHeader";
import {APP_ROUTES, PICKING_ROUTES, WAVES_ROUTES} from "../../../Routes";
import WithSnackBarMessages from "../../../components/WithSnackBarMessages";
import {
    AddCircle as AddCircleIcon,
    RemoveCircle as RemoveCircleIcon,
    Visibility as ShowIcon,
    CropFree as CropFreeIcon,
    Close as CloseIcon,
} from '@material-ui/icons';
import MaterialTable from 'material-table';
import materialTableLocaleES from '../../../components/MaterialTableLocaleES';
import {useSnackbar} from "notistack";
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import StagingForm from '../components/StagingForm';
import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {getPalletById} from "../../../api/WaveServices/WavesApi";

const useStyles = makeStyles((theme) => ({
    themeSlide:{
        height: (window.innerHeight-200),
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        borderRadius:'5px',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        width:"100%"
    },
    rootCard: {
        marginTop: 10,
        padding: theme.spacing(2),
        textAlign: 'center',
        marginLeft:10,
        marginRight:10,
        marginBottom:10,
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    pos: {
        marginBottom: 12,
    },
    paperCard: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    chip: {
        margin: theme.spacing(0.5),
    },
    paperChip: {
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        listStyle: 'none',
        padding: theme.spacing(0.5),
        margin: 0,
    },
    boxesCellInfo: {
        color: 'blue',
        'fontSize': '1rem',
    },
    receivedBoxesCellInfo: {
        color: 'green',
        'fontSize': '1rem',
    },
    totalBoxesCellInfo: {
        color: 'purple',
        'fontSize': '1rem',
    },
    receivedBinesCellInfo: {
        color: 'green',
        'fontSize': '1rem',
    },
    totalBinesCellInfo: {
        color: 'purple',
        'fontSize': '1rem',
    }
}));

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function StagingPage() {
    const appStyles = AppStyles();
    const tableRef = useRef();
    const tableRef2 = useRef();
    const tableRefPalletContent = useRef();
    const [open, setOpen] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const toggleForm = () => {
        setOpen(!open);
    };
    const binRef = useRef();
    const zoneRef = useRef();
    const [search, setSearch] = useState('');
    const [nextPallets, setNextPallets] = useState([]);
    const [nextLocations, setNextLocations] = useState('');
    const [inductionForm, setInductionForm] = useState({
        pallet: '',
    });
    const [loading, setLoading] = useState(false);
    const [timerChange, setTimerChange] = useState([]);
    const [location, setLocation] = useState('');
    const [inputFocusLPN, setInputFocusLPN] = useState(false);
    const [disableLPN, setLPN] = useState(false);
    const [dataObject, setDataObject] = useState(0);
    const [dataWave, setDataWave] = useState(0);
    const [boxes, setBoxes] = useState(0);
    const [headerList,setHeaderList] = useState([]);
    const classes = useStyles();
    const theme = useTheme();
    const [value, setValue] = useState(0);
    const [waves, setWaves] = useState([]);
    const [selectedWave, setSelectedWave] = useState(0);
    const [bufferValue, setBuffer] = useState("");
    const [cant, setCant] = useState(1);
    const [error,setError] = useState(false);
    const [btn,setBtn] = useState(false);
    const [visibleBtn,setVisibleBtn] =useState(true);
    const [zones, setZones] = useState([]);
    const [idsWave, setIdsWave] = useState([]);
    const [openForm, setOpenForm] = useState(false);
    const [valueZone, setValueZone] = React.useState(0);
    const [openDialog, setOpenDialog] = React.useState(false);
    const [openDialogDepartment, setOpenDialogDepartment] = React.useState(false);
    const [descriptionZone, setDescriptionZone] = useState("");
    const [fullWidth, setFullWidth] = React.useState(true);
    const [chipData, setChipData] = React.useState([]);
    const [indexChip, setIndexChip] = useState(0);
    const [dialogAccept, setDialogAccept] = useState(false);
    const [totalBoxes, setTotalBoxes] = useState();
    const [pickedBoxes, setPickedBoxes] = useState();
    const [totalBines, setTotalBines] = useState();
    const [totalBinesReceive, setTotalBinesReceive] = useState();

    const colorWave = [
        "SkyBlue",
        "Pink",
        "DarkOrange",
        "Azure",
        "Gold",
        "RosyBrown",
        "Bisque",
        "DarkGray",
        "GoldenRod",
        "WhiteSmoke",
        "YellowGreen",
        "Wheat",
        "Thistle",
        "Salmon",
        "PowderBlue",
        "Plum",
        "MintCream"
    ];
    const zone = [
        {id: 5, name:"STAGING"},
        {id: 1, name:"BUFFER S1"},
        {id: 2, name:"BUFFER S3"},
        {id: 3, name:"BUFFER PBL"},
        {id: 0, name:"STAGING LIST"}
    ];

    const handleDelete = (chipToDelete) => () => {
        setChipData((chips) => chips.filter((chip) => chip.key !== chipToDelete.key));
        setBoxes(boxes - chipToDelete.boxes);
    };

    const handleClickOpenDialog = () => {
        setOpenDialogDepartment(true);
    };

    const handleCloseDialogDepartment = (accept) => {
        if (accept === true) {
            setOpenDialogDepartment(false);

        } else {
            setOpenDialogDepartment(false);
        }

    };

    const handleCloseDialog = (accept) => {
        if (accept === true) {
            let index = indexChip + 1;
            setIndexChip(index);
            setChipData([...chipData,{key: index, label: search}]);
            setSearch('');
            binRef.current.focus();
            setOpenDialog(false);
            setTimeout(function() {
                binRef.current.focus();
            }, 500);

        } else {
            setSearch('');
            binRef.current.focus();
            setOpenDialog(false);
            setSearch('');
        }

    };


    const toggleFormA = () => {
        if (tableRef.current) {
            tableRef.current.state.query.page = 0;
            if (openForm) {
                tableRef.current.onQueryChange();
            }
        }
        setDataObject(0);
        setDataWave(0);
        setOpenForm(!openForm);
    };

    const breadcrumbsData = [
        { id: 1, label: 'PICKING', base: APP_ROUTES.PICKING + PICKING_ROUTES.STAGING },
        {
            id: 2,
            label: 'STAGING',
            base: APP_ROUTES.PICKING + PICKING_ROUTES.STAGING,
        },
    ];

    const handleChange = (event, newValue) => {
        setValue(newValue);
        getStagingAllList(newValue)
            .then(response =>{
                if(response.length === 0){
                    setVisibleBtn(false);
                    setZones([]);
                } else {
                    setHeaderList([]);
                    setZones(response);
                    setVisibleBtn(true);
                }
            }).catch((e)=>{
            console.log("Error",e.message);
        });
        changeStatusBin(null , newValue)
            .then(responseTotal => {
                setTotalBoxes(responseTotal['totalBoxes']);
                setPickedBoxes(responseTotal['pickedBoxes']);
                setTotalBines(responseTotal['totalBines']);
                setTotalBinesReceive(responseTotal['totalBinesReceive']);
            })
            .catch(response => {

            });
    };


    const getDetailDepartment = (query, value) =>
        getDepartmentWave(value, query.page +1)
            .then(response => {
                return {
                    ...query,
                    page: 0,
                    pageSize: 15,
                    totalCount: 0,
                    data: response.data,
                };
            })
            .catch(() => ({
                ...query,
                page: 0,
                pageSize: 15,
                totalCount: 0,
                data: [],
            }));


    const handleChangeInputSearch = (event, bin, focus) => {
        getListStaging(5, bin)
            .then(response => {
                setHeaderList(response);
                if (response.length === 0) {
                    setVisibleBtn(false);
                    if (bin.length > 5) {
                        setSearch('');
                        enqueueSnackbar('Codigo de BIN no encontrado', { variant: 'warning' });
                    }
                } else {
                    let success = true;
                    if (response[0].zone_id > 0 &&  response[0].zone_type_id !== 6) {
                        changeStatusBin(bin, value)
                            .then(response => {
                                setTotalBoxes(response['totalBoxes']);
                                setPickedBoxes(response['pickedBoxes']);
                                return response;
                            })
                            .catch(response => {

                            });
                        setDescriptionZone(response[0].description);
                        setBoxes(boxes + parseInt(response[0].cajas));
                        setOpenDialog(true);
                        setSearch(bin);
                        success = false;
                    }
                    setVisibleBtn(true);
                    if (bin.length > 5 && success === true) {
                        // TODO::aqui poner logica para cambiar status
                        changeStatusBin(bin, value)
                            .then(response => {
                                setTotalBoxes(response['totalBoxes']);
                                setPickedBoxes(response['pickedBoxes']);
                                return response;
                            })
                            .catch(response => {

                            });
                        let index = indexChip + 1;
                        setIndexChip(index);
                        setBoxes(boxes + parseInt(response[0].cajas));
                        setChipData([...chipData,{key: index, label: bin, boxes: parseInt(response[0].cajas)}]);
                        setSearch('');
                        binRef.current.focus();
                    }

                }
            })
            .catch(e => {
                console.log('Error', e.message);
            });
    };

    const toggleModal = () => {
        getNextPallets(value)
            .then(response => {
                setNextPallets(response);
            })
            .catch(e => {
                console.log('ERROR', e);
            });
        setOpenModal(!openModal);
    };

    const getNetxPal = (query) =>
        getNextPallets(value)
            .then(response => {
                return response;
            })
            .then(response => ({
                ...query,
                data: response,
            }))
            .catch(() => ({
                ...query,
                page: 0,
                pageSize: 15,
                totalCount: 0,
                data: [],
            }));


    const getNetxPalletDetail = (query,value) =>
        getNextDetailPallet(value)
            .then(response => {
                return response;
            })
            .then(response => ({
                ...query,
                data: response,
            }))
            .catch(() => ({
                ...query,
                page: 0,
                pageSize: 15,
                totalCount: 0,
                data: [],
            }));


    const handleChangeSelect = (event) => {
        if(error){
            setError(false);
        }
        setBuffer(event.target.value);
    };

    const handleChangeSelectWave = (event) => {
        if(error){
            setError(false);
        }
        setSelectedWave(event.target.value);
    };

    const handleChangeInput = (e)=>{
        setCant(e.target.value)
    };

    const addNumber = ()=>{
        setCant(cant+1);
    };

    const removeNumber = ()=>{
        if(cant>1)
            setCant(cant-1);
    };

    const searchPallet = e => {
        const text = e.target.value.toUpperCase().trim();
        setSearch(text);
        if (!loading) {
            setLoading(true);
            clearTimeout(timerChange);
            setTimerChange(
                setTimeout(function() {
                    handleChangeInputSearch(null, text, "input[name='searchLoc']");
                    setLoading(false);
                    setLocation('');
                    toggleInput();
                }, 500),
            );
        }
    };

    const changeInput = e => {
        if(e.key === 'Enter' && chipData.length > 0){
            document.querySelector("input[name='searchLoc']").focus()
        }
    };

    const setLoc = e => {
        let loc = e.target.value.toUpperCase().trim();
        if (loc.length > 12) {
            loc = loc.substring(1, loc.length - 1);
        }
        setTimeout(function() {
            const verify = true;
            const zoneType = 0;
            setLocation(loc);
            if (!loading) {
                setLoading(true);
                if (verify) {
                    verifyZoneEmpty(loc, zoneType, chipData)
                        .then(response => {
                            if (response.exists) {
                                if (response.occupied) {
                                    enqueueSnackbar(`La ubicación, (${loc}) no esta disponible`, {
                                        variant: 'warning',
                                    });
                                } else {
                                    enqueueSnackbar(`Los bines fueron ubicados en ${loc}`, { variant: 'success' });
                                    setTimeout(function() {
                                        document.querySelector("input[name='searchBin']").focus()
                                    }, 500);
                                    setSearch('');
                                    setLocation('');
                                    setIndexChip(0);
                                    setChipData([]);
                                    setBoxes(0);
                                    toggleInput();
                                    handleChange(null, response.wave);
                                }
                            } else {
                                enqueueSnackbar('La ubicación no es correcta', { variant: 'warning' });
                            }
                            setLoading(false);
                        })
                        .catch(e => {
                            console.log('Error', e);
                        });
                }
            }
        });
    };

    const toggleInput = () => {
        setInputFocusLPN(inputFocusLPN);
        setLPN(!disableLPN);
    };

    const { enqueueSnackbar } = useSnackbar();

    useEffect(()=>{
        getPickingWaves()
            .then(response =>{
                setWaves(response);
                let ids = [];
                response.map(key => {
                    ids.push(key.id);
                });
                setIdsWave(ids);
                setValue(ids[0]);
                changeStatusBin(null , ids[0])
                    .then(responseTotal => {
                        setTotalBoxes(responseTotal['totalBoxes']);
                        setPickedBoxes(responseTotal['pickedBoxes']);
                        setTotalBines(responseTotal['totalBines']);
                        setTotalBinesReceive(responseTotal['totalBinesReceive']);
                    })
                    .catch(response => {

                    });
                getStagingAllList(ids[0])
                    .then(response =>{
                        setZones(response);
                        if(response.length === 0){
                            setVisibleBtn(false);
                        } else {
                            setHeaderList([]);
                            setZones(response);
                            setVisibleBtn(true);
                        }
                    }).catch((e)=>{
                    console.log("Error",e);
                });
            }).catch((e)=>{
            console.log("Error",e.message);
        });
    },[open]);

    function callPallets(){
        let buffer = bufferValue;
        let pallets = headerList.slice(0,cant);
        let wave = selectedWave;
        if(buffer==""){
            setError(true);
        }else{
            if(!error){
                setBtn(true);
                saveOrderStaging(value+5, selectedWave,buffer, cant)
                    .then((response)=>{
                        if(response.success){
                            enqueueSnackbar("Los bins han sido solicitadas al buffer correctamente", { variant: 'success' });
                            setCant(1);
                            setOpen(false);
                        } else
                            enqueueSnackbar(response.msg, { variant: 'warning' });
                        setBtn(false);
                    }).catch((e)=>{
                    console.log("Error",e.message);
                    enqueueSnackbar(e.message, { variant: 'error' });
                    setBtn(false);
                });
            }
            setError(false);
        }
    }

    function getColorWave(pallets) {

        let position;
        pallets.map(option => {
                position = idsWave.indexOf(option.wave_id);
            }
        );
        let color = colorWave[position];
        return color;
    }

    const hdlInductionInput = event => {
        setInductionForm({
            ...inductionForm,
            [event.target.name]: event.target.value,
        });
    };

    const handleKeyPress = event => {
        if(event.key === 'Enter'){
            setInductionForm({
                ...inductionForm,
                [event.target.name]: event.target.value,
            });
            console.log(inductionForm);
            savePallets();
        }
    };

    function getWaves(pallets) {
        let waveList = [];
        pallets.map(option => {
                waveList.push(option.wave_id);
            }
        );
        return [...new Set(waveList)].join(',');
    }

    const savePallets = () => {
        const bin = inductionForm.pallet;
        sendToInduction(bin)
            .then(response => {
                enqueueSnackbar(`Se ha enviado a inducir el bin ${bin}.`, {
                    variant: 'success',
                });
                setInductionForm({
                    ...inductionForm,
                    pallet: '',
                });
                getNextPallets(value)
                    .then(response => {
                        console.log(response);
                        setNextPallets(response);
                    })
                    .catch(e => {
                        console.log('ERROR', e);
                    });
                handleChange(null, value);
            })
            .catch(e => {
                enqueueSnackbar(e, {
                    variant: 'error',
                });
            });

    };

    const renderDetailPallet = rowData => {
        return (
            <MaterialTable
                nameEntity="Detalle"
                columns={[
                    {
                        title: 'Estilo',
                        field: 'style',
                        type: 'string',
                    },
                    {
                        title: 'Departamento',
                        field: 'name',
                        type: 'string',
                    },
                    {
                        title: 'Cantidad',
                        field: 'cantidad',
                        type: 'string',
                    },

                ]}

                data={query => getNetxPalletDetail(query, rowData.id)}
                tableRef={tableRefPalletContent}
                options={{
                    search: false,
                    showTitle: false,
                    toolbar: false,
                    paging: false,
                    header: true,
                    padding: 'dense',
                    rowStyle: {
                        backgroundColor: '#EEE',
                    },
                }}
            />
        );
    };

    function getDepto(aPallets) {
        let deptos = [];
        let names = [];

        if (aPallets !== undefined) {
            if (aPallets.length > 0 ) {
                aPallets.forEach( function(valor, indice, array) {
                    deptos.push(valor.pallets_sku);
                });
                deptos.forEach( function(valor) {
                    for (var i = 0; i < valor.length; i++) {
                        names.push(valor[i].name_department);
                    }
                });

                return [...new Set(names)].join(',');

            }
        }
    }

    function searchListPallets() {
        return <Grid container>
            {headerList.map((pallets,i)=>{
                return (
                    <Grid item xs={12} key={`tarima${i}`}>
                        <List>
                            <ListItem>
                                <ListItemText
                                    primary={"Bin "+(i+1)+": "+pallets.lpn_transportador+" Depto: "+pallets.name}
                                    secondary={`Ola: ${pallets.wave_ref} Ubicación: ${pallets.code}`}
                                />
                            </ListItem>
                            <Divider />
                        </List>
                    </Grid>
                )
            })}        
            </Grid>
    }

    return (        
        <Box id="app-routes-page" component="main" className={appStyles.pageRoot}>
            <Dialog fullScreen open={openModal} onClose={() => setOpenModal(false)} TransitionComponent={Transition}>
              <AppBar className={classes.appBar}>
                <Toolbar>
                  <IconButton edge="start" color="inherit" onClick={() => setOpenModal(false)} aria-label="close">
                    <CloseIcon />
                  </IconButton>
                  <Typography variant="h6" className={classes.title}>
                    INDUCCION
                  </Typography>                  
                </Toolbar>
              </AppBar>
              <div className={classes.paper}>
                <Grid container spacing={3} alignItems="flex-end">                                        
                  <Grid item xs={12} lg={12} style={{ textAlign: 'center' }}>
                    <h2 id="transition-modal-title">Capture código del BIN</h2>
                  </Grid>                    
                  <Grid item xs={12}>
                    <TextField
                      autoFocus
                      autoComplete="off"
                      name="pallet"
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      type="text"
                      label="Código de BIN"
                      value={inductionForm.pallet}
                      onChange={hdlInductionInput}
                      onKeyPress={handleKeyPress}
                    />
                  </Grid>                    
                  <Grid item xs={6} lg={6} style={{ textAlign: 'center' }}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={savePallets}                  
                    >
                      Aceptar
                    </Button>
                  </Grid>
                  <Grid item xs={6} lg={6} style={{ textAlign: 'center' }}>
                    <Button variant="contained" color="secondary" onClick={toggleModal}>
                      Cancelar
                    </Button>
                  </Grid>
                </Grid>
                <MaterialTable
                  columns={[            
                    { 
                      title: 'Bin',
                      field: 'lpn_transportador',
                      render: rowData => (rowData.lpn_transportador.slice(0, -1)),
                    },
                    // {
                    //   title: 'Estilo',
                    //   field: 'style',
                    // },
                    {
                      title: 'Depto(s)',
                      field: 'pallets_sku',
                      render: rowData => {
                        const deptoList = [];
                        rowData.pallets_sku.forEach(option => {
                          deptoList.push(option.department.name);
                        });
                        return <Typography>{[...new Set(deptoList)].join(',')}</Typography>;
                      },
                    },
                    { title: 'Posicion', field: 'code' },
                  ]}
                  title={`Bines sugeridos`}                      
                  localization={materialTableLocaleES}
                  data={nextPallets}
                  detailPanel={rowData => renderDetailPallet(rowData)}
                  tableRef={tableRef2}
                  options={{
                    pageSize: 10,
                    padding: 'dense',
                    pageSizeOptions: [5, 10, 20, 50, 100],
                  }}          
                />
              </div>
            </Dialog>
            <Modal
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              className={classes.modal}
              open={false}
              onClose={() => {}}
              closeAfterTransition
              BackdropComponent={Backdrop}
              BackdropProps={{
                timeout: 500,
              }}
              disableBackdropClick
            >
              <Fade in={false}>
                <div className={classes.paper}>
                  
                </div>
              </Fade>
            </Modal>
            <PageHeader
                title="STAGING"
                titleButton="ENVIAR A INDUCCION"
                displayButton
                canExecuteButton={visibleBtn}
                action={toggleModal}
                breadcrumbsData={breadcrumbsData}
            />
            <StagingForm
                formOpen={openForm}
                toggleForm={toggleFormA}
                refreshTable={zone => {
                    setValueZone(zone);
                    tableRef2.current.onQueryChange();
                }}
                dataObject={dataObject}
                dataWave={dataWave}
            />
            <Grid container>
              {
                idsWave.map((wave, i) => {
                  return (
                      <Grid item xs={2} key={`grid-${i}`}>
                        <Button
                          variant="outlined"
                          key={`tab${i}`}
                          style={{ backgroundColor: colorWave[i], color: 'black', margin:"10px" , width: "80%"}}
                          onClick={() => {
                            handleChange(null, wave);
                          }}
                        >
                          {`Ola: ${wave}`}
                        </Button>
                      </Grid>
                  );
                })
              }
            </Grid>
          
            <Grid container spacing={1}>
              <Grid container style={{ marginTop: '15px' }}>
                <Grid item xs={1} lg={1} />
                <Grid item xs={4} >
                    <TextField
                        autoComplete="off"
                        ref={binRef}
                        name="searchBin"
                        onChange={searchPallet}
                        onKeyPress={changeInput}
                        style={{ width: '100%' }}
                        id="input-with-icon-grid"
                        label="Codigo de Bin"
                        value={search}
                    />
                </Grid>
                <Grid item xs={1} style={{ marginTop: '15px'}}>
                    <CropFreeIcon />
                </Grid>
                <Grid item xs={1} lg={1} />
                <Grid item xs={4} >
                    <TextField
                        name="searchLoc"
                        autoComplete="off"
                        onChange={setLoc}
                        style={{ width: '100%' }}
                        id="input-with-icon"
                        label="Codigo de Ubicación"
                        value={location}
                    />
                </Grid>
                <Grid item xs={1} style={{ marginTop: '15px'}}>
                    <CropFreeIcon />
                </Grid>
                  <Grid item xs={12} style={{ marginTop: '15px' }}>
                      <Paper component="ul" className={classes.paperChip}>
                          <TableContainer>
                              <Table className={classes.table} size="small" aria-label="a dense table">
                                  <TableHead>
                                      <TableRow>
                                          <TableCell>Cajas en este pallet</TableCell>
                                          <TableCell align="right">Total de bines</TableCell>
                                          <TableCell align="right">Total de bines a recibir</TableCell>
                                          <TableCell align="right">Total de cajas recibidas</TableCell>
                                          <TableCell align="right">Total a recibir</TableCell>
                                      </TableRow>
                                  </TableHead>
                                  <TableBody>
                                      <TableRow>
                                          <TableCell className={classes.boxesCellInfo}>{boxes}</TableCell>
                                          <TableCell className={classes.receivedBinesCellInfo} align="right">{totalBines}</TableCell>
                                          <TableCell className={classes.totalBinesCellInfo} align="right">{totalBinesReceive}</TableCell>
                                          <TableCell className={classes.receivedBoxesCellInfo} align="right">{totalBoxes}</TableCell>
                                          <TableCell className={classes.totalBoxesCellInfo} align="right">{pickedBoxes}</TableCell>
                                      </TableRow>
                                  </TableBody>
                              </Table>
                          </TableContainer>
                      </Paper>
                      <Paper component="ul" className={classes.paperChip}>
                          {chipData.map((data) => {
                              return (
                                  <li key={data.key}>
                                      <Chip
                                          label={data.label}
                                          onDelete={data.label === 'React' ? undefined : handleDelete(data)}
                                          className={classes.chip}
                                      />
                                  </li>
                              );
                          })}
                      </Paper>
                  </Grid>
                <Grid item xs={12} style={{ textAlign: 'center'}}>
                    <br/>
                    <Button
                        variant="outlined"
                        color="primary"
                        style={{width:'30%'}}
                        onClick={handleClickOpenDialog}
                    >
                        Ver departamentos
                    </Button>
                </Grid>
                
              </Grid>
                {
                    zones.length === 0 ?
                    headerList.length === 0?                      
                      <List>
                          <ListItem>
                              <ListItemText primary={'Sin datos en la ubicación '} />
                          </ListItem>
                      </List>
                      :
                      searchListPallets()
                    :
                    zones.map((zones,i)=>{
                        return (
                            <Grid item xs={4} key={`zones${zones.id}`} >
                                <Card className={classes.rootCard} style={{backgroundColor: getColorWave(zones.pallets)}}>
                                    <CardContent>
                                        <Typography omponent="h6" gutterBottom>
                                            DEPTO: {getDepto(zones.pallets) }
                                        </Typography>
                                        <Typography variant="h5" component="h2">
                                            {zones.code}
                                        </Typography>
                                        <Typography variant="h6" component="p">
                                            OLA: {(getWaves(zones.pallets) <= 0 ) ? 'N/A' : getWaves(zones.pallets)}
                                            <br />
                                            BINS: {zones.pallets_count}
                                        </Typography>
                                    </CardContent>
                                    <CardActions>
                                        <Button
                                            color="primary"
                                            onClick={
                                                (event, a) => {
                                                    setDataObject(zones.id);
                                                    setDataWave(zones.pallets[0].wave_id)
                                                    setOpenForm(!openForm);
                                                }
                                            }>
                                            <Typography variant="h6">
                                                <ShowIcon color={(zones.pallets.length > 0) ? 'primary' : 'disabled'} className="icon-small"/>
                                            </Typography>
                                        </Button>
                                    </CardActions>
                                </Card>
                            </Grid>
                        )
                    })}
            </Grid>
            <Dialog
                open={openDialog}
                onClose={handleCloseDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Este BIN ya cuenta con una ubicación: "+ descriptionZone}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        ¿Desea actualizarlo?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {handleCloseDialog(false)}} color="secondary">
                        Cerrar
                    </Button>
                    <Button onClick={() => {handleCloseDialog(true)}} color="primary" autoFocus>
                        Aceptar
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={openDialogDepartment}
                onClose={handleCloseDialogDepartment}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="sm"
                fullWidth={fullWidth}
            >
                <DialogTitle id="alert-dialog-title">{"DEPARTAMENTOS"}</DialogTitle>
                <DialogContent>
                    <MaterialTable
                        nameEntity="Departments"
                        columns={[
                            {
                                title: 'Nombre',
                                field: 'name',
                                type: 'string',
                            },
                            {
                                title: 'Ranking',
                                field: 'ranking',
                                type: 'string',
                            },
                        ]}
                        data={query => getDetailDepartment(query, value)}
                        tableRef={tableRef}
                        options={{
                            search: false,
                            showTitle: false,
                            toolbar: false,
                            paging: false,
                            header: true,
                            padding: 'dense',
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {handleCloseDialogDepartment(true)}} color="primary" autoFocus>
                        Aceptar
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
}

export default WithSnackBarMessages(StagingPage);