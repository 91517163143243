import React, { useState, useRef } from 'react';

//Components
import {
    Typography,
    Button,
    Card,
    CardContent,
    Container,
    CardActions,
    CircularProgress,
} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import { useSnackbar } from 'notistack';
import { makeStyles } from '@material-ui/core/styles';
import WithSnackBarMessages from '../../../components/WithSnackBarMessages';

//Api
import { sqsMessage } from '../../../api/SqsService/SqsApi';
import {scannerBox} from "../../../api/CartonService/CartonApi";

// Styles
const useStyles = makeStyles(theme => ({
    title: {
        marginBottom: theme.spacing(2),
    },
    divImage: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginBottom: theme.spacing(2),
    },
    imageCard: {
        width: 140,
        height: 140,
        resizeMode: 'center',
        alignSelf: 'center',
    },
    buttonProgress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    wrapper: {
        position: 'relative',
    },
    cardForm: {
        marginTop: theme.spacing(1),
    },
    forgot: {
        fontSize: 12,
    },
    textArea : {
        width: '100%',
        height: '50%',
    }
}));

function SqsPage() {
    const classes = useStyles();
    const [typeMessage, setTypeMessage] = useState(0);
    const { enqueueSnackbar } = useSnackbar();
    const [isLoading, setIsLoading] = useState(false);
    const selectedMessage = [
        '',

        '{\n' +
        '        "resourceIds": [ \n' +
        '        1,2,3\n' +
        '        ],\n' +
        '        "operation": "new",\n' +
        '        "entity": "",\n' +
        '        "method": "GET",\n' +
        '        "path": "/path",\n' +
        '        "withBody": true\n' +
        '}',

        '{\n' +
        '        "resourceIds": [ \n' +
        '        1,2,3\n' +
        '        ],\n' +
        '        "operation": "update",\n' +
        '        "entity": "",\n' +
        '        "method": "GET",\n' +
        '        "path": "/path",\n' +
        '        "withBody": true\n' +
        '}',

    ];
    const [message, setMessage] = useState('');

    const handleChange = (event) => {
        setMessage('');
        setTypeMessage(event.target.value);
        if (event.target.value === '') {
            setMessage('');
        } else {
            setMessage(selectedMessage[event.target.value]);
        }
    };

    const handleChangeMessage = (event) => {
        setMessage(event.target.value);
    };

    const handleSubmit = event => {
        event.preventDefault();
        setIsLoading(true);
        sqsMessage(message)
            .then(response => {
                setMessage('');
                setTypeMessage(selectedMessage[0]);
                setIsLoading(false);
                enqueueSnackbar('Mensaje enviado', { variant: 'success' });
            })
            .catch(error => {
                enqueueSnackbar('No se encontró Box Id asociado al código escaneado.', { variant: 'error' });
                setIsLoading(false);
            });
        setMessage('');
        setTypeMessage(selectedMessage[0]);
    };

    return (
        <Container id="app-login-page" maxWidth="lg">
            <Typography variant="h4" align="center" className={classes.title}>
                Simulación mensajes SQS
            </Typography>
            <Card component="form" onSubmit={handleSubmit} className={classes.cardForm}>
                <CardContent>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <InputLabel id="demo-simple-select-helper-label">Tipo de mensaje</InputLabel>
                            <Select
                                fullWidth
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                value={typeMessage}
                                label="Age"
                                onChange={handleChange}
                            >
                                <MenuItem value="">
                                    <em>Seleccione</em>
                                </MenuItem>
                                <MenuItem value={1}>Creacion</MenuItem>
                                <MenuItem value={2}>Actualizacion</MenuItem>
                            </Select>
                        </Grid>
                        <Grid item xs={6}>

                        </Grid>
                        <Grid item xs={12}>
                            <TextareaAutosize
                                className={classes.textArea}
                                aria-label="empty textarea"
                                placeholder="Ingrese el mensaje"
                                rowsMin={10}
                                value={message}
                                onChange={handleChangeMessage}
                            />
                        </Grid>
                    </Grid>
                </CardContent>
                <CardActions className={classes.wrapper}>
                    <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        type="submit"
                        disabled={isLoading}
                    >
                        Enviar
                    </Button>
                    {isLoading && (
                        <CircularProgress
                            size={24}
                            className={classes.buttonProgress}
                            style={{ marginLeft: -8 }}
                        />
                    )}
                </CardActions>
            </Card>
        </Container>
    );
}

export default WithSnackBarMessages(SqsPage);
