import { apiClientBearerToken } from './ApiClientConfig';
import Logger from '../Logger';

const Log = new Logger('src/api/WaveServices/WavesApi.js');

const PICKING_PATH = '/picking';
const PALLETS_PATH = '/pallets/staging';
const PALLETS_ONLY_PATH = '/pallets';
const WAVES_PICKING_PATH = '/waves/getinpicking';

export const getAllWaves = (
  paginated: Boolean,
  size?: Number,
  page?: Number,
  search?: String,
): Promise<Array<Object>> => {
  return apiClientBearerToken()
    .get(`${PICKING_PATH}/all`, {
      params: {
        paginated,
        size,
        page,
        search: search?.trim(),
      },
    })
    .then(response => {
      return Promise.resolve(response.data);
    })
    .catch(error => {
      Log.error(
        // getAllWaves.name,
        error.message,
        error.response ? error.response : error,
        !(error.response && error.response.status === 404),
      );
      return Promise.reject();
    });
};

export const getListStaging = (zoneType, bin?: String): Promise<Array<Object>> => {
  const searchPallet = bin ? `&bin=${bin}` : '';
  return apiClientBearerToken()
    .get(`${PALLETS_PATH}/getlist/?zonetype=${zoneType}${searchPallet}`)
    .then(response => {
      return Promise.resolve(response.data);
    })
    .catch(error => {
      Log.error(
        error.message,
        error.response ? error.response : error,
        !(error.response && error.response.status === 404),
      );
      return Promise.reject();
    });
};

export const getNextDetailPallet= (pallet: Number): Promise<Array<Object>> => {
    return apiClientBearerToken()
        .get(`${PALLETS_ONLY_PATH}/details/detailnextpallet`, {
            params: {
                pallet
            },
        })
        .then(response => {
            return Promise.resolve(response.data);
        })
        .catch(error => {
            Log.error(
                error.message,
                error.response ? error.response : error,
                !(error.response && error.response.status === 404),
            );
            return Promise.reject();
        });
};

export const getStagingAllList = (wave): Promise<Array<Object>> => {
    return apiClientBearerToken()
        .get(`${PALLETS_PATH}/getliststaging/?wave=${wave}`)
        .then(response => {
            return Promise.resolve(response.data);
        })
        .catch(error => {
            Log.error(
                error.message,
                error.response ? error.response : error,
                !(error.response && error.response.status === 404),
            );
            return Promise.reject();
        });
};

export const getPickingWaves = (): Promise<Array<Object>> => {
  return apiClientBearerToken()
    .get(`${WAVES_PICKING_PATH}`)
    .then(response => {
      return Promise.resolve(response.data);
    })
    .catch(error => {
      Log.error(
        error.message,
        error.response ? error.response : error,
        !(error.response && error.response.status === 404),
      );
      return Promise.reject();
    });
};

export const getNextPallet = (buffer: Number): Promise<Array<Object>> => {
  return apiClientBearerToken()
    .get(`/pallets/getnext?buffer=${buffer}`)
    .then(response => {
      return Promise.resolve(response.data);
    })
    .catch(error => {
      Log.error(
        error.message,
        error.response ? error.response : error,
        !(error.response && error.response.status === 404),
      );
      return Promise.reject();
    });
};

export const getNextPallets = (buffer: Number): Promise<Array<Object>> => {
  return apiClientBearerToken()
    .get(`/pallets/getnext?wave=${buffer}`)
    .then(response => {
      return Promise.resolve(response.data);
    })
    .catch(error => {
      Log.error(
        error.message,
        error.response ? error.response : error,
        !(error.response && error.response.status === 404),
      );
      return Promise.reject();
    });
};

export const sendToInduction = (pallet?: String): Promise<Array<Object>> => {
  return apiClientBearerToken()
    .post(`${PALLETS_PATH}/induct`, {
      pallet,
    })
    .then(response => {
      return Promise.resolve(response.data);
    })
    .catch(error => {
      Log.error(
        // getAllWaves.name,
        error.message,
        error.response ? error.response : error,
        !(error.response && error.response.status === 404),
      );
      return Promise.reject(error.response.data.message);
    });
};

export const saveOrderStaging = (origin?: Number, wave?: Number, buffer?: Number, cant?: Number): Promise<Array<Object>> => {
  return apiClientBearerToken()
    .post(`${PALLETS_PATH}/order`, {
      origin,
      wave,
      buffer,
      cant
    })
    .then(response => {
      return Promise.resolve(response.data);
    })
    .catch(error => {
      Log.error(
        // getAllWaves.name,
        error.message,
        error.response ? error.response : error,
        !(error.response && error.response.status === 404),
      );
      return Promise.reject();
    });
};

export const getPalletsFromStaging = (): Promise<Array<Object>> => {
  return apiClientBearerToken()
    .get(`${PALLETS_PATH}/manager`)
    .then(response => {
      return Promise.resolve(response.data);
    })
    .catch(error => {
      Log.error(
        // getAllWaves.name,
        error.message,
        error.response ? error.response : error,
        !(error.response && error.response.status === 404),
      );
      return Promise.reject();
    });
};

export const getPalletsByZone = (search?: String): Promise<Array<Object>> => {
  return apiClientBearerToken()
    .get(`${PALLETS_PATH}/getpallets/`, {
      params: {
        search,        
      },
    })
    .then(response => {
      return Promise.resolve(response.data);
    })
    .catch(error => {
      Log.error(
        // getAllWaves.name,
        error.message,
        error.response ? error.response : error,
        !(error.response && error.response.status === 404),
      );
      return Promise.reject();
    });
};

export const verifyZoneEmpty = (
    code?:String,
    zoneType?:Number,
    bin?:Array,
): Promise<Array<Object>> => {
    return apiClientBearerToken()
        .post(`${PALLETS_PATH}/verifyZone`,{
            code,
            zoneType,
            bin
        })
        .then(response => {
            return Promise.resolve(response.data);
        })
        .catch(error => {
            Log.error(
                //getAllWaves.name,
                error.message,
                error.response ? error.response : error,
                !(error.response && error.response.status === 404),
            );
            return Promise.reject();
        });
};

export const sendPalletsToBuffer = (pallets?: Array): Promise<Array<Object>> => {
  return apiClientBearerToken()
    .post(`${PALLETS_PATH}/store`, {
      pallets,
    })
    .then(response => {
      return Promise.resolve(response.data);
    })
    .catch(error => {
      Log.error(
        // getAllWaves.name,
        error.message,
        error.response ? error.response : error,
        !(error.response && error.response.status === 404),
      );
      return Promise.reject();
    });
};

export const getPalletsFromBuffer = (zone_type?: number): Promise<Array<Object>> => {
  return apiClientBearerToken()
    .get(`${PALLETS_PATH}/buffer`, {
      params: {
        zoneType: zone_type,
      },
    })
    .then(response => {
      return Promise.resolve(response.data);
    })
    .catch(error => {
      Log.error(
        // getAllWaves.name,
        error.message,
        error.response ? error.response : error,
        !(error.response && error.response.status === 404),
      );
      return Promise.reject();
    });
};

export const sendPalletsToInduction = (pallets?: Array): Promise<Array<Object>> => {
  return apiClientBearerToken()
    .post(`${PALLETS_PATH}/pallets/staging/dispatch`, {
      params: {
        pallets,
      },
    })
    .then(response => {
      return Promise.resolve(response.data);
    })
    .catch(error => {
      Log.error(
        // getAllWaves.name,
        error.message,
        error.response ? error.response : error,
        !(error.response && error.response.status === 404),
      );
      return Promise.reject();
    });
};

export const getPalletByLPN = (pallet?: String): Promise<Array<Object>> => {
  return apiClientBearerToken()
    .get(`${PALLETS_PATH}/pallet/`, {
      params: {
        lpn: pallet,
      },
    })
    .then(response => {
      return Promise.resolve(response.data);
    })
    .catch(error => {
      Log.error(
        // getAllWaves.name,
        error.message,
        error.response ? error.response : error,
        !(error.response && error.response.status === 404),
      );
      return Promise.reject();
    });
};

export const sendPalletsToMoving = (pallets?: Array): Promise<Array<Object>> => {
  return apiClientBearerToken()
    .post(`${PALLETS_PATH}/moving/`, {
      pallets,
    })
    .then(response => {
      return Promise.resolve(response.data);
    })
    .catch(error => {
      Log.error(
        // getAllWaves.name,
        error.message,
        error.response ? error.response : error,
        !(error.response && error.response.status === 404),
      );
      return Promise.reject();
    });
};

export const changeStatusBin = (
    pallet?: String,
    wave?: Number): Promise<Array<Object>> => {
    return apiClientBearerToken()
        .post(`${PALLETS_ONLY_PATH}/receivedpallet/`, {
            bin: pallet,
            wave: wave
        })
        .then(response => {
            return Promise.resolve(response.data);
        })
        .catch(error => {
            Log.error(
                changeStatusBin.name,
                error.message,
                error.response ? error.response : error,
                !(error.response && error.response.status === 404),
            );
            return Promise.reject();
        });
};