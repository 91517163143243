import 'date-fns';
import React, { useState, useRef, useEffect } from 'react';

// Components
import MaterialTable, { MTableToolbar } from 'material-table';
import {Box, TextField, FormControl, Typography, Tooltip, Avatar} from '@material-ui/core';
import {
    Visibility as ShowIcon,
    AccountTree as TreeIcon,
    Search as SearchIcon,
    RadioButtonChecked as EnableIcon,
} from '@material-ui/icons';
import WithSnackBarMessages from '../../components/WithSnackBarMessages';
import UserReportForm from './components/UserReportAdminForm';


// Styles
import { AppStyles } from '../../AppStyles';
import materialTableLocaleES from '../../components/MaterialTableLocaleES';
import { makeStyles } from '@material-ui/core/styles';

// Data Apis
import {
    getSubscriptions
} from '../../api/UserReportService/UserReportServiceApi';
import PageHeader from "../../components/PageHeader";
import {APP_ROUTES, PRIORITY_ROUTES, USERS_ROUTES} from "../../Routes";
import AdminForm from "../Users/components/AdministratorForm";


const useStyles = makeStyles(theme => ({
    actionDescriptionEdit: {
        '& .MuiInput-root': {
            fontSize: 'small',
        },
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    display: {
        display: 'none',
    },
    formControlTableToolBar: {
        margin: theme.spacing(3),
        marginTop: -theme.spacing(7),
        minWidth: 170,
    },
    formClearData: {
        marginTop: '10px',
    },
}));


const breadcrumbsData = [
    {
        id: 1,
        label: 'Catalogos',
        base: APP_ROUTES.PRIORITY,
    },
    {
        id: 2,
        label: 'Suscripciones',
        base: APP_ROUTES.PRIORITY + PRIORITY_ROUTES.USER_REPORTS_ADMIN,
    },
];


function UserReportAdminPage() {
    const classes = useStyles();
    const appStyles = AppStyles();
    const tableRef = useRef();
    const [openForm, setOpenForm] = useState(false);
    const [dataObject, setDataObject] = useState(0);

    const toggleForm = () => {
        if (tableRef.current) {
            tableRef.current.state.query.page = 0;
            if (openForm) {
                tableRef.current.onQueryChange();
            }
        }
        setOpenForm(!openForm);
        setDataObject(0);
    };

    useEffect(() => {
        // hasAuthority(Permissions.GetAllRoutes, setCanGetAllRoutes);
        // hasAuthority(Permissions.CreateRoute, setCanCreateRoute);
        // hasAuthority(Permissions.UpdateRouteByID, setCanUpdateRoute);
        // hasAuthority(Permissions.DeleteRouteByID, setCanDeleteRoute);
        // hasAuthority(Permissions.AddShopToRouteByID, setCanAddShopToRoute);
        // hasAuthority(Permissions.RemoveShopFromRouteByID, setRemoveShopFromRoute);
    }, []);

    const getSubsUsers = query =>
        getSubscriptions(query.pageSize, query.page + 1, query.search)
            .then(response => ({
                ...query,
                data: response.data,
                page: response.current_page -1 ,
                totalCount: response.total,
            }))
            .catch(() => ({
                ...query,
                page: 0,
                pageSize: 15,
                totalCount: 0,
                data: [],
            }));
    
    return (
        <Box
            id="app-routes-page"
            component="main"
            className={appStyles.pageRoot}
            style={{ backgroundColor: 'white', height: '100vh' }}
        >
            <PageHeader
                title="Suscripciones a reportes"
                titleButton="Nueva Suscripción"
                displayButton
                canExecuteButton={true}
                action={toggleForm}
                breadcrumbsData={breadcrumbsData}
            />

            <UserReportForm
                formOpen={openForm}
                toggleForm={toggleForm}
                refreshTable={() => tableRef.current && tableRef.current.onQueryChange()}
                dataObject={dataObject}
            />

            <MaterialTable
                columns={[
                    { title: 'Nombre', field: 'name' },
                    { title: 'Email', field: 'email' },
                    { title: 'Suscrito a',
                        render: rowData => {
                            let array = rowData.subscrited_to;
                            let subscritions = JSON.parse(array);
                            return subscritions.toString();
                        },
                    },
                    { title: 'Activo',
                        render: rowData => (
                            <div>
                                <Tooltip
                                    title={
                                        rowData.active ? 'Usuario habilitado' : 'Usuario deshabilitado'
                                    }
                                    aria-label="add"
                                >
                                    <EnableIcon style={{ color: rowData.active ? 'green' : 'gray' }} />
                                </Tooltip>
                            </div>
                        ),
                    },
                    { title: 'AWS',
                        render: rowData => (
                            <div>
                                <Tooltip
                                    title={
                                        rowData.aws ? 'Verificado' : 'No verificado'
                                    }
                                    aria-label="add"
                                >
                                    <EnableIcon style={{ color: rowData.aws ? 'green' : 'gray' }} />
                                </Tooltip>
                            </div>
                        ),
                    },
                ]}
                title="Usuarios suscritos a reportes"
                localization={materialTableLocaleES}
                tableRef={tableRef}
                data={query => getSubsUsers(query)}
                options={{
                    search: true,
                    pageSize: 20,
                    actionsColumnIndex: -1,
                    paging: true,
                    toolbar: true,
                    showTitle: false,
                    padding: 'dense',
                    pageSizeOptions: [5, 10, 20, 50, 100],
                }}
                actions={[
                    rowData => ({
                        icon: () => (
                            <ShowIcon color={rowData.id ? 'primary' : 'disabled'} className="icon-small" />
                        ),
                        tooltip: rowData.id ? 'Ver Suscripción' : undefined,
                        onClick: (event, rowData) => {
                            if (rowData.id) {
                                setDataObject(rowData.id);
                                setOpenForm(!openForm);
                            }
                        },
                    })
                ]}
            />
        </Box>
    );
}

export default WithSnackBarMessages(UserReportAdminPage);
