/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

import { APP_ROUTES } from '../Routes';
import { userHasToken, checkPermission } from '../api/AuthApi';

export default function AppRoute({ component: Component, redirectTo, requireAuth, ...rest }) {
  return (
    <Route
      {...rest}
      render={props => {
        if (requireAuth) {
          return userHasToken() ? <Component {...props} /> : <Redirect to={redirectTo} />;
        }

        return !userHasToken() ? <Component {...props} /> : <Redirect to={redirectTo} />;
      }}
    />
  );
}

AppRoute.defaultProps = {
  redirectTo: APP_ROUTES.LOGIN,
  requireAuth: false,
};

AppRoute.propTypes = {
  component: PropTypes.func.isRequired,
  redirectTo: PropTypes.string,
  requireAuth: PropTypes.bool,
};
