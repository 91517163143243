import React, { useState, useEffect } from 'react';

// Components
import {
    sortableContainer,
    sortableElement,
} from 'react-sortable-hoc';
import Grid from '@material-ui/core/Grid';
import arrayMove from 'array-move';
import "../../Priority/styles/list.css";

import { useSnackbar } from 'notistack';
import {
    Drawer,
    TextField,
    Typography,
    Box,
    Button,
    CircularProgress,
    IconButton, AppBar, Toolbar, Slide,
} from '@material-ui/core';

import PropTypes from 'prop-types';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import {
    ImportExport as PriorityIcon,
    Close as CloseIcon,
    Edit as EditIcon,
} from '@material-ui/icons';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Autocomplete from "@material-ui/lab/Autocomplete";
import MaterialTable from "material-table";
import materialTableLocaleES from "../../../components/MaterialTableLocaleES";
import {AppStyles} from "../../../AppStyles";


// Data Api
import {
    getRouteById,
    createRoute,
    updateRouteById
} from "../../../api/RouteServices/RouteCatalogApi";

const drawerWidth = 900;
const useStyles = makeStyles(theme => ({
    container: {
        width: drawerWidth,
    },
    containerPaper: {
        width: drawerWidth,
        padding: 20,
    },
    header: {
        display: 'flex',
        alignItems: 'center',
    },
    headerIcon: {
        fontSize: 35,
        marginRight: theme.spacing(1),
    },
    headerCloseButton: {
        padding: theme.spacing(1),
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        justifyContent: 'space-between',
    },
    buttonProgress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    selectForm: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },
    selectStyle: {
        minWidth: 195,
        maxWidth: 195,
    },
    pickerStyle: {
        minWidth: 225,
        maxWidth: 225,
    },
    selectAddButton: {
        padding: theme.spacing(1),
        marginTop: theme.spacing(1),
    },
    addRolesPolices: {
        margin: theme.spacing(4),
    },
    card: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    cardContent: {
        padding: theme.spacing(2),
    },
    formCardInput: {
        display: 'flex',
        flexGrow: 1,
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    bottomSpace: {
        paddingTop: theme.spacing(2),
    },
}));

const initialState = {
    routeId: 0,
    nameRoute: '',
    descriptionRoute: '',
    colorRoute: '',
    nameError: false,
    accountDate: moment()
        .add(1, 'M')
        .startOf('day'),
    accountDateError: false,
    accountDateErrorMessage: '',
    credentialDate: moment()
        .add(1, 'M')
        .startOf('day'),
};

const stateInit = {
    enabled: true,
};


function RouteForm({
                       formOpen,
                       toggleForm,
                       refreshTable,
                       dataObject,
                       canUpdateUser,
                       canGetUserData,
                       canUpdateUserData,
                   }) {
    const appStyles = AppStyles();
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const [isLoading, setIsLoading] = useState(false);
    const [form, setForm] = useState(initialState);
    const [closeClick, setCloseClick] = useState('');
    const [editForm, setEditForm] = useState(false);
    const [state, setState] = useState(stateInit);

    const messageError = (message, variant) => {
        enqueueSnackbar(message, {
            variant,
        });
    };

    useEffect(() => {
        //hasAuthority(Permissions.ResetPasswordByEmail, setResetPasswordByEmail);
    }, []);

    const handleChange = event => {
        let value = event.target.checked;

        setState({ ...state, [event.target.name]: value });
        setForm({
            ...form,
            routeId: dataObject,
            [event.target.name]: event.target.value,
        });
        setCloseClick(true);
    };

    const handleSubmit = event => {
        event.preventDefault();
        setIsLoading(true);
        if (dataObject === 0) {
            createRoute(
                form.nameRoute,
                form.descriptionRoute,
                )
                .then(response => {
                    toggleForm();
                    setForm(initialState);
                    setIsLoading(false);
                    messageError('Ruta Creada', 'success');
                    refreshTable();
                })
                .catch(error => {
                    if (error !== undefined) {
                        setForm({
                            ...form,
                        });
                    } else {
                        messageError('Servicio no disponible.', 'warning');
                        refreshTable();
                    }
                    setIsLoading(false);
                });
        } else {
            updateRouteById(
                form.routeId,
                form.nameRoute,
                form.descriptionRoute,
                form.colorRoute
            )
                .then(() => {
                    toggleForm();
                    setForm(initialState);
                    setIsLoading(false);
                    messageError('Ruta Actualizada', 'success');
                    refreshTable();
                })
                .catch(error => {
                    if (error !== undefined) {
                        setForm({
                            ...form,
                        });
                    } else {
                        refreshTable();
                        messageError('Servicio no disponible.', 'warning');
                    }
                    setIsLoading(false);
                });
        }
    };

    const clearData = () => {
        setCloseClick('');
        setEditForm(false);
        setState(stateInit);
        setForm({
            ...form,
        });
    };

    const initData = () => {
        if (dataObject === 0) {
            setForm({
                ...form,
                routeId: 0,
                nameRoute: '',
                descriptionRoute: '',
                colorRoute: '',
            });
        } else {
            getRouteById(dataObject)
                .then(response => {
                    setForm({
                        routeId: response.id,
                        nameRoute: response.name,
                        descriptionRoute: response.description,
                        colorRoute: response.colo
                    });
                })
                .catch(() => {
                    return Promise.resolve(setForm({}));
                });
        }
        setCloseClick(false);
        dataObject !== 0 ? setEditForm(false) : setEditForm(true);

    };

    const disableFormControl = () => {
        if (isLoading) {
            return true;
        }
        if (dataObject === 0) {
            return false;
        }
        if (dataObject !== 0 && editForm) {
            return false;
        }
        return true;
    };

    return (
        <Box>
            <Drawer
                anchor="right"
                open={formOpen}
                onClose={toggleForm}
                className={classes.container}
                classes={{ paper: classes.containerPaper }}
            >
                <Box className={classes.header}>
                    <PriorityIcon color="primary" className={classes.headerIcon} />
                    <Box flexGrow={1}>
                        <Typography variant="h5" color="primary">
                            {dataObject === 0 ? 'Nueva Ruta' : 'Actualizar Ruta'}
                        </Typography>
                    </Box>
                    {dataObject !== 0 && (
                        <IconButton
                            disabled={!canUpdateUser}
                            color="primary"
                            className={classes.headerCloseButton}
                            onClick={() => {
                                setEditForm(!editForm);
                            }}
                        >
                            <EditIcon />
                        </IconButton>
                    )}
                    <IconButton
                        color="secondary"
                        className={classes.headerCloseButton}
                        onClick={() => {
                            toggleForm();
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </Box>
                <Box component="form" className={classes.form} onSubmit={handleSubmit}>
                    <Box>
                        <TextField
                            size="small"
                            required
                            name="nameRoute"
                            label="Nombre de ruta"
                            margin="normal"
                            variant="outlined"
                            fullWidth
                            disabled={disableFormControl()}
                            value={form.nameRoute}
                            onChange={handleChange}
                        />
                        <TextField
                            size="small"
                            required
                            name="descriptionRoute"
                            label="Descripción de ruta"
                            margin="normal"
                            variant="outlined"
                            fullWidth
                            disabled={disableFormControl()}
                            value={form.descriptionRoute}
                            onChange={handleChange}
                        />
                    </Box>
                    <Box className={classes.bottomSpace}>
                        <Button
                            fullWidth
                            variant="contained"
                            color="primary"
                            type="submit"
                            disabled={disableFormControl()}
                            style={{position: 'static', marginLeft: 'auto', marginRight: 'auto', left: 4, right: 4, textAlign: 'center'}}
                            size="small"
                        >
                            {dataObject === 0 ? 'Guardar Ruta' : 'Actualizar Ruta'}
                        </Button>
                        {isLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
                        {formOpen && closeClick === '' && initData()}
                        {!formOpen && closeClick !== '' && clearData()}
                    </Box>
                </Box>
            </Drawer>
        </Box>
    );
}

RouteForm.defaultProps = {
    refreshTable: null,
    dataObject: 0,
    canUpdateUser: false,
    canGetUserData: false,
    canUpdateUserData: false,
};

RouteForm.propTypes = {
    refreshTable: PropTypes.func,
    formOpen: PropTypes.bool.isRequired,
    toggleForm: PropTypes.func.isRequired,
    dataObject: PropTypes.number,
    canUpdateUser: PropTypes.bool,
    canGetUserData: PropTypes.bool,
    canUpdateUserData: PropTypes.bool,
};

export default RouteForm;
