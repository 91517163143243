import React, { useState } from 'react';
// Components
import { withRouter } from 'react-router';
import {
  Typography,
  TextField,
  Button,
  CardMedia,
  InputAdornment,
  IconButton,
  Card,
  CardContent,
  Container,
  CardActions,
  Box,
  CircularProgress,
} from '@material-ui/core';
import {
  Visibility as ShowPassIcon,
  VisibilityOff as HidePassIcon,
  Lock as LockIcon,
} from '@material-ui/icons';

import { useSnackbar } from 'notistack';

// Components
import WithSnackBarMessages from '../../../components/WithSnackBarMessages';

// Styles
import LoginStyles from '../../Login/styles/LoginStyles';

// Data Apis
import { resetPassword } from '../../../api/AuthApi';

const ChangePasswordPage: React.FC<> = ({ match }) => {
  const classes = LoginStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [loginForm, setLoginForm] = useState({
    password: '',
    passwordConfirmation: '',
    isPasswordValid: true,
    showPassword: false,
    showPasswordConfirmation: false,
  });

  const handleChange = event => {
    let passwordValid = loginForm.isPasswordValid;
    if (event.target.name === 'password' && event.target.name === 'passwordConfirmation') {
      if (event.target.value.length < 1) {
        passwordValid = false;
      } else passwordValid = true;
    }
    setLoginForm({
      ...loginForm,
      [event.target.name]: event.target.value,
      isPasswordValid: passwordValid,
    });
  };

  const handleSubmit = event => {
    event.preventDefault();
    setIsLoading(true);
    resetPassword(loginForm.password, loginForm.passwordConfirmation, match.params.token)
      .then(response => {
        window.location.replace('/login');
        setIsLoading(false);
      })
      .catch(error => {
        enqueueSnackbar(error.message, { variant: 'error' });
        setIsLoading(false);
      });
  };

  return (
    <Container id="app-login-page" maxWidth="xs">
      <Box className={classes.divImage}>
        <CardMedia className={classes.imageCard} image="/logo512.png" title="CCP Logo" />
      </Box>
      <Typography variant="h4" align="center" className={classes.title}>
        CCP Order Manager
      </Typography>
      <Typography variant="h5" align="center" className={classes.title}>
        Cambia Contraseña
      </Typography>
      <Card component="form" onSubmit={handleSubmit} className={classes.cardForm}>
        <CardContent>
          <TextField
            error={!loginForm.isPasswordValid}
            name="password"
            variant="outlined"
            fullWidth
            margin="normal"
            type={loginForm.showPassword ? 'text' : 'password'}
            label="Nueva contraseña"
            value={loginForm.password}
            autoComplete="current-password"
            onChange={handleChange}
            disabled={isLoading}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LockIcon />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    disabled={isLoading}
                    edge="end"
                    aria-label="toggle-password-visibility"
                    onClick={() => {
                      setLoginForm({
                        ...loginForm,
                        showPassword: !loginForm.showPassword,
                      });
                    }}
                  >
                    {loginForm.showPassword ? <HidePassIcon /> : <ShowPassIcon />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <TextField
            error={!loginForm.isPasswordValid}
            name="passwordConfirmation"
            variant="outlined"
            fullWidth
            margin="normal"
            type={loginForm.showPasswordConfirmation ? 'text' : 'password'}
            label="Confirma nueva contraseña"
            value={loginForm.passwordConfirmation}
            autoComplete="current-password"
            onChange={handleChange}
            disabled={isLoading}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LockIcon />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    disabled={isLoading}
                    edge="end"
                    aria-label="toggle-password-visibility"
                    onClick={() => {
                      setLoginForm({
                        ...loginForm,
                        showPasswordConfirmation: !loginForm.showPasswordConfirmation,
                      });
                    }}
                  >
                    {loginForm.showPasswordConfirmation ? <HidePassIcon /> : <ShowPassIcon />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </CardContent>
        <CardActions className={classes.wrapper}>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            type="submit"
            disabled={
              !(
                loginForm.isPasswordValid &&
                loginForm.password.length > 0 &&
                loginForm.passwordConfirmation.length > 0 &&
                !isLoading
              )
            }
          >
            Reestablecer
          </Button>
          {isLoading && (
            <CircularProgress
              size={24}
              className={classes.buttonProgress}
              style={{ marginLeft: -8 }}
            />
          )}
        </CardActions>
      </Card>
    </Container>
  );
};
export default WithSnackBarMessages(withRouter(ChangePasswordPage));
