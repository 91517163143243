import React, { useState } from 'react';
import {
  Drawer,
  Box,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableContainer,
  TableCell,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import MaterialTable from 'material-table';
import materialTableLocaleES from '../../../components/MaterialTableLocaleES';

// Components
import { WaveSkuDetail } from '../../../api/WaveServices/WavesApi';
import SnackBarMessage from '../../../components/SnackBarMessage';

const drawerWidth = 1050;
const useStyles = makeStyles(theme => ({
  container: {
    width: drawerWidth,
  },
  containerPaper: {
    width: drawerWidth,
    padding: 20,
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  smallColor: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
}));

const initialState = {
  sumPieces: 0,
  sumExpected: 0,
  sumDif: 0,
  waveLines: [],
};

export default function WaveLines({
  formOpen,
  toggleForm,
  refreshTable,
  dataObject,
  waveRef,
  waveId,
}) {
  const classes = useStyles();
  const [form, setForm] = useState(initialState);
  const [closeClick, setCloseClick] = useState('');
  const tableRef = React.createRef();

  const clearData = () => {
    setForm(initialState);
    setCloseClick('');
  };

  const initData = () => {
    WaveSkuDetail(waveId, dataObject)
      .then(response => {
        let sumPieces = 0;
        let sumExpected = 0;
        let sumPrepacks = 0;
        let sumDif = 0;
        response.forEach(line => {
          sumPieces += line.pieces;
          sumPrepacks += line.prepacks;
          sumExpected += line.expected_pieces;
          sumDif += line.difference;
        });
        setForm({
          sumPieces,
          sumExpected,
          sumPrepacks,
          sumDif,
          waveLines: response,
        });        
      })
      .catch(error => {
        console.log(error);        
      });
    setCloseClick(false);
  };

  const downloadCsv = (data, fileName) => {
      const finalFileName = fileName.endsWith(".csv") ? fileName : `${fileName}.csv`;
      const a = document.createElement("a");
      a.href = URL.createObjectURL(new Blob([data], { type: "text/csv" }));
      a.setAttribute("download", finalFileName);
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
  };

  return (
    <Box>
      <Drawer
        anchor="right"
        open={formOpen}
        onClose={toggleForm}
        className={classes.container}
        classes={{ paper: classes.containerPaper }}
      >
        <TableContainer>
          <Table aria-label="Resumen">
            <TableHead>
              <TableRow>
                <TableCell align="right">Total P. solicitadas</TableCell>
                <TableCell align="right">Total P. en stock</TableCell>
                <TableCell align="right">Total prepacks</TableCell>
                <TableCell align="right">Total diferencia</TableCell>                
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell align="right">{form.sumPieces}</TableCell>
                <TableCell align="right">{form.sumExpected}</TableCell>
                <TableCell align="right">{form.sumPrepacks}</TableCell>
                <TableCell align="right">{form.sumDif}</TableCell>                
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <MaterialTable
          tableRef={tableRef}
          nameEntity="Detalles del SKU"
          columns={[
            { title: 'Sku', field: 'sku', type: 'string' },
            { title: 'PPK', field: 'ppk', type: 'numeric' },
            { title: 'Tienda', field: 'order.storeNumber', type: 'string' },
            { title: 'Prioridad Tienda', field: 'order.storePriority', type: 'numeric' },
            { title: 'Piezas solicitadas', field: 'pieces', type: 'numeric' },
            { title: 'Piezas surtidas', field: 'expected_pieces', type: 'numeric' },
            { title: 'Prepacks', field: 'prepacks', type: 'numeric' },
            {
              title: 'Diferencia',
              field: 'difference',
              type: 'numeric',
              render: rowData => {
                let color = 'green';
                let sign = '+';
                if (rowData.difference === 0) {
                  sign = '';
                }
                if (rowData.difference < 0) {
                  color = 'red';
                  sign = '';
                }
                return <Typography style={{ color }}>{`${sign}${rowData.difference}`}</Typography>;
              },
            },
          ]}
          title="Detalle de distribución por tienda"
          localization={materialTableLocaleES}
          data={form.waveLines}
          options={{
            search: true,
            pageSize: 10,
            padding: 'dense',
            pageSizeOptions: [5, 10, 20, 50, 100],
            exportButton: true,
            exportCsv: (columns, rowData) => {
                let data = [];
                rowData.forEach( function(value, key, array) {
                    let sign = '+';
                    const dif = Math.abs(value.difference);
                    if (value.difference === 0) {
                        sign = '';
                    }
                    if (value.difference > 0) {
                        sign = '+';
                    }
                    data[key] = [
                        value.sku,
                        value.ppk,
                        value.order.storeNumber,
                        value.order.storePriority,
                        value.pieces,
                        value.expected_pieces,
                        value.prepacks,
                        sign + dif
                    ]
                });
                const headerRow = columns.map(col => {
                    if (typeof col.title === 'object') {
                        return col.title.props.text;
                    }
                    return col.title;
                });
                const { exportDelimiter } = tableRef.current.props.options;
                const delimiter = exportDelimiter ? exportDelimiter : ",";
                const csvContent = [headerRow, ...data].map(e => e.join(delimiter)).join("\n");
                const csvFileName = tableRef.current.props.title;
                downloadCsv(csvContent, csvFileName);
            }

          }}
        />

        <Box component="form" className={classes.form}>
          <Box />
          <Box className={classes.bottomSpace}>
            {formOpen && closeClick === '' && initData()}
            {!formOpen && closeClick !== '' && clearData()}
          </Box>
        </Box>
      </Drawer>

      <SnackBarMessage
        open={form.policyAccessError}
        message={form.roleAccessMessage}
        onClose={() => setForm({ ...form, policyAccessError: false })}
        variant="warning"
      />
    </Box>
  );
}

WaveLines.defaultProps = {
  refreshTable: null,
  dataObject: 0,
  waveRef: '',
  waveId: 0,
};

WaveLines.propTypes = {
  refreshTable: PropTypes.func,
  formOpen: PropTypes.bool.isRequired,
  toggleForm: PropTypes.func.isRequired,
  dataObject: PropTypes.number,
  waveRef: PropTypes.string,
  waveId: PropTypes.number,
};
