import React, { useState, useRef, useEffect } from 'react';
import { Box } from '@material-ui/core';
import { Visibility as ViewIcon} from '@material-ui/icons';

// Components
import PermissionsForm from '../components/PermissionsForm';
import PageHeader from '../../../components/PageHeader';
import WithSnackBarMessages from '../../../components/WithSnackBarMessages';

// Security
import Permissions from '../../../AppsPermissions/RolesAndPoliciesAdministratorPermissions';
import { hasAuthority } from '../../AccessValidators';

// Styles
import { AppStyles } from '../../../AppStyles';

// Data Api
import { getAllPolicies } from '../../../api/PoliciesApi';

// Routes
import { APP_ROUTES, USERS_ROUTES } from '../../../Routes';
import DataTable from '../../../components/DataTable';

const breadcrumbsData = [
  { id: 1, label: 'Usuarios', base: APP_ROUTES.USERS },
  { id: 2, label: 'Roles y Políticas', base: APP_ROUTES.USERS + USERS_ROUTES.PERMISSIONS },
  { id: 3, label: 'Permisos', base: APP_ROUTES.USERS + USERS_ROUTES.PERMISSIONS },
];

function PermissionsPage() {
  const appStyles = AppStyles();
  const tableRef = useRef();
  const [openForm, setOpenForm] = useState(false);
  const [dataObject, setDataObject] = useState(0);
  const [canGetAllPolicies, setCanGetAllPolicies] = useState(false);
  const [canGetPolicy, setCanGetPolicy] = useState(false);
  const [canCreatePolicy, setCanCreatePolicy] = useState(false);

  const toggleForm = () => {
    if (tableRef.current) {
      tableRef.current.state.query.page = 0;
      if (openForm) {
        tableRef.current.onQueryChange();
      }
    }
    setOpenForm(!openForm);
    setDataObject(0);
  };

  useEffect(() => {
    hasAuthority(Permissions.GetAllPolicies, setCanGetAllPolicies);
    hasAuthority(Permissions.GetPolicyById, setCanGetPolicy);
    hasAuthority(Permissions.CreatePolicy, setCanCreatePolicy);
  }, []);

  const getPoliciesData = query =>
    canGetAllPolicies
      ? getAllPolicies(true, query.pageSize, query.page, query.search)
          .then(response => ({
            ...query,
            data: response.data,
            page: response.current_page - 1,
            totalCount: response.total,
          }))
          .catch(() => ({
            ...query,
            page: 0,
            pageSize: 15,
            totalCount: 0,
            data: [],
          }))
      : new Promise(resolve => {
          resolve({ ...query, data: [], page: 0, totalCount: 0 });
        });


  useEffect(() => {
    tableRef.current && tableRef.current.onQueryChange();
  }, [canGetAllPolicies]);

  return (
    <Box id="app-users-policies-page" component="main" className={appStyles.pageRoot}>
      <PageHeader
        title="Permisos"
        action={toggleForm}
        breadcrumbsData={breadcrumbsData}
      />

      <PermissionsForm
        formOpen={openForm}
        toggleForm={toggleForm}
        refreshTable={() => tableRef.current && tableRef.current.onQueryChange()}
        dataObject={dataObject}
      />

      <DataTable
        nameEntity="permisos"
        columns={[
          { title: 'Nombre', field: 'name', type: 'string' },
          { title: 'Descripción', field: 'description', type: 'string' },
        ]}
        tableActions={[
          {
            icon: () => (
              <ViewIcon color={'primary'} className="icon-small" />
            ),
            tooltip: canGetPolicy ? 'Detalle' : undefined,
            onClick: (event, data) => {
              setDataObject(data.id);
              setOpenForm(!openForm);
            },
          },
        ]}
        tableRef={tableRef}
        getDataFunction={getPoliciesData}
        canGetAll={canGetAllPolicies}
      />
    </Box>
  );
}

export default WithSnackBarMessages(PermissionsPage);
