import React, { useState, useEffect } from 'react';

// Components
import {
    sortableContainer,
    sortableElement,
} from 'react-sortable-hoc';
import Grid from '@material-ui/core/Grid';
import arrayMove from 'array-move';
import "../../Priority/styles/list.css";

import { useSnackbar } from 'notistack';
import {
    Drawer,
    TextField,
    Typography,
    Box,
    Button,
    CircularProgress,
    IconButton, AppBar, Toolbar, Slide,
} from '@material-ui/core';

import PropTypes from 'prop-types';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import {
    ImportExport as PriorityIcon,
    Close as CloseIcon,
    Edit as EditIcon,
} from '@material-ui/icons';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Autocomplete from "@material-ui/lab/Autocomplete";
import MaterialTable from "material-table";
import materialTableLocaleES from "../../../components/MaterialTableLocaleES";
import {AppStyles} from "../../../AppStyles";


// Data Api
import {
    getAllRoutes
} from '../../../api/WaveServices/WavesApi';

import {
    getStoreById,
    createStore,
    getAllStores,
    updateStoreById
} from '../../../api/StoreService/StoreApi';

const drawerWidth = 900;
const useStyles = makeStyles(theme => ({
    container: {
        width: drawerWidth,
    },
    containerPaper: {
        width: drawerWidth,
        padding: 20,
    },
    header: {
        display: 'flex',
        alignItems: 'center',
    },
    headerIcon: {
        fontSize: 35,
        marginRight: theme.spacing(1),
    },
    headerCloseButton: {
        padding: theme.spacing(1),
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        justifyContent: 'space-between',
    },
    buttonProgress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    selectForm: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },
    selectStyle: {
        minWidth: 195,
        maxWidth: 195,
    },
    pickerStyle: {
        minWidth: 225,
        maxWidth: 225,
    },
    selectAddButton: {
        padding: theme.spacing(1),
        marginTop: theme.spacing(1),
    },
    addRolesPolices: {
        margin: theme.spacing(4),
    },
    card: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    cardContent: {
        padding: theme.spacing(2),
    },
    formCardInput: {
        display: 'flex',
        flexGrow: 1,
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    bottomSpace: {
        paddingTop: theme.spacing(2),
    },
}));

const initialState = {
    storeId: 0,
    nameStore: '',
    numberStore: '',
    routeId: 0,
    rankingStore: 0,
    pblRankingStore: 0,
    sorterRankingStore: 0,
    namePriority: '',
    nameError: false,
    accountDate: moment()
        .add(1, 'M')
        .startOf('day'),
    accountDateError: false,
    accountDateErrorMessage: '',
    credentialDate: moment()
        .add(1, 'M')
        .startOf('day'),
};

const stateInit = {
    enabled: true,
};

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function StoreForm({
                          formOpen,
                          toggleForm,
                          refreshTable,
                          dataObject,
                          canUpdateUser,
                          canGetUserData,
                          canUpdateUserData,
                      }) {
    const appStyles = AppStyles();
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const [isLoading, setIsLoading] = useState(false);
    const [form, setForm] = useState(initialState);
    const [closeClick, setCloseClick] = useState('');
    const [editForm, setEditForm] = useState(false);
    const [state, setState] = useState(stateInit);
    const [openModal, setOpenModal] = useState(false);
    const [allRoutes, setAllRoutes] = useState([]);
    const [newStore, setNewStore] = useState([]);
    const [storesCreate, setStoresCreate] = useState([]);
    const [storesIdCreate, setStoresIdCreate] = useState([]);
    const [storesCreateTwo, setStoresCreateTwo] = useState([]);
    const [storesIdCreateTwo, setStoresIdCreateTwo] = useState([]);


    const messageError = (message, variant) => {
        enqueueSnackbar(message, {
            variant,
        });
    };

    const onSortEndCreateStore = ({oldIndex, newIndex}) => {
        setStoresCreate(arrayMove(storesCreate, oldIndex, newIndex));
        setStoresIdCreate(arrayMove(storesIdCreate, oldIndex, newIndex));
    };

    const onSortEndCreateStoreTwo = ({oldIndex, newIndex}) => {
        setStoresCreateTwo(arrayMove(storesCreateTwo, oldIndex, newIndex));
        setStoresIdCreateTwo(arrayMove(storesIdCreateTwo, oldIndex, newIndex));
    };

    const onSortEnd = ({oldIndex, newIndex}) => {
        setStoresCreate(arrayMove(storesCreate, oldIndex, newIndex));
        setStoresIdCreateTwo(arrayMove(storesIdCreate, oldIndex, newIndex));
    };

    const onSortEndTwo = ({oldIndex, newIndex}) => {
        setStoresCreateTwo(arrayMove(storesCreateTwo, oldIndex, newIndex));
        setStoresIdCreateTwo(arrayMove(storesIdCreateTwo, oldIndex, newIndex));
    };

    const SortableListCreate = sortableContainer(({items}) => {
        return (
            <ul className="SortableList"  style={{height:'65%', width:'45%'}} >
                {items.map((value, index) => (
                    <SortableItemCreate key={`item-${index}`} index={index} value={value} className="sortableHelper"/>
                ))}
            </ul>
        );
    });

    const SortableListCreateTwo = sortableContainer(({items}) => {
        return (
            <ul className="SortableList"  style={{height:'65%', width:'45%'}} >
                {items.map((value, index) => (
                    <SortableItemCreateTwo key={`item-${index}`} index={index} value={value} className="sortableHelper"/>
                ))}
            </ul>
        );
    });

    const SortableList = sortableContainer(({items}) => {
        return (
            <ul className="SortableList"  style={{height:"55%",position:'sticky',width:'45%'}} >
                {items.map((value, index) => (
                    <SortableItem key={`item-${index}`} index={index} value={value} className="sortableHelper" disabled={disableFormControl()}/>
                ))}
            </ul>
        );
    });

    const SortableItemTwo = sortableElement(({value}) =>
        <li className="SortableItem" style={{height:40}} id={disableFormControl() === true ? "disabled" : ""}>{value}</li>
    );

    const SortableListTwo = sortableContainer(({items}) => {
        return (
            <ul className="SortableList"  style={{height:"55%", position:'sticky', width:'40%'}} >
                {items.map((value, index) => (
                    <SortableItem key={`item-${index}`} index={index} value={value} className="sortableHelper" disabled={disableFormControl()}/>
                ))}
            </ul>
        );
    });

    const SortableItemCreate = sortableElement(({value}) =>
        <li className="SortableItem" style={{height:40}} >{value}</li>
    );

    const SortableItemCreateTwo = sortableElement(({value}) =>
        <li className="SortableItem" style={{height:40}}>{value}</li>
    );

    const SortableItem = sortableElement(({value}) =>
        <li className="SortableItem" style={{height:40}} id={disableFormControl() === true ? "disabled" : ""}>{value}</li>
    );


    useEffect(() => {
        //hasAuthority(Permissions.ResetPasswordByEmail, setResetPasswordByEmail);
    }, []);

    const handleChange = event => {
        let value = event.target.checked;

        setState({ ...state, [event.target.name]: value });
        setForm({
            ...form,
            storeId: dataObject,
            [event.target.name]: event.target.value,
        });
        setCloseClick(true);
    };

    const handleSubmit = event => {
        event.preventDefault();
        setIsLoading(true);
        if (dataObject === 0) {
            createStore(
                form.nameStore,
                form.numberStore,
                0,
                form.routeId,
                0,
                0,
            )
                .then(response => {
                    setNewStore(response);
                    setOpenModal(true);
                    getAllStores(1000,1,null, 'ranking', 'asc')
                        .then(response => {
                            let store = [];
                            let storeId = [];
                            response.data.forEach((key, p) => {
                                var desc = key.number+' '+key.name;
                                store.push(desc);
                                storeId.push(key.id);
                            });
                            setStoresCreate(store);
                            setStoresIdCreate(storeId);
                        })
                        .catch((error) => {
                            return Promise.resolve(setStoresCreate([]));
                        });
                    getAllStores(1000,1,null,'sorter_ranking', 'asc')
                        .then(response => {
                            let store = [];
                            let storeId = [];
                            response.data.forEach((key, p) => {
                                var desc = key.number+' '+key.name;
                                store.push(desc);
                                storeId.push(key.id);
                            });
                            setStoresCreateTwo(store);
                            setStoresIdCreateTwo(storeId);
                        })
                        .catch((error) => {
                            return Promise.resolve(setStoresCreate([]));
                        });
                    setOpenModal(true);
                })
                .catch(error => {
                    if (error !== undefined) {
                        setForm({
                            ...form,
                        });
                    } else {
                        messageError('Servicio no disponible.', 'warning');
                        refreshTable();
                    }
                    setIsLoading(false);
                });
        } else {
            updateStoreById(
                form.storeId,
                form.nameStore,
                form.numberStore,
                0,
                form.routeId,
                0,
                storesIdCreate,
                storesIdCreateTwo
            )
                .then(() => {
                    toggleForm();
                    setForm(initialState);
                    setIsLoading(false);
                    messageError('Tienda Actualizada', 'success');
                    refreshTable();
                })
                .catch(error => {
                    if (error !== undefined) {
                        setForm({
                            ...form,
                        });
                    } else {
                        refreshTable();
                        messageError('Servicio no disponible.', 'warning');
                    }
                    setIsLoading(false);
                });
        }
    };

    const updatePriorityCreated = () => {
        updateStoreById(
            newStore.id,
            newStore.name,
            newStore.number,
            newStore.ranking,
            newStore.route_id,
            newStore.pbl_ranking,
            newStore.sorter_ranking,
            storesIdCreate,
            storesIdCreateTwo
        )
            .then(() => {
                toggleForm();
                setForm(initialState);
                setIsLoading(false);
                messageError('Tienda Creada', 'success');
                setOpenModal(false);
                refreshTable();
            })
            .catch(error => {
                if (error !== undefined) {
                    setForm({
                        ...form,
                    });
                } else {
                    refreshTable();
                    messageError('Servicio no disponible.', 'warning');
                }
                setIsLoading(false);
            });
    }

    const clearData = () => {
        setCloseClick('');
        setEditForm(false);
        setState(stateInit);
        setForm({
            ...form,
        });
    };

    const options = allRoutes.map(option => {
        const firstLetter = option.id[0];
        return {
            firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
            ...option,
        };
    });

    const optionStartRoute = () => {
        let selectedRoute = null;
        if (allRoutes.length > 0)
            allRoutes.forEach(route => {
                if (route.id === form.routeId) {
                    selectedRoute = route;
                }
            });
        return selectedRoute;
    };

    const initData = () => {
        if (dataObject === 0) {
            setForm({
                ...form,
                storeId: 0,
                nameStore: '',
                numberStore: '',
                rankingStore: 0,
                pblRankingStore: 0,
                sorterRankingStore: 0,
                routeId: 0,
            });
            getAllRoutes(false, 100, 1, null)
                .then(response => {
                    setAllRoutes(response);
                })
                .catch(error => {
                    console.log(error);
                    setAllRoutes([]);
                });
        } else {
            getStoreById(dataObject)
                .then(response => {
                    setForm({
                        storeId: response.id,
                        nameStore: response.name,
                        numberStore: response.number,
                        routeId: response.route_id
                    });
                })
                .catch(() => {
                    return Promise.resolve(setForm({}));
                });
            getAllRoutes(false, 100, 1, null)
                .then(response => {
                    setAllRoutes(response);
                })
                .catch(error => {
                    console.log(error);
                    setAllRoutes([]);
                });
            getAllStores(1000,1,null,'ranking','asc')
                .then(response => {
                    let store = [];
                    let storeId = [];
                    response.data.forEach((key, p) => {
                        var desc = key.number+' '+key.name;
                        store.push(desc);
                        storeId.push(key.id);
                    });
                    setStoresCreate(store);
                    setStoresIdCreate(storeId);
                })
                .catch((error) => {
                    return Promise.resolve(setStoresCreate([]));
                });
            getAllStores(1000,1,null,'sorter_ranking','asc')
                .then(response => {
                    let store = [];
                    let storeId = [];
                    response.data.forEach((key, p) => {
                        var desc = key.number+' '+key.name;
                        store.push(desc);
                        storeId.push(key.id);
                    });
                    setStoresCreateTwo(store);
                    setStoresIdCreateTwo(storeId);
                })
                .catch((error) => {
                    return Promise.resolve(setStoresCreate([]));
                });
        }
        setCloseClick(false);
        dataObject !== 0 ? setEditForm(false) : setEditForm(true);

    };

    const disableFormControl = () => {
        if (isLoading) {
            return true;
        }
        if (dataObject === 0) {
            return false;
        }
        if (dataObject !== 0 && editForm) {
            return false;
        }
        return true;
    };

    return (
        <Box>
            <Drawer
                anchor="right"
                open={formOpen}
                onClose={toggleForm}
                className={classes.container}
                classes={{ paper: classes.containerPaper }}
            >
                <Box className={classes.header}>
                    <PriorityIcon color="primary" className={classes.headerIcon} />
                    <Box flexGrow={1}>
                        <Typography variant="h5" color="primary">
                            {dataObject === 0 ? 'Nueva Tienda' : 'Actualizar Tienda'}
                        </Typography>
                    </Box>
                    {dataObject !== 0 && (
                        <IconButton
                            disabled={!canUpdateUser}
                            color="primary"
                            className={classes.headerCloseButton}
                            onClick={() => {
                                setEditForm(!editForm);
                            }}
                        >
                            <EditIcon />
                        </IconButton>
                    )}
                    <IconButton
                        color="secondary"
                        className={classes.headerCloseButton}
                        onClick={() => {
                            toggleForm();
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </Box>
                <Box component="form" className={classes.form} onSubmit={handleSubmit}>
                    <Box>
                        <TextField
                            size="small"
                            required
                            name="nameStore"
                            label="Nombre de tienda"
                            margin="normal"
                            variant="outlined"
                            fullWidth
                            disabled={disableFormControl()}
                            value={form.nameStore}
                            onChange={handleChange}
                        />
                        <TextField
                            size="small"
                            required
                            name="numberStore"
                            label="Número de tienda"
                            margin="normal"
                            variant="outlined"
                            fullWidth
                            disabled={disableFormControl()}
                            value={form.numberStore}
                            onChange={handleChange}
                        />
                        <Autocomplete
                            id="combo-box-ordergroup"
                            size="small"
                            options={options}
                            renderOption={option => (
                                <div className={classes.selectOrderGroup}>
                                    <Box className={classes.spaceOption}>
                                        <option value={option.id} name={option.id} key={option.id}>
                                            {`${option.description}`}
                                        </option>
                                    </Box>
                                </div>
                            )}
                            noOptionsText="No hay rutas registradas"
                            disabled={disableFormControl()}
                            getOptionLabel={option =>
                                `${option.description}`
                            }
                            value={optionStartRoute()}
                            autoComplete
                            onChange={(event, value) => {
                                setForm({ ...form, routeId: value?.id || 0 });
                            }}
                            renderInput={params => (
                                <TextField
                                    {...params}
                                    required
                                    label="Rutas"
                                    variant="outlined"
                                    placeholder="Seleccione una ruta"
                                    fullWidth
                                    error={form.orderGroupError}
                                    helperText={form.orderGroupErrorMessage}
                                />
                            )}
                            style={{marginTop:'10px'}}
                        />
                        {dataObject === 0 ? null :
                            [
                                <div>
                                <Grid container spacing={2} style={{width:'100%', marginTop: '10px'}}>
                                    <Grid item xs={6}>
                                        <Typography id={disableFormControl() === true ? "disabled" : ""}>
                                            Arrastra para obtener el orden requerido de RANKING
                                        </Typography>
                                        <SortableList items={storesCreate} onSortEnd={onSortEnd} className="SortableHelper"/>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography id={disableFormControl() === true ? "disabled" : ""}>
                                            Arrastra para obtener el orden requerido de SORTER
                                        </Typography>
                                        <SortableListTwo items={storesCreateTwo} onSortEnd={onSortEndTwo} className="SortableHelper"/>
                                    </Grid>
                                </Grid>
                                </div>
                            ]
                        }
                    </Box>
                    <Box className={classes.bottomSpace}>
                        <Button
                            fullWidth
                            variant="contained"
                            color="primary"
                            type="submit"
                            disabled={disableFormControl()}
                            style={{position: 'static', marginLeft: 'auto', marginRight: 'auto', left: 4, right: 4, textAlign: 'center'}}
                            size="small"
                        >
                            {dataObject === 0 ? 'Guardar Tienda' : 'Actualizar Tienda'}
                        </Button>
                        {isLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
                        {formOpen && closeClick === '' && initData()}
                        {!formOpen && closeClick !== '' && clearData()}
                    </Box>
                </Box>
            </Drawer>
            <Dialog fullScreen open={openModal} onClose={() => setOpenModal(false)} TransitionComponent={Transition}>
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={() => setOpenModal(false)} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" className={classes.title}>
                            Seleccione el orden de RANKING Y SORTER para la tienda {newStore.name}
                        </Typography>
                        <Button
                            variant="contained"
                            onClick={updatePriorityCreated}
                            style={{ marginLeft: "auto" }}
                        >
                            Aceptar
                        </Button>
                    </Toolbar>
                </AppBar>
                <div className={classes.paper}>
                    <Grid container spacing={3} alignItems="flex-end">

                        <Grid item xs={12} lg={12} style={{ textAlign: 'center' }}>
                            <h2 id="transition-modal-title">Lista de orden para {newStore.name}</h2>
                        </Grid>
                        <Grid container spacing={2} style={{marginLeft:'5%', marginTop:'20px'}}>
                            <Grid item  xs={6} lg={6}>
                                <Typography>
                                    Arrastra para obtener el orden requerido de RANKING
                                </Typography>
                                <SortableListCreate items={storesCreate} onSortEnd={onSortEndCreateStore} className="SortableHelper"/>
                            </Grid>
                            <Grid item  xs={6} lg={6} >
                                <Typography>
                                    Arrastra para obtener el orden requerido de SORTER
                                </Typography>
                                <SortableListCreateTwo items={storesCreateTwo} onSortEnd={onSortEndCreateStoreTwo} className="SortableHelper"/>
                            </Grid>

                        </Grid>
                    </Grid>
                </div>
            </Dialog>
        </Box>
    );
}

StoreForm.defaultProps = {
    refreshTable: null,
    dataObject: 0,
    canUpdateUser: false,
    canGetUserData: false,
    canUpdateUserData: false,
};

StoreForm.propTypes = {
    refreshTable: PropTypes.func,
    formOpen: PropTypes.bool.isRequired,
    toggleForm: PropTypes.func.isRequired,
    dataObject: PropTypes.number,
    canUpdateUser: PropTypes.bool,
    canGetUserData: PropTypes.bool,
    canUpdateUserData: PropTypes.bool,
};

export default StoreForm;
