import { apiClientBearerToken } from '../ApiClientConfig';
import Logger from '../../Logger';
import {createWave, page, size} from "../WaveServices/WavesApi";

const Log = new Logger('src/api/SupplyOrderService/SupplyOrderApi.js');


const REPORT_USER_PATH = '/ureport';


export const getSubscriptions = (
    size?: Number,
    page?: Number,
    search?: String,
): Promise<Array<Object>> => {
    return apiClientBearerToken()
        .get(`${REPORT_USER_PATH}/all`, {
            params: {
                size,
                page,
                search: search?.trim(),
            },
        })
        .then(response => {
            return Promise.resolve(response.data);
        })
        .catch(error => {
            Log.error(
                getSubscriptions.name,
                error.message,
                error.response ? error.response : error,
                !(error.response && error.response.status === 404),
            );
            return Promise.reject();
        });
};

export const createSubscription = (
    name: String,
    email: String,
    active: Boolean,
    aws: Boolean,
    subscrited_to: Array
): Promise<Object> => {
    return apiClientBearerToken()
        .post(`${REPORT_USER_PATH}/save`, {
            name,
            email,
            active,
            aws,
            subscrited_to
        })
        .then(response => {
            return Promise.resolve(response.data);
        })
        .catch(error => {
            Log.error(
                createSubscription.name,
                error.message,
                error.response ? error.response : error,
                !(error.response && error.response.status === 404),
            );
            return Promise.reject();
        });
};

export const getSubscriptionById = (id: Number): Promise<Object> => {
    return apiClientBearerToken()
        .get(`${REPORT_USER_PATH}/edit/${id}`)
        .then(response => {
            return Promise.resolve(response.data);
        })
        .catch(error => {
            Log.error(getSubscriptionById.name, error.message, error.response ? error.response : error);
            return Promise.reject();
        });
};

export const updateSuscriptionById = (
    id: Number,
    name: String,
    email: String,
    active: Boolean,
    aws: Boolean,
    subscrited_to: Array
): Promise<Object> => {
    return apiClientBearerToken()
        .post(`${REPORT_USER_PATH}/update`, {
            id,
            name,
            email,
            active,
            aws,
            subscrited_to
        })
        .then(response => {
            return Promise.resolve(response.data);
        })
        .catch(error => {
            return Promise.reject();
        });
};
