import React, { useState, useRef, useEffect } from 'react';
import { Box, Chip, Tabs, Tab, AppBar } from '@material-ui/core';
import { Visibility as ShowIcon } from '@material-ui/icons';

// Components
import MaterialTable from 'material-table';
import PageHeader from '../../../components/PageHeader';
import WithSnackBarMessages from '../../../components/WithSnackBarMessages';
import BufferForm from '../components/BufferForm';

// Styles
import { AppStyles } from '../../../AppStyles';
import materialTableLocaleES from '../../../components/MaterialTableLocaleES';

// Data Apis
import { getAllZones, getZoneNameWave } from '../../../api/WaveServices/WavesApi';

// Routes
import { APP_ROUTES, WAVES_ROUTES } from '../../../Routes';

const breadcrumbsData = [
  { id: 1, label: 'Olas', base: APP_ROUTES.WAVES },
  {
    id: 2,
    label: 'Staging',
    base: APP_ROUTES.WAVES + WAVES_ROUTES.STAGING,
  },
];

function StagingPage() {
  const appStyles = AppStyles();
  const [openForm, setOpenForm] = useState(false);
  const tableRefZone = useRef();
  const [bufferStaging, setBufferStaging] = useState([]);
  const [chips, setChips] = useState([]);

  const tableRef = useRef();
  const [dataObject, setDataObject] = useState(0);
  const [areaValue, setAreaValue] = useState(0);

  const toggleForm = () => {
    if (tableRef.current) {
      tableRef.current.state.query.page = 0;
      if (openForm) {
        tableRef.current.onQueryChange();
      }
    }
    setOpenForm(!openForm);
    setDataObject(0);
  };

  const handleChangeTabTwo = (event, newValue) => {
    setAreaValue(newValue);
    tableRef.current.onQueryChange();
  };

  function a11yProps(index: any) {
    return {
      id: `scrollable-auto-tab-${index}`,
      'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
  }

  useEffect(() => {}, []);

  const parseQueryZones = (query, zoneType) => {
    return getZoneByZoneType(query, zoneType);
  };

  const getZoneByZoneType = (query, zoneType) =>
    getAllZones(5, 1, query.pageSize, query.page + 1)
      .then(response => ({
        ...query,
        data: response.data,
        pageSize: 40,
        page: response.current_page - 1,
        totalCount: response.total,
      }))
      .catch(error => {
        return Promise.reject(error);
      });
  const initData = () => {
    if (bufferStaging.length === 0) {
      getAllZones(areaValue + 5, 1, 15, 1)
        .then(response => {
          setBufferStaging(response);
        })
        .catch(() => {
          setBufferStaging([]);
        });
    }
    if (chips.length === 0) {
      getZoneNameWave(5)
        .then(response => {
          const chip = [
            <Box>
              <h3>Olas en Staging: </h3>
            </Box>,
          ];
          response.forEach((value, index) => {
            const tab = (
              <Chip
                label={value.wave_ref}
                variant="outlined"
                key={`a${index}`}
                color="primary"
                size="small"
              />
            );
            chip.push(tab);
            setChips(chip);
          });
        })
        .catch(() => {
          setChips([
            <Box>
              <h3>Olas en Staging: </h3>
            </Box>,
          ]);
        });
    }
  };

  return (
    <Box
      id="app-routes-page"
      component="main"
      className={appStyles.pageRoot}
      style={{ backgroundColor: 'white', height: '100vh' }}
    >
      {initData()}
      <PageHeader title="Staging" canExecuteButton breadcrumbsData={breadcrumbsData} />
      <BufferForm
        formOpen={openForm}
        toggleForm={toggleForm}
        refreshTable={() => {
          tableRefZone.current.onQueryChange();
        }}
        dataObject={dataObject}
      />
      <AppBar
        position="static"
        color="default"
        style={{ alignItems: 'center', backgroundColor: 'white' }}
      >
        <Tabs
          value={areaValue}
          onChange={handleChangeTabTwo}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="Elija el area"
        >
          <Tab label="STAGING" {...a11yProps(1)} />
        </Tabs>
      </AppBar>
      <MaterialTable
        columns={[
          { title: 'Descripción', field: 'description', type: 'string' },
          { title: 'Código', field: 'code', type: 'string' },
          {
            title: 'Tarima',
            field: 'lpn_transportador',
            type: 'string',
            render: rowData =>
              rowData.lpn_transportador ? rowData.lpn_transportador : '--------------------',
          },
          {
            title: 'Ola',
            field: 'wave_ref',
            type: 'string',
            render: rowData => (rowData.wave_ref ? rowData.wave_ref : '--------------------'),
          },
        ]}
        title="Ubicaciones"
        localization={materialTableLocaleES}
        tableRef={tableRef}
        data={query => parseQueryZones(query, areaValue)}
        actions={[
          rowData => ({
            icon: () => (
              <ShowIcon color={rowData.wave_ref ? 'primary' : 'disabled'} className="icon-small" />
            ),
            disabled: true,
            tooltip: rowData.wave_ref ? 'Ver Tarima' : undefined,
            onClick: (event, rowData) => {
              if (rowData.wave_ref) {
                setDataObject(rowData.pallet_id);
                setOpenForm(!openForm);
              }
            },
          }),
        ]}
        options={{
          search: false,
          pageSize: 20,
          actionsColumnIndex: -1,
          paging: true,
          toolbar: true,
          showTitle: true,
          padding: 'dense',
          pageSizeOptions: [5, 10, 20, 50, 100],
        }}
      />
    </Box>
  );
}

export default WithSnackBarMessages(StagingPage);
