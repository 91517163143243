import React, {useEffect, useState} from 'react';
import {Box, Fab, Grid} from '@material-ui/core';
import { Route, Switch, useRouteMatch, useHistory } from 'react-router-dom';
import { purple } from '@material-ui/core/colors';
// Routes
import { makeStyles } from '@material-ui/core/styles';
import { PICKING_ROUTES } from '../../Routes';

// Styles
import { AppStyles } from '../../AppStyles';

// Components
// import { WithPublicLayout } from '../../components/AppLayout';
import { NotFoundPage } from '../NotFoundPage';
// import BufferPage from './pages/BufferPage';
// import InductionPage from './pages/InductionPage';
import StagingPage from './pages/StagingPage';
// import BufferManagerPage from './pages/BufferManagerPage';
// import StagingManagerPage from "./pages/StagingManagerPage";

// import Permissions from '../../AppsPermissions/StagingPermissions';
// import { hasAuthority } from '../AccessValidators';


const useStyles = makeStyles(theme => ({
  margin: {
    margin: theme.spacing(1),
  },
  button: {
    color: theme.palette.getContrastText(purple[500]),
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
}));

function PickingRouter() {
  const classes = useStyles();
  const { push } = useHistory();
  const appStyles = AppStyles();
  const { path } = useRouteMatch();

  const canAccessToStaging = useState(true);
  // const canAccessToMoving = useState(true);
  // const canAccessToRequest = useState(true);
  // const canAccessToBuffer = useState(true);

  useEffect(() => {
    // hasAuthority(Permissions.HasAccessToStaging, setAccessToStaging);
    // hasAuthority(Permissions.HasAccessToMoving, setAccessToMoving);
    // hasAuthority(Permissions.HasAccessToRequest, setAccessToRequest);
    // hasAuthority(Permissions.HasAccessToBuffer, setAccessToBuffer);
  }, []);

  return (
    <Box id="app-picking-router" component="main" className={appStyles.pageRouter}>
      <Switch>
        <Route exact path={`/staging`} component={StagingPage} />
        {/*<Route exact path={`${path}${PICKING_ROUTES.STAGING_MANAGER}`} component={StagingManagerPage} />
        <Route exact path={`${path}${PICKING_ROUTES.BUFFER}`} component={BufferPage} />
        <Route
          exact
          path={`${path}${PICKING_ROUTES.BUFFER_MANAGER}`}
          component={BufferManagerPage}
        />
        <Route exact path={`${path}${PICKING_ROUTES.INDUCTION}`} component={InductionPage} />*/}
        <Route component={NotFoundPage} />
      </Switch>      
    </Box>
  );
}

export default PickingRouter;
