import React, {useRef, useState, useEffect} from 'react';
import {
    Box, CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle, Drawer,
    TextField,
    Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

// Styles
import { AppStyles } from '../../../AppStyles';
import PageHeader from "../../../components/PageHeader";
import {APP_ROUTES, WAVES_ROUTES} from "../../../Routes";
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import WaveFormDashboard from '../pages/WaveFormDashboard';

import Card from "../../../components/DashboardWaceComponents/Card/Card.js";
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardBody from "../../../components/DashboardWaceComponents/Card/CardBody.js";
import CardHeader from "../../../components/DashboardWaceComponents/Card/CardHeader.js";
import CardIcon from "../../../components/DashboardWaceComponents/Card/CardIcon.js";
import CardFooter from "../../../components/DashboardWaceComponents/Card/CardFooter.js";
import Button from '@material-ui/core/Button';


// API
import {
    getWaveByArea,
    getDataDashboard,
    getWavesToday,
    getWavesWeek,
    getCartonsToday,
    getCartonsWeek,
    verifyPassword,
    forceFinishWave
} from '../../../api/WaveServices/WavesApi';
import { getPermissions } from '../../../api/AuthApi';
import materialTableLocaleES from "../../../components/MaterialTableLocaleES";
import MaterialTable from "material-table";
import {Visibility as ShowIcon} from "@material-ui/icons";
import PanToolIcon from '@material-ui/icons/PanTool';
import {useSnackbar} from "notistack";
import WithSnackBarMessages from "../../../components/WithSnackBarMessages";


const breadcrumbsData = [
    {
        id: 1,
        label: 'Olas',
        base: APP_ROUTES.WAVES + WAVES_ROUTES.WAVES,
    },
    { id: 1, label: 'Dashboard', base: APP_ROUTES.WAVES },
];

const useStyles = makeStyles(theme => ({
    actionDescriptionEdit: {
        '& .MuiInput-root': {
            fontSize: 'small',
        },
    },
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(3),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    typography: {
        textAlign: 'center',
    },
    cardCategory: {
        color: "black",
        fontSize: "14px",
        marginTop: "0",
    },
    cardCategoryWhite: {
        color: "white",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
    },
    cardTitle: {
        color: "gray",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "gray",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    cardTitleWhite: {
        color: "white",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "gray",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    cardFooter : {
        with: "100%",
    },
    cardStyle: {
        height: '50px',
    }
}));

function WavesDashboardPage() {
    const classes = useStyles();
    const appStyles = AppStyles();
    const { enqueueSnackbar } = useSnackbar();
    const [openForm, setOpenForm] = useState(false);
    const tableRef = useRef();
    const tableRef1 = useRef();
    const tableRef2 = useRef();
    const [dataObject, setDataObject] = useState(0);
    const [wavesToday, setWavesToday] = useState(0);
    const [wavesWeek, setWavesWeek] = useState(0);
    const [cartonsToday, setCartonsToday] = useState(0);
    const [cartonsWeek, setCartonsWeek] = useState(0);
    const [piecesToday, setPiecesToday] = useState(0);
    const [piecesWeek, setPiecesWeek] = useState(0);
    const [cartonsTranfer, setCartonsTranfer] = useState(0);
    const [cartonsWithoutTranfer, setCartonsWithoutTranfer] = useState(0);
    const [openPasswordDialog, setOpenPasswordDialog] = useState(false);
    const [password, setPassword] = useState([]);
    const [messagePassword, setMessagePassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [checkedPassword, setCheckedPassword] = useState(true);
    const [userId, setUserId] = useState([]);
    const [permissions, setPermissions] = useState([]);
    const [wave, setWave] = useState(0);

    const messageError = (message, variant) => {
        enqueueSnackbar(message, {
            variant,
        });
    };
    const messageSuccess = (message, variant) => {
        enqueueSnackbar(message, {
            variant,
        });
    };

    useEffect(() => {
        getInitData();
        const intervalData = setInterval(() => {  //assign interval to a variable to clear it.
            initData();
            getDataDashboard().then(response => {
                setWavesToday(response.data.wavesToday);
                setWavesWeek(response.data.wavesWeek);
                setCartonsToday(response.data.cartonsToday);
                setCartonsWeek(response.data.cartonsWeek);
                setPiecesToday(response.data.piecesToday);
                setPiecesWeek(response.data.piecesWeek);
                setCartonsTranfer(response.data.cartonsWithTransfer);
                setCartonsWithoutTranfer(response.data.cartonsWithoutTransfer);
                tableRef.current.onQueryChange();
                tableRef1.current.onQueryChange();
                tableRef2.current.onQueryChange();
            }).catch(e => {
                console.log(e);
            });
        }, 60000);
        return () => clearInterval(intervalData);

    }, []);

    const getInitData = () => {
        initData();
        getDataDashboard().then(response => {
            setWavesToday(response.data.wavesToday);
            setWavesWeek(response.data.wavesWeek);
            setCartonsToday(response.data.cartonsToday);
            setCartonsWeek(response.data.cartonsWeek);
            setPiecesToday(response.data.piecesToday);
            setPiecesWeek(response.data.piecesWeek);
            setCartonsTranfer(response.data.cartonsWithTransfer);
            setCartonsWithoutTranfer(response.data.cartonsWithoutTransfer);
            if (tableRef.current) {
                tableRef.current.state.query.page = 0;
                if (openForm) {
                    tableRef.current.onQueryChange();
                    tableRef1.current.onQueryChange();
                    tableRef2.current.onQueryChange();
                }
            }
        }).catch(e => {
            console.log(e);
        });
    };

    const toggleForm = () => {
        if (tableRef.current) {
            tableRef.current.state.query.page = 0;
            if (openForm) {
                tableRef.current.onQueryChange();
                tableRef1.current.onQueryChange();
                tableRef2.current.onQueryChange();
            }
        }
        setDataObject(0);
        setOpenForm(!openForm);
    };

    const handleChangePassword = (event) => {
        setPassword(event.target.value);
    };

    const handlePasswordDialog = () => {
        setOpenPasswordDialog(false);
        setMessagePassword('');
        setCheckedPassword(true);
    };

    const checkPassword = () => {
        verifyPassword(userId, password)
            .then(response => {
                console.log(password);
                setCheckedPassword(response.status);
                setMessagePassword(response.message);
                setLoading(false);
            })
            .catch(() => {
                console.log('errors');
                setLoading(false);
            });
    };

    const handleSubmit = () => {
        setLoading(true);
        setMessagePassword('');
        forceFinishWave(wave)
            .then(response => {
                setLoading(false);
                messageSuccess(response.message, 'success');
                setOpenPasswordDialog(false);
                setCheckedPassword(true);
                if (tableRef.current) {
                    tableRef.current.state.query.page = 0;
                    tableRef.current.onQueryChange();
                    tableRef1.current.onQueryChange();
                    tableRef2.current.onQueryChange();

                }
            })
            .catch(() => {
                messageError('Error al procesar la solicitud', 'error');
                console.log('errors');
                setLoading(false);
                setOpenPasswordDialog(false);
                setCheckedPassword(true);
            });
    };

    const getWaveArea = (query, area) =>
        getWaveByArea(area, 0)
            .then(response => {                
                return response;
            })
            .then(response => ({
                ...query,
                data: response.data,
                page: 0,
                totalCount: 0,
            }))
            .catch(() => ({
                ...query,
                page: 0,
                pageSize: 15,
                totalCount: 0,
                data: [],
            }));

    const getReportWaveToday = () =>
        getWavesToday()
            .then(response => {
                const contentDisposition = response.headers['content-disposition'];
                const contentType = response.headers['content-type'];
                const filename = 'reporte-olas-hoy';
                const file = new Blob([response.data], { type: contentType });
                // For Internet Explorer and Edge
                if ('msSaveOrOpenBlob' in window.navigator) {
                    window.navigator.msSaveOrOpenBlob(file, filename);
                }
                // For Firefox and Chrome
                else {
                    // Bind blob on disk to ObjectURL
                    const data = URL.createObjectURL(file);
                    const a = document.createElement('a');
                    a.style = 'display: none';
                    a.href = data;
                    a.download = filename;
                    document.body.appendChild(a);
                    a.click();
                    // For Firefox
                    setTimeout(function() {
                        document.body.removeChild(a);
                        // Release resource on disk after triggering the download
                        window.URL.revokeObjectURL(data);
                    }, 100);
                }
            })
            .catch(e => {
                console.log(e);
            });

    const getReportWaveWeek = () =>
        getWavesWeek()
            .then(response => {
                const contentDisposition = response.headers['content-disposition'];
                const contentType = response.headers['content-type'];
                const filename = 'reporte-olas-semana';
                const file = new Blob([response.data], { type: contentType });
                // For Internet Explorer and Edge
                if ('msSaveOrOpenBlob' in window.navigator) {
                    window.navigator.msSaveOrOpenBlob(file, filename);
                }
                // For Firefox and Chrome
                else {
                    // Bind blob on disk to ObjectURL
                    const data = URL.createObjectURL(file);
                    const a = document.createElement('a');
                    a.style = 'display: none';
                    a.href = data;
                    a.download = filename;
                    document.body.appendChild(a);
                    a.click();
                    // For Firefox
                    setTimeout(function() {
                        document.body.removeChild(a);
                        // Release resource on disk after triggering the download
                        window.URL.revokeObjectURL(data);
                    }, 100);
                }
            })
            .catch(e => {
                console.log(e);
            });

    const getReportCartonToday = () =>
        getCartonsToday()
            .then(response => {
                const contentDisposition = response.headers['content-disposition'];
                const contentType = response.headers['content-type'];
                const filename = 'reporte-cartones-hoy';
                const file = new Blob([response.data], { type: contentType });
                // For Internet Explorer and Edge
                if ('msSaveOrOpenBlob' in window.navigator) {
                    window.navigator.msSaveOrOpenBlob(file, filename);
                }
                // For Firefox and Chrome
                else {
                    // Bind blob on disk to ObjectURL
                    const data = URL.createObjectURL(file);
                    const a = document.createElement('a');
                    a.style = 'display: none';
                    a.href = data;
                    a.download = filename;
                    document.body.appendChild(a);
                    a.click();
                    // For Firefox
                    setTimeout(function() {
                        document.body.removeChild(a);
                        // Release resource on disk after triggering the download
                        window.URL.revokeObjectURL(data);
                    }, 100);
                }
            })
            .catch(e => {
                console.log(e);
            });

    const getReportCartonWeek = () =>
        getCartonsWeek()
            .then(response => {
                const contentDisposition = response.headers['content-disposition'];
                const contentType = response.headers['content-type'];
                const filename = 'reporte-cartones-semana';
                const file = new Blob([response.data], { type: contentType });
                // For Internet Explorer and Edge
                if ('msSaveOrOpenBlob' in window.navigator) {
                    window.navigator.msSaveOrOpenBlob(file, filename);
                }
                // For Firefox and Chrome
                else {
                    // Bind blob on disk to ObjectURL
                    const data = URL.createObjectURL(file);
                    const a = document.createElement('a');
                    a.style = 'display: none';
                    a.href = data;
                    a.download = filename;
                    document.body.appendChild(a);
                    a.click();
                    // For Firefox
                    setTimeout(function() {
                        document.body.removeChild(a);
                        // Release resource on disk after triggering the download
                        window.URL.revokeObjectURL(data);
                    }, 100);
                }
            })
            .catch(e => {
                console.log(e);
            });

    const calculatePercent = (sorted_pieces, pieces) => {
        let avg = (sorted_pieces / pieces) * 100;
        let round = avg.toFixed(2);
        return round;
    };

    const checkFullAccess = () => {
        let check = permissions.filter(obj => obj.name === 'Full Access');
        if (check.length === 0) {
            return true
        } else {
            return false
        }
    };

    const initData = () => {
        getPermissions().then(response => {
            setPermissions(response);
            setUserId(response[0].pivot.model_id);
        }).catch(e => {
            console.log(e);
        })
    };

    return (
        <Box id="app-home-waves-page" component="main" className={appStyles.pageRoot}>
            <PageHeader
                title="Dashboard Olas"
                breadcrumbsData={breadcrumbsData}
            />
            <WaveFormDashboard
                formOpen={openForm}
                toggleForm={toggleForm}
                refreshTable={wave => {
                    tableRef.current.onQueryChange();
                }}
                dataObject={dataObject}
            />
            <Grid container spacing={1} style={{marginTop:'20px'}}>
                <Grid item xs={3}>
                    <Card className={classes.root}>
                        <CardHeader color="success" stats icon>
                            <CardIcon color="success">
                                <h3>OLAS HOY</h3>
                            </CardIcon>
                        </CardHeader>
                        <CardBody>
                            <Typography variant="h3" component="h3" align='center' color="secondary">
                                { wavesToday }
                            </Typography>
                        </CardBody>
                        <CardFooter>
                            <Button
                                size="small"
                                onClick={
                                    (event) => {
                                        getReportWaveToday();
                                    }
                                }
                            >
                                Reporte
                            </Button>
                        </CardFooter>
                    </Card>
                </Grid>
                <Grid item xs={3}>
                    <Card className={classes.root}>
                        <CardHeader color="success" stats icon>
                            <CardIcon color="success">
                                <h3>OLAS ESTA SEMANA</h3>
                            </CardIcon>
                        </CardHeader>
                        <CardBody>
                            <Typography variant="h3" component="h3" align='center' color="secondary">
                                { wavesWeek }
                            </Typography>
                        </CardBody>
                        <CardFooter>
                            <Button
                                size="small"
                                onClick={
                                    (event) => {
                                        getReportWaveWeek();
                                    }
                                }
                            >
                                Reporte
                            </Button>
                        </CardFooter>
                    </Card>
                </Grid>
                <Grid item xs={3}>
                    <Card className={classes.root}>
                        <CardHeader color="success" stats icon>
                            <CardIcon color="success">
                                <h3>CAJAS HOY</h3>
                            </CardIcon>
                        </CardHeader>
                        <CardBody>
                            <Typography variant="h3" component="h3" align='center' color="secondary">
                                { cartonsToday }
                            </Typography>
                        </CardBody>
                        <CardFooter>
                            <Button
                                size="small"
                                onClick={
                                    (event) => {
                                        getReportCartonToday();
                                    }
                                }
                            >
                                Reporte
                            </Button>
                        </CardFooter>
                    </Card>
                </Grid>
                <Grid item xs={3}>
                    <Card className={classes.root}>
                        <CardHeader color="success" stats icon>
                            <CardIcon color="success">
                                <h3>CAJAS ESTA SEMANA</h3>
                            </CardIcon>
                        </CardHeader>
                        <CardBody>
                            <Typography variant="h3" component="h3" align='center' color="secondary">
                                { cartonsWeek }
                            </Typography>
                        </CardBody>
                        <CardFooter>
                            <Button
                                size="small"
                                onClick={
                                    (event) => {
                                        getReportCartonWeek();
                                    }
                                }
                            >
                                Reporte
                            </Button>
                        </CardFooter>
                    </Card>
                </Grid>
                <Grid item xs={3}>
                    <Card className={classes.root}>
                        <CardHeader color="success" stats icon>
                            <CardIcon color="success">
                                <h3>PIEZAS HOY</h3>
                            </CardIcon>
                        </CardHeader>
                        <CardBody>
                            <Typography variant="h3" component="h3" align='center' color="secondary">
                                { piecesToday }
                            </Typography>
                        </CardBody>
                        <CardFooter>
                        </CardFooter>
                    </Card>
                </Grid>
                <Grid item xs={3}>
                    <Card className={classes.root}>
                        <CardHeader color="success" stats icon>
                            <CardIcon color="success">
                                <h3>PIEZAS ESTA SEMANA</h3>
                            </CardIcon>
                        </CardHeader>
                        <CardBody>
                            <Typography variant="h3" component="h3" align='center' color="secondary">
                                { piecesWeek }
                            </Typography>
                        </CardBody>
                        <CardFooter>
                        </CardFooter>
                    </Card>
                </Grid>
                <Grid item xs={3}>
                    <Card className={classes.root}>
                        <CardHeader color="success" stats icon>
                            <CardIcon color="success">
                                <h3>TRANSFERENCIAS</h3>
                            </CardIcon>
                        </CardHeader>
                        <CardBody>
                            <Typography variant="h3" component="h3" align='center' color="secondary">
                                { cartonsTranfer }
                            </Typography>
                        </CardBody>
                        <CardFooter>
                        </CardFooter>
                    </Card>
                </Grid>
                <Grid item xs={3}>
                    <Card className={classes.root}>
                        <CardHeader color="success" stats icon>
                            <CardIcon color="danger">
                                <h3>TRFS FALTANTES</h3>
                            </CardIcon>
                        </CardHeader>
                        <CardBody>
                            <Typography variant="h3" component="h3" align='center' color="secondary">
                                { cartonsWithoutTranfer }
                            </Typography>
                        </CardBody>
                        <CardFooter>
                        </CardFooter>
                    </Card>
                </Grid>
                <Grid item xs={12}>
                    <Card>
                        <CardHeader color="success" stats icon>
                            <CardIcon color="success">
                                <h1>SORTER 1</h1>
                            </CardIcon>                            
                            <Typography variant="h6" color="primary">
                                OLAS EN SORTER 1
                            </Typography>                                
                        </CardHeader>
                        <CardBody>
                            <MaterialTable
                                columns={[
                                    {
                                        title: 'OLA',
                                        field: 'id',
                                    },
                                    {
                                        title: 'Piezas Totales',
                                        field: 'pieces',
                                    },
                                    {
                                        title: 'Piezas Procesadas',
                                        field: 'sorted_pieces',
                                    },

                                    { title: 'Piezas Restantes',
                                        field: 'pieces',
                                        render : rowData => {
                                            return rowData.pieces - rowData.sorted_pieces
                                        }

                                    },
                                    {
                                        title: 'Progreso',
                                        field: 'sorted_pieces',
                                        render: rowData => {
                                            let avg = (rowData.sorted_pieces / rowData.pieces) * 100;
                                            let round = avg.toFixed(2);                                            
                                            return (
                                                <div>
                                                    <LinearProgress  variant="determinate" value={parseFloat(round)} />
                                                    <Typography className={classes.typography}>
                                                        {`${round}%`}
                                                    </Typography>
                                                </div>
                                            );
                                        },
                                    },
                                ]}
                                localization={materialTableLocaleES}
                                tableRef={tableRef}
                                data={query => getWaveArea(query, 'SORTER1')}
                                options={{
                                    search: false,
                                    paging: false,
                                    toolbar: false,
                                    padding: 'dense',
                                }}
                                actions={[
                                    rowData => ({
                                        icon: () => <ShowIcon color={rowData.id ? 'primary' : 'disabled'} className="icon-small" />,
                                        tooltip: rowData.id ? 'Ver detalle' : undefined,
                                        onClick: (event, rowData) => {
                                            if(rowData.id ){
                                                setDataObject(rowData.id);
                                                setOpenForm(!openForm);
                                            }
                                        },
                                    }),
                                    rowData => ({
                                        icon: () => <PanToolIcon color={ (calculatePercent(rowData.sorted_pieces, rowData.pieces) < 95) ? 'disabled' : 'primary'} className="icon-small" />,
                                        tooltip: 'Terminar Ola',
                                        onClick: (event, rowData) => {
                                            setWave(rowData.id);
                                            setOpenPasswordDialog(true);
                                        },
                                        disabled : calculatePercent(rowData.sorted_pieces, rowData.pieces) < 95,
                                        hidden: checkFullAccess()
                                    })
                                ]}
                            />
                        </CardBody>
                        <CardFooter>
                        </CardFooter>
                    </Card>
                </Grid>
                <Grid item xs={12}>
                    <Card>
                        <CardHeader color="success" stats icon>
                            <CardIcon color="success">
                                <h1>SORTER 3</h1>
                            </CardIcon>
                            <Typography variant="h6" color="primary">
                                OLAS EN SORTER 3
                            </Typography>
                        </CardHeader>
                        <CardBody>
                            <MaterialTable
                                columns={[
                                    {
                                        title: 'OLA',
                                        field: 'id',
                                    },
                                    {
                                        title: 'Piezas Totales',
                                        field: 'pieces',
                                    },
                                    {
                                        title: 'Piezas Procesadas',
                                        field: 'sorted_pieces',
                                    },

                                    { title: 'Piezas Restantes',
                                        field: 'pieces',
                                        render : rowData => {
                                            return rowData.pieces - rowData.sorted_pieces
                                        }

                                    },
                                    {
                                        title: 'Progreso',
                                        field: 'sorted_pieces',
                                        render: rowData => {
                                            let avg = (rowData.sorted_pieces / rowData.pieces) * 100;
                                            let round = avg.toFixed(2);
                                            return (
                                                <div>
                                                    <LinearProgress  variant="determinate" value={parseFloat(round)} />
                                                    <Typography className={classes.typography}>
                                                        {`${round}%`}
                                                    </Typography>
                                                </div>
                                            );
                                        },
                                    },
                                ]}
                                localization={materialTableLocaleES}
                                tableRef={tableRef1}
                                data={query => getWaveArea(query, 'SORTER3')}
                                options={{
                                    search: false,
                                    paging: false,
                                    toolbar: false,
                                    padding: 'dense',
                                }}
                                actions={[
                                    rowData => ({
                                        icon: () => <ShowIcon color={rowData.id ? 'primary' : 'disabled'} className="icon-small" />,
                                        tooltip: rowData.id ? 'Ver detalle' : undefined,
                                        onClick: (event, rowData) => {
                                            if(rowData.id ){
                                                setDataObject(rowData.id);
                                                setOpenForm(!openForm);
                                            }
                                        },
                                    }),
                                    rowData => ({
                                        icon: () => <PanToolIcon color={ (calculatePercent(rowData.sorted_pieces, rowData.pieces) < 95) ? 'disabled' : 'primary'} className="icon-small" />,
                                        tooltip: 'Terminar Ola',
                                        onClick: (event, rowData) => {
                                            setWave(rowData.id);
                                            setOpenPasswordDialog(true);
                                        },
                                        disabled : calculatePercent(rowData.sorted_pieces, rowData.pieces) < 95,
                                        hidden: checkFullAccess()
                                    })
                                ]}
                            />
                        </CardBody>
                        <CardFooter>
                        </CardFooter>
                    </Card>
                </Grid>
                <Grid item xs={12}>
                    <Card>
                        <CardHeader color="success" stats icon>
                            <CardIcon color="success">
                                <h1>PBL</h1>
                            </CardIcon>
                            <Typography variant="h6" color="primary">
                                OLAS EN PBL
                            </Typography>
                        </CardHeader>
                        <CardBody>
                            <MaterialTable
                                columns={[
                                    {
                                        title: 'OLA',
                                        field: 'id',
                                    },
                                    {
                                        title: 'Piezas Totales',
                                        field: 'pieces',
                                    },
                                    {
                                        title: 'Piezas Procesadas',
                                        field: 'sorted_pieces',
                                    },

                                    { title: 'Piezas Restantes',
                                        field: 'pieces',
                                        render : rowData => {
                                            return rowData.pieces - rowData.sorted_pieces
                                        }

                                    },
                                    {
                                        title: 'Progreso',
                                        field: 'sorted_pieces',
                                        render: rowData => {
                                            let avg = (rowData.sorted_pieces / rowData.pieces) * 100;
                                            let round = avg.toFixed(2);
                                            return (
                                                <div>
                                                    <LinearProgress  variant="determinate" value={parseFloat(round)} />
                                                    <Typography className={classes.typography}>
                                                        {`${round}%`}
                                                    </Typography>
                                                </div>
                                            );
                                        },
                                    },
                                ]}
                                title=" "
                                localization={materialTableLocaleES}
                                tableRef={tableRef2}
                                data={query => getWaveArea(query, 'PTL')}
                                options={{
                                    search: false,
                                    paging: false,
                                    toolbar: false,
                                    padding: 'dense',
                                }}
                                actions={[
                                    rowData => ({
                                        icon: () => <ShowIcon color={rowData.id ? 'primary' : 'disabled'} className="icon-small" />,                                        
                                        tooltip: rowData.id ? 'Ver detalle' : undefined,
                                        onClick: (event, rowData) => {
                                            if(rowData.id ){
                                                setDataObject(rowData.id);
                                                setOpenForm(!openForm);
                                            }
                                        },
                                    }),
                                    rowData => ({
                                        icon: () => <PanToolIcon color={ (calculatePercent(rowData.sorted_pieces, rowData.pieces) < 95) ? 'disabled' : 'primary'} className="icon-small" />,
                                        tooltip: 'Terminar Ola',
                                        onClick: (event, rowData) => {
                                            setWave(rowData.id);
                                            setOpenPasswordDialog(true);
                                        },
                                        disabled : calculatePercent(rowData.sorted_pieces, rowData.pieces) < 95,
                                        hidden: checkFullAccess()
                                    })
                                ]}
                            />
                        </CardBody>
                        <CardFooter >
                        </CardFooter>
                    </Card>
                </Grid>
            </Grid>
            <Dialog
                disableBackdropClick
                disableEscapeKeyDown
                maxWidth="xs"
                onEntering={undefined}
                aria-labelledby="confirmation-dialog-title"
                open={openPasswordDialog}
            >
                <DialogTitle id="confirmation-dialog-title">{`Terminar Ola : ${wave}`}</DialogTitle>
                <DialogContent dividers>
                    <DialogContentText id="alert-dialog-description">
                        Para continuar con el proceso, ingrese su contraseña.
                    </DialogContentText>
                    <TextField
                        id="input-password"
                        name="input-password"
                        fullWidth
                        type="password"
                        onChange={handleChangePassword}
                    ></TextField>
                    <Button className={classes.buttonValidate} color="primary" onClick={checkPassword}>Validar</Button>
                    <Typography className={classes.messagePwd} style={checkedPassword == false ? { color: 'green'} : {color: 'red'} } variant="caption" display="block" gutterBottom>
                        { messagePassword }
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handlePasswordDialog} color="primary">
                        Cerrar
                    </Button>
                    <Button onClick={handleSubmit} color="primary" disabled={checkedPassword || loading}>
                        Terminar
                        {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
}

export default WithSnackBarMessages(WavesDashboardPage);