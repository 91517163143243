import { version } from '../package.json';

const LOCAL = {
  API_BASE_URL: 'https://dev-api.ccp.supply/api/v1',
  BASE_URL: 'https://dev-api.ccp.supply',
  PRODUCTS_BASE_URL: 'https://localhost:3001',
  COOKIE_DOMAIN: 'dev.ccp.supply',
  COOKIE_ENCRYPT: true,
  COOKIE_SECRET: 'uwbZ4HyyXBQBmeMW',
  COOKIE_USER_TOKEN: 'HWtcXsA7rLv2XQyL',
  CLIENT_ID: 2,
  CLIENT_SECRET: 'LE4EAgmyIkqFPZIBzOx2mO2qKEVVpQkBr6ms9x11',
  SENTRY_DSN: '',
};

const LOCALSSL = {
  API_BASE_URL: 'https://dev-api.ccp.supply/api/v',
  BASE_URL: 'https://dev-api.ccp.supply',
  PRODUCTS_BASE_URL: 'http://localhost:3001',
  COOKIE_DOMAIN: 'dev.ccp.supply',
  COOKIE_ENCRYPT: true,
  COOKIE_SECRET: 'uwbZ4HyyXBQBmeMW',
  COOKIE_USER_TOKEN: 'HWtcXsA7rLv2XQyL',
  CLIENT_ID: 2,
  CLIENT_SECRET: 'LE4EAgmyIkqFPZIBzOx2mO2qKEVVpQkBr6ms9x11',
  SENTRY_DSN: '',
};

const DEVELOPMENT = {
  API_BASE_URL: 'https://dev-api.ccp.supply/api/v1',
  BASE_URL: 'https://dev-api.ccp.supply',
  COOKIE_DOMAIN: 'dev.ccp.supply',
  COOKIE_ENCRYPT: true,
  COOKIE_SECRET: 'uwbZ4HyyXBQBmeMW',
  COOKIE_USER_TOKEN: 'HWtcXsA7rLv2XQyL',
  CLIENT_ID: 2,
  CLIENT_SECRET: 'LE4EAgmyIkqFPZIBzOx2mO2qKEVVpQkBr6ms9x11',
  SENTRY_DSN: '',
};

const PRODUCTION = {
  API_BASE_URL: 'http://harrier.agarcia.com.mx/api/v1',
  BASE_URL: 'http://harrier.agarcia.com.mx',
  COOKIE_DOMAIN: '172.16.38.2',
  COOKIE_ENCRYPT: true,
  COOKIE_SECRET: 'VsTxEqcnL3t76r9DwLfYJdZER9M4Vxavmw5kq7Xt8AYnmBZ22ATEXFuasNd4JT5b',
  COOKIE_USER_TOKEN: 'dkWTQZtpNjV5ZcVDFa8sJ6xEXdHenMn8DVqGyNy2EdBUYMH8AjM5Y3SEnWjmna7U',
  CLIENT_ID: 2,
  CLIENT_SECRET: 'LE4EAgmyIkqFPZIBzOx2mO2qKEVVpQkBr6ms9x11',
  SENTRY_DSN: '',
};

let config = {};

switch (process.env.REACT_APP_PROFILE) {
  case 'DEVELOPMENT':
    config = DEVELOPMENT;
    break;

  case 'PRODUCTION':
    config = PRODUCTION;
    break;

  case 'LOCALSSL':
    config = LOCALSSL;
    break;

  default:
    config = LOCAL;
    break;
}

export default {
  APP_VERSION: version,
  API_CLIENT_ID: 'web',
  API_CLIENT_SECRET: '4TvsdRgdCtYM7QD6',
  CHANGE_LOG_BASE_URL: '',
  COOKIE_DOMAIN: 'harrier.agarcia.com.mx',
  ...config,
};

