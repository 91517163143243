export default {
  body: {
    emptyDataSourceMessage: 'No se encontraron registros',
    editRow: {
      deleteText: '¿Seguro que deseas eliminar este registro?',
      cancelTooltip: 'Cancelar',
      saveTooltip: 'Guardar',
    },
    addTooltip: 'Agregar',
    deleteTooltip: 'Eliminar',
    editTooltip: 'Editar',
  },
  toolbar: {
    searchTooltip: 'Buscar',
    searchPlaceholder: 'Búsqueda en tabla',
    exportName: 'Exportar como CSV',
    exportTitle: 'Exportar',
    exportAriaLabel: 'Exportar',
    showColumnsTitle: 'Mostrar Columnas',
    showColumnsAriaLabel: 'Mostrar Columnas',
    nRowsSelected: '{0} fila(s) seleccionadas',
  },
  header: {
    actions: 'Acciones',
  },
  pagination: {
    labelRowsSelect: 'Filas',
    labelDisplayedRows: ' {from}-{to} de {count}',
    firstTooltip: 'Primera',
    previousTooltip: 'Anterior',
    nextTooltip: 'Siguiente',
    lastTooltip: 'Ultima',
  },
};
