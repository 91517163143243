import React, { useState } from 'react';
import {
    Drawer,
    Typography,
    Box,
    Button,
    Table,
    TableHead,
    TableContainer,
    TableBody,
    TableCell,
    TableRow,
    CircularProgress, IconButton,
} from '@material-ui/core';
import LibraryAddCheckIcon from '@material-ui/icons/LibraryAddCheck';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import MaterialTable from 'material-table';
import materialTableLocaleES from '../../../components/MaterialTableLocaleES';

// Components
import { getWaveInventory, registerWave, verifyStockAgain, getDetailOrder } from '../../../api/WaveServices/WavesApi';
import SnackBarMessage from '../../../components/SnackBarMessage';
import {Close as CloseIcon, Edit as EditIcon, ImportExport as PriorityIcon} from "@material-ui/icons";

const drawerWidth = 1050;
const useStyles = makeStyles(theme => ({
    container: {
        width: drawerWidth,
    },
    containerPaper: {
        width: drawerWidth,
        padding: 20,
    },
    buttonProgress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    smallColor: {
        width: theme.spacing(3),
        height: theme.spacing(3),
    },
    header: {
        display: 'flex',
        alignItems: 'center',
    },
    headerIcon: {
        fontSize: 35,
        marginRight: theme.spacing(1),
    },
    headerCloseButton: {
        padding: theme.spacing(1),
    },
}));

const initialState = {
    linesData: []
};

export default function ReportSupplyOrderForm({
                                          formOpen,
                                          toggleForm,
                                          refreshTable,
                                          dataObject,
                                          nameObject,
                                      }) {
    const classes = useStyles();
    const [isLoading, setIsLoading] = useState(false);
    const [openLinesDrawer, setOpenLinesDrawer] = useState(false);
    const [dataLinesDrawer, setDataLinesDrawer] = useState(0);
    const [form, setForm] = useState(initialState);
    const [closeClick, setCloseClick] = useState('');
    const tableRef = React.createRef();

    const clearData = () => {
        setForm(initialState);
        setCloseClick('');
        setIsLoading(true);
    };

    const toggleLinesDrawer = () => {
        setDataLinesDrawer(0);
        setOpenLinesDrawer(!setOpenLinesDrawer);
    };

    const downloadCsv = (data, fileName) => {
        const finalFileName = fileName.endsWith(".csv") ? fileName : `${fileName}.csv`;
        const a = document.createElement("a");
        a.href = URL.createObjectURL(new Blob([data], { type: "text/csv" }));
        a.setAttribute("download", finalFileName);
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    };

    const initData = () => {
        setIsLoading(true);
        getDetailOrder(dataObject)
            .then(response => {
                setForm({
                    linesData: response.data,
                });
                setIsLoading(false);
            })
            .catch(error => {
                console.log(error);
                setIsLoading(false);
            });
        setCloseClick(false);
    };

    return (
        <Box>
            <Drawer
                anchor="right"
                open={formOpen}
                onClose={toggleForm}
                className={classes.container}
                classes={{ paper: classes.containerPaper }}
            >
                <Box className={classes.header}>
                    <PriorityIcon color="primary" className={classes.headerIcon} />
                    <Box flexGrow={1}>
                        <Typography variant="h5" color="primary">
                            Reporte orden de surtido
                        </Typography>
                    </Box>
                    <IconButton
                        color="secondary"
                        className={classes.headerCloseButton}
                        onClick={() => {
                            toggleForm();
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </Box>
                <MaterialTable
                    tableRef={tableRef}
                    columns={[
                        { title: 'SKU', field: 'sku' },
                        { title: 'Estilo', field: 'style' },
                        { title: 'Total Piezas', field: 'sumpieces' },
                    ]}
                    title={`Detalle de orden de surtido`}
                    isLoading={isLoading}
                    localization={materialTableLocaleES}
                    data={form.linesData}
                    options={{
                        pageSize: 25,
                        padding: 'dense',
                        search: true,
                        pageSizeOptions: [5, 10, 20, 50, 100],
                        exportButton: true,
                        exportCsv: (columns, rowData) => {
                            let data = [];
                            rowData.forEach( function(value, key, array) {
                                data[key] = [
                                    value.sku,
                                    value.style,
                                    value.sumpieces,
                                ]
                            });
                            const headerRow = columns.map(col => {
                                if (typeof col.title === 'object') {
                                    return col.title.props.text;
                                }
                                return col.title;
                            });
                            const { exportDelimiter } = tableRef.current.props.options;
                            const delimiter = exportDelimiter ? exportDelimiter : ",";
                            const csvContent = [headerRow, ...data].map(e => e.join(delimiter)).join("\n");
                            downloadCsv(csvContent, nameObject);
                        }
                    }}
                />

                <Box component="form" className={classes.form}>
                    <Box />
                    <Box className={classes.bottomSpace}>
                        {formOpen && closeClick === '' && initData()}
                        {!formOpen && closeClick !== '' && clearData()}
                    </Box>
                </Box>
            </Drawer>

            <SnackBarMessage
                open={form.policyAccessError}
                message={form.roleAccessMessage}
                onClose={() => setForm({ ...form, policyAccessError: false })}
                variant="warning"
            />
        </Box>
    );
}

ReportSupplyOrderForm.defaultProps = {
    refreshTable: null,
    dataObject: 0,
    nameObject : '',
};

ReportSupplyOrderForm.propTypes = {
    refreshTable: PropTypes.func,
    formOpen: PropTypes.bool.isRequired,
    toggleForm: PropTypes.func.isRequired,
    dataObject: PropTypes.number,
    nameObject: PropTypes.string,
};

