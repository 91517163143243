import { apiClientBasicAuth, apiClientBearerToken } from './ApiClientConfig';
import { setCookie, getCookie, removeCookie } from '../AppCookies';
import AppConfig from '../AppConfig';
import Logger from '../Logger';

const Log = new Logger('src/api/AuthApi.js');

const AUTH_PATH = '/oauth';
const USER_PATH = '/user';
const USER_PATH_API = '/api/user';

const RESET_PASSWORD = '/api/gettoken';
const CHANGE_PASSWORD = '/api/resetpassword';

export const userHasToken = (): Boolean => {
  if (getCookie('user_token')) {
    return true;
  }
  return false;
};

export const getUserToken = (username: String, password: String): Promise<Object> => {
  const formData = new FormData();
  formData.set('grant_type', 'password');
  formData.set('username', username);
  formData.set('password', password);
  formData.set('client_id', AppConfig.CLIENT_ID);
  formData.set('client_secret', AppConfig.CLIENT_SECRET);
  return apiClientBasicAuth
    .post(`${USER_PATH_API}/token`, formData)
    .then(response => {
      setCookie('user_token', response.data);
      return Promise.resolve();
    })
    .catch(error => {
      removeCookie('user_token');
      Log.error(
        getUserToken.name,
        error.message,
        error.response ? error.response : error,
        error.response ? !error.response.status === 401 : true,
      );
      if (error.response) {
        switch (error.response.data.message) {
         case 'User does not exist':
            return Promise.reject(new Error('Cuenta deshabilitada, contacte con su administrador.'));
          case 'User account is locked':
            return Promise.reject(new Error('Cuenta bloqueada, contacte con su administrador.'));
          case 'User account has expired':
            return Promise.reject(new Error('Cuenta expirada, contacte con su administrador.'));
          case 'Password missmatch':
            return Promise.reject(new Error('Usuario y/o Contraseña Incorrectas.'));
          default:
            return Promise.reject(new Error('Servicio no disponible.'));
        }
      }
      return Promise.reject(new Error('Servicio no disponible.'));
    });
};

export const getTokenResetPassword = (email: String) => {
      return apiClientBasicAuth
      .post(RESET_PASSWORD, {
          email: email
      })
      .then(response => {
        return Promise.resolve(response);
      })
      .catch(error => {
          return Promise.reject(error.response);
      });
};

export const resetPassword = (password: String, passcordConfirmation: String, token: String) => {
  return apiClientBasicAuth
      .post(CHANGE_PASSWORD, {
          token : token,
          password: password,
          password_confirmation: passcordConfirmation
      })
      .then(response => {
          return Promise.resolve(response);
      })
      .catch(error => {
          return Promise.reject(error.response.data);
      });
};

export const revokeToken = (): void => {
  apiClientBearerToken()
    .delete(`${AUTH_PATH}/revoke`)
    .catch(error => {
      Log.error(
        revokeToken.name,
        error.message,
        error.response ? error.response : error,
        error.response ? !error.response.status === 401 : true,
      );
    });
  removeCookie('user_token');
};

const handleError = (error: Object, methodName: String) => {
  if (error.response && error.response.status === 401) {
    revokeToken();
    window.location.reload();
  } else {
    Log.error(
      methodName,
      error.message,
      error.response ? error.response : error,
      error.response ? !error.response.status === 401 : true,
    );
  }
};

export const validateAppAccess = (appKey: String): Promise<Object> => {
  return apiClientBearerToken()
    .get(`${AUTH_PATH}/me/hasAppAccess`, {
      params: {
        appKey,
      },
    })
    .then(response => {
      return Promise.resolve(response.data.access);
    })
    .catch(error => {
      handleError(error, validateAppAccess.name);
      return Promise.reject();
    });
};

export const checkRol = (): Promise<Object> => {
  return apiClientBearerToken()
    //.get(`${AUTH_PATH}/me/hasAuthority`, {
    .get(`${AUTH_PATH}/me/hasAuthority`, {
      params: {        
      },
    })
    .then(response => {
      return Promise.resolve(response.data.rol);
    })
    .catch(error => {
      handleError(error, validateAuthority.name);
      return Promise.reject();
    });
};

export const checkPermission = (match: String): Promise<Object> => {
  return apiClientBearerToken()    
    .get(`${USER_PATH}/permission`, {
      params: {
        match
      },
    })
    .then(response => {
      return Promise.resolve(response.data);
    })
    .catch(error => {
      handleError(error, checkPermission.name);
      return Promise.reject();
    });
};

export const getPermissions = (): Promise<Object> => {
  return apiClientBearerToken()    
    .get(`${USER_PATH}/getpermissions`, {})
    .then(response => {
      return Promise.resolve(response.data);
    })
    .catch(error => {
      handleError(error, checkPermission.name);
      return Promise.reject();
    });
};

export const validateAuthority = (authority: String): Promise<Object> => {
  return apiClientBearerToken()
    //.get(`${AUTH_PATH}/me/hasAuthority`, {
    .get(`${AUTH_PATH}/me/hasAuthority`, {
      params: {
        authority,
      },
    })
    .then(response => {
      return Promise.resolve(response.data.access);
    })
    .catch(error => {
      handleError(error, validateAuthority.name);
      return Promise.reject();
    });
};
