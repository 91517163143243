import React from 'react';
import { Box } from '@material-ui/core';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

// Styles
import { AppStyles } from '../../AppStyles';

// Components
import { WithPublicLayout } from '../../components/AppLayout';
import ProfilePage from './pages/ProfilePage';
import { NotFoundPage } from '../NotFoundPage';

function ProfileRouter() {
    const appStyles = AppStyles();
    const { path } = useRouteMatch();

    return (
        <Box id="app-users-router" component="main" className={appStyles.pageRouter}>
            <Switch>
                <Route exact path={path} component={ProfilePage} />
                <Route component={NotFoundPage} />
            </Switch>
        </Box>
    );
}

export default WithPublicLayout(ProfileRouter);
