import React, { useState } from 'react';
import {
  Drawer,
  ListItem,
  List,
  ListItemIcon,
  ListItemText,
  Collapse,
  Box,
  Divider,
  Fab,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory, useRouteMatch } from 'react-router-dom';
import {
  Security as SecurityIcon,
  People as UsersIcon,
  ExpandLess as LessIcon,
  ExpandMore as MoreIcon,
  Beenhere as PoliciesIcon,
  HowToReg as RolesIcon,
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
  AssignmentTurnedIn as AssignmentTurnedInIcon
} from '@material-ui/icons';
import clsx from 'clsx';

// Routes
import { USERS_ROUTES } from '../../../Routes';

const drawerWidth = 220;
const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: theme.palette.primary.contrastText,
    left: 'auto',
  },
  nestedItemList: {
    paddingLeft: theme.spacing(4),
  },
  content: {
    flexGrow: 1,
    paddingTop: theme.spacing(4),
    transition: theme.transitions.create('margin'),
    marginLeft: -drawerWidth,
    width: theme.spacing(1),
  },
  contentShift: {
    transition: theme.transitions.create('margin'),
    marginLeft: 0,
    width: theme.spacing(1),
  },
  fabOpen: {
    transition: theme.transitions.create(['left', 'background-color', 'color']),
    position: 'fixed',
    height: 36,
    width: 36,
    left: 58 + drawerWidth,
    zIndex: 1201,
    '&:hover': {
      color: 'white',
      backgroundColor: theme.palette.primary.main,
    },
  },
  fabClose: {
    transition: theme.transitions.create(['left', 'background-color', 'color']),
    position: 'fixed',
    height: 36,
    width: 36,
    left: 58,
    zIndex: 1201,
    '&:hover': {
      color: 'white',
      backgroundColor: theme.palette.primary.main,
    },
  },
}));

export default function UsersDrawer() {
  const [openRolesPolicies, setOpenRolesPolicies] = useState(false);
  const classes = useStyles();
  const [open, setOpen] = useState(true);
  const { push } = useHistory();
  const { url } = useRouteMatch();

  const handleClickRolesAndPolicies = () => {
    setOpenRolesPolicies(!openRolesPolicies);
  };

  const handleDrawerClose = () => {
    setOpen(!open);
  };

  return (
    <Box className={classes.root}>
      <Drawer
        id="app-users-router-drawer"
        className={classes.drawer}
        variant="persistent"
        open={open}
        onClose={handleDrawerClose}
        classes={{
          paper: classes.drawerPaper,
        }}
        anchor="left"
      >
        <List disablePadding>
          <ListItem button onClick={() => push(`${url}${USERS_ROUTES.ADMINISTRATORS}`)}>
            <ListItemIcon>
              <UsersIcon color="primary" />
            </ListItemIcon>
            <ListItemText primary="Administradores" />
          </ListItem>

          <Box>
            <ListItem button onClick={handleClickRolesAndPolicies}>
              <ListItemIcon>
                <SecurityIcon color="primary" />
              </ListItemIcon>
              <ListItemText primary="Roles y Políticas" />
              {openRolesPolicies ? <LessIcon color="primary" /> : <MoreIcon color="primary" />}
            </ListItem>
            <Collapse in={openRolesPolicies} timeout="auto" unmountOnExit>
              <List disablePadding>
                <ListItem
                    button
                    onClick={() => push(`${url}${USERS_ROUTES.PERMISSIONS}`)}
                    className={classes.nestedItemList}
                >
                  <ListItemIcon>
                    <AssignmentTurnedInIcon color="primary" />
                  </ListItemIcon>
                  <ListItemText primary="Permisos" />
                </ListItem>
              </List>
            </Collapse>
          </Box>
        </List>
      </Drawer>
      <Box
        component="main"
        className={clsx(classes.content, {
          [classes.contentShift]: open,
        })}
      >
        <Box>
          <Divider orientation="vertical" variant="inset" absolute light />
          <Fab
            onClick={handleDrawerClose}
            className={clsx(classes.fabOpen, {
              [classes.fabClose]: !open,
            })}
            size="small"
          >
            {open ? <ChevronLeftIcon fontSize="small" /> : <ChevronRightIcon fontSize="small" />}
          </Fab>
        </Box>
      </Box>
    </Box>
  );
}
