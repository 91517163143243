import React, { useState, useRef } from 'react';
import {
  Typography,
  TextField,
  Button,
  InputAdornment,
  Card,
  CardContent,
  Container,
  CardActions,
  CircularProgress,
} from '@material-ui/core';
import {
  ListAlt as ListAltIcon,
  Print as PrintIcon,
} from '@material-ui/icons';
import { useSnackbar } from 'notistack';
import { makeStyles } from '@material-ui/core/styles';
// Components
import WithSnackBarMessages from '../../../components/WithSnackBarMessages';
import { scannerBox } from '../../../api/CartonService/CartonApi';
// Styles

const useStyles = makeStyles(theme => ({
  title: {
    marginBottom: theme.spacing(2),
  },
  divImage: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
  },
  imageCard: {
    width: 140,
    height: 140,
    resizeMode: 'center',
    alignSelf: 'center',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  wrapper: {
    position: 'relative',
  },
  cardForm: {
    marginTop: theme.spacing(1),
  },
  forgot: {
    fontSize: 12,
  },
}));

function PrintPage() {
  const classes = useStyles();
  const textInput = useRef();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [printForm, setPrintForm] = useState({
    barcode: '',
    ipAdress: '',
  });

  const handleChange = event => {
    setPrintForm({
      ...printForm,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = event => {
    event.preventDefault();    
    setIsLoading(true);
    scannerBox(printForm.barcode, printForm.ipAdress)
      .then(response => {        
        enqueueSnackbar(response.message, { variant: 'success' });
        setPrintForm({...printForm, barcode: ''});
        setIsLoading(false);
      })
      .catch(error => {
        enqueueSnackbar('No se encontró Box Id asociado al código escaneado.', { variant: 'error' });
        setIsLoading(false);
      });
    textInput.current.focus();
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSubmit(event);      
    }
  }

  return (
    <Container id="app-login-page" maxWidth="xs">
      <Typography variant="h4" align="center" className={classes.title}>
        Impresion de etiquetas
      </Typography>
      <Card component="form" onSubmit={handleSubmit} className={classes.cardForm}>
        <CardContent>
          <TextField
            ref={textInput}
            name="barcode"
            label="BoxId"
            value={printForm.barcode}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            fullWidth
            variant="outlined"
            margin="normal"            
            type="text"            
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <ListAltIcon />
                </InputAdornment>
              )
            }}
          />
          <TextField            
            name="ipAdress"
            variant="outlined"
            fullWidth
            margin="normal"
            type="text"
            label="IP de Impresora"
            value={printForm.ipAdress}            
            onChange={handleChange}            
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <PrintIcon />
                </InputAdornment>
              ),
            }}
          />
        </CardContent>
        <CardActions className={classes.wrapper}>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            type="submit"
            disabled={isLoading}
          >
            Imprimir
          </Button>
          {isLoading && (
            <CircularProgress
              size={24}
              className={classes.buttonProgress}
              style={{ marginLeft: -8 }}
            />
          )}
        </CardActions>        
      </Card>
    </Container>
  );
}

export default WithSnackBarMessages(PrintPage);
