import React, { useState, useEffect } from 'react';

// Components
import {
    sortableContainer,
    sortableElement,    
} from 'react-sortable-hoc';

import arrayMove from 'array-move';
import "../styles/list.css";

import { useSnackbar } from 'notistack';
import {
    Drawer,
    TextField,
    Typography,
    Box,
    Button,
    CircularProgress,        
    IconButton,    
} from '@material-ui/core';

import PropTypes from 'prop-types';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import {
    ImportExport as PriorityIcon,
    Close as CloseIcon,    
    Edit as EditIcon,    
} from '@material-ui/icons';

// Data Api
import {
    createPriority, getAllPriorities,
    getpriorityById,
    updatePriorityById,
} from '../../../api/PriorityServices/PriorityApi';

const drawerWidth = 500;
const useStyles = makeStyles(theme => ({
    container: {
        width: drawerWidth,
    },
    containerPaper: {
        width: drawerWidth,
        padding: 20,
    },
    header: {
        display: 'flex',
        alignItems: 'center',
    },
    headerIcon: {
        fontSize: 35,
        marginRight: theme.spacing(1),
    },
    headerCloseButton: {
        padding: theme.spacing(1),
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        justifyContent: 'space-between',
    },
    buttonProgress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    selectForm: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },
    selectStyle: {
        minWidth: 195,
        maxWidth: 195,
    },
    pickerStyle: {
        minWidth: 225,
        maxWidth: 225,
    },
    selectAddButton: {
        padding: theme.spacing(1),
        marginTop: theme.spacing(1),
    },
    addRolesPolices: {
        margin: theme.spacing(4),
    },
    card: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    cardContent: {
        padding: theme.spacing(2),
    },
    formCardInput: {
        display: 'flex',
        flexGrow: 1,
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    bottomSpace: {
        paddingTop: theme.spacing(2),
    },
}));

const initialState = {
    priorityId : 0,
    namePriority: '',
    orderPriority : 0,
    nameError: false,
    UserNameErrorMessage: '',
    accountDate: moment()
        .add(1, 'M')
        .startOf('day'),
    accountDateError: false,
    accountDateErrorMessage: '',
    credentialDate: moment()
        .add(1, 'M')
        .startOf('day'),
    credentialDateError: false,
    credentialDateErrorMessage: '',
};

const stateInit = {
    enabled: true,
    accountNonExpired: false,
    credentialsNonExpired: false,
    accountNonLocked: false,
};


function PriorityForm({
   formOpen,
   toggleForm,
   refreshTable,
   dataObject,
   canUpdateUser,
   canGetUserData,
   canUpdateUserData,
}) {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const [isLoading, setIsLoading] = useState(false);
    const [form, setForm] = useState(initialState);
    const [closeClick, setCloseClick] = useState('');
    const [editForm, setEditForm] = useState(false);
    const [state, setState] = useState(stateInit);
    const [priorities, setPriority] = useState([]);
    const [prioritiesId, setPriorityId] = useState([]);

    const messageError = (message, variant) => {
        enqueueSnackbar(message, {
            variant,
        });
    };

    const onSortEnd = ({oldIndex, newIndex}) => {

        setPriority(arrayMove(priorities, oldIndex, newIndex));
        setPriorityId(arrayMove(prioritiesId, oldIndex, newIndex));
        console.log(priorities);
        console.log(prioritiesId);
    };

    const SortableItem = sortableElement(({value}) =>
        <li className="SortableItem" style={{height:40}} id={disableFormControl() === true ? "disabled" : ""}>{value}</li>
    );

    const SortableList = sortableContainer(({items}) => {
        return (
            <ul className="SortableList"  style={{height:'66%'}} >
                {items.map((value, index) => (
                    <SortableItem key={`item-${index}`} index={index} value={value} className="sortableHelper" disabled={disableFormControl()}/>
                ))}
            </ul>
        );
    });

    useEffect(() => {
        //hasAuthority(Permissions.ResetPasswordByEmail, setResetPasswordByEmail);
    }, []);

    const handleChange = event => {
        let value = event.target.checked;

        setState({ ...state, [event.target.name]: value });
        setForm({
            ...form,
            priorityId: dataObject,
            [event.target.name]: event.target.value,
        });
        setCloseClick(true);
    };

    const handleSubmit = event => {
        event.preventDefault();
        setIsLoading(true);
        if (dataObject === 0) {

            createPriority(
                form.namePriority,
            )
                .then(response => {
                    setForm({
                        ...form,
                        priorityId: response.id,
                    });
                    toggleForm();
                    setForm(initialState);
                    setIsLoading(false);
                    refreshTable();
                })
                .catch(error => {
                    if (error !== undefined) {
                        setForm({
                            ...form,
                        });
                    } else {
                        messageError('Servicio no disponible.', 'warning');
                        refreshTable();
                    }
                    setIsLoading(false);
                });

        } else {
            updatePriorityById(
                form.priorityId,
                form.namePriority,
                prioritiesId,
            )
                .then(() => {
                    toggleForm();
                    setForm(initialState);
                    setIsLoading(false);
                    messageError('Prioridad actualizada', 'success');
                    refreshTable();
                })
                .catch(error => {
                    if (error !== undefined) {
                        setForm({
                            ...form,
                        });
                    } else {
                        refreshTable();
                        messageError('Servicio no disponible.', 'warning');
                    }
                    setIsLoading(false);
                });
        }
    };

    const clearData = () => {
        setCloseClick('');
        setEditForm(false);
        setState(stateInit);        
        setForm({
            ...form,
        });
    };

    const initData = () => {
        if (dataObject === 0) {
            setForm({
                ...form,
                priorityId: 0,
                namePriority: '',
                orderPriority: 0,
            });
        } else {
            getpriorityById(dataObject)
                .then(response => {
                    setForm({
                        priorityId: response.id,
                        namePriority: response.label,
                        orderPriority: response.order,
                    });
                })
                .catch(() => {
                    return Promise.resolve(setForm({}));
                });
            getAllPriorities(100, 1, null)
                .then(response => {
                    let priority = [];
                    let priorityId = [];
                    response.priorities.data.forEach((key, p) => {
                        var desc = key.label;
                        priority.push(desc);
                        priorityId.push(key.id);
                    });
                    setPriorityId(priorityId);
                    setPriority(priority);
                })
                .catch((error) => {
                    return Promise.resolve(setPriority([]));
                });
        }
        setCloseClick(false);
        dataObject !== 0 ? setEditForm(false) : setEditForm(true);

    };

    const disableFormControl = () => {
        if (isLoading) {
            return true;
        }
        if (dataObject === 0) {
            return false;
        }
        if (dataObject !== 0 && editForm) {
            return false;
        }
        return true;
    };

    return (
        <Box>
            <Drawer
                anchor="right"
                open={formOpen}
                onClose={toggleForm}
                className={classes.container}
                classes={{ paper: classes.containerPaper }}
            >
                <Box className={classes.header}>
                    <PriorityIcon color="primary" className={classes.headerIcon} />
                    <Box flexGrow={1}>
                        <Typography variant="h5" color="primary">
                            {dataObject === 0 ? 'Nueva Prioridad' : 'Actualizar Prioridad'}
                        </Typography>
                    </Box>
                    {dataObject !== 0 && (
                        <IconButton
                            disabled={!canUpdateUser}
                            color="primary"
                            className={classes.headerCloseButton}
                            onClick={() => {
                                setEditForm(!editForm);
                            }}
                        >
                            <EditIcon />
                        </IconButton>
                    )}
                    <IconButton
                        color="secondary"
                        className={classes.headerCloseButton}
                        onClick={() => {
                            toggleForm();
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </Box>
                <Box component="form" className={classes.form} onSubmit={handleSubmit}>
                    <Box>
                        <TextField
                            error={!!form.UserNameErrorMessage}
                            size="small"
                            required
                            name="namePriority"
                            label="Nombre"
                            margin="normal"
                            variant="outlined"
                            fullWidth
                            disabled={disableFormControl()}
                            value={form.namePriority}
                            onChange={handleChange}
                        />
                        {dataObject === 0 ? null :
                            <Typography id={disableFormControl() === true ? "disabled" : ""}>
                                Arrastra para obtener el orden requerido
                            </Typography>
                        }
                        {dataObject === 0 ? null :
                            <SortableList items={priorities} onSortEnd={onSortEnd} className="SortableHelper"/>
                        }
                    </Box>
                    <Box className={classes.bottomSpace}>
                        <Button
                            fullWidth
                            variant="contained"
                            color="primary"
                            type="submit"
                            disabled={disableFormControl()}
                            style={{ borderRadius: 30 }}
                            size="small"
                        >
                            {dataObject === 0 ? 'Guardar Prioridad' : 'Actualizar Prioridad'}
                        </Button>
                        {isLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
                        {formOpen && closeClick === '' && initData()}
                        {!formOpen && closeClick !== '' && clearData()}
                    </Box>
                </Box>
            </Drawer>
        </Box>
    );
}

PriorityForm.defaultProps = {
    refreshTable: null,
    dataObject: 0,
    canUpdateUser: false,
    canGetUserData: false,
    canUpdateUserData: false,
};

PriorityForm.propTypes = {
    refreshTable: PropTypes.func,
    formOpen: PropTypes.bool.isRequired,
    toggleForm: PropTypes.func.isRequired,
    dataObject: PropTypes.number,
    canUpdateUser: PropTypes.bool,
    canGetUserData: PropTypes.bool,
    canUpdateUserData: PropTypes.bool,
};

export default PriorityForm;
