import React from 'react';
import { Box } from '@material-ui/core';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

// Routes
import {REPORT_ROUTES} from '../../Routes';

// Styles
import { AppStyles } from '../../AppStyles';

// Components
import { WithPublicLayout } from '../../components/AppLayout';

// Components
import WavesReport from './pages/WavesReport';

function ReportRouter() {
    const appStyles = AppStyles();
    const {path} = useRouteMatch();

    return (
        <Box id="app-report-router" component="main" className={appStyles.pageRouter}>
            <Switch>
                <Route exact path={path} component={WavesReport} />
                <Route exact path={`${path}${REPORT_ROUTES.DASHBOARD_WAVES}`} component={WavesReport} />
            </Switch>
        </Box>
    );

}


export default WithPublicLayout(ReportRouter);