import React, { useState, useEffect } from 'react';
import { Drawer, TextField, Typography, Box, Button, IconButton } from '@material-ui/core';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import { Close as CloseIcon, Edit as EditIcon } from '@material-ui/icons';
import { useSnackbar } from 'notistack';
// Components

import CopyPasswordDialog from '../../Users/components/CopyPasswordDialog';

// Data Api
import { changePasswordByUser } from '../../../api/UsersApi';

// import Permissions from '../../../AppsPermissions/UsersAdministratorPermissions';
// import { hasAuthority } from '../../AccessValidators';

import Confirmation from '../../Users/components/Confirmation';

const drawerWidth = 500;
const useStyles = makeStyles(theme => ({
  container: {
    width: drawerWidth,
  },
  containerPaper: {
    width: drawerWidth,
    padding: 20,
  },
  header: {
    display: 'flex',
    alignItems: 'center',
  },
  headerIcon: {
    fontSize: 35,
    marginRight: theme.spacing(1),
  },
  headerCloseButton: {
    padding: theme.spacing(1),
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    justifyContent: 'space-between',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  selectForm: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  selectStyle: {
    minWidth: 195,
    maxWidth: 195,
  },
  pickerStyle: {
    minWidth: 225,
    maxWidth: 225,
  },
  selectAddButton: {
    padding: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  addRolesPolices: {
    margin: theme.spacing(4),
  },
  card: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  cardContent: {
    padding: theme.spacing(2),
  },
  formCardInput: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  bottomSpace: {
    paddingTop: theme.spacing(2),
  },
}));

const initialState = {
  newPassword: '',
  newPasswordConfirmation: '',
  oldPassword: '',
  accountDate: moment()
    .add(1, 'M')
    .startOf('day'),
  accountDateError: false,
  accountDateErrorMessage: '',
  credentialDate: moment()
    .add(1, 'M')
    .startOf('day'),
  credentialDateError: false,
  credentialDateErrorMessage: '',
};

const stateInit = {
  enabled: true,
  accountNonExpired: false,
  credentialsNonExpired: false,
  accountNonLocked: false,
};

function ProfileForm({
  formOpen,
  toggleForm,
  refreshTable,
  dataObject,
  canUpdateUser,
  canGetUserData,
  canUpdateUserData,
}) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [form, setForm] = useState(initialState);
  const [editForm, setEditForm] = useState(false);
  const [state, setState] = useState(stateInit);
  const [seePassword, setSeePassword] = useState(false);
  const [open, setOpen] = useState(false);
  const [confirmationPassword, setConfirmationPassword] = useState({
    confirmationOpen: false,
    confirmationChange: false,
  });

  const messageError = (message, variant) => {
    enqueueSnackbar(message, {
      variant,
    });
  };

  useEffect(() => {
    // hasAuthority(Permissions.ResetPasswordByEmail, setResetPasswordByEmail);
  }, []);

  const handleChange = event => {
    let value = event.target.checked;
    if (
      event.target.name === 'accountNonExpired' ||
      event.target.name === 'accountNonLocked' ||
      event.target.name === 'credentialsNonExpired'
    ) {
      value = !value;
    }
    setState({ ...state, [event.target.name]: value });
    setForm({
      ...form,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = event => {
    event.preventDefault();
    changePasswordByUser(form.oldPassword, form.newPassword, form.newPasswordConfirmation)
      .then(() => {
        toggleForm();
        messageError('Se actualizo la contraseña.', 'success');
        setForm({
          ...form,
          newPassword: '',
          oldPassword: '',
          newPasswordConfirmation: '',
        });
      })
      .catch(error => {
        messageError(error.response.data.message, 'warning');
        if (error !== undefined) {
          setForm({
            ...form,
          });
        } else {
          console.log(error);
          messageError('Servicio no disponible.', 'warning');
        }
      });
  };

  const actionClose = () => {
    setOpen(!open);
  };

  const hide = () => {
    setSeePassword(false);
  };

  return (
    <Box>
      <Drawer
        anchor="right"
        open={formOpen}
        onClose={toggleForm}
        className={classes.container}
        classes={{ paper: classes.containerPaper }}
      >
        <Box className={classes.header}>
          <Box flexGrow={1}>
            <Typography variant="h5" color="primary">
              Actualizar Contraseña
            </Typography>
          </Box>
          {dataObject !== 0 && (
            <IconButton
              disabled={!canUpdateUser}
              color="primary"
              className={classes.headerCloseButton}
              onClick={() => {
                setEditForm(!editForm);
              }}
            >
              <EditIcon />
            </IconButton>
          )}
          <IconButton
            color="secondary"
            className={classes.headerCloseButton}
            onClick={() => {
              toggleForm();
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>

        <Box component="form" className={classes.form} onSubmit={handleSubmit}>
          <Box>
            <TextField
              name="oldPassword"
              required
              autoFocus
              label="Antigua Contraseña"
              margin="normal"
              variant="outlined"
              fullWidth
              value={form.oldPassword}
              onChange={handleChange}
            />
            <TextField
              name="newPassword"
              required
              label="Nueva Contraseña"
              margin="normal"
              variant="outlined"
              fullWidth
              value={form.newPassword}
              onChange={handleChange}
            />
            <TextField
              name="newPasswordConfirmation"
              required
              label="Confirma la contraseña"
              margin="normal"
              variant="outlined"
              fullWidth
              value={form.newPasswordConfirmation}
              onChange={handleChange}
            />
          </Box>
          <Box className={classes.bottomSpace}>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              type="submit"
              style={{ borderRadius: 30 }}
              size="small"
            >
              Cambiar Contraseña
            </Button>
          </Box>
        </Box>
      </Drawer>
      <Box>
        <CopyPasswordDialog
          seePassword={seePassword}
          open={open}
          password="prueba"
          userError={form.emailUserError}
          txtUserError={form.emailUserErrorMessage}
          action={toggleForm}
          actionClose={actionClose}
          actionChange={handleChange}
          hideDialog={hide}
          reseted={false}
        />
        <Confirmation
          open={confirmationPassword.confirmationOpen}
          hideDialog={() =>
            setConfirmationPassword({ ...confirmationPassword, confirmationOpen: false })
          }
          changeOk={() =>
            setConfirmationPassword({
              ...confirmationPassword,
              confirmationChange: true,
              confirmationOpen: false,
            })
          }
          changeCancel={() =>
            setConfirmationPassword({
              ...confirmationPassword,
              confirmationChange: false,
              confirmationOpen: false,
            })
          }
        />
      </Box>
    </Box>
  );
}

export default ProfileForm;
