import { apiClientBearerToken } from './../ApiClientConfig';
import Logger from '../../Logger';

const Log = new Logger('src/api/UsersApi.js');

const ROUTE_PATH = '/routes';

export const getAllRoutes = (
    size?: Number = null,
    page?: Number = null,
    search?: String = null,
    order?: String = null,
    sort?:String = null,
): Promise<Array<Object>> => {
    return apiClientBearerToken()
        .get(`${ROUTE_PATH}/getallroutes`, {
            params: {
                per_page:size,
                page,
                search: search?.trim(),
                order,
                sort
            },
        })
        .then(response => {
            return Promise.resolve(response.data);
        })
        .catch(error => {
            Log.error(
                getAllRoutes.name,
                error.message,
                error.response ? error.response : error,
                !(error.response && error.response.status === 404),
            );
            return Promise.reject();
        });
};

export const deleteRoute = (id: Number): Promise<Object> => {
    return apiClientBearerToken()
        .get(`${ROUTE_PATH}/delete/${id}`, {
        })
        .then(() => {
            return Promise.resolve();
        })
        .catch(error => {
            Log.error(deleteRoute.name, error.message, error.response ? error.response : error);
            return Promise.reject();
        });
};


export const createRoute = (
    name: String,
    description: String,
): Promise<Object> => {
    return apiClientBearerToken()
        .post(`${ROUTE_PATH}/create`, {
            name,
            description
        })
        .then(response => {
            return Promise.resolve(response.data);
        })
        .catch(error => {
            if (error.response) {
                return Promise.reject();
            }
            Log.error(createRoute.name, error.message, error.response ? error.response : error);
            return Promise.reject();
        });
};

export const getRouteById = (id: Number): Promise<Object> => {
    return apiClientBearerToken()
        .get(`${ROUTE_PATH}/${id}`)
        .then(response => {
            return Promise.resolve(response.data);
        })
        .catch(error => {
            Log.error(getRouteById.name, error.message, error.response ? error.response : error);
            return Promise.reject();
        });
};


export const updateRouteById = (
    id: Number,
    name: String,
    description: String,
    color: String

): Promise<Object> => {
    return apiClientBearerToken()
        .post(`${ROUTE_PATH}/update`, {
            id,
            name,
            description,
            color
        })
        .then(response => {
            return Promise.resolve(response.data);
        })
        .catch(error => {
            return Promise.reject();
        });
};