import { apiClientBearerToken, apiClient } from '../ApiClientConfig';
import Logger from '../../Logger';

const Log = new Logger('src/api/CartonService/CartonApi.js');

const CARTONS_PATH = '/cartons';
const CARTONS_LOGS_PATH = '/getlogs';

export const getCartons = (
  area?: Number,
  size?: Number,
  page?: Number,
  search?: String,
  dateInit?: String,
  dateEnd?: String,
  style?: String,
  sku?: String,
  wave?: String,
): Promise<Array<Object>> => {
  return apiClientBearerToken()
    .get(`${CARTONS_PATH}/get`, {
      params: {
        area,
        per_page: size,
        page,
        search: search?.trim(),
        dateInit,
        dateEnd,
        style,
        sku,
        wave,
      },
    })
    .then(response => {
      return Promise.resolve(response.data.cartons);
    })
    .catch(error => {
      Log.error(
        getCartons.name,
        error.message,
        error.response ? error.response : error,
        !(error.response && error.response.status === 404),
      );
      return Promise.reject();
    });
};

export const getCartonsCsv = (
  area?: Number,  
  search?: String,
  dateInit?: String,
  dateEnd?: String,
  style?: String,
  sku?: String,
  wave?: String,
): Promise<Array<Object>> => {
  return apiClientBearerToken()
    .get(`${CARTONS_PATH}/getinone`, {
      params: {
        area,                
        search: search?.trim(),
        dateInit,
        dateEnd,
        style,
        sku,
        wave,
      },
    })
    .then(response => {
      return Promise.resolve(response.data.cartons);
    })
    .catch(error => {
      Log.error(
        getCartons.name,
        error.message,
        error.response ? error.response : error,
        !(error.response && error.response.status === 404),
      );
      return Promise.reject();
    });
};

export const getCartonsByWave = (
    wave?: Number,
    size?: Number,
    page?: Number,
): Promise<Array<Object>> => {
    return apiClientBearerToken()
        .get(`${CARTONS_PATH}/wave/${wave}`, {
            params: {
                per_page: size,
                page,
            },
        })
        .then(response => {
            return Promise.resolve(response.data);
        })
        .catch(error => {
            Log.error(
                getCartonsByWave.name,
                error.message,
                error.response ? error.response : error,
                !(error.response && error.response.status === 404),
            );
            return Promise.reject();
        });
};
export const getZplCarton = (id?: String): Promise<Object> => {
  return apiClientBearerToken()
    .get(`${CARTONS_PATH}/zpl/${id}`)
    .then(response => {
      return Promise.resolve(response.data);
    })
    .catch(error => {
      Log.error(getZplCarton.name, error.message, error.response ? error.response : error);
      return Promise.reject();
    });
};

export const scannerBox = (id?: String, ipAdress?: String): Promise<Object> => {
  return apiClient()
    .get(`/scannerbox/${id}?ipAddress=${ipAdress}`)
    .then(response => {
      return Promise.resolve(response.data);
    })
    .catch(error => {
      Log.error(scannerBox.name, error.message, error.response ? error.response : error);
      return Promise.reject();
    });
};

export const getDetailCaronById = (id?: Number): Promise<Object> => {
  return apiClientBearerToken()
    .get(`${CARTONS_PATH}/find/${id}`)
    .then(response => {
      return Promise.resolve(response.data);
    })
    .catch(error => {
      Log.error(getDetailCaronById.name, error.message, error.response ? error.response : error);
      return Promise.reject();
    });
};

export const resendCartons = (
  boxId?: String,
): Promise<Array<Object>> => {
  return apiClientBearerToken()
    .get(`${CARTONS_PATH}/resend`, {
      params: {
        boxId,
      },
    })
    .then(response => {
      return Promise.resolve(response.data);
    })
    .catch(error => {
      Log.error(
        error.message,
        error.response ? error.response : error,
        !(error.response && error.response.status === 404),
      );
      return Promise.reject();
    });
};

export const getCartonLog = (barcode?: String): Promise<Object> => {
    return apiClientBearerToken()
        .get(`${CARTONS_LOGS_PATH}/${barcode}`)
        .then(response => {
            return Promise.resolve(response.data);
        })
        .catch(error => {
            Log.error(getCartonLog.name, error.message, error.response ? error.response : error);
            return Promise.reject();
        });
};