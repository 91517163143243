import React, {useState, useRef, useEffect} from 'react';
import {
    Typography,
    Button,
    Box,
} from '@material-ui/core';

import { useSnackbar } from 'notistack';
import { makeStyles } from '@material-ui/core/styles';
import { AppStyles } from '../../../AppStyles';
// Components
import WithSnackBarMessages from '../../../components/WithSnackBarMessages';
import { scannerBox } from '../../../api/CartonService/CartonApi';
import PageHeader from "../../../components/PageHeader";
import Grid from "@material-ui/core/Grid/Grid";
import Card from "../../../components/DashboardWaceComponents/Card/Card.js";
import CardHeader from "../../../components/DashboardWaceComponents/Card/CardHeader";
import CardIcon from "../../../components/DashboardWaceComponents/Card/CardIcon";
import CardBody from "../../../components/DashboardWaceComponents/Card/CardBody";
import CardFooter from "../../../components/DashboardWaceComponents/Card/CardFooter";

import {
    getDataDashboard,
    getCartonsToday,
    getCartonsWeek,
    getWavesToday,
    getWavesWeek
} from "../../../api/FastServices/FastApi";

import {APP_ROUTES, WAVES_ROUTES} from "../../../Routes";

const breadcrumbsData = [
    {
        id: 1,
        label: 'Conteo',
        base: APP_ROUTES.COUNT ,
    }
];

// Styles
const useStyles = makeStyles(theme => ({
    actionDescriptionEdit: {
        '& .MuiInput-root': {
            fontSize: 'small',
        },
    },
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(3),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    typography: {
        textAlign: 'center',
    },
    cardCategory: {
        color: "black",
        fontSize: "14px",
        marginTop: "0",
    },
    cardCategoryWhite: {
        color: "white",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
    },
    cardTitle: {
        color: "gray",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "gray",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    cardTitleWhite: {
        color: "white",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "gray",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    cardFooter : {
        with: "100%",
    },
    cardStyle: {
        height: '50px',
    }
}));

function FastCountPage() {
    const classes = useStyles();
    const appStyles = AppStyles();
    const [wavesToday, setWavesToday] = useState(0);
    const [wavesWeek, setWavesWeek] = useState(0);
    const [cartonsToday, setCartonsToday] = useState(0);
    const [cartonsWeek, setCartonsWeek] = useState(0);
    const [piecesToday, setPiecesToday] = useState(0);
    const [piecesWeek, setPiecesWeek] = useState(0);
    const [cartonsTranfer, setCartonsTranfer] = useState(0);
    const [cartonsWithoutTranfer, setCartonsWithoutTranfer] = useState(0);

    useEffect(() => {
        getDataDashboard().then(response => {
            setWavesToday(response.data.wavesToday);
            setWavesWeek(response.data.wavesWeek);
            setCartonsToday(response.data.cartonsToday);
            setCartonsWeek(response.data.cartonsWeek);
            setPiecesToday(response.data.piecesToday);
            setPiecesWeek(response.data.piecesWeek);
            setCartonsTranfer(response.data.cartonsWithTransfer);
            setCartonsWithoutTranfer(response.data.cartonsWithoutTransfer);
        }).catch(e => {
            console.log(e);
        })
    }, []);


    const getReportWaveToday = () =>
        getWavesToday()
            .then(response => {
                const contentDisposition = response.headers['content-disposition'];
                const contentType = response.headers['content-type'];
                const filename = 'reporte-olas-hoy';
                const file = new Blob([response.data], { type: contentType });
                // For Internet Explorer and Edge
                if ('msSaveOrOpenBlob' in window.navigator) {
                    window.navigator.msSaveOrOpenBlob(file, filename);
                }
                // For Firefox and Chrome
                else {
                    // Bind blob on disk to ObjectURL
                    const data = URL.createObjectURL(file);
                    const a = document.createElement('a');
                    a.style = 'display: none';
                    a.href = data;
                    a.download = filename;
                    document.body.appendChild(a);
                    a.click();
                    // For Firefox
                    setTimeout(function() {
                        document.body.removeChild(a);
                        // Release resource on disk after triggering the download
                        window.URL.revokeObjectURL(data);
                    }, 100);
                }
            })
            .catch(e => {
                console.log(e);
            });

    const getReportWaveWeek = () =>
        getWavesWeek()
            .then(response => {
                const contentDisposition = response.headers['content-disposition'];
                const contentType = response.headers['content-type'];
                const filename = 'reporte-olas-semana';
                const file = new Blob([response.data], { type: contentType });
                // For Internet Explorer and Edge
                if ('msSaveOrOpenBlob' in window.navigator) {
                    window.navigator.msSaveOrOpenBlob(file, filename);
                }
                // For Firefox and Chrome
                else {
                    // Bind blob on disk to ObjectURL
                    const data = URL.createObjectURL(file);
                    const a = document.createElement('a');
                    a.style = 'display: none';
                    a.href = data;
                    a.download = filename;
                    document.body.appendChild(a);
                    a.click();
                    // For Firefox
                    setTimeout(function() {
                        document.body.removeChild(a);
                        // Release resource on disk after triggering the download
                        window.URL.revokeObjectURL(data);
                    }, 100);
                }
            })
            .catch(e => {
                console.log(e);
            });

    const getReportCartonToday = () =>
        getCartonsToday()
            .then(response => {
                const contentDisposition = response.headers['content-disposition'];
                const contentType = response.headers['content-type'];
                const filename = 'reporte-cartones-hoy';
                const file = new Blob([response.data], { type: contentType });
                // For Internet Explorer and Edge
                if ('msSaveOrOpenBlob' in window.navigator) {
                    window.navigator.msSaveOrOpenBlob(file, filename);
                }
                // For Firefox and Chrome
                else {
                    // Bind blob on disk to ObjectURL
                    const data = URL.createObjectURL(file);
                    const a = document.createElement('a');
                    a.style = 'display: none';
                    a.href = data;
                    a.download = filename;
                    document.body.appendChild(a);
                    a.click();
                    // For Firefox
                    setTimeout(function() {
                        document.body.removeChild(a);
                        // Release resource on disk after triggering the download
                        window.URL.revokeObjectURL(data);
                    }, 100);
                }
            })
            .catch(e => {
                console.log(e);
            });

    const getReportCartonWeek = () =>
        getCartonsWeek()
            .then(response => {
                const contentDisposition = response.headers['content-disposition'];
                const contentType = response.headers['content-type'];
                const filename = 'reporte-cartones-semana';
                const file = new Blob([response.data], { type: contentType });
                // For Internet Explorer and Edge
                if ('msSaveOrOpenBlob' in window.navigator) {
                    window.navigator.msSaveOrOpenBlob(file, filename);
                }
                // For Firefox and Chrome
                else {
                    // Bind blob on disk to ObjectURL
                    const data = URL.createObjectURL(file);
                    const a = document.createElement('a');
                    a.style = 'display: none';
                    a.href = data;
                    a.download = filename;
                    document.body.appendChild(a);
                    a.click();
                    // For Firefox
                    setTimeout(function() {
                        document.body.removeChild(a);
                        // Release resource on disk after triggering the download
                        window.URL.revokeObjectURL(data);
                    }, 100);
                }
            })
            .catch(e => {
                console.log(e);
            });


    return (
        <Box id="app-home-waves-page" component="main" className={appStyles.pageRoot}>
            <PageHeader
                title="Conteo rápido de olas"
                breadcrumbsData={breadcrumbsData}
            />
            <Grid container spacing={1} style={{marginTop:'20px'}}>
                <Grid item xs={3}>
                    <Card className={classes.root}>
                        <CardHeader color="success" stats icon>
                            <CardIcon color="success">
                                <h3>OLAS HOY</h3>
                            </CardIcon>
                        </CardHeader>
                        <CardBody>
                            <Typography variant="h3" component="h3" align='center' color="secondary">
                                { wavesToday }
                            </Typography>
                        </CardBody>
                        <CardFooter>
                            <Button
                                size="small"
                                onClick={
                                    (event) => {
                                        getReportWaveToday();
                                    }
                                }
                            >
                                Reporte
                            </Button>
                        </CardFooter>
                    </Card>
                </Grid>
                <Grid item xs={3}>
                    <Card className={classes.root}>
                        <CardHeader color="success" stats icon>
                            <CardIcon color="success">
                                <h3>OLAS ESTA SEMANA</h3>
                            </CardIcon>
                        </CardHeader>
                        <CardBody>
                            <Typography variant="h3" component="h3" align='center' color="secondary">
                                { wavesWeek }
                            </Typography>
                        </CardBody>
                        <CardFooter>
                            <Button
                                size="small"
                                onClick={
                                    (event) => {
                                        getReportWaveWeek();
                                    }
                                }
                            >
                                Reporte
                            </Button>
                        </CardFooter>
                    </Card>
                </Grid>
                <Grid item xs={3}>
                    <Card className={classes.root}>
                        <CardHeader color="success" stats icon>
                            <CardIcon color="success">
                                <h3>CAJAS HOY</h3>
                            </CardIcon>
                        </CardHeader>
                        <CardBody>
                            <Typography variant="h3" component="h3" align='center' color="secondary">
                                { cartonsToday }
                            </Typography>
                        </CardBody>
                        <CardFooter>
                            <Button
                                size="small"
                                onClick={
                                    (event) => {
                                        getReportCartonToday();
                                    }
                                }
                            >
                                Reporte
                            </Button>
                        </CardFooter>
                    </Card>
                </Grid>
                <Grid item xs={3}>
                    <Card className={classes.root}>
                        <CardHeader color="success" stats icon>
                            <CardIcon color="success">
                                <h3>CAJAS ESTA SEMANA</h3>
                            </CardIcon>
                        </CardHeader>
                        <CardBody>
                            <Typography variant="h3" component="h3" align='center' color="secondary">
                                { cartonsWeek }
                            </Typography>
                        </CardBody>
                        <CardFooter>
                            <Button
                                size="small"
                                onClick={
                                    (event) => {
                                        getReportCartonWeek();
                                    }
                                }
                            >
                                Reporte
                            </Button>
                        </CardFooter>
                    </Card>
                </Grid>
                <Grid item xs={3}>
                    <Card className={classes.root}>
                        <CardHeader color="success" stats icon>
                            <CardIcon color="success">
                                <h3>PIEZAS HOY</h3>
                            </CardIcon>
                        </CardHeader>
                        <CardBody>
                            <Typography variant="h3" component="h3" align='center' color="secondary">
                                { piecesToday }
                            </Typography>
                        </CardBody>
                        <CardFooter>
                        </CardFooter>
                    </Card>
                </Grid>
                <Grid item xs={3}>
                    <Card className={classes.root}>
                        <CardHeader color="success" stats icon>
                            <CardIcon color="success">
                                <h3>PIEZAS ESTA SEMANA</h3>
                            </CardIcon>
                        </CardHeader>
                        <CardBody>
                            <Typography variant="h3" component="h3" align='center' color="secondary">
                                { piecesWeek }
                            </Typography>
                        </CardBody>
                        <CardFooter>
                        </CardFooter>
                    </Card>
                </Grid>
                <Grid item xs={3}>
                    <Card className={classes.root}>
                        <CardHeader color="success" stats icon>
                            <CardIcon color="success">
                                <h3>TRANSFERENCIAS</h3>
                            </CardIcon>
                        </CardHeader>
                        <CardBody>
                            <Typography variant="h3" component="h3" align='center' color="secondary">
                                { cartonsTranfer }
                            </Typography>
                        </CardBody>
                        <CardFooter>
                        </CardFooter>
                    </Card>
                </Grid>
                <Grid item xs={3}>
                    <Card className={classes.root}>
                        <CardHeader color="success" stats icon>
                            <CardIcon color="danger">
                                <h3>TRFS FALTANTES</h3>
                            </CardIcon>
                        </CardHeader>
                        <CardBody>
                            <Typography variant="h3" component="h3" align='center' color="secondary">
                                { cartonsWithoutTranfer }
                            </Typography>
                        </CardBody>
                        <CardFooter>
                        </CardFooter>
                    </Card>
                </Grid>
            </Grid>
        </Box>
    );
}

export default WithSnackBarMessages(FastCountPage);
