import React, { useState, useRef } from 'react';

// Components
import {
    Drawer,
    TextField,
    Typography,
    Box,
    IconButton,
} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import MaterialTable from 'material-table';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
    Close as CloseIcon,
} from '@material-ui/icons';
import LayersIcon from '@material-ui/icons/Layers';
import TableCell from "@material-ui/core/TableCell/TableCell";
import TableContainer from "@material-ui/core/TableContainer/TableContainer";
import Table from "@material-ui/core/Table/Table";
import TableHead from "@material-ui/core/TableHead/TableHead";
import TableRow from "@material-ui/core/TableRow/TableRow";
import TableBody from "@material-ui/core/TableBody/TableBody";
import {getDetailsForWaveRef} from "../../../api/WaveServices/WavesApi";
import materialTableLocaleES from "../../../components/MaterialTableLocaleES";
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import AssignmentIcon from '@material-ui/icons/Assignment';

// Data Api
const drawerWidth = "50%";
const useStyles = makeStyles(theme => ({
    container: {
        width: drawerWidth,
    },
    containerPaper: {
        width: drawerWidth,
        padding: 20,
    },
    header: {
        display: 'flex',
        alignItems: 'center',
    },
    headerIcon: {
        fontSize: 35,
        marginRight: theme.spacing(1),
    },
    headerCloseButton: {
        padding: theme.spacing(1),
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        justifyContent: 'space-between',
    },
    buttonProgress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    selectForm: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },
    table: {
        minWidth: 650,
    },
    selectStyle: {
        minWidth: 195,
        maxWidth: 195,
    },
    pickerStyle: {
        minWidth: 225,
        maxWidth: 225,
    },
    selectAddButton: {
        padding: theme.spacing(1),
        marginTop: theme.spacing(1),
    },
    addRolesPolices: {
        margin: theme.spacing(4),
    },
    card: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    cardContent: {
        padding: theme.spacing(2),
    },
    formCardInput: {
        display: 'flex',
        flexGrow: 1,
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    bottomSpace: {
        paddingTop: theme.spacing(2),
    },
    progressWave : {
        marginTop: theme.spacing(1),
        padding: 20,
        alignItems: 'rigth',
    },
    typography: {
        textAlign: 'center',
    },
    inputWave : {
        marginRight: '10px',
    },
    footerBox : {
        marginRight: '10px',
        marginTop: '80%',
    }
}));


function OrderPickingDetail({
                               formOpen,
                               toggleForm,
                               refreshTable,
                               dataObject,
                           }) {

    const classes = useStyles();
    const tableRef = useRef();
    const [isLoading, setIsLoading] = useState(true);
    const getStatus = [
        'Cancelada',
        'Creada',
        'En Picking',
        'Pickeada',
        'En Sorter',
        'Completada',
    ];

    const getStatusPallet = [
        'Picking',
        'Staging',
        'Recibido',
        'Buffer',
        'Inducción',
    ];

    const renderOrderGroupsDetail = rowData => {
        return (
            <TableContainer>
                <Table className={classes.table} size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="left">ID</TableCell>
                            <TableCell align="center">SKU</TableCell>
                            <TableCell align="center">Piezas</TableCell>
                            <TableCell align="center">Cajas</TableCell>                            
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rowData.pallets_sku.map(row => (
                            <TableRow key={row.id}>
                              <TableCell component="th" scope="row">{row.id}</TableCell>
                              <TableCell align="center">{row.sku}</TableCell>
                              <TableCell align="center">{row.cantidad}</TableCell>
                              <TableCell align="center">{row.cajas}</TableCell>                                
                            </TableRow>
                          )
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        );
    };

    const getSupplyOrders = query => {
        return getDetailsForWaveRef(dataObject.id,dataObject.department_id,query.page+1,query.pageSize)
            .then(response => {
                    setIsLoading(false);
                    return ({
                        ...query,
                        data: response.data,
                        page: query.page,
                        pageSize: query.pageSize,
                        totalCount: response.total,
                    })
                }
            )
            .catch(() => ({
                ...query,
                page: 0,
                pageSize: 15,
                totalCount: 0,
            }));
    }


    return (
        <Box>
            <Drawer
                anchor="right"
                open={formOpen}
                onClose={toggleForm}
                className={classes.container}
                classes={{ paper: classes.containerPaper }}
            >
                <Box className={classes.header}>
                    <LayersIcon color="primary" className={classes.headerIcon} />
                    <Box flexGrow={4}>
                        <Typography variant="h5" color="primary">
                            {`Ola ${dataObject.id}`}
                        </Typography>
                        <Typography variant="h5" color="primary">
                            {( typeof dataObject.name == "undefined" ) ? "" : dataObject.name}
                        </Typography>
                    </Box>
                    <IconButton
                        color="secondary"
                        className={classes.headerCloseButton}
                        onClick={() => {
                            setIsLoading(true);
                            toggleForm();
                        }}
                        disabled={isLoading}
                    >
                        <CloseIcon />
                    </IconButton>
                </Box>
                <Grid container spacing={3}>                    
                    <Grid item xs={12}>
                        <Typography variant="h5" color="primary">
                            Surtido
                        </Typography>
                        <MaterialTable
                            columns={[
                                { title: 'No', field: 'id', },
                                { title: 'Almacen', field:'almacen_dest'},
                                { title: 'Tarima', field:'lpn_transportador'},
                                { title: 'Destino', field: 'ubicacion_dest' },
                                {
                                    title: 'Ultima ubicación',
                                    field: 'status',
                                    render: rowData => {
                                     let status = getStatusPallet[rowData.status];
                                     return status;
                                    }
                                },

                            ]}
                            title=" "
                            localization={materialTableLocaleES}
                            tableRef={tableRef}
                            data={getSupplyOrders}
                            options={{                                
                                paging: true,
                                toolbar: false,
                                padding: 'dense',
                                pageSize: 10,
                                pageSizeOptions: [10,20,50,100]
                            }}
                            detailPanel={rowData => renderOrderGroupsDetail(rowData)}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Box component="form" className={classes.form} style={{borderBottom:'1px solid rgb(222,205,205)'}}>
                            <Box className={classes.inputWave}>
                                <Grid container spacing={3}>
                                    <Grid item xs={6}>
                                        <TextField
                                            required
                                            name="wavePiezas"
                                            label="Piezas solicitadas"
                                            margin="normal"
                                            variant="outlined"
                                            fullWidth
                                            value={dataObject.pieces}
                                            disabled
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            required
                                            name="waveSku"
                                            label="Total SKU's"
                                            margin="normal"
                                            variant="outlined"
                                            fullWidth
                                            value={dataObject.skus}
                                            disabled
                                        />
                                    </Grid>
                                </Grid>
                            </Box>
                            <Box className={classes.inputWave} style={{marginBottom:'20px'}}>
                                <Grid container spacing={3}>
                                    <Grid item xs={6}>
                                        <TextField
                                            required
                                            name="waveSumPiecesSku"
                                            label="Total Piezas Surtidas"
                                            margin="normal"
                                            variant="outlined"
                                            fullWidth
                                            value={dataObject.pzas}
                                            disabled
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            required
                                            name="waveCajas"
                                            label="Total De Cajas"
                                            margin="normal"
                                            variant="outlined"
                                            fullWidth
                                            value={dataObject.cajas}
                                            disabled
                                        />
                                    </Grid>
                                </Grid>
                            </Box>
                            <Box className={classes.inputWave} style={{marginBottom:'20px'}}>
                                <Grid container spacing={3}>
                                    <Grid item xs={6}>
                                        <TextField
                                            required
                                            name="waveStatus"
                                            label="Estatus"
                                            margin="normal"
                                            variant="outlined"
                                            fullWidth
                                            value={getStatus[dataObject.status]}
                                            disabled
                                        />
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Drawer>
        </Box>
    );
}


OrderPickingDetail.defaultProps = {
    refreshTable: null,
    dataObject: {},
};

OrderPickingDetail.propTypes = {
    refreshTable: PropTypes.func,
    formOpen: PropTypes.bool.isRequired,
    toggleForm: PropTypes.func.isRequired,
    dataObject: PropTypes.any
};

export default OrderPickingDetail;