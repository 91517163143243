import React, { useState, useRef, useEffect } from 'react';
import {
    Box,
    Typography,
    Select,
    MenuItem,
    Button,
    Tooltip,
    InputLabel,
    TextField,
    CircularProgress
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';


// Components
import PageHeader from '../../../components/PageHeader';
import { FormControl } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';


// Styles
import { AppStyles } from '../../../AppStyles';

// Data Apis
import {
    getAllBrands,
    getAllDivisions,
    getAllDepartments,
    getAllProductClasses,
    getAllProductType,
    getAllProductClassification,
    getAllProductFamilies,
    getAllProductProviders,
    createStyle
}
    from '../../../api/StyleServices/StyleApi';

// Routes
import { APP_ROUTES, PRIORITY_ROUTES } from '../../../Routes';
import PropTypes from "prop-types";
import {useSnackbar} from "notistack";

const breadcrumbsData = [
    {
        id: 1,
        label: 'Catalogos',
        base: APP_ROUTES.PRIORITY,
    },
    {
        id: 2,
        label: 'Alta de Estilos',
        base: PRIORITY_ROUTES.STYLES,
    },
];

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    formControlTableToolBar: {
        margin: theme.spacing(3),
        minWidth: 200,
    },
    formControlTableToolBarSelects: {
        margin: theme.spacing(3),
        minWidth: 350,
    },
    input:{
        padding: 20,
    },
    selectOrderGroup: {
        display: 'flex',
        justifyContent: 'space-between',
      },
    buttonProgress: {
        position: 'absolute',
        top: '50%',
        left: '120%',
        marginTop: -12,
        marginLeft: -12,
    },
}));

const initialState = {
    division: 0,
    department: 0,
    class: 0,
    type: 0,
    classification: 0,
    family: 0,
    description: '',
    brand: 0,
    sat_code: '',
    sat_unit: '',
    precio_publico: 0,
    precio_original: 0,
    precio_regular: 0,
    precio_publico_usd: 0,
    precio_publico_qtz: 0,
    costo: 0,
    style: '',
    suplier_id: 0,
    skus: []
};

export default function StylesForm({
     }) {
    const appStyles = AppStyles();
    const { enqueueSnackbar } = useSnackbar();
    const tableRef = useRef();
    const [openForm, setOpenForm] = useState(false);
    const [dataObject, setDataObject] = useState(0);
    const classes = useStyles();
    const [skus, setSkus] = React.useState([]);
    const [selectedDepartmentJdaId, setSelectedDepartmentJdaId] = React.useState(0);
    const [selectedProductClassJdaId, setSelectedProductClassJdaId] = React.useState(0);
    const [brand, setBrand] = useState([]);
    const [division, setDivision] = useState([]);
    const [department, setDepartment] = useState([]);
    const [productClass, setProductClass] = useState([]);
    const [productType, setProductType] = useState([]);
    const [productClassification, setProductClassification] = useState([]);
    const [productFamily, setProductFamily] = useState([]);
    const [provider, setProvider] = useState([]);
    const [form, setForm] = useState(initialState);
    const [isLoading, setIsLoading] = useState(false);
    const [departmentsByDivisionId, setDepartmentsByDivisionId] = React.useState([])
    const [classesByDepartmentId, setClassesByDeparmentId] = React.useState([])
    const [typesByClassId, setTypesByClassId] = React.useState([]);

    const messageError = (message, variant) => {
        enqueueSnackbar(message, {
            variant,
        });
    };
    const messageSuccess = (message, variant) => {
        enqueueSnackbar(message, {
            variant,
        });
    };

    const toggleForm = () => {
        if (tableRef.current) {
            tableRef.current.state.query.page = 0;
            if (openForm) {
                tableRef.current.onQueryChange();
            }
        }
        setOpenForm(!openForm);
        setDataObject(0);
    };

    useEffect(() => {
        getAllBrands()
            .then(response => {
                setBrand(response.brands);
            })
            .catch(error => {
                return Promise.reject(error);
            });

        getAllDivisions()
            .then(response => {
                setDivision(response);
            })
            .catch(error => {
                return Promise.reject(error);
            });

        getAllDepartments()
            .then(response => {
                setDepartment(response.departments);
            })
            .catch(error => {
                return Promise.reject(error);
            });

        getAllProductClasses()
            .then(response => {
                setProductClass(response);
            })
            .catch(error => {
                return Promise.reject(error);
            });

        getAllProductType()
            .then(response => {
                setProductType(response);
            })
            .catch(error => {
                return Promise.reject(error);
            });

        getAllProductClassification()
            .then(response => {
                setProductClassification(response);
            })
            .catch(error => {
                return Promise.reject(error);
            });

        getAllProductFamilies()
            .then(response => {
                setProductFamily(response);
            })
            .catch(error => {
                return Promise.reject(error);
            });

        getAllProductProviders()
            .then(response => {
                setProvider(response);
            })
            .catch(error => {
                return Promise.reject(error);
            });

    }, []);

    const handleChangeAll = (event) => {
        setForm({
            ...form,
            [event.target.name]: event.target.value
        });
    };

    const handleChangeSkus = (event) => {
        const skuList = event.target.value;
        setSkus(skuList);
        setForm({
            ...form, skus: skuList.replace(/\n+/g, ',')
        });
    }

    const handleChangeBrand = (event) => {
        setForm({
            ...form, brand: event.target.value
        });
    }

    const getBrands = () => {
        const allBrands = [];
        if (brand.length > 0)
            brand.forEach(element => {
                allBrands.push(
                    <MenuItem value={element.jdaId} name={element.jdaName} key={element.id}>
                        {element.jdaName}
                    </MenuItem>,
                );
            });
        return allBrands;
    }

    const getDivisions = () => {
        const allDivisions = [];
        if (division.length > 0)
            division.forEach(element => {
                allDivisions.push(
                    <MenuItem value={element.id} name={element.jda_id} key={element.id}>
                        {element.name}
                    </MenuItem>,
                );
            });
        return allDivisions;
    }

    const handleChangeDivision = (event) => {
        setDepartmentsByDivisionId(department.filter(element => element.division_id === event.target.value));
        setForm({
            ...form, division: event.nativeEvent.srcElement.attributes[4].value
        });
    };

    const getDepartmentsByDivisionId = () => {
        const allDepartmentsByDivisionId = [];
        if (departmentsByDivisionId.length > 0)
            departmentsByDivisionId.forEach(element => {
                allDepartmentsByDivisionId.push(
                    <MenuItem value={element.id} name={element.jda_id} key={element.id}>
                        {element.name}
                    </MenuItem>,
                );
            });
        return allDepartmentsByDivisionId;
    };

    const handleChangeDepartment = (event) => {
        setClassesByDeparmentId(productClass.filter(element => element.department_id === event.target.value))
        setSelectedDepartmentJdaId(event.target.value);
        setForm({
            ...form, department: event.nativeEvent.srcElement.attributes[4].value
        });
    };

    const getProductClassesByDepartmenId = () => {
        const allClassesByDepartmentId = [];
        if (classesByDepartmentId.length > 0)
            classesByDepartmentId.forEach(element => {
                allClassesByDepartmentId.push(
                    <MenuItem value={element.id} name={element.jdaId} key={element.id}>
                        {element.jdaName}
                    </MenuItem>,
                );
            });
        return allClassesByDepartmentId;
    }

    const handleChangeProductClass = (event) => {
        setTypesByClassId(productType.filter(element => element.clasz_id === event.target.value))
        setSelectedProductClassJdaId(event.target.value);
        setForm({
            ...form, class: event.nativeEvent.srcElement.attributes[4].value
        });
    };

    const getProductTypeByClassId = () => {
        const allTypesByClassId = [];
        if (typesByClassId.length > 0)
            typesByClassId.forEach(element => {
                allTypesByClassId.push(
                    <MenuItem value={element.jdaId} name={element.jdaId} key={element.id}>
                        {element.jdaName}
                    </MenuItem>,
                );
            });
        return allTypesByClassId;
    }

    const getProductClassifications = () => {
        const allProductClassifications = [];
        if (productClassification.length > 0)
            productClassification.forEach(element => {
                allProductClassifications.push(
                    <MenuItem value={element.jdaId} name={element.jdaName} key={element.id}>
                        {element.jdaName}
                    </MenuItem>,
                );
            });
        return allProductClassifications;
    }

    const getProductFamilies = () => {
        const allProductFamilies = [];
        if (productFamily.length > 0)
            productFamily.forEach(element => {
                allProductFamilies.push(
                    <MenuItem value={element.jdaId} name={element.jdaName} key={element.id}>
                        {element.jdaName}
                    </MenuItem>,
                );
            });
        return allProductFamilies;
    }

    const handleChangeProductType = (event) => {
        setForm({
            ...form, type: event.target.value
        });
    };

    const handleChangeProductClassification = (event) => {
        setForm({
            ...form, classification: event.target.value
        });
    };

    const handleChangeProductFamily = (event) => {
        setForm({
            ...form, family: event.target.value
        });
    };

    const handleChangeProvider = (event) => {
        const provider_id = event.target.value;
        provider_id.charAt(7);
       setForm({
            ...form, suplier_id: parseInt(provider_id)
        });
    };

    const handleSubmit = event => {
        event.preventDefault();
        setIsLoading(true);
        if (dataObject === 0) {
            createStyle(
                parseInt(form.division),
                parseInt(form.department),
                parseInt(form.class),
                parseInt(form.type),
                parseInt(form.classification),
                parseInt(form.family),
                form.description,
                parseInt(form.brand),
                form.sat_code,
                form.sat_unit,
                parseInt(form.precio_publico),
                parseInt(form.precio_original),
                parseInt(form.precio_regular),
                parseInt(form.precio_publico_usd),
                parseInt(form.precio_publico_qtz),
                parseInt(form.costo),
                form.style,
                parseInt(form.suplier_id),
                form.skus
            )
        .then(response => {
            toggleForm();
            document.getElementById("provider-select").value = "";
            if (response.status == 200) {
                setForm(initialState);
                setSelectedDepartmentJdaId(0);
                setSelectedProductClassJdaId(0);
                setSkus('');
                setIsLoading(false);
                messageSuccess(response.message, 'success');
                    }else {
                        messageError('Algo salio mal, intente de nuevo', 'warning');
                        setIsLoading(false);
            }
                })
                .catch(error => {
                    if (error !== undefined) {
                        console.log(error);
                    } else {
                        messageError('Error al procesar la solicitud', 'error');
                    }
                    setIsLoading(false);
                });
        }
    };

    return (
        <Box
            id="app-users-admins-page"
            component="main"
            className={appStyles.pageRoot}
            style={{ backgroundColor: 'white', height: '100vh' }}
        >
            <PageHeader
                title="Alta de Estilos"
                canExecuteButton={true}
                action={toggleForm}
                breadcrumbsData={breadcrumbsData}
            />
            <div className={classes.root}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Paper className={classes.paper}>
                            <Typography variant="h5" component="main">
                                Estilos
                            </Typography>
                            <FormControl className={classes.formControlTableToolBar} hiddenLabel>
                                <TextField
                                    name="style"
                                    label="Estilo"
                                    margin="normal"
                                    fullWidth
                                    value={form.style}
                                    onChange={handleChangeAll}
                                    required
                                />
                            </FormControl>
                            <FormControl className={classes.formControlTableToolBar}>
                                <TextField
                                    name="description"
                                    label="Descripción"
                                    margin="normal"
                                    fullWidth
                                    value={form.description}
                                    onChange={handleChangeAll}
                                    required
                                />
                            </FormControl>
                            <FormControl className={classes.formControlTableToolBar}>
                                <TextField
                                    name="sat_code"
                                    label="Sat Code"
                                    margin="normal"
                                    fullWidth
                                    value={form.sat_code}
                                    onChange={handleChangeAll}
                                    required
                                />
                            </FormControl>
                            <FormControl className={classes.formControlTableToolBar}>
                                <TextField
                                    name="sat_unit"
                                    label="Sat Unit"
                                    margin="normal"
                                    fullWidth
                                    value={form.sat_unit}
                                    onChange={handleChangeAll}
                                    required
                                />
                            </FormControl>
                            <FormControl className={classes.formControlTableToolBar}>
                                <TextField
                                    name="precio_publico"
                                    label="Precio publico"
                                    margin="normal"
                                    fullWidth
                                    value={form.precio_publico}
                                    onChange={handleChangeAll}
                                    required
                                />
                            </FormControl>
                            <FormControl className={classes.formControlTableToolBar}>
                                <TextField
                                    name="precio_original"
                                    label="Precio Original"
                                    margin="normal"
                                    fullWidth
                                    value={form.precio_original}
                                    onChange={handleChangeAll}
                                    required
                                />
                            </FormControl>
                            <FormControl className={classes.formControlTableToolBar}>
                                <TextField
                                    name="precio_regular"
                                    label="Precio Regular"
                                    margin="normal"
                                    fullWidth
                                    value={form.precio_regular}
                                    onChange={handleChangeAll}
                                    required
                                />
                            </FormControl>
                            <FormControl className={classes.formControlTableToolBar}>
                                <TextField
                                    name="precio_publico_usd"
                                    label="Precio publico (USD)"
                                    margin="normal"
                                    fullWidth
                                    value={form.precio_publico_usd}
                                    onChange={handleChangeAll}
                                    required
                                />
                            </FormControl>
                            <FormControl className={classes.formControlTableToolBar}>
                                <TextField
                                    name="precio_publico_qtz"
                                    label="Precio publico (QTZ)"
                                    margin="normal"
                                    fullWidth
                                    value={form.precio_publico_qtz}
                                    onChange={handleChangeAll}
                                    required
                                />
                            </FormControl>
                            <FormControl className={classes.formControlTableToolBar}>
                                <TextField
                                    name="costo"
                                    label="Costo"
                                    margin="normal"
                                    fullWidth
                                    value={form.costo}
                                    onChange={handleChangeAll}
                                    required
                                />
                            </FormControl>
                            <FormControl className={classes.formControlTableToolBar}>
                                <TextField
                                    id="outlined-multiline-static"
                                    label="Lista de Sku´s"
                                    multiline
                                    rows={4}
                                    placeholder="Sku"
                                    onChange={handleChangeSkus}
                                    value={skus}
                                    required
                                />
                            </FormControl>
                        </Paper>
                    </Grid>
                    <Grid item xs={6}>
                        <Paper className={classes.paper}>
                            <FormControl className={classes.formControlTableToolBarSelects}>
                                <InputLabel id="brand_jda_name" required>Brand</InputLabel>
                                <Select
                                    id="brand_jda_name"
                                    value={form.brand}
                                    onChange={handleChangeBrand}
                                >
                                    {getBrands()}
                                </Select>
                            </FormControl>
                            <FormControl className={classes.formControlTableToolBarSelects}>
                                <InputLabel id="division_jda_name" required>División</InputLabel>
                                <Select
                                    id="division_jda_name"
                                    value={form.division}
                                    onChange={handleChangeDivision}
                                >
                                    {getDivisions()}
                                </Select>
                            </FormControl>
                        </Paper>
                    </Grid>
                    <Grid item xs={6}>
                        <Paper className={classes.paper}>
                            <FormControl className={classes.formControlTableToolBarSelects}>
                                <InputLabel id="department_jda_name" required>Departamento</InputLabel>
                                <Select
                                    id="department_jda_name"
                                    value={selectedDepartmentJdaId}
                                    onChange={handleChangeDepartment}
                                >
                                    {getDepartmentsByDivisionId()}
                                </Select>
                            </FormControl>
                            <FormControl className={classes.formControlTableToolBarSelects}>
                                <InputLabel id="product_class_jda_name" required>Clase</InputLabel>
                                <Select
                                    id="product_class_jda_name"
                                    value={selectedProductClassJdaId}
                                    onChange={handleChangeProductClass}
                                >
                                    {getProductClassesByDepartmenId()}
                                </Select>
                            </FormControl>
                        </Paper>
                    </Grid>
                    <Grid item xs={6}>
                        <Paper className={classes.paper}>
                            <FormControl className={classes.formControlTableToolBarSelects}>
                                <InputLabel id="product_type_jda_name" required>Tipo</InputLabel>
                                <Select
                                    id="product_type_jda_name"
                                    value={form.type}
                                    onChange={handleChangeProductType}
                                >
                                    {getProductTypeByClassId()}
                                </Select>
                            </FormControl>
                            <FormControl className={classes.formControlTableToolBarSelects}>
                                <InputLabel id="product_classification_jda_name" required>Clasificación</InputLabel>
                                <Select
                                    id="product_classification_jda_name"
                                    value={form.classification}
                                    onChange={handleChangeProductClassification}
                                >
                                    {getProductClassifications()}
                                </Select>
                            </FormControl>
                        </Paper>
                    </Grid>
                    <Grid item xs={6}>
                        <Paper className={classes.paper}>
                            <FormControl className={classes.formControlTableToolBarSelects}>
                                <InputLabel id="product_family_jda_name" required>Familia</InputLabel>
                                <Select
                                    id="product_family_jda_name"
                                    value={form.family}
                                    onChange={handleChangeProductFamily}
                                >
                                    {getProductFamilies()}
                                </Select>
                            </FormControl>
                            <FormControl className={classes.formControlTableToolBarSelects}>
                                <Autocomplete
                                    id="provider-select"
                                    options={provider}
                                    autoComplete={true}
                                    onSelect={handleChangeProvider}
                                    clearOnEscape={true}
                                    getOptionLabel={provider =>
                                    `${provider.jdaId} - ${provider.jdaName}`
                                    }
                                    renderOption={(provider) => (
                                        <div>
                                            {`${provider.jdaId} - ${provider.jdaName}`}
                                        </div>
                                    )}
                                    renderInput={provider => (
                                    <TextField
                                        {...provider}
                                        variant="outlined"
                                        label="Proveedores"
                                        placeholder="Selecciona"
                                        fullWidth
                                    />
                                    )}
                                />
                            </FormControl>
                        </Paper>
                    </Grid>
                </Grid>
                <FormControl className={classes.formControlTableToolBar}>
                    <Tooltip title="Crear Estilo" placement="bottom-end">
                        <Button variant="contained" color="primary" component="span" className={classes.input} onClick={handleSubmit}>
                            Guardar
                        </Button>
                    </Tooltip>
                    {isLoading && <CircularProgress size={30} className={classes.buttonProgress} />}
                </FormControl>
            </div>
        </Box>
    );
}

StylesForm.defaultProps = {
    refreshTable: null,
    dataObject: 0,
};

StylesForm.propTypes = {
    refreshTable: PropTypes.func,
    formOpen: PropTypes.bool.isRequired,
    dataObject: PropTypes.number,
};