import { apiClientBearerToken } from '../ApiClientConfig';
import Logger from '../../Logger';
const Log = new Logger('src/api/FastService/FastApi.js');


export const getDataDashboard = () : Promise<Array<Object>> => {
    return apiClientBearerToken()
        .get(`/getdata`)
        .then(response => {
            return Promise.resolve(response);
        })
        .catch(error => {
            Log.error(
                getDataDashboard.name,
                error.message,
                error.response ? error.response : error,
                !(error.response && error.response.status === 404),
            );
            return Promise.reject();
        });
};

export const getCartonsToday = () : Promise<Array<Object>> => {
    return apiClientBearerToken()
        .get(`/carton/today`,{
            headers: {
                "Access-Control-Allow-Origin": "*",
            },
            responseType: 'blob',
        })
        .then(response => {
            return Promise.resolve(response);
        })
        .catch( error => {
            Log.error(
                getCartonsToday.name,
                error.message,
                error.response ? error.response : error,
                !(error.response && error.response.status === 404),
            );
            return Promise.reject();
        })
};

export const getCartonsWeek = () : Promise<Array<Object>> => {
    return apiClientBearerToken()
        .get(`/carton/week`,{
            headers: {
                "Access-Control-Allow-Origin": "*",
            },
            responseType: 'blob',
        })
        .then(response => {
            return Promise.resolve(response);
        })
        .catch( error => {
            Log.error(
                getCartonsWeek.name,
                error.message,
                error.response ? error.response : error,
                !(error.response && error.response.status === 404),
            );
            return Promise.reject();
        })
};

export const getWavesToday = () : Promise<Array<Object>> => {
    return apiClientBearerToken()
        .get(`/wave/today`,{
            headers: {
                "Access-Control-Allow-Origin": "*",
            },
            responseType: 'blob',
        })
        .then(response => {
            return Promise.resolve(response);
        })
        .catch( error => {
            Log.error(
                getWavesToday.name,
                error.message,
                error.response ? error.response : error,
                !(error.response && error.response.status === 404),
            );
            return Promise.reject();
        })
};

export const getWavesWeek = () : Promise<Array<Object>> => {
    return apiClientBearerToken()
        .get(`/wave/week`,{
            headers: {
                "Access-Control-Allow-Origin": "*",
            },
            responseType: 'blob',
        })
        .then(response => {
            return Promise.resolve(response);
        })
        .catch( error => {
            Log.error(
                getWavesWeek.name,
                error.message,
                error.response ? error.response : error,
                !(error.response && error.response.status === 404),
            );
            return Promise.reject();
        })
};