import React, { useState } from 'react';
import {
  Drawer,
  TextField,
  Typography,
  Box,
  Button,
  CircularProgress,
  IconButton,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import { Edit as EditIcon, Close as CloseIcon } from '@material-ui/icons';
import { useSnackbar } from 'notistack';
// Components
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import SnackBarMessage from '../../../components/SnackBarMessage';

// Data Api
import {
  getTeamByID,
  createTeam,
  updateTeamByID,
  getUserByRol,
  getAllDepartmentsWhitNotFree,
} from '../../../api/TeamServices/TeamsApi';

const drawerWidth = 500;
const useStyles = makeStyles(theme => ({
  container: {
    width: drawerWidth,
  },
  containerPaper: {
    width: drawerWidth,
    padding: 20,
  },
  header: {
    display: 'flex',
    alignItems: 'center',
  },
  selectZoneType: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  selectFormTextField: {
    justifyContent: 'space-between',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  spaceOption: { padding: theme.spacing(1) },
  headerIcon: {
    fontSize: 35,
    marginRight: theme.spacing(1),
    alignSelf: 'baseline',
  },
  headerCloseButton: {
    padding: theme.spacing(1),
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    justifyContent: 'space-between',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  selectForm: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  selectStyle: {
    minWidth: '100%',
    maxWidth: '100%',
  },
  selectShops: {
    minWidth: 355,
    maxWidth: 355,
  },

  selectAddButton: {
    padding: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  bottomSpace: {
    paddingTop: theme.spacing(2),
  },
  actionDescriptionEdit: {
    '& .MuiInput-root': {
      fontSize: 'small',
    },
  },
}));

const initialState = {
  teamId: 0,
  name: ' ',
  description: ' ',
  administrator: 0,
  department: 0,
};

export default function TeamForm({
  formOpen,
  toggleForm,
  refreshTable,
  dataObject,
  canUpdateZone,
  match,
}) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [form, setForm] = useState(initialState);
  const [closeClick, setCloseClick] = useState('');
  const [editForm, setEditForm] = useState(false);

  const [administrators, setAdministrators] = useState([]);
  const [departments, setDepartments] = useState([]);

  const messageError = (message, variant) => {
    enqueueSnackbar(message, {
      variant,
    });
  };

  const handleChange = event => {
    setForm({
      ...form,
      [event.target.name]: event.target.value,
    });

    setCloseClick(true);
  };

  const handleSubmit = event => {
    event.preventDefault();
    setIsLoading(true);
    if (dataObject === 0) {
      createTeam(form.name, form.description, form.administrator, form.department)
        .then(() => {
          toggleForm();
          setForm(initialState);
          setIsLoading(false);
          refreshTable();
          messageError('Equipo creado', 'success');
        })
        .catch(error => {
          if (error !== undefined) {
            setForm({
              ...form,
            });
          } else {
            toggleForm();
            messageError('Sucedio algun error.', 'error');
            refreshTable();
          }
          setIsLoading(false);
        });
    } else {
      updateTeamByID(form.teamId, form.name, form.description, form.administrator, form.department)
        .then(() => {
          toggleForm();
          setForm(initialState);
          setIsLoading(false);
          messageError('Equipo actualizadao', 'success');
          refreshTable();
        })
        .catch(error => {
          if (error !== undefined) {
            setForm({
              ...form,
            });
          } else {
            toggleForm();
            messageError('Sucedio algun error.', 'error');
            refreshTable();
          }
          setIsLoading(false);
        });
    }
  };

  const clearData = () => {
    setForm(initialState);
    setCloseClick('');
    setEditForm(false);
    setIsLoading(false);
  };

  const initData = () => {
    if (departments.length === 0) {
      getAllDepartmentsWhitNotFree()
        .then(response => {
          setDepartments(response.departments);
        })
        .catch(() => {
          setDepartments([]);
        });
    }
    if (administrators.length === 0) {
      getUserByRol(1)
        .then(response => {
          setAdministrators(response);
        })
        .catch(() => {
          setAdministrators([]);
        });
    }
    if (dataObject === 0) {
      setForm(initialState);
    } else {
      getTeamByID(dataObject)
        .then(response => {
          setForm({
            teamId: response.id,
            name: response.name,
            description: response.description,
            administrator: response.id_administrator,
            department: response.id_department,
          });
        })
        .catch(() => {
          refreshTable(form.zoneTypeId - 1);
          toggleForm();
          messageError('Servicio no disponible.', 'warning');
        });
    }

    setCloseClick(false);

    dataObject !== 0 ? setEditForm(false) : setEditForm(true);
  };

  const disableFormControl = () => {
    if (isLoading) {
      return true;
    }
    if (dataObject === 0) {
      return false;
    }
    if (dataObject !== 0 && editForm) {
      return false;
    }
    return true;
  };

  const optionStartAdministrator = () => {
    let selectedAdmin = null;
    if (administrators.length > 0)
      administrators.forEach(administrator => {
        if (administrator.id === form.administrator) {
          selectedAdmin = administrator;
        }
      });
    return selectedAdmin;
  };

  const optionStartDepartment = () => {
    let selectedDepto = null;
    if (departments.length > 0)
      departments.forEach(depto => {
        if (depto.id === form.department) {
          selectedDepto = depto;
        }
      });

    return selectedDepto;
  };

  return (
    <Box>
      <Drawer
        anchor="right"
        open={formOpen}
        onClose={toggleForm}
        className={classes.container}
        classes={{ paper: classes.containerPaper }}
      >
        <Box className={classes.header}>
          <GroupAddIcon color="primary" className={classes.headerIcon} />
          <Box flexGrow={1}>
            <Typography variant="h5" color="primary">
              {dataObject === 0 ? ' Nuevo Equipo' : 'Actualizar Equipo'}
            </Typography>
          </Box>
          {dataObject !== 0 && (
            <IconButton
              disabled={!canUpdateZone}
              color="primary"
              className={classes.headerCloseButton}
              onClick={() => {
                setEditForm(!editForm);
              }}
            >
              <EditIcon />
            </IconButton>
          )}
          <IconButton
            color="secondary"
            className={classes.headerCloseButton}
            onClick={() => {
              toggleForm();
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <Box className={classes.selectFormTextField}>
          <TextField
            required
            disabled={disableFormControl()}
            label="Nombre"
            id="description-textfield"
            value={form.name}
            name="name"
            fullWidth
            variant="outlined"
            onChange={handleChange}
          />
        </Box>
        <Box className={classes.selectFormTextField}>
          <TextField
            required
            disabled={disableFormControl()}
            label="Descripción"
            id="description-textfield"
            value={form.description}
            name="description"
            fullWidth
            variant="outlined"
            onChange={handleChange}
          />
        </Box>
        <Box className={classes.selectFormTextField}>
          <Autocomplete
            id="combo-box-zonetypes"
            options={administrators}
            renderOption={option => (
              <div className={classes.selectZoneType}>
                <Box className={classes.spaceOption}>
                  <option value={option.name} name={option.id} key={option.id}>
                    {`${option.name}`}
                  </option>
                </Box>
              </div>
            )}
            noOptionsText="No hay administradores disponibles"
            disabled={disableFormControl()}
            getOptionLabel={option => `${option.name}`}
            value={optionStartAdministrator()}
            autoComplete
            onChange={(event, value) => {
              setForm({ ...form, administrator: value?.id || 0 });
            }}
            renderInput={params => (
              // eslint-disable-next-line react/jsx-props-no-spreading
              <TextField
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...params}
                label="Administrador"
                variant="outlined"
                placeholder="Seleccione un administrador"
                fullWidth
              />
            )}
          />
        </Box>
        <Box className={classes.selectFormTextField}>
          <Autocomplete
            id="combo-box-zonetypes"
            options={departments}
            renderOption={option => (
              <div className={classes.selectZoneType}>
                <Box className={classes.spaceOption}>
                  <option value={option.name} name={option.id} key={option.id}>
                    {`${option.name}`}
                  </option>
                </Box>
              </div>
            )}
            noOptionsText="No hay administradores disponibles"
            disabled={disableFormControl()}
            getOptionLabel={option => `${option.name}`}
            value={optionStartDepartment()}
            autoComplete
            onChange={(event, value) => {
              setForm({ ...form, department: value?.id || 0 });
            }}
            renderInput={params => (
              // eslint-disable-next-line react/jsx-props-no-spreading
              <TextField
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...params}
                label="Departamento"
                variant="outlined"
                placeholder="Seleccione un departamento"
                fullWidth
              />
            )}
          />
        </Box>

        <Box component="form" className={classes.form} onSubmit={handleSubmit}>
          <Box />
          <Box className={classes.bottomSpace}>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              type="submit"
              disabled={disableFormControl()}
              style={{ borderRadius: 30 }}
              size="small"
            >
              {dataObject === 0 ? 'Guardar Equipo' : 'Actualizar Equipo'}
            </Button>

            {isLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
            {formOpen && closeClick === '' && initData()}
            {!formOpen && closeClick !== '' && clearData()}
          </Box>
        </Box>
      </Drawer>

      <SnackBarMessage
        open={form.policyAccessError}
        message={form.roleAccessMessage}
        onClose={() => setForm({ ...form, policyAccessError: false })}
        variant="warning"
      />
    </Box>
  );
}

TeamForm.defaultProps = {
  refreshTable: null,
  dataObject: 0,
  canUpdateZone: false,
};

TeamForm.propTypes = {
  refreshTable: PropTypes.func,
  formOpen: PropTypes.bool.isRequired,
  toggleForm: PropTypes.func.isRequired,
  dataObject: PropTypes.number,
  canUpdateZone: PropTypes.bool,
};
