import React, { useState, useEffect } from 'react';
import { Drawer, Button, ListItem, List } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { grey } from '@material-ui/core/colors';
import { useHistory } from 'react-router-dom';
import {
  PeopleAlt as UsersIcon,
  Person as UserIcon,  
  ExitToApp,
  Waves as WavesIcon,
  Launch as Picking
} from '@material-ui/icons';
import BarChartIcon from '@material-ui/icons/BarChart';
import { getPermissions } from '../api/AuthApi';
import ImportContactsIcon from '@material-ui/icons/ImportContacts';
//import LaunchIcon from '@material-ui/icons/Launch';

// Routes
import { APP_ROUTES, PICKING_ROUTES } from '../Routes';

// Security
import { revokeToken } from '../api/AuthApi';

const AdminButton = withStyles(theme => ({
  root: {
    color: theme.palette.getContrastText(grey[600]),
    backgroundColor: grey[500],
    '&:hover': {
      backgroundColor: grey[700],
    },
    padding: theme.spacing(1, 1.5),
    minWidth: theme.spacing(0),
    fontWeight: 'bold',
  },
}))(Button);

const drawerWidth = 75;

const useStyles = makeStyles(theme => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: 'black',
    zIndex: 1201,
  },
  adminList: {
    height: 'inherit',
    display: 'flex',
    flexDirection: 'column-reverse',
  },
}));

export default function AppDrawer() {
  const classes = useStyles();
  const [permissions, setPermissions] = useState([]);
  const { push } = useHistory();

  const icons = [
    '',
    '',
    '',
    <WavesIcon />,
    <ImportContactsIcon />,
    <Picking />,
    <UsersIcon />,
    <BarChartIcon />,
  ];

  const renderButtons = () => {
    const checkFullAccess = permissions.filter(obj => obj.name === 'Full Access');
        
    if (checkFullAccess.length === 0) {
      return permissions.map((permission, key) => {
        if (permission.name !== '/' && permission.name !== '/perfil') {
          return <ListItem alignItems="center" key={`routeIcon${key}`}>
            <AdminButton variant="contained" color="primary" onClick={() => push(permission.name)}>
              {icons[permission.id-1]}
            </AdminButton>
          </ListItem>
        }
      });
    } else {
      return (
        <div>
        <ListItem alignItems="center">
          <AdminButton variant="contained" color="primary" onClick={() => push(PICKING_ROUTES.STAGING)}>
            <Picking />
          </AdminButton>
        </ListItem>
          <ListItem alignItems="center">
            <AdminButton variant="contained" color="primary" onClick={() => push(APP_ROUTES.REPORTS)}>
            <BarChartIcon />
            </AdminButton>
        </ListItem>
        <ListItem alignItems="center">
          <AdminButton variant="contained" color="primary" onClick={() => push(APP_ROUTES.WAVES)}>
            <WavesIcon />
          </AdminButton>
        </ListItem>
        <ListItem alignItems="center">
            <AdminButton variant="contained" color="primary" onClick={() => push(APP_ROUTES.PRIORITY)}>
                <ImportContactsIcon />
            </AdminButton>
        </ListItem>
        <ListItem alignItems="center">
          <AdminButton variant="contained" color="primary" onClick={() => push(APP_ROUTES.USERS)}>
            <UsersIcon />
          </AdminButton>
        </ListItem>
        </div>
      );
    }
    
  }

  useEffect(() => {
      getPermissions().then(response => {
        setPermissions(response);
      }).catch(e => {
        console.log(e);
      })
  }, []);

  const handleLogout = () => {
    revokeToken();
    window.location.reload();
  };

  return (
    <Drawer
      id="app-layout-drawer"
      className={classes.drawer}
      variant="permanent"
      classes={{
        paper: classes.drawerPaper,
      }}
      anchor="left"
    >
      {/* Applications list */}
      <List>
        {/* <ListItem alignItems="center">
          <AppButton
            variant="contained"
            color="primary"
            onClick={() => {
              window.location.href = AppConfig.PRODUCTS_BASE_URL;
              return null;
            }}
          >
            PR
          </AppButton>
        </ListItem> */}
      </List>

      {/* Admin Buttons List */}
      <List className={classes.adminList}>
        <ListItem alignItems="center">
          <AdminButton variant="contained" color="primary" onClick={handleLogout}>
            <ExitToApp />
          </AdminButton>
        </ListItem>
        {renderButtons()}        
        <ListItem alignItems="center">
            <AdminButton variant="contained" color="primary" onClick={() => push(APP_ROUTES.PROFILE)}>
                <UserIcon />
            </AdminButton>
        </ListItem>
      </List>
    </Drawer>
  );
}
