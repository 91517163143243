import { apiClientBearerToken, apiClient } from '../ApiClientConfig';
import Logger from '../../Logger';

const Log = new Logger('src/api/SqsService/SqsApi.js');

const SQS_PATH = '/sqs';

export const sqsMessage = (msg): Promise<Object> => {
    return apiClientBearerToken()
        .post(`${SQS_PATH}/message`, {
            message: msg,
        })
        .then(response => {
            return Promise.resolve(response.data);
        })
        .catch(error => {
            if (error.response) {
                return Promise.reject(error.response.data.errors);
            }
            Log.error(sqsMessage.name, error.message, error.response ? error.response : error);
            return Promise.reject();
        });
};
