import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Typography,
  TextField,
  Button,
  CardMedia,
  InputAdornment,
  IconButton,
  Card,
  CardContent,
  Container,
  CardActions,
  Box,
  CircularProgress,
} from '@material-ui/core';
import {
  AccountCircle as AccountIcon,
  Visibility as ShowPassIcon,
  CheckCircleRounded as CheckIcon,
  ClearRounded as ClearIcon,
  VisibilityOff as HidePassIcon,
  Lock as LockIcon,
} from '@material-ui/icons';
import { useSnackbar } from 'notistack';

// Components
import WithSnackBarMessages from '../../../components/WithSnackBarMessages';

// Styles
import LoginStyles from '../styles/LoginStyles';

// Data Apis
import { getUserToken, checkRol } from '../../../api/AuthApi';

function LoginPage() {
  const classes = LoginStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { push } = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [loginForm, setLoginForm] = useState({
    username: '',
    password: '',
    isEmailValid: null,
    isPasswordValid: true,
    showPassword: false,
  });

  const isValidEmail = (email: String) => {
    const reg = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
    return reg.test(email);
  };

  const handleChange = event => {
    let emailValid = loginForm.isEmailValid;
    let passwordValid = loginForm.isPasswordValid;
    if (event.target.name === 'username') {
      if (event.target.value.length === 0) {
        emailValid = null;
      } else emailValid = isValidEmail(event.target.value);
    }
    if (event.target.name === 'password') {
      if (event.target.value.length < 1) {
        passwordValid = false;
      } else passwordValid = true;
    }

    setLoginForm({
      ...loginForm,
      [event.target.name]: event.target.value,
      isPasswordValid: passwordValid,
      isEmailValid: emailValid,
    });
  };

  const handleSubmit = event => {
    event.preventDefault();
    setIsLoading(true);
    getUserToken(loginForm.username, loginForm.password)
      .then(response => {
        checkRol().then(response => {
          if (response[0] === 3) {
            push('/staging');
          } else {
            window.location.reload();
          }
        });        
      })
      .catch(error => {
        enqueueSnackbar(error.message, { variant: 'error' });
        setIsLoading(false);
      });
  };

  return (
    <Container id="app-login-page" maxWidth="xs">
      <Box className={classes.divImage}>
        <CardMedia className={classes.imageCard} image="/logo512.png" title="CCP Logo" />
      </Box>
      <Typography variant="h4" align="center" className={classes.title}>
        CCP Order Manager
      </Typography>
      <Card component="form" onSubmit={handleSubmit} className={classes.cardForm}>
        <CardContent>
          <TextField
            error={!loginForm.isEmailValid && loginForm.isEmailValid !== null}
            name="username"
            label="Email"
            value={loginForm.username}
            onChange={handleChange}
            fullWidth
            variant="outlined"
            margin="normal"
            autoComplete="email"
            type="email"
            disabled={isLoading}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <AccountIcon />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  {loginForm.isEmailValid ? (
                    <CheckIcon style={{ color: 'green' }} />
                  ) : (
                    loginForm.isEmailValid !== null && <ClearIcon color="error" />
                  )}
                </InputAdornment>
              ),
            }}
          />
          <TextField
            error={!loginForm.isPasswordValid}
            name="password"
            variant="outlined"
            fullWidth
            margin="normal"
            type={loginForm.showPassword ? 'text' : 'password'}
            label="Password"
            value={loginForm.password}
            autoComplete="current-password"
            onChange={handleChange}
            disabled={isLoading}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LockIcon />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    disabled={isLoading}
                    edge="end"
                    aria-label="toggle-password-visibility"
                    onClick={() => {
                      setLoginForm({
                        ...loginForm,
                        showPassword: !loginForm.showPassword,
                      });
                    }}
                  >
                    {loginForm.showPassword ? <HidePassIcon /> : <ShowPassIcon />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </CardContent>
        <CardActions className={classes.wrapper}>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            type="submit"
            disabled={
              !(
                loginForm.isEmailValid &&
                loginForm.isPasswordValid &&
                loginForm.password.length > 0 &&
                loginForm.username.length > 0 &&
                !isLoading
              )
            }
          >
            Iniciar Sesión
          </Button>
          {isLoading && (
            <CircularProgress
              size={24}
              className={classes.buttonProgress}
              style={{ marginLeft: -8 }}
            />
          )}
        </CardActions>
        <CardActions className={classes.wrapper}>
          <Button
              className={classes.forgot}
              fullWidth
              href={'/reset_password/'}
              color="primary"
          >
              Olvide mi contraseña
          </Button>
        </CardActions>
      </Card>
    </Container>
  );
}

export default WithSnackBarMessages(LoginPage);
