import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
    Typography,
    TextField,
    Button,
    CardMedia,
    InputAdornment,
    IconButton,
    Card,
    CardContent,
    Container,
    CardActions,
    Box,
    CircularProgress,
} from '@material-ui/core';
import {
    AccountCircle as AccountIcon,
    CheckCircleRounded as CheckIcon,
    ClearRounded as ClearIcon,
} from '@material-ui/icons';
import EmailIcon from '@material-ui/icons/Email';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Favorite from '@material-ui/icons/Favorite';
import FavoriteBorder from '@material-ui/icons/FavoriteBorder';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useSnackbar } from 'notistack';

// Components
import WithSnackBarMessages from '../components/WithSnackBarMessages';

// Styles
import LoginStyles from './Login/styles/LoginStyles';

// Data Apis
import { getUserToken, checkRol } from '../api/AuthApi';
import { subscriteReport } from '../api/ReportService/ReportApi';

function SubscritePage() {
    const classes = LoginStyles();
    const { enqueueSnackbar } = useSnackbar();
    const { push } = useHistory();
    const [isLoading, setIsLoading] = useState(false);
    const [loginForm, setLoginForm] = useState({
        username: '',
        name: '',
        isEmailValid: null,
        showPassword: false,
    });
    const [state, setState] = React.useState({
        reportOrders: false,
        reportWaves: false,
    });
    const [open, setOpen] = React.useState(false);


    const isValidEmail = (email: String) => {
        const reg = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
        return reg.test(email);
    };

    const handleChange = event => {
        let emailValid = loginForm.isEmailValid;
        let passwordValid = loginForm.isPasswordValid;
        if (event.target.name === 'username') {
            if (event.target.value.length === 0) {
                emailValid = null;
            } else emailValid = isValidEmail(event.target.value);
        }

        setLoginForm({
            ...loginForm,
            [event.target.name]: event.target.value,
            isPasswordValid: passwordValid,
            isEmailValid: emailValid,
        });
    };

    const handleChangeCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
        setState({ ...state, [event.target.name]: event.target.checked });
    };

    const handleClose = () => {
        setOpen(false);
        window.location.reload();
    };

    const handleSubmit = event => {
        event.preventDefault();
        setIsLoading(true);
        let subscriteTo = [];
        if (state.reportOrders === true){
            subscriteTo.push("order_group");
        }
        if (state.reportWaves === true) {
            subscriteTo.push("report_waves");
        }
        console.log(loginForm.username);
        subscriteReport(loginForm.name, loginForm.username, subscriteTo)
            .then(response => {
                setOpen(true);
            })
            .catch(error => {
                enqueueSnackbar(error.message, { variant: 'error' });
                setIsLoading(false);
            });
    };


    return (
        <Container id="app-login-page" maxWidth="xs">
            <Box className={classes.divImage}>
                <CardMedia className={classes.imageCard} image="/logo512.png" title="CCP Logo" />
            </Box>
            <Typography variant="h4" align="center" className={classes.title}>
                CCP Order Manager
            </Typography>
            <Card component="form" onSubmit={handleSubmit} className={classes.cardForm}>
                <CardContent>
                    <Typography variant="h5" align="center" className={classes.title}>
                        Ingresa tu correo para recibir reportes
                    </Typography>
                    <TextField
                        name="name"
                        label="Nombre"
                        value={loginForm.name}
                        onChange={handleChange}
                        fullWidth
                        variant="outlined"
                        margin="normal"
                        autoComplete="name"
                        type="text"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <AccountIcon />
                                </InputAdornment>
                            ),
                        }}
                    />
                    <TextField
                        error={!loginForm.isEmailValid && loginForm.isEmailValid !== null}
                        name="username"
                        label="Email"
                        value={loginForm.username}
                        onChange={handleChange}
                        fullWidth
                        variant="outlined"
                        margin="normal"
                        autoComplete="email"
                        type="email"
                        disabled={isLoading}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <EmailIcon />
                                </InputAdornment>
                            ),
                            endAdornment: (
                                <InputAdornment position="end">
                                    {loginForm.isEmailValid ? (
                                        <CheckIcon style={{ color: 'green' }} />
                                    ) : (
                                        loginForm.isEmailValid !== null && <ClearIcon color="error" />
                                    )}
                                </InputAdornment>
                            ),
                        }}
                    />
                    <Typography variant="h6" align="center">
                        Elige los reportes que deseas recibir
                    </Typography>
                    <FormGroup row>
                        <FormControlLabel
                            control={<Checkbox checked={state.reportOrders} onChange={handleChangeCheck} name="reportOrders" color="primary" />}
                            label="Ordenes de Surtido"
                        />
                        <FormControlLabel
                            control={<Checkbox checked={state.reportWaves} onChange={handleChangeCheck} name="reportWaves" color="primary" />}
                            label="Indicadores de olas"
                        />
                    </FormGroup>
                </CardContent>
                <CardActions className={classes.wrapper}>
                    <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        type="submit"
                        disabled={
                            !(
                                loginForm.isEmailValid && (state.reportOrders || state.reportWaves)
                            )
                        }
                    >
                        Enviar
                    </Button>
                    {isLoading && (
                        <CircularProgress
                            size={24}
                            className={classes.buttonProgress}
                            style={{ marginLeft: -8 }}
                        />
                    )}
                </CardActions>
            </Card>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Te has suscrito para recibir reportes de OMS"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Tu correo ha quedado registrado
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary" autoFocus>
                        Aceptar
                    </Button>
                </DialogActions>
            </Dialog>
        </Container>

    );
}

export default WithSnackBarMessages(SubscritePage);
