import React, { useState } from 'react';
import {
  Typography,
  TextField,
  Button,
  CardMedia,
  InputAdornment,
  Card,
  CardContent,
  Container,
  CardActions,
  Box,
  CircularProgress,
} from '@material-ui/core';
import {
  AccountCircle as AccountIcon,
  CheckCircleRounded as CheckIcon,
  ClearRounded as ClearIcon,
} from '@material-ui/icons';
import { useSnackbar } from 'notistack';

// Components
import WithSnackBarMessages from '../../../components/WithSnackBarMessages';

// Styles
import LoginStyles from '../../Login/styles/LoginStyles';

// Data Apis
import { getTokenResetPassword } from '../../../api/AuthApi';

function ResetPassword() {
  const classes = LoginStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [loginForm, setLoginForm] = useState({
    useremail: '',
    isEmailValid: null,
  });
  const messageError = (message, variant) => {
    enqueueSnackbar(message, {
      variant,
    });
  };

  const isValidEmail = (email: String) => {
    const reg = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
    return reg.test(email);
  };

  const handleChange = event => {
    let emailValid = loginForm.isEmailValid;
    if (event.target.name === 'useremail') {
      if (event.target.value.length === 0) {
        emailValid = null;
      } else emailValid = isValidEmail(event.target.value);
    }
    setLoginForm({
      ...loginForm,
      [event.target.name]: event.target.value,
      isEmailValid: emailValid,
    });
  };

  const handleSubmit = event => {
    event.preventDefault();
    setIsLoading(true);
    getTokenResetPassword(loginForm.useremail)
      .then(response => {
        messageError('Correo enviado', 'success');
        setIsLoading(false);
      })
      .catch(error => {
        messageError(error.data.message, 'error');
        setIsLoading(false);
      });
  };

  return (
    <Container id="app-login-page" maxWidth="xs">
      <Box className={classes.divImage}>
        <CardMedia className={classes.imageCard} image="/logo512.png" title="CCP Logo" />
      </Box>
      <Typography variant="h4" align="center" className={classes.title}>
        CCP Order Manager
      </Typography>
      <Typography variant="h5" align="center" className={classes.title}>
        Reestablecer Contraseña
      </Typography>
      <Card component="form" onSubmit={handleSubmit} className={classes.cardForm}>
        <CardContent>
          <TextField
            error={!loginForm.isEmailValid && loginForm.isEmailValid !== null}
            name="useremail"
            label="Email"
            value={loginForm.useremail}
            onChange={handleChange}
            fullWidth
            variant="outlined"
            margin="normal"
            autoComplete="email"
            type="email"
            disabled={isLoading}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <AccountIcon />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  {loginForm.isEmailValid ? (
                    <CheckIcon style={{ color: 'green' }} />
                  ) : (
                    loginForm.isEmailValid !== null && <ClearIcon color="error" />
                  )}
                </InputAdornment>
              ),
            }}
          />
        </CardContent>
        <CardActions className={classes.wrapper}>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            type="submit"
            disabled={!(loginForm.isEmailValid && loginForm.useremail.length > 0 && !isLoading)}
          >
            Solicitar Reestablecimiento
          </Button>
          {isLoading && (
            <CircularProgress
              size={24}
              className={classes.buttonProgress}
              style={{ marginLeft: -8 }}
            />
          )}
        </CardActions>
      </Card>
    </Container>
  );
}

export default WithSnackBarMessages(ResetPassword);
