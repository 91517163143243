export const APP_ROUTES = {
  LOGIN: '/login',
  CHANGEPASSWORD: '/changepassword/:token',
  RESETPASSWORD: '/reset_password',
  HOME: '/',
  PRINT: '/print',
  COUNT: '/fast_count',
  USERS: '/users',
  SHOPS: '/shops',
  WAVES: '/olas',
  PICKING: '/staging',
  MORE: '/more',
  PROFILE: '/perfil',
  PRIORITY: '/catalogs',
  REPORTS: '/reports',
  SUBSCRITE: '/subscribe',
  SQS: '/sqs'
};

export const PRIORITY_ROUTES = {
  HOME_CATALOG: '/index',
  PRIORITY: '/priorities',
  PRODUCT_FAMILIES: '/productsfamilies',
  STORES: '/stores',
  ROUTES: '/routes',
  REPORT_SUPPLY: '/detailorder',
  STYLES: '/styles',
  USER_REPORTS_ADMIN: '/userreports',
};

export const PICKING_ROUTES = {
  PICKING: '/picking',
  STAGING: '/staging',
  STAGING_MANAGER: '/staging/manager',
  BUFFER: '/buffer',
  BUFFER_MANAGER: '/buffer/manager',
  INDUCTION: '/induction'
};

export const PROFILE_ROUTES = {
  PROFILE: '/index'
};

export const USERS_ROUTES = {
  ADMINISTRATORS: '/administrators',
  POLICIES: '/policies',
  PERMISSIONS: '/permissions'
};

export const WAVES_ROUTES = {
  CREATE: '/crear',  
  WAVES: '/listar',
  PICKING: '/picking',
  SUPPLYORDERS: '/ods',
  WAVE_NOT_FOUND: '/waves/notfound',
  CARTON: '/cartones',
  ZONES: '/zones',
  TEAMS: '/teams',
  BUFFER: '/buffer',
  STAGING: '/staging',
};

export const MORE_ROUTES = {
  APPLICATIONS: '/applications',
  COLORS: '/colors',
  PAPER_BIN: '/bin',
  PAPER_BIN_MORE: '/bin/more',
  PAPER_BIN_USERS: '/bin/users',
  PAPER_BIN_SHOPS: '/bin/shops',
};

export const SHOPS_ROUTES = {
  PORTAGES: '/portages',
  CATALOGUES: '/catalogues',
  TERRITORIES: '/territories',
  ROUTES: '/routes',
  SHOPS: '/shops',
  PORTAGE_NOT_FOUND: '/portages/notfound',
  SHOP_NOT_FOUND: '/shops/notfound',
};

export const REPORT_ROUTES = {
  DASHBOARD_WAVES: '/waves',
  WAVE_NOT_FOUND: '/waves/notfound',
};

export const SQS_ROUTES = {
  DASHBOARD_SQS: '/messages',
};