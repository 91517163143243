import { apiClientBearerToken } from './../ApiClientConfig';
import Logger from '../../Logger';

const Log = new Logger('src/api/UsersApi.js');

const PRODUCT_FAMILY_PATH = 'productfamily';

export const getAllProductFamilies = (
    per_page?: Number,
    page?: Number,
): Promise<Array<Object>> => {
    return apiClientBearerToken()
        .get(`${PRODUCT_FAMILY_PATH}/all`, {
            params: {
                per_page,
                page: page,
            }
        })
        .then(response => {
            return Promise.resolve(response.data);
        })
        .catch(error => {
            Log.error(
                getAllProductFamilies.name,
                error.message,
                error.response ? error.response : error,
                !(error.response && error.response.status === 404),
            );
            return Promise.reject();
        });
};

export const getProductFamilyById = (id: Number): Promise<Object> => {
    return apiClientBearerToken()
        .get(`${PRODUCT_FAMILY_PATH}/${id}`)
        .then(response => {
            return Promise.resolve(response.data);
        })
        .catch(error => {
            Log.error(getProductFamilyById.name, error.message, error.response ? error.response : error);
            return Promise.reject();
        });
};

export const updateProductFamilyById = (
    id: Number,
    jdaId: Number,
    jdaName: String,
    label: String,
    ranking: Number,
    familiesId: Array,
): Promise<Object> => {
    return apiClientBearerToken()
        .post(`${PRODUCT_FAMILY_PATH}/update`, {
            id,
            jdaId,
            jdaName,
            label,
            ranking,
            familiesId
        })
        .then(response => {
            return Promise.resolve(response.data);
        })
        .catch(error => {
            Log.error(
                updateProductFamilyById.name,
                error.message,
                error.response ? error.response : error,
                !(error.response && error.response.status === 404),
            );
            return Promise.reject();
        });
};