import React from 'react';
import { Typography } from '@material-ui/core';
import { Delete as DeleteIcon, Restore as RestoreIcon } from '@material-ui/icons';
import MaterialTable from 'material-table';
import PropTypes from 'prop-types';

export default function DataTable({
  nameEntity,
  columns,
  tableActions,
  tableDetailPanel,
  tableRef,
  getDataFunction,
  deleteDataFunction,
  canGetAll,
  canDelete,
  isBin,
}) {
  const emptyMessageSource = canGetAll !== undefined && (
    <Typography color={canGetAll ? 'primary' : 'error'} variant="subtitle1">
      {canGetAll
        ? `No hay ${nameEntity}(s) que mostrar`
        : 'No tienes permisos para ver este contenido'}
    </Typography>
  );

  return (
    <MaterialTable
      isLoading={canGetAll === undefined}
      tableRef={tableRef}
      columns={columns}
      data={query => getDataFunction(query)}
      options={{
        search: true,
        sorting: true,
        toolbar: true,
        showTitle: false,
        actionsColumnIndex: -1,
        pageSize: 20,
        pageSizeOptions: [10, 15, 20, 25, 50, 100],
        showFirstLastPageButtons: true,
        maxBodyHeight: isBin ? window.innerHeight - 290 : window.innerHeight - 240,
        minBodyHeight: isBin ? window.innerHeight - 290 : window.innerHeight - 240,
        emptyRowsWhenPaging: false,
        debounceInterval: 300,
        padding: 'dense',
      }}
      localization={{
        body: {
          emptyDataSourceMessage: emptyMessageSource,
          editRow: {
            deleteText: `¿Esta Seguro de ${
              isBin ? 'Restaurar' : 'Borrar'
            } el/la ${nameEntity} Seleccionado(a)?`,
            saveTooltip: 'Aceptar',
            cancelTooltip: 'Cancelar',
          },
          deleteTooltip: `${isBin ? 'Restaurar' : 'Eliminar'} ${nameEntity}`,
        },
        header: {
          actions: 'Acciones',
        },
        pagination: {
          firstTooltip: 'Primera Página ',
          lastTooltip: 'Ultima Página',
          nextTooltip: 'Siguiente Página',
          previousTooltip: 'Página Anterior',
        },
        toolbar: {
          exportTitle: 'Exportar',
          exportName: 'Exportar CSV',
          searchPlaceholder: `Buscar ${nameEntity}`,
        },
      }}
      actions={tableActions}
      icons={{
        Delete: () =>
          isBin ? (
            <RestoreIcon color="primary" className="icon-small" />
          ) : (
            <DeleteIcon color="secondary" className="icon-small" />
          ),
      }}
      editable={{
        onRowDelete: canDelete ? oldData => deleteDataFunction(oldData) : undefined,
      }}
      detailPanel={tableDetailPanel}
    />
  );
}

DataTable.defaultProps = {
  canGetAll: undefined,
  canDelete: false,
  tableDetailPanel: [],
  isBin: false,
};

DataTable.propTypes = {
  nameEntity: PropTypes.string.isRequired,
  //columns: PropTypes.arrayOf<Column>(PropTypes.object).isRequired,
  //tableActions: PropTypes.arrayOf<Action>(PropTypes.object).isRequired,
  //tableDetailPanel: PropTypes.arrayOf<DetailPanel>(PropTypes.object),
  tableRef: PropTypes.objectOf(PropTypes.object).isRequired,
  getDataFunction: PropTypes.func.isRequired,
  deleteDataFunction: PropTypes.func.isRequired,
  canGetAll: PropTypes.bool,
  canDelete: PropTypes.bool,
  isBin: PropTypes.bool,
};
