import React, { useState, useRef } from 'react';
import { Drawer, TextField, Typography, Box, IconButton } from '@material-ui/core';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  Edit as EditIcon,
  Close as CloseIcon,
  Beenhere as PoliciesIcon,
} from '@material-ui/icons';
import { useSnackbar } from 'notistack';

// Components

// Data Api
import {
  getPoliciesApplicationActionById,
} from '../../../api/PoliciesApi';

const drawerWidth = 500;
const useStyles = makeStyles(theme => ({
  container: {
    width: drawerWidth,
  },
  containerPaper: {
    width: drawerWidth,
    padding: 20,
  },
  header: {
    display: 'flex',
    alignItems: 'center',
  },
  headerIcon: {
    fontSize: 35,
    marginRight: theme.spacing(1),
  },
  headerCloseButton: {
    padding: theme.spacing(1),
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    justifyContent: 'space-between',
  },
  selectForm: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  selectStyle: {
    minWidth: 195,
    maxWidth: 195,
  },
  selectAddButton: {
    padding: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  bottomSpace: {
    paddingTop: theme.spacing(2),
  },
  optionList: {
    paddingLeft: theme.spacing(6),
    paddingTop: theme.spacing(2),
    minWidth: 300,
    minHeight: '1vh',
  },
}));

const initialState = {
  policyId: 0,
  policyName: ' ',
  policyNameError: false,
  policyNameErrorMessage: '',
  policyDescription: ' ',
  policyDescriptionError: false,
  policyDescriptionErrorMessage: '',
  policyAccessError: false,
  policyAccessMessage: '',
};

const applicationAction = {
  access: '',
  application_name: '',
  action_name: '',
};

function PermissionsForm({ formOpen, toggleForm, dataObject, canUpdatePolicy }) {
  const classes                     = useStyles();
  const [isLoading, setIsLoading]   = useState(false);
  const [form, setForm]             = useState(initialState);
  const [closeClick, setCloseClick] = useState('');
  const [editForm, setEditForm]     = useState(false);
  const [updatePolicy, setUpdatePolicy]     = useState({ accessPolicy: [] });

  const handleChange = event => {
    setForm({
      ...form,
      policyId: dataObject,
      [event.target.name]: event.target.value,
    });
    setCloseClick(true);
  };

  const initData = () => {
    if (dataObject === 0) {
      setForm({
        form,
        policyId: 0,
        policyName: '',
        policyDescription: '',
      });
    } else {
      getPoliciesApplicationActionById(dataObject)
        .then(response => {
          setForm({
            policyId: response.id,
            policyName: response.name,
            policyDescription: response.description,
          });
          return Promise.resolve(setUpdatePolicy(response));
        })
        .catch(() => Promise.resolve(setUpdatePolicy({ accessPolicy: [] })));
    }
    setCloseClick(false);
  };

  const disableFormControl = () => {
    if (isLoading) {
      return true;
    }
    if (dataObject === 0) {
      return false;
    }
    if (dataObject !== 0 && editForm) {
      return false;
    }
    return true;
  };

  return (
    <Box>
      <Drawer
        anchor="right"
        open={formOpen}
        onClose={toggleForm}
        className={classes.container}
        classes={{ paper: classes.containerPaper }}
      >
        <Box className={classes.header}>
          <PoliciesIcon color="primary" className={classes.headerIcon} />
          <Box flexGrow={1}>
            <Typography variant="h5" color="primary">
              Ver Detalle
            </Typography>
          </Box>
          {dataObject !== 0 && (
            <IconButton
              disabled={!canUpdatePolicy}
              color="primary"
              className={classes.headerCloseButton}
              onClick={() => {
                setEditForm(!editForm);
              }}
            >
              <EditIcon />
            </IconButton>
          )}
          <IconButton
            color="secondary"
            className={classes.headerCloseButton}
            onClick={() => {
              toggleForm();
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>

        <Box component="form" className={classes.form}>
          <Box>
            <TextField
              error={form.policyNameError}
              name="policyName"
              label="Nombre"
              margin="normal"
              variant="outlined"
              fullWidth
              disabled={disableFormControl()}
              value={form.policyName}
              onChange={handleChange}
            />
            <TextField
              error={form.policyDescriptionError}
              helperText={form.policyDescriptionErrorMessage}
              name="policyDescription"
              label="Descripción"
              multiline
              rows="4"
              margin="normal"
              variant="outlined"
              fullWidth
              disabled={disableFormControl()}
              value={form.policyDescription}
              onChange={handleChange}
            />
          </Box>
            {formOpen && closeClick === '' && initData()}
        </Box>
      </Drawer>
    </Box>
  );
}

PermissionsForm.defaultProps = {
  refreshTable: null,
  dataObject: 0,
  canUpdatePolicy: false,
};

PermissionsForm.propTypes = {
  refreshTable: PropTypes.func,
  formOpen: PropTypes.bool.isRequired,
  toggleForm: PropTypes.func.isRequired,
  dataObject: PropTypes.number,
  canUpdatePolicy: PropTypes.bool,
};

export default PermissionsForm;
