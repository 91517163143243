import React from 'react';

import { Route, Switch } from 'react-router-dom';
import AppRoute from './components/AppRoute';
import { APP_ROUTES } from './Routes';

import NotFound from './pages/NotFoundPage';
import LoginPage from './pages/Login/pages/LoginPage';
import PrintPage from './pages/Print/pages/PrintPage';
import FastCountPage from './pages/FastCount/pages/FastCountPage';
import HomePage from './pages/HomePage';
import UsersRouter from './pages/Users/UsersRouter';
import WavesRouter from './pages/Waves/WavesRouter';
import ProfileRouter from './pages/Perfil/ProfileRouter';
import PriorityRouter from './pages/Priority/PriorityRouter';
import PickingRouter from './pages/Picking/PickingRouter';
import ResetPasswordPage from './pages/ForgotPassword/pages/ResetPasswordPage';
import ChangePasswordPage from './pages/ForgotPassword/pages/ChangePasswordPage';
import ReportRouter from './pages/Report/ReportRouter';
import SubscriteRouter from './pages/SubscritePage';
import SqsRouter from './pages/Sqs/SqsRouter';

export default function Routes() {
  return (
    <Switch>
      <AppRoute exact path={APP_ROUTES.LOGIN} component={LoginPage} redirectTo={APP_ROUTES.HOME} />
      <AppRoute exact path={APP_ROUTES.RESETPASSWORD} component={ResetPasswordPage} redirectTo={APP_ROUTES.HOME} />
      <AppRoute exact path={APP_ROUTES.CHANGEPASSWORD} component={ChangePasswordPage} redirectTo={APP_ROUTES.HOME} />
      <AppRoute exact path={APP_ROUTES.HOME} component={HomePage} requireAuth />
      <AppRoute path={APP_ROUTES.PRINT} component={PrintPage} redirectTo={APP_ROUTES.HOME} />
      <AppRoute path={APP_ROUTES.COUNT} component={FastCountPage} redirectTo={APP_ROUTES.HOME} />
      <AppRoute path={APP_ROUTES.USERS} component={UsersRouter} requireAuth />
      <AppRoute path={APP_ROUTES.WAVES} component={WavesRouter} requireAuth />
      <AppRoute path={APP_ROUTES.PROFILE} component={ProfileRouter} requireAuth />
      <AppRoute path={APP_ROUTES.PRIORITY} component={PriorityRouter} requireAuth />
      <AppRoute path={APP_ROUTES.PICKING} component={PickingRouter} requireAuth/>
      <AppRoute path={APP_ROUTES.REPORTS} component={ReportRouter} requireAuth/>
      <AppRoute path={APP_ROUTES.SUBSCRITE} component={SubscriteRouter}/>
      <AppRoute path={APP_ROUTES.SQS} component={SqsRouter} requireAuth/>
      <Route component={NotFound} />
    </Switch>
  );
}