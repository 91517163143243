import { apiClientBearerToken } from '../ApiClientConfig';
import Logger from '../../Logger';

const Log = new Logger('src/api/StyleServices/StyleApi.js');

const STYLES_PATH = '/styles';
const BRANDS_PATH = '/brands';
const DIVISIONS_PATH = '/divisions';
const DEPARTMENT_PATH = '/departments';
const PRODUCT_CLASSES = '/productclasses';
const PRODUCT_TYPE = '/producttypes';
const PRODUCT_CLASSIFICATION = '/classifications';
const FAMILIES_PATH = '/productfamily';
const PROVIDER = '/productprovider';


export const getAllBrands = (): Promise<Array<Object>> => {
    return apiClientBearerToken()
        .get(`${BRANDS_PATH}/get`, {
        })
        .then(response => {
            return Promise.resolve(response.data);
        })
        .catch(error => {
            Log.error(
                getAllBrands.name,
                error.message,
                error.response ? error.response : error,
                !(error.response && error.response.status === 404),
            );
            return Promise.reject();
        });
};

export const getAllDivisions = (): Promise<Array<Object>> => {
    return apiClientBearerToken()
        .get(`${DIVISIONS_PATH}/all`, {
            params: {},
        })
        .then(response => {
            return Promise.resolve(response.data);
        })
        .catch(error => {
            Log.error(
                getAllDivisions.name,
                error.message,
                error.response ? error.response : error,
                !(error.response && error.response.status === 404),
            );
            return Promise.reject();
        });
};

export const getAllDepartments = (): Promise<Array<Object>> => {
    return apiClientBearerToken()
        .get(`${DEPARTMENT_PATH}/all`, {
            params: {},
        })
        .then(response => {
            return Promise.resolve(response.data);
        })
        .catch(error => {
            Log.error(
                getAllDepartments.name,
                error.message,
                error.response ? error.response : error,
                !(error.response && error.response.status === 404),
            );
            return Promise.reject();
        });
};

export const getAllProductClasses = (): Promise<Array<Object>> => {
    return apiClientBearerToken()
        .get(`${PRODUCT_CLASSES}/all`, {
        })
        .then(response => {
            return Promise.resolve(response.data);
        })
        .catch(error => {
            Log.error(
                getAllProductClasses.name,
                error.message,
                error.response ? error.response : error,
                !(error.response && error.response.status === 404),
            );
            return Promise.reject();
        });
};

export const getAllProductType = (): Promise<Array<Object>> => {
    return apiClientBearerToken()
        .get(`${PRODUCT_TYPE}/all`, {
        })
        .then(response => {
            return Promise.resolve(response.data);
        })
        .catch(error => {
            Log.error(
                getAllProductType.name,
                error.message,
                error.response ? error.response : error,
                !(error.response && error.response.status === 404),
            );
            return Promise.reject();
        });
};

export const getAllProductClassification = (): Promise<Array<Object>> => {
    return apiClientBearerToken()
        .get(`${PRODUCT_CLASSIFICATION}/getAll`, {
        })
        .then(response => {
            return Promise.resolve(response.data);
        })
        .catch(error => {
            Log.error(
                getAllProductClassification.name,
                error.message,
                error.response ? error.response : error,
                !(error.response && error.response.status === 404),
            );
            return Promise.reject();
        });
};

export const getAllProductFamilies = (): Promise<Array<Object>> => {
    return apiClientBearerToken()
        .get(`${FAMILIES_PATH}/getall`, {
        })
        .then(response => {
            return Promise.resolve(response.data);
        })
        .catch(error => {
            Log.error(
                getAllProductFamilies.name,
                error.message,
                error.response ? error.response : error,
                !(error.response && error.response.status === 404),
            );
            return Promise.reject();
        });
};

export const getAllProductProviders = (): Promise<Array<Object>> => {
    return apiClientBearerToken()
        .get(`${PROVIDER}/all`, {
        })
        .then(response => {
            return Promise.resolve(response.data);
        })
        .catch(error => {
            Log.error(
                getAllProductProviders.name,
                error.message,
                error.response ? error.response : error,
                !(error.response && error.response.status === 404),
            );
            return Promise.reject();
        });
};

export const createStyle = (
    division: Number,
    department: Number,
    classes: Number,
    type: Number,
    classification: Number,
    family: Number,
    description: String,
    brand: Number,
    sat_code: String,
    sat_unit: String,
    precio_publico: Number,
    precio_original: Number,
    precio_regular: Number,
    precio_publico_usd: Number,
    precio_publico_qtz: Number,
    costo: Number,
    style: String,
    supplier_id: Number,
    skus: String

): Promise<Object> => {
    return apiClientBearerToken()
        .post(`${STYLES_PATH}/create`, {
            division,
            department,
            classes,
            type,
            classification,
            family,
            description,
            brand,
            sat_code,
            sat_unit,
            precio_publico,
            precio_original,
            precio_regular,
            precio_publico_usd,
            precio_publico_qtz,
            costo,
            style,
            supplier_id,
            skus
        })
        .then(response => {
            return Promise.resolve(response.data);
        })
        .catch(error => {
            Log.error(
                createStyle.name,
                error.message,
                error.response ? error.response : error,
                !(error.response && error.response.status === 404),
            );
            return Promise.reject();
        });
};