import React, { useState } from 'react';
import {
  Drawer,
  TextField,
  Typography,
  Box,
  Button,
  CircularProgress,
  IconButton,
  Checkbox,
  Grid,
  Chip,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import {
  Edit as EditIcon,
  Close as CloseIcon,
  HowToReg as RolesIcon,
  CheckBoxOutlineBlank,
} from '@material-ui/icons';
import { useSnackbar } from 'notistack';
// Components
import Devolutions from './Devolutions';
import SnackBarMessage from '../../../components/SnackBarMessage';

// Data Api
import {
  getAllOrderGroups,
  getAllDepartments,
  getAllRoutes,
  getAllStores,
  preCalculateWave,
  createWave,
  getAllClassifications,
  getAllFamilies,
  getAllProviders,
  getStyles,
} from '../../../api/WaveServices/WavesApi';

const drawerWidth = 450;
const icon = <CheckBoxOutlineBlank fontSize="small" />;
const useStyles = makeStyles(theme => ({
  container: {
    width: drawerWidth,
  },
  containerPaper: {
    width: drawerWidth,
    padding: 20,
  },
  header: {
    display: 'flex',
    alignItems: 'center',
  },
  selectOrderGroup: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  selectFormOrderGroup: {
    justifyContent: 'space-between',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  spaceOption: { padding: theme.spacing(1) },
  headerIcon: {
    fontSize: 35,
    marginRight: theme.spacing(1),
    alignSelf: 'baseline',
  },
  headerCloseButton: {
    padding: theme.spacing(1),
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    justifyContent: 'space-between',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  selectForm: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  selectStyle: {
    minWidth: '100%',
    maxWidth: '100%',
  },
  selectShops: {
    minWidth: 355,
    maxWidth: 355,
  },

  selectAddButton: {
    padding: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  bottomSpace: {
    paddingTop: theme.spacing(2),
  },
  actionDescriptionEdit: {
    '& .MuiInput-root': {
      fontSize: 'small',
    },
  },
}));

const initialState = {
  orderGroupId: 0,
  classification: '1',
  disabledDivs: [false, false, false, false, false],
  divisions: [],
  divisionsNames: [],
  excludedDepartments: [],
  excludedRoutes: [],
  excludedClassifications: [],
  excludedFamilies: [],
  excludedStores: [],
  excludedProviders: [],
  excludedStyles: [],
  orderGroupError: false,
  orderGroupErrorMessage: '',
  description: '',
};

const precalc = {
  skus: 0,
  pieces: 0,
  stores: 0,
};

export default function WaveForm({
  formOpen,
  toggleForm,
  refreshTable,
  dataObject,
  canUpdateWave,
  match,
}) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [openDevolutions, setOpenDevolutions] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [form, setForm] = useState(initialState);
  const [calc, setCalc] = useState(precalc);
  const [closeClick, setCloseClick] = useState('');
  const [allOrderGroups, setAllOrderGroups] = useState([]);
  const [divisions, setDivisions] = useState([]);
  const [stores, setStores] = useState([]);
  const [classifications, setClassifications] = useState([]);
  const [families, setFamilies] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [providers, setProviders] = useState([]);
  const [styles, setStyles] = useState([]);
  const [selectedOrderGroupId, setSelectedOrderGroupId] = useState([]);
  const [routes, setRoutes] = useState([]);
  const [editForm, setEditForm] = useState(false);

  const messageError = (message, variant) => {
    enqueueSnackbar(message, {
      variant,
    });
  };

  const handleChange = event => {
    setForm({
      ...form,
      [event.target.name]: event.target.value,
    });

    setCloseClick(true);
  };

  const toggleDevolutions = () => {
    setOpenDevolutions(!openDevolutions);
  };

  const handlePreCalculate = () => {
    setIsLoading(true);

    if (dataObject === 0) {
      preCalculateWave(
        form.orderGroupId,
        form.divisions,
        form.description,
        form.divisionsNames,
        form.excludedDepartments,
        form.excludedRoutes,
        form.excludedClassifications,
        form.excludedFamilies,
        form.excludedStores,
        form.excludedProviders,
        form.excludedStyles
      )
        .then(response => {
          setCalc({
            ...calc,
            skus: response.skus,
            pieces: response.pieces,
            stores: response.orders,
          });
          setIsLoading(false);
        })
        .catch(error => {
          if (error !== undefined) {
            console.log(error);
          } else {
            messageError('Estos parametros no retornan ningun resultado.', 'warning');
          }
          setIsLoading(false);
        });
    }
  };

  const handleSubmit = event => {
    event.preventDefault();
    setIsLoading(true);
    if (dataObject === 0) {
      createWave(
        form.orderGroupId,
        form.divisions,
        form.description,
        form.divisionsNames,
        form.excludedDepartments,
        form.excludedRoutes,
        form.excludedClassifications,
        form.excludedFamilies,
        form.excludedStores,
        form.excludedProviders,
        form.excludedStyles,
      )
        .then(response => {
          if (response.message !== undefined) {
            messageError(response.message, 'error');
          } else {
            toggleForm();
            setForm(initialState);
            refreshTable();
          }
          setIsLoading(false);
        })
        .catch(error => {
          if (error !== undefined) {
            console.log(error);
          } else {
            refreshTable();
            messageError('Estos parametros no retornan ningun resultado.', 'warning');
          }
          setIsLoading(false);
        });
    }
  };

  const options = allOrderGroups.map(option => {
    const firstLetter = option.order_group[0];
    return {
      firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
      ...option,
    };
  });

  const clearData = () => {
    setForm(initialState);
    setCloseClick('');
    setEditForm(false);
    setIsLoading(false);
  };

  const optionStartOrderGroup = () => {
    let selectedOrderGroup = null;
    if (allOrderGroups.length > 0)
      allOrderGroups.forEach(orderGroup => {
        if (orderGroup.order_group_id === form.orderGroupId) {
          selectedOrderGroup = orderGroup;
        }
      });
    return selectedOrderGroup;
  };

  const initData = () => {
    if (dataObject === 0) {
      setForm(initialState);
    }

    getAllOrderGroups(false)
      .then(response => {
        setAllOrderGroups(response);
      })
      .catch(error => {
        console.log(error);
        setAllOrderGroups([]);
      });

    getAllClassifications()
      .then(response => {
        setClassifications(response);
      })
      .catch(error => {
        console.log(error);
        setClassifications([]);
      });

    getAllFamilies()
      .then(response => {
        setFamilies(response);
      })
      .catch(error => {
        console.log(error);
        setFamilies([]);
      });
    getAllStores()
      .then(response => {
        setStores(response);
      })
      .catch(error => {
        console.log(error);
        setStores([]);
      });    

    getAllRoutes(false, 0, 0)
      .then(response => {
        setRoutes(response);
      })
      .catch(error => {
        console.log(error);
        setRoutes([]);
      });

    getAllDepartments('1,2,3,4,5,6')
      .then(response => {
        const divisions = response;
        const departments = [];
        divisions.forEach(division => {
          division.departments.forEach(department => {
            departments.push({
              id: department.id,
              department: department.name,
              division: division.name,
            });
          });
        });
        setDepartments(departments);
      })
      .catch(error => {
        console.log(error);
        setDepartments([]);
      });

    const divs = [
      { id: 1, division: 'Mujer', pieces: 0, in_wave: 0, pending: 0 },
      { id: 2, division: 'Hombre', pieces: 0, in_wave: 0, pending: 0 },
      { id: 3, division: 'Kids y Bebés', pieces: 0, in_wave: 0, pending: 0 },
      { id: 4, division: 'Interiores', pieces: 0, in_wave: 0, pending: 0 },
      { id: 5, division: 'Accesorios', pieces: 0, in_wave: 0, pending: 0 },
    ];
    setDivisions(divs);

    setCloseClick(false);

    dataObject !== 0 ? setEditForm(false) : setEditForm(true);
  };

  const disableFormControl = () => {
    if (isLoading) {
      return true;
    }
    if (dataObject === 0) {
      return false;
    }
    if (dataObject !== 0 && editForm) {
      return false;
    }
    return true;
  };

  return (
    <Box>
      <Devolutions formOpen={openDevolutions} toggleForm={toggleDevolutions} dataObject={0} />
      <Drawer
        anchor="right"
        open={formOpen}
        onClose={toggleForm}
        className={classes.container}
        classes={{ paper: classes.containerPaper }}
      >
        <Box>
          <Button color="secondary" onClick={toggleDevolutions}>
            Devoluciones
          </Button>
        </Box>
        <Box className={classes.header}>
          <RolesIcon color="primary" className={classes.headerIcon} />
          <Box flexGrow={1}>
            <Typography variant="h5" color="primary">
              {dataObject === 0 ? ' Nueva Ola' : 'Actualizar Ola'}
            </Typography>
          </Box>
          {dataObject !== 0 && (
            <IconButton
              disabled={!canUpdateWave}
              color="primary"
              className={classes.headerCloseButton}
              onClick={() => {
                setEditForm(!editForm);
              }}
            >
              <EditIcon />
            </IconButton>
          )}
          <IconButton
            color="secondary"
            className={classes.headerCloseButton}
            onClick={() => {
              toggleForm();
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <Box className={classes.selectFormOrderGroup}>
          <Autocomplete
            id="combo-box-ordergroup"
            options={options.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter))}
            renderOption={option => (
              <div className={classes.selectOrderGroup}>
                <Box className={classes.spaceOption}>
                  <option value={option.title} name={option.id} key={option.id}>
                    {`${option.reference} / ${option.total_pending.toLocaleString()} Pzs.`}
                  </option>
                </Box>
              </div>
            )}
            noOptionsText="No hay ordenes de surtido disponibles"
            disabled={disableFormControl()}
            getOptionLabel={option =>
              `${option.reference} / ${option.total_pending.toLocaleString()} Pzs.`
            }
            value={optionStartOrderGroup()}
            autoComplete
            onChange={(event, value) => {
              setForm({ ...form, orderGroupId: value?.order_group_id || 0 });
              getAllProviders(value?.order_group_id || null)
                .then(response => {
                  setProviders(response);
                })
                .catch(error => {
                  console.log(error);
                  setProviders([]);
                });
              setDivisions(value?.divisions);
              setSelectedOrderGroupId(value?.order_group_id);
            }}
            renderInput={params => (
              // eslint-disable-next-line react/jsx-props-no-spreading
              <TextField
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...params}
                label="Ordenes de surtido"
                variant="outlined"
                placeholder="Seleccione una orden de surtido"
                fullWidth
                error={form.orderGroupError}
                helperText={form.orderGroupErrorMessage}
              />
            )}
          />
        </Box>
        <Box className={classes.selectFormOrderGroup}>
          <Autocomplete
            multiple
            id="divisions-select"
            options={divisions}
            disableCloseOnSelect
            getOptionLabel={option =>
              `${option.division[0].toUpperCase() + option.division.slice(1)}`
            }
            renderOption={(option, { selected }) => (
              <div className={classes.selectOrderGroup}>
                <Box disabled={form.disabledDivs[option.id]}>
                  <Checkbox
                    icon={icon}
                    style={{ marginRight: 8 }}
                    color="primary"
                    checked={selected}
                    disabled={form.disabledDivs[option.id]}
                  />
                  {`${option.division[0].toUpperCase() +
                    option.division.slice(1)} - ${option.pending.toLocaleString()} Pzs.`}
                </Box>
              </div>
            )}
            onChange={(event, values) => {
              let divisions = [];
              let divisionsNames = [];
              let disabledDivs = [false, false, false, false, false];
              let divisionIds = [];
              values.forEach(value => {
                if (value.id < 3) {
                  divisions.push(value.id);
                }
                if (value.id === 3) {
                  divisions.push(3);
                  divisions.push(4);
                } else if (value.id === 4) {
                  divisions.push(5);
                } else if (value.id === 5) {
                  divisions.push(6);
                  divisions.push(7);
                  divisions.push(8);
                }
                divisionsNames.push(value.division[0].toUpperCase() + value.division.slice(1));
              });
              if (divisions.includes(6)) {
                divisions = [6, 7, 8];
                divisionsNames = ['Accesorios'];
                disabledDivs = [true, true, true, true, true, false];
              } else if (
                divisions.includes(1) ||
                divisions.includes(2) ||
                divisions.includes(3) ||
                divisions.includes(4) ||
                divisions.includes(5)
              ) {
                const indx1 = divisions.indexOf(6);
                const indx2 = divisions.indexOf(7);
                const indx4 = divisions.indexOf(8);
                const indx3 = divisionsNames.indexOf('Accesorios');
                if (indx1 > -1) {
                  divisions.splice(indx1, 1);
                }
                if (indx2 > -1) {
                  divisions.splice(indx2, 1);
                }
                if (indx4 > -1) {
                  divisions.splice(indx4, 1);
                }
                if (indx3 > -1) {
                  divisionsNames.splice(indx3, 1);
                }
                disabledDivs = [false, false, false, false, false, true];
              }
              getAllClassifications(divisions.join())
                .then(response => {
                  setClassifications(response);
                })
                .catch(error => {
                  console.log(error);
                  setClassifications([]);
                });
              getAllFamilies(divisions.join())
                .then(response => {
                  setFamilies(response);
                })
                .catch(error => {
                  console.log(error);
                  setFamilies([]);
                });

              getAllDepartments(divisions.join())
                .then(response => {
                  const divisions = response;
                  const departments = [];
                  divisions.forEach(division => {
                    division.departments.forEach(department => {
                      departments.push({
                        id: department.id,
                        department: department.name,
                        division: division.name,
                      });
                    });
                  });
                  setDepartments(departments);
                })
                .catch(error => {
                  console.log(error);
                  setDepartments([]);
                });
              setForm({ ...form, divisions, divisionsNames, disabledDivs });
              values.forEach(value => {
                  divisionIds.push(value.id);
              });
              getStyles(selectedOrderGroupId, (divisionIds.length>0) ? divisionIds : [0] )
                .then(response => {
                  setStyles(response);
                })
                .catch(error => {
                  console.log(error);
                  setStyles([]);
                });
            }}
            renderInput={params => (
              <TextField
                {...params}
                variant="outlined"
                label="Divisiones"
                placeholder="Seleccione una o mas divisiones"
                fullWidth
              />
            )}
          />
        </Box>

        <Box className={classes.selectFormOrderGroup}>
          <Autocomplete
            multiple
            id="styles-select"
            options={styles}
            disableCloseOnSelect
            getOptionLabel={option => `${option.style}`}
            onChange={(event, values) => {
              const excludedStyles = [];
              values.forEach(value => {
                excludedStyles.push(value.id);
              });
              setForm({ ...form, excludedStyles });
            }}
            renderOption={(option, { selected }) => (
              <div className={classes.selectOrderGroup}>
                <Box>
                  <Checkbox
                    icon={icon}
                    style={{ marginRight: 8 }}
                    color="primary"
                    checked={!selected}
                  />
                  {`${option.style} - ${option.pzas} Pzs.`}
                </Box>
              </div>
            )}
            renderInput={params => (
              <TextField
                {...params}
                variant="outlined"
                label="Excluir los estilos..."
                placeholder="Seleccione uno o más estilos a excluir"
                fullWidth
              />
            )}
          />
        </Box>

        <Box className={classes.selectFormOrderGroup}>
          <Autocomplete
            multiple
            id="classifications-select"
            options={classifications}
            disableCloseOnSelect
            getOptionLabel={option => `${option.jdaName.substring(7)}`}
            onChange={(event, values) => {
              const excludedClassifications = [];
              const excludedClassificationsNames = [];
              values.forEach(value => {
                excludedClassifications.push(value.id);
                excludedClassificationsNames.push(value.jdaName);
              });
              setForm({ ...form, excludedClassifications, excludedClassificationsNames });
            }}
            renderOption={(option, { selected }) => (
              <div className={classes.selectOrderGroup}>
                <Box>
                  <Checkbox
                    icon={icon}
                    style={{ marginRight: 8 }}
                    color="primary"
                    checked={!selected}
                  />
                  {`${option.jdaName.substring(7)}`}
                </Box>
              </div>
            )}
            renderInput={params => (
              <TextField
                {...params}
                variant="outlined"
                label="Excluir las clasificaciones..."
                placeholder="Seleccione una o más clasificaciones a excluir"
                fullWidth
              />
            )}
          />
        </Box>

        <Box className={classes.selectFormOrderGroup}>
          <Autocomplete
            multiple
            id="families-select"
            options={families}
            disableCloseOnSelect
            getOptionLabel={option => `${option.jdaName.substring(4)}`}
            onChange={(event, values) => {
              const excludedFamilies = [];
              const excludedFamiliesNames = [];
              values.forEach(value => {
                excludedFamilies.push(value.id);
                excludedFamiliesNames.push(value.jdaName);
              });
              setForm({ ...form, excludedFamilies, excludedFamiliesNames });
            }}
            renderOption={(option, { selected }) => (
              <div className={classes.selectOrderGroup}>
                <Box>
                  <Checkbox
                    icon={icon}
                    style={{ marginRight: 8 }}
                    color="primary"
                    checked={!selected}
                  />
                  {`${option.jdaName.substring(4)}`}
                </Box>
              </div>
            )}
            renderInput={params => (
              <TextField
                {...params}
                variant="outlined"
                label="Excluir las familias..."
                placeholder="Seleccione una o más familias a excluir"
                fullWidth
              />
            )}
          />
        </Box>

        <Box className={classes.selectFormOrderGroup}>
          <Autocomplete
            multiple
            id="departments-select"
            options={departments}
            disableCloseOnSelect
            getOptionLabel={option => `${option.department}`}
            groupBy={option => option.division}
            onChange={(event, values) => {
              const excludedDepartments = [];
              const excludedDepartmentsNames = [];
              values.forEach(value => {
                excludedDepartments.push(value.id);
                excludedDepartmentsNames.push(value.department);
              });
              setForm({ ...form, excludedDepartments, excludedDepartmentsNames });
            }}
            renderOption={(option, { selected }) => (
              <div className={classes.selectOrderGroup}>
                <Box>
                  <Checkbox
                    icon={icon}
                    style={{ marginRight: 8 }}
                    color="primary"
                    checked={!selected}
                  />
                  {option.department}
                </Box>
              </div>
            )}
            renderInput={params => (
              <TextField
                {...params}
                variant="outlined"
                label="Excluir los departamentos..."
                placeholder="Seleccione uno o más departamentos a excluir"
                fullWidth
              />
            )}
          />
        </Box>

        {/* <Box className={classes.selectFormOrderGroup}>
          <Autocomplete
            multiple
            id="classes-select"
            options={productClasses}
            disableCloseOnSelect
            getOptionLabel={option =>
              `${option.jdaName}`
            }
            onChange={(event, values) => {
              let excludedClasses = [];
              let excludedClassesNames = [];
              values.forEach(value => {
                excludedClasses.push(value.id);
                excludedClassesNames.push(value.jdaName);
              })
              setForm({ ...form, excludedClasses, excludedClassesNames });
            }}
            renderOption={(option, { selected }) => (
              <div className={classes.selectOrderGroup}>
                <Box>
                  <Checkbox
                    icon={icon}
                    style={{ marginRight: 8 }}
                    color="primary"
                    checked={!selected}
                  />
                  {option.jdaName}
                </Box>
              </div>
            )}
            renderInput={params => (
              <TextField
                {...params}
                variant="outlined"
                label="Excluir las clases..."
                placeholder="Seleccione una o más clases a excluir"
                fullWidth
              />
            )}
          />
        </Box>

        <Box className={classes.selectFormOrderGroup}>
          <Autocomplete
            multiple
            id="types-select"
            options={types}
            disableCloseOnSelect
            getOptionLabel={option =>
              `${option.jdaName}`
            }
            onChange={(event, values) => {
              let excludedTypes = [];
              let excludedTypesNames = [];
              values.forEach(value => {
                excludedTypes.push(value.id);
                excludedTypesNames.push(value.jdaName);
              })
              setForm({ ...form, excludedTypes, excludedTypesNames });
            }}
            renderOption={(option, { selected }) => (
              <div className={classes.selectOrderGroup}>
                <Box>
                  <Checkbox
                    icon={icon}
                    style={{ marginRight: 8 }}
                    color="primary"
                    checked={!selected}
                  />
                  {option.jdaName}
                </Box>
              </div>
            )}
            renderInput={params => (
              <TextField
                {...params}
                variant="outlined"
                label="Excluir los tipos..."
                placeholder="Seleccione uno o más tipos a excluir"
                fullWidth
              />
            )}
          />
        </Box> */}

        <Box className={classes.selectFormOrderGroup}>
          <Autocomplete
            multiple
            id="routes-select"
            options={routes}
            disableCloseOnSelect
            getOptionLabel={option => `${option.name} - ${option.description}`}
            onChange={(event, values) => {
              const excludedRoutes = [];
              const excludedRoutesNames = [];
              values.forEach(value => {
                excludedRoutes.push(value.id);
                excludedRoutesNames.push(value.name);
              });
              setForm({ ...form, excludedRoutes, excludedRoutesNames });
            }}
            renderOption={(option, { selected }) => (
              <div className={classes.selectOrderGroup}>
                <Box>
                  <Checkbox
                    icon={icon}
                    style={{ marginRight: 8 }}
                    color="primary"
                    checked={!selected}
                  />
                  {`${option.name} - ${option.description}`}
                </Box>
              </div>
            )}
            renderInput={params => (
              <TextField
                {...params}
                variant="outlined"
                label="Excluir las rutas..."
                placeholder="Seleccione una o más rutas a excluir"
                fullWidth
              />
            )}
          />
        </Box>

        <Box className={classes.selectFormOrderGroup}>
          <Autocomplete
            multiple
            id="stores-select"
            options={stores}
            disableCloseOnSelect
            getOptionLabel={option => `${option.number}`}
            onChange={(event, values) => {
              const excludedStores = [];
              const excludedStoresNames = [];
              values.forEach(value => {
                excludedStores.push(value.id);
                excludedStoresNames.push(value.number);
              });
              console.log(excludedStores);
              setForm({ ...form, excludedStores, excludedStoresNames });
            }}
            renderOption={(option, { selected }) => (
              <div className={classes.selectOrderGroup}>
                <Box>
                  <Checkbox
                    icon={icon}
                    style={{ marginRight: 8 }}
                    color="primary"
                    checked={!selected}
                  />
                  {`${option.number} - ${option.name}`}
                </Box>
              </div>
            )}
            renderInput={params => (
              <TextField
                {...params}
                variant="outlined"
                label="Excluir las tiendas..."
                placeholder="Seleccione una o más tiendas a excluir"
                fullWidth
              />
            )}
          />
        </Box>
        <Box className={classes.selectFormOrderGroup}>
          <Autocomplete
            multiple
            id="stores-select"
            options={providers}
            disableCloseOnSelect
            getOptionLabel={option => `${option.jdaId} - ${option.jdaName}`}
            onChange={(event, values) => {
              const excludedProviders = [];
              values.forEach(value => {
                excludedProviders.push(value.id);
              });
              setForm({ ...form, excludedProviders });
            }}
            renderOption={(option, { selected }) => (
              <div className={classes.selectOrderGroup}>
                <Box>
                  <Checkbox
                    icon={icon}
                    style={{ marginRight: 8 }}
                    color="primary"
                    checked={!selected}
                  />
                  {`${option.jdaId} - ${option.jdaName}`}
                </Box>
              </div>
            )}
            renderInput={params => (
              <TextField
                {...params}
                variant="outlined"
                label="Excluir los proveedores..."
                placeholder="Seleccione una o más proveedores a excluir"
                fullWidth
              />
            )}
          />
        </Box>
        <Box className={classes.selectFormOrderGroup}>
          <TextField
            name="description"
            label="Descripción"
            margin="normal"
            variant="outlined"
            fullWidth
            disabled={dataObject !== 0}
            value={form.description}
            onChange={handleChange}
          />
        </Box>
        <Grid item xs={12}>
          <Chip label={`SKUs: ${calc.skus}`} xs={4} lg={4} />
          <Chip label={`Pzs: ${calc.pieces}`} xs={4} lg={4} />
          <Chip label={`Tiendas: ${calc.stores}`} xs={4} lg={4} />
        </Grid>
        <Box className={classes.bottomSpace}>
          <Button
            fullWidth
            variant="contained"
            color="secondary"
            style={{ borderRadius: 30 }}
            size="small"
            onClick={handlePreCalculate}
          >
            Precalcular Ola
          </Button>
        </Box>
        <Box component="form" className={classes.form} onSubmit={handleSubmit}>
          <Box />
          <Box className={classes.bottomSpace}>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              type="submit"
              disabled={disableFormControl()}
              style={{ borderRadius: 30 }}
              size="small"
            >
              {dataObject === 0 ? 'Guardar Ola' : 'Actualizar Ola'}
            </Button>

            {isLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
            {formOpen && closeClick === '' && initData()}
            {!formOpen && closeClick !== '' && clearData()}
          </Box>
        </Box>
      </Drawer>

      <SnackBarMessage
        open={form.policyAccessError}
        message={form.roleAccessMessage}
        onClose={() => setForm({ ...form, policyAccessError: false })}
        variant="warning"
      />
    </Box>
  );
}

WaveForm.defaultProps = {
  refreshTable: null,
  dataObject: 0,
  canUpdateWave: false,
  canAddShopToRoute: false,
  canRemoveShopFromRoute: false,
};

WaveForm.propTypes = {
  refreshTable: PropTypes.func,
  formOpen: PropTypes.bool.isRequired,
  toggleForm: PropTypes.func.isRequired,
  dataObject: PropTypes.number,
  canUpdateWave: PropTypes.bool,
};
