import React, { useState, useEffect } from 'react';

// Components
import {
    sortableContainer,
    sortableElement,
} from 'react-sortable-hoc';
import "../../Priority/styles/list.css";
import { useSnackbar } from 'notistack';
import {
    Drawer,
    TextField,
    Typography,
    Box,
    Button,
    CircularProgress,
    IconButton, AppBar, Toolbar, Slide,
} from '@material-ui/core';

import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
    Close as CloseIcon,
    Edit as EditIcon,
} from '@material-ui/icons';
import CardMembershipIcon from '@material-ui/icons/CardMembership';
import {AppStyles} from "../../../AppStyles";

import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import FormGroup from '@material-ui/core/FormGroup';

// Data Api
import {
    createSubscription,
    getSubscriptionById,
    updateSuscriptionById
} from "../../../api/UserReportService/UserReportServiceApi";

const drawerWidth = 900;
const useStyles = makeStyles(theme => ({
    container: {
        width: drawerWidth,
    },
    containerPaper: {
        width: drawerWidth,
        padding: 20,
    },
    header: {
        display: 'flex',
        alignItems: 'center',
    },
    headerIcon: {
        fontSize: 35,
        marginRight: theme.spacing(1),
    },
    headerCloseButton: {
        padding: theme.spacing(1),
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        justifyContent: 'space-between',
    },
    buttonProgress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    selectForm: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },
    selectStyle: {
        minWidth: 195,
        maxWidth: 195,
    },
    pickerStyle: {
        minWidth: 225,
        maxWidth: 225,
    },
    selectAddButton: {
        padding: theme.spacing(1),
        marginTop: theme.spacing(1),
    },
    addRolesPolices: {
        margin: theme.spacing(4),
    },
    card: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    cardContent: {
        padding: theme.spacing(2),
    },
    formCardInput: {
        display: 'flex',
        flexGrow: 1,
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    bottomSpace: {
        paddingTop: theme.spacing(2),
    },
}));

const initialState = {
    subscriptionId: 0,
    name: '',
    email: '',
    subscrite_to: [],
    waves: false,
    orders: false,
    active: false,
    aws: false
};

const stateInit = {
    enabled: true,
};

function UserReportAdminForm({
                       formOpen,
                       toggleForm,
                       refreshTable,
                       dataObject,
                   }) {
    const appStyles = AppStyles();
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const [isLoading, setIsLoading] = useState(false);
    const [form, setForm] = useState(initialState);
    const [closeClick, setCloseClick] = useState('');
    const [editForm, setEditForm] = useState(false);
    const [state, setState] = useState(stateInit);
    const messageError = (message, variant) => {
        enqueueSnackbar(message, {
            variant,
        });
    };

    useEffect(() => {
        //hasAuthority(Permissions.ResetPasswordByEmail, setResetPasswordByEmail);
    }, []);

    const handleChange = event => {
        let value = event.target.checked;

        setState({ ...state, [event.target.name]: value });
        setForm({
            ...form,
            subscriptionId: dataObject,
            [event.target.name]: event.target.value,
        });
        setCloseClick(true);
    };

    const handleChangeCheckedWave = (event) => {
        setForm({ ...form, waves: event.target.checked });
    };

    const handleChangeCheckedOrder = (event) => {
        setForm({ ...form, orders: event.target.checked });
    };

    const handleChangeActive = (event) => {
        setForm({ ...form, active: event.target.checked });
    };

    const handleChangeAws = (event) => {
        setForm({ ...form, aws: event.target.checked });
    };


    const handleSubmit = event => {
        event.preventDefault();
        setIsLoading(true);

        if (dataObject === 0) {

            let subscription = [];
            if (form.waves === true) {
                subscription.push("report_waves");
            }
            if (form.orders === true) {
                subscription.push("order_group");
            }
            createSubscription(
                form.name,
                form.email,
                form.active,
                form.aws,
                subscription
            )
                .then(response => {
                    toggleForm();
                    setForm(initialState);
                    setIsLoading(false);
                    messageError('Suscripción Creada', 'success');
                    refreshTable();
                }) .catch(error => {
                if (error !== undefined) {
                    setForm({
                        ...form,
                    });
                } else {
                    messageError('Servicio no disponible.', 'warning');
                    refreshTable();
                }
                setIsLoading(false);
            });


        } else {
            let subscription = [];
            if (form.waves === true) {
                subscription.push("report_waves");
            }
            if (form.orders === true) {
                subscription.push("order_group");
            }

            updateSuscriptionById(
                form.subscriptionId,
                form.name,
                form.email,
                form.active,
                form.aws,
                subscription
            )
                .then(() => {
                    toggleForm();
                    setForm(initialState);
                    setIsLoading(false);
                    messageError('Suscripción Actualizada', 'success');
                    refreshTable();
                })
                .catch(error => {
                    if (error !== undefined) {
                        setForm({
                            ...form,
                        });
                    } else {
                        refreshTable();
                        messageError('Servicio no disponible.', 'warning');
                    }
                    setIsLoading(false);
                });
        }
    };

    const clearData = () => {
        setCloseClick('');
        setEditForm(false);
        setState(stateInit);
        setForm({
            ...form,
        });
    };

    const initData = () => {
        if (dataObject === 0) {
            setForm({
                ...form,
                subscriptionId: 0,
                name: '',
                email: '',
                subscrite_to: [],
                waves: false,
                orders: false,
                active: false,
                aws: false,
            });
        } else {
            getSubscriptionById(dataObject)
                .then(response => {
                    let array = response.subscrited_to;
                    let subscritions = JSON.parse(array);
                    let waves = false;
                    let orders = false;

                    subscritions.map(function(subs) {
                        if (subs === "order_group") {
                            orders = true;
                        }
                        if (subs === "report_waves") {
                            waves = true;
                        }
                    });
                    setForm({
                        subscriptionId: response.id,
                        name: response.name,
                        email: response.email,
                        subscrite_to: subscritions,
                        active: response.active,
                        aws: response.aws,
                        waves: waves,
                        orders: orders
                    });
                })
                .catch(() => {
                    return Promise.resolve(setForm({}));
                });

        }
        setCloseClick(false);
        dataObject !== 0 ? setEditForm(false) : setEditForm(true);

    };

    const disableFormControl = () => {
        if (isLoading) {
            return true;
        }
        if (dataObject === 0) {
            return false;
        }
        if (dataObject !== 0 && editForm) {
            return false;
        }
        return true;
    };

    return (
        <Box>
            <Drawer
                anchor="right"
                open={formOpen}
                onClose={toggleForm}
                className={classes.container}
                classes={{ paper: classes.containerPaper }}
            >
                <Box className={classes.header}>
                    <CardMembershipIcon color="primary" className={classes.headerIcon} />
                    <Box flexGrow={1}>
                        <Typography variant="h5" color="primary">
                            {dataObject === 0 ? 'Nueva Suscripción' : 'Actualizar Suscripción'}
                        </Typography>
                    </Box>
                    {dataObject !== 0 && (
                        <IconButton
                            color="primary"
                            className={classes.headerCloseButton}
                            onClick={() => {
                                setEditForm(!editForm);
                            }}
                        >
                            <EditIcon />
                        </IconButton>
                    )}
                    <IconButton
                        color="secondary"
                        className={classes.headerCloseButton}
                        onClick={() => {
                            toggleForm();
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </Box>
                <Box component="form" className={classes.form} onSubmit={handleSubmit}>
                    <Box>
                        <TextField
                            size="small"
                            required
                            name="name"
                            label="Nombre"
                            margin="normal"
                            variant="outlined"
                            fullWidth
                            disabled={disableFormControl()}
                            value={form.name}
                            onChange={handleChange}
                        />
                        <TextField
                            size="small"
                            required
                            name="email"
                            label="Email"
                            margin="normal"
                            variant="outlined"
                            fullWidth
                            disabled={disableFormControl()}
                            value={form.email}
                            onChange={handleChange}
                        />
                        <FormGroup>
                            <FormControlLabel
                                control={<Switch checked={form.active} onChange={handleChangeActive} name="active" disabled={disableFormControl()} />}
                                label="Activo"
                            />
                            <FormControlLabel
                                control={<Switch checked={form.aws} onChange={handleChangeAws} name="aws" disabled={disableFormControl()} />}
                                label="Verificado en AWS"
                            />
                        </FormGroup>
                        <Typography variant="subtitle1" gutterBottom>
                            Suscrito a:
                        </Typography>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={form.waves}
                                    onChange={handleChangeCheckedWave}
                                    name="wave"
                                    color="primary"
                                    disabled={disableFormControl()}
                                />
                            }
                            label="Olas"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={form.orders}
                                    onChange={handleChangeCheckedOrder}
                                    name="order"
                                    color="primary"
                                    disabled={disableFormControl()}
                                />
                            }
                            label="Orden de surtido"
                        />
                    </Box>
                    <Box className={classes.bottomSpace}>
                        <Button
                            fullWidth
                            variant="contained"
                            color="primary"
                            type="submit"
                            disabled={disableFormControl()}
                            style={{position: 'static', marginLeft: 'auto', marginRight: 'auto', left: 4, right: 4, textAlign: 'center'}}
                            size="small"
                        >
                            {dataObject === 0 ? 'Guardar Suscripción' : 'Actualizar Suscripción'}
                        </Button>
                        {isLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
                        {formOpen && closeClick === '' && initData()}
                        {!formOpen && closeClick !== '' && clearData()}
                    </Box>
                </Box>
            </Drawer>
        </Box>
    );
}

UserReportAdminForm.defaultProps = {
    refreshTable: null,
    dataObject: 0,
};

UserReportAdminForm.propTypes = {
    refreshTable: PropTypes.func,
    formOpen: PropTypes.bool.isRequired,
    toggleForm: PropTypes.func.isRequired,
    dataObject: PropTypes.number,
};

export default UserReportAdminForm;
