import { apiClientBearerToken } from '../ApiClientConfig';
import Logger from '../../Logger';
import {createWave} from "../WaveServices/WavesApi";

const Log = new Logger('src/api/SupplyOrderService/SupplyOrderApi.js');

const ORDER_GROUPS_PATH = '/ordergroups';
const ORDERS_PATH = '/orders';


export const getStoresBySupplyOrder = (
    active?: Boolean,
    order_group: Number
): Promise<Array<Object>> => {
    return apiClientBearerToken()
        .get(`${ORDER_GROUPS_PATH}/getstores`, {
            params: {
                active : active,
                order_group : order_group
            },
        })
        .then(response => {
            return Promise.resolve(response.data);
        })
        .catch(error => {
            Log.error(
                getStoresBySupplyOrder.name,
                error.message,
                error.response ? error.response : error,
                !(error.response && error.response.status === 404),
            );
            return Promise.reject();
        });
};

export const updateOrder = (
    orderId: Number,
    storeNumber: Number,
    desactive? : Boolean
    ): Promise<Object> => {
    return apiClientBearerToken()
        .post(`${ORDER_GROUPS_PATH}/updateorder`, {
            id: orderId,
            number: storeNumber,
            desactive
        })
        .then(response => {
            return Promise.resolve(response.data);
        })
        .catch(error => {
            if (error.response) {
                return Promise.reject(error.response.data.errors);
            }
            Log.error(createWave.name, error.message, error.response ? error.response : error);
            return Promise.reject();
        });
};

export const updateOrderByID = (idOrder: Number, slots: Number, wave: Number): Promise<Object> => {
    return apiClientBearerToken()
        .post(`${ORDER_GROUPS_PATH}/updateslots/`, {
            id: idOrder,
            slots,
            wave : wave,
        })
        .then(response => {
            return Promise.resolve(response.data);
        })
        .catch(error => {
            if (error.response) {
                return Promise.reject(error.response.data.errors);
            }
            Log.error(updateOrderByID.name, error.message, error.response ? error.response : error);
            return Promise.reject();
        });
};

export const getOrderGroupReports = (order_group: Number, route: String): Promise<Array<Object>> => {
  return apiClientBearerToken()
    .get(`${ORDER_GROUPS_PATH}${route}`, {
      params: {
        order_group,
      },
      responseType: 'blob',
    })
    .then(response => {
      return Promise.resolve(response);
    })
    .catch(error => {
      Log.error(
        getOrderGroupReports.name,
        error.message,
        error.response ? error.response : error,
        !(error.response && error.response.status === 404),
      );
      return Promise.reject();
    });
};


export const mergeSupplyOrders = (
    order_one: Number,
    order_two: Number,
): Promise<Object> => {
    return apiClientBearerToken()
        .post(`${ORDERS_PATH}/mergeSupplyOrder`, {
            supply_order_one: order_one,
            supply_order_two: order_two
        })
        .then(response => {
            return Promise.resolve(response.data);
        })
        .catch(error => {
            if (error.response) {
                return Promise.reject(error.response.data.errors);
            }
            Log.error(mergeSupplyOrders.name, error.message, error.response ? error.response : error);
            return Promise.reject();
        });
};