import React from 'react';
import { Box, Typography, CardMedia } from '@material-ui/core';

// Styles
import LoginStyles from '../../Login/styles/LoginStyles';
import { AppStyles } from '../../../AppStyles';

function HomePriorities() {
    const appStyles = AppStyles();
    const loginStyles = LoginStyles();

    return (
        <Box id="app-home-page" component="main" className={appStyles.pageRoot}>
            <Box className={loginStyles.divImage}>
                <CardMedia className={loginStyles.imageCard} image="/logo512.png" title="CCP Logo" />
            </Box>
            <Typography align="center" variant="h2">
                Catalogos
            </Typography>
        </Box>
    );
}

export default HomePriorities;
